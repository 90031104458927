import React, { Component } from 'react';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import billingIcon from '../../assets/images/sidebar-icons/billing-icon.svg';
import usersIcon from '../../assets/images/sidebar-icons/user.svg';
import roleIcon from '../../assets/images/sidebar-icons/role.svg';
import apiIcon from '../../assets/images/icons/api-icon.svg';
import User from '../Settings/users';
import Roles from '../Settings/roles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification } from '../../helpers';

import YourPlan from './YourPlan';
import { Actions } from 'ReduxStore/Actions';
import { generateUrl, languageCode } from '../../helpers/common';
import ApiKey from './ApiKey';
import { Navigate, Link } from 'react-router-dom';
import withCustomRouter from '../../Common/withCustomRouter';


class Settings extends Component {
  constructor(props) {
    super(props);
    this.permData = {};
    this.tabs = ["users", "roles", "yourPlan", "api-key"];
    const userData = localStorage.getItem('user_details');
    const user = JSON.parse(userData);  
    const seg = window.location.href.split('/');
    const mid = seg[seg.length - 1];
    this.state = {
      userDetail:user,
      perms: user.permissions,
      redirect: false,
      activeTabIndex: this.tabs.indexOf(mid),
      language: user.language,
      langObj: {},
    };
  }

  async componentDidMount() {
    this.permData = this.getPreparePermission(this.state.perms); 
    let isExists = true;
    if (this.state.userDetail?.role == 1) {
      isExists = false;
    } 
    if (this.permData && this.state.userDetail?.role > 1) {
      Object.keys(this.permData).forEach((data) => {
        if (['general', 'users', 'roles & permission', 'billing', 'customization'].indexOf(data.toLowerCase()) !== -1) {
          isExists = false;
        }
      }); 
        if (isExists) {
        createNotification('error', "Unauthorized to access this resource");
      }  
    }

    setTimeout(() => {
      this.setState({ redirect: isExists });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.users.getUserDetailsSuccess &&
      !prevProps.users.getUserDetailsSuccess &&
      this.props.users.message !== prevProps.users.message
    ) {
      const userData = localStorage.getItem('user_details');
      const user = JSON.parse(userData);
      const languageObject = languageCode(user.language);
      this.setState({ langObj: languageObject });
    }
  }

  getPreparePermission(state) {
    let perms = {};
    if (Array.isArray(state)) {
      state.forEach((data) => {
        perms[data.permission_name] = true;
      });
    }
    return perms;
  }

  handleTabChange = (index) => {
    this.setState({ activeTabIndex: index });
    const tab = this.tabs[index];
    this.props.navigate(generateUrl(`/${tab}`));
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to="/my-events" />;
    }
    const { activeTabIndex } = this.state;
    return (
      <DashboardLayout title="Settings" pageTitle="Settings">
        <div className="dashboard-containor">
          <div className="container-with-sidebar">
            <h3 className='heading'>
              {this.props.users.languageStateForRedux?.dropdowns?.common?.settings}
            </h3>

            <Tabs selectedIndex={activeTabIndex} onSelect={this.handleTabChange}>
              <div className="project-sidebar">
                <TabList>
                  <Tab value='1'>
                    <img src={usersIcon} />
                    <Link to={'/users'}>
                      {this.props.users.languageStateForRedux?.buttons?.teams?.users}
                    </Link>
                  </Tab>
                  <Tab value='2'>
                    <img src={roleIcon} />
                    <Link to={'/roles'}>
                      {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.leftHeading}
                    </Link>
                  </Tab>
                  <Tab value='3'>
                    <img src={billingIcon} />
                    <Link to={'/yourPlan'}>
                      {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.leftHeading}
                    </Link>
                  </Tab>
                  <Tab value='4'>
                    <img src={apiIcon} />
                    <Link to={'/api-key'}>API Key</Link>
                  </Tab>
                </TabList>
              </div>

              <div className="container-sidebar-right">
                <TabPanel value='1'>
                  <User />
                </TabPanel>
                <TabPanel value='2'>
                  <Roles />
                </TabPanel>
                <TabPanel value='3'>
                  <YourPlan navigate={this.props.navigate} location={this.props.location} />
                </TabPanel>
                <TabPanel value='4'>
                  <ApiKey />
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.Permission,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDetailsRequest: Actions.getUserDetailsRequest,
      changeLangForUserRequest: Actions.changeLangForUserRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(Settings));
