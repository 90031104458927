import { Message } from './gmailView';

import { Box, Button, Stack, Tooltip } from '@mui/material';
import ReactQuill from 'react-quill';
import Grid from '@mui/material/Grid2';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//@ts-ignore
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
//@ts-ignore
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';
import SendIcon from '@mui/icons-material/Send';

import { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
    i: number,
    mail: Message,
    handleSendOrDraft: (status: string, i: any, mail: Message, editorText: string, fileNames: File[], removedAttachments: string[]) => Promise<void>,
    handleDiscard: (i: any, mail: any) => Promise<void>,
    gmailId: string,
    downloadAttachment: (messageId: any, filename: any, attachmentId: any) => Promise<void>,
    modules: any,
}

export default function DraftComposeInThread({i, mail, handleSendOrDraft, handleDiscard, gmailId, downloadAttachment, modules}: Props) {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [editorText, setEditorText] = useState<string>('');
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<File[]>([]);

  useEffect(() => {
    setEditorText(mail.snippet || '')
  }, [])

  console.log("DraftComposeInThread=============editorText", editorText)
  console.log("DraftComposeInThread=============removedAttachments", removedAttachments)


  const handleEditorText = (val: string, mail) => {
    setEditorText(val);
  };

  const handleDelete = (fileToDelete: File, i) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((file) => file.name !== fileToDelete.name)
    );
  };

  const handleAttachment = (i) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', ''); // Enable multiple file selection
    input.click();

    input.onchange = () => {
      const files = Array.from(input.files || []); // Convert the FileList to an array
      if (files.length > 0) {
        console.log('Selected files:', files);
        setFileNames((prev) => [...prev, ...files]);
      }
    };
  };

  const handleRemovedAttachments = (partId) => {
    setRemovedAttachments((prev) => [...prev, partId]);
  };

  const loadReactQuill = (i: number, mail?: Message) => {
    return (
      <Box ref={boxRef}>
        <ReactQuill
          theme="snow"
          value={editorText}
          onChange={(val) => handleEditorText(val, mail)}
          modules={modules}
        />
        <Stack direction={'row'} mt={2} justifyContent={'space-between'}>
          <Box className={'attachment-container'}>
            <Grid container spacing={1} className="attachments">
              {Array.isArray(fileNames) &&
                fileNames?.map((file) => {
                  const imageSizeInBytes = file?.size;
                  let convertedFileSizeKbMb;
                  if (imageSizeInBytes > 1024 * 1024) {
                    // Convert to MB if file size is larger than 1024 KB
                    convertedFileSizeKbMb = `${(
                      imageSizeInBytes /
                      (1024 * 1024)
                    ).toFixed(2)} MB`;
                  } else {
                    convertedFileSizeKbMb = `${(
                      imageSizeInBytes / 1024
                    ).toFixed(2)} KB`;
                  }
                  return (
                    <Grid>
                      <Box className="attachment-file">
                        <Box display={'flex'} alignItems={'center'}>
                          <Box className="file-icon">
                            <img src={DocIcon} alt="file-icon" />
                          </Box>
                          <Box>
                            <Box className="file-name">{file.name}</Box>
                            <Box className="file-size">
                              Size: {convertedFileSizeKbMb}
                            </Box>
                          </Box>
                        </Box>
                        <Box ml={1} className="options">
                          <ClearIcon
                            onClick={() => handleDelete(file, i)}
                            style={{ fontSize: '18px' }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              <Grid>
                <Box
                  className="attachment-file upload"
                  onClick={() => handleAttachment(i)}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <Box className="file-icon" p={1}>
                      <img src={AddFile} alt="add-file" />
                    </Box>
                    <Box>
                      <Box className="file-name secondary-text">
                        Attach file
                      </Box>
                      {/* <Box className="file-size">10 Mb Max.</Box> */}
                    </Box>
                  </Box>
                </Box>
                {/* <Stack
                    spacing={1}
                    direction={'row'}
                    mt={1}
                    className="attachments"
                >
                  {draftMessage !==undefined ? renderAttachments(draftMessage) : null}
                </Stack> */}
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={1}>
            <Grid>
              <Button
                onClick={() => {
                    handleSendOrDraft('draft', i, mail, editorText, fileNames, removedAttachments)
                    setFileNames([])
                    setRemovedAttachments([])
                }
                }
                variant="outlined"
                color="primary"
                size="small"
              >
                Draft
              </Button>
            </Grid>
            <Grid>
              <Button
                onClick={() => {
                    handleDiscard(i, mail)
                    setFileNames([])
                    setRemovedAttachments([])
                }}
                variant="outlined"
                color="primary"
                size="small"
              >
                Discard
              </Button>
            </Grid>
            <Grid>
              <Button
                onClick={() => {
                    handleSendOrDraft('send', i, mail, editorText, fileNames, removedAttachments)
                    setFileNames([])
                    setRemovedAttachments([])
                }}
                variant="contained"
                color="primary"
                size="small"
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    );
  };

  const renderAttachments = (
    gmail,
    showRemoveAttachmentIcon = false,
    indexFromGmail,
  ) => {
    const { payload } = gmail;
    const { parts } = payload;

    if (
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (
          i > 0 &&
          !removedAttachments?.includes(part.partId)
        ) {
          return (
            <Box className="attachment-file" key={part.partId}>
              <Box display={'flex'} alignItems={'center'}>
                <Box className="file-icon">
                  <img src={DocIcon} alt="file-icon" />
                </Box>
                <Box>
                  <Box className="file-name">{part.filename}</Box>
                  <Box className="file-size">{part.body.size}</Box>
                </Box>
              </Box>
              <FileDownloadIcon
                onClick={() =>
                  downloadAttachment(
                    gmail.id,
                    part.filename,
                    part.body.attachmentId,
                  )
                }
                style={{ fontSize: '18px' }}
                className="options-button"
              />
              {showRemoveAttachmentIcon && (
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() =>
                      handleRemovedAttachments(part.partId)
                    }
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              )}
            </Box>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  return (
    <>
      {loadReactQuill(i, mail)}
      <Box className={'attachment-container'} mt={1}>
        <Grid container spacing={1} className="attachments">
          {renderAttachments(mail, true, i)}
        </Grid>
      </Box>
    </>
  );
}
