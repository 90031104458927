//@ts-nocheck
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Stack from '@mui/material/Stack';
import { Box, Grid } from '@mui/material';
import GroupIcon from '../../../../src/assets/images/icons/contact-group-icon.svg';
import SmartGroupIcon from '../../../../src/assets/images/icons/smart-group-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import { AudienceChild, AudienceParent, AudienceState, UnassignContacts } from 'ReduxStore/Reducers/opportunity/audience.reducer';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import { Modal } from 'react-responsive-modal';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AddOrEditAudience from './AddOrEditAudience';
import { FetchAudienceQueryParams } from './ContactGroup';
import { Actions } from 'ReduxStore/Actions';
import { ContactState } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import SmartGroup from './SmartGroup';
import Loader from 'Components/Loader/loader'; 

import { useNavigate, useLocation } from 'react-router-dom';
import { generateUrl } from 'helpers/common';

const useStyles = makeStyles({
  root: {
    // height: 216,
    // flexGrow: 1,
    // maxWidth: 400,
  },
});

type Props = {
  editData?: AudienceChild;
  setFilterBy: React.Dispatch<React.SetStateAction<string[]>>;
  // setGroupClick:React.Dispatch<React.SetStateAction<boolean>>;
  setGroupIdForAdd: React.Dispatch<React.SetStateAction<string>>;
  setGroupClickObject: React.Dispatch<React.SetStateAction<any>>;
  groupClickObject: any;
  getData: (data: any) => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;

};

type GroupsDetail = {
  _id: string; // or _id if that's the actual field name
  audienceName: string;
  // Add other properties as needed
};

const GroupsTreeView: React.FC<Props> = ({
  setFilterBy,
  // setGroupClick,
  groupClickObject,
  setGroupIdForAdd,
  setGroupClickObject,
  getData,
  setShowAddNew
}) => {
  const { loading, audienceList, unassignContactsList, assignContactListSuccess, totalUnassignContact } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
  const {
    assignOrUnassignOpportunityContactsSuccess,
  } = useSelector((state: any) => state.OpportunityContacts) as ContactState;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [expandedNodes, setExpandedNodes] = useState<{ [key: string]: any[]; }>({});
  const [expanded, setExpanded] = useState<string[]>([]);
  const [assign, setAssign] = useState<boolean>(false);
  const [addOrEditAudience, setAddOrEditAudience] = useState<boolean>(false);
  const [editData, setEditData] = useState<AudienceParent | undefined>({} as AudienceParent);
  const [groupId, setGroupId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [order, setOrder] = useState<string>('desc');
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [loadMoreNumber, setLoadMoreNumber] = useState(0);
  const [increaseLoadMoreBy, setIncreaseLoadMoreBy] = useState(10); // Assuming an initial value
  const [disableLoadMorePoints, setDisableLoadMorePoints] = useState<boolean>(false);
  const [totalUnassignContacts, setTotalUnassignContacts] = useState<number>(totalUnassignContact);
  const [audienceIds, setAudienceIds] = useState<string[]>([]);
  const [audienceIdsTemp, setAudienceIdsTemp] = useState<string[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [contacts, setContacts] = useState<Array[]>([]);
  const [action, setAction] = useState<string>('');
  const [audienceName, setAudienceName] = useState<string>('');
  const [openAddSmartGroup, setOpenAddSmartGroup] = useState<boolean>(false);
  const [groupsDetail, setGroupsDetail] = useState<GroupsDetail | undefined>(undefined);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleNodeExpand = (node: AudienceChild) => {
    if (node.isSmartGroup) {
      setAudienceIds([node.id]);
      setAudienceIdsTemp([node.id]);
      setAudienceName(node.audienceName);
      setGroupClickObject({ groupClick: true, isSmartGroup: true, groupId: node.id });
      setGroupIdForAdd(node.id);
      setShowAddNew(false);
    } else {
      setFilterBy([node.id]);
      setAudienceIds([node.id]);
      setAudienceIdsTemp([node.id]);
      setAudienceName(node.audienceName);
      setGroupClickObject({ groupClick: true, isSmartGroup: false, groupId: node.id });
      setGroupIdForAdd(node.id);
      setShowAddNew(false);
    } 
    navigate(generateUrl(`/opportunity/contacts`)); 
  };

  useEffect(() => {
    getData({ openAddSmartGroup: openAddSmartGroup });

  }, [openAddSmartGroup]);

  useEffect(() => {
    if (assignOrUnassignOpportunityContactsSuccess) {
      setAudienceName('');
      handleClose();
      setFilterBy(audienceIdsTemp);
    }

  }, [assignOrUnassignOpportunityContactsSuccess]);

  const queryParams: FetchAudienceQueryParams = {
    pageNo,
    limit,
    sortBy,
    order,
    searchText,
  };

  useEffect(() => {
    if (assignContactListSuccess) {
      setTotalUnassignContacts(totalUnassignContact);
      if (unassignContactsList.length > 0) {
        if (searchText.length > 0) {
          setContacts(unassignContactsList);
        } else {
          setContacts(prevContacts => [...prevContacts, ...unassignContactsList]);
        }
      } else {
        setContacts([]);
      }
    }
  }, [assignContactListSuccess]);

  useEffect(() => {
    if (groupId) {
      assignContactList();
    }
  }, [groupId,
    pageNo,
    limit,
    sortBy,
    order,
    searchText,]);

  const assignContactList = () => {
    const data = {
      ...queryParams,
      groupId: groupId,
      reqType: "assignContacts",
      searchText: searchText,
    };

    dispatch(Actions.assignContactListRequest(data));
  };

  const openAsignModel = (event: React.MouseEvent, nodeId: string, action: string, nodes: AudienceParent) => {
    setGroupsDetail([{ _id: nodes.id, audienceName: nodes.audienceName }]);
    setAction(action);
    // event.stopPropagation();
    setGroupId(nodeId);
    setAssign(true);
  };

  const handleClose = () => {
    setAction('');
    setAssign(false);
    setGroupId('');
    setContacts([]);
    setTotalUnassignContacts(0);
    setAudienceIds([]);
    setSelectedCheckboxes([]);
    setSearchText('');
    setSearchTextTemp('');
  };


  const handleRename = (event: React.MouseEvent, nodes: AudienceChild) => {
    event.stopPropagation();
    addOrEditAudienceModal(nodes);
    setGroupClickObject({ groupClick: true, isSmartGroup: false, groupId: nodes.id });
  };

  const handleAddGroup = (event: React.MouseEvent, nodes: AudienceChild) => {
    event.stopPropagation();
    addOrEditAudienceModal();
    setGroupClickObject({ groupClick: true, isSmartGroup: false, groupId: nodes.id });
  };

  const handleClickOpenAddSmartGroup = (event: React.MouseEvent, nodes: AudienceChild) => {
    event.stopPropagation();
    addOrEditAudienceModal(nodes);
    setGroupClickObject({ groupClick: true, isSmartGroup: true, groupId: nodes.id });
  };

  const handleAddOrUpdate = (event: React.MouseEvent, nodes: AudienceChild) => {
    event.stopPropagation();
    setShowAddNew(true);
    setGroupClickObject({ groupClick: true, isSmartGroup: false, groupId: nodes.id });
  };

  const handleOpenDeleteConfirmationModel = (event: React.MouseEvent, nodeId: string) => {
    event.stopPropagation();
    setAudienceIds([nodeId]);
    setOpenDeleteConfirmationModel(true);
  };

  /**
* Handles the delete action for selected accounts.
* Dispatches an action to delete selected accounts.
* @function handleDelete
* @description Handles the delete action for selected accounts.
* @return {void}
*/
  const handleDelete = () => {
    let data = {
      bodyParams: { audienceIds: audienceIds, },
      queryParams: queryParams,
    };
    dispatch(Actions.deleteOpportunityAudienceRequest(data));
    closeDeleteConfirmationModel();
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const closeAddOrEditAudience = () => {
    setAddOrEditAudience(false);
    setEditData({} as AudienceParent); // Reset to initial stage
  };

  const addOrEditAudienceModal = (editData?: AudienceChild) => {
    setEditData(editData);
    if (editData?.isSmartGroup) {
      setOpenAddSmartGroup(true);
    } else {
      setAddOrEditAudience(true);
    }
  };

  const handleAddSmartGroup = (event: React.MouseEvent, nodes: AudienceChild) => {
    event.stopPropagation();
    setOpenAddSmartGroup(true);
    setGroupClickObject({ groupClick: true, isSmartGroup: false, groupId: nodes.id });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextTemp(e.target.value);
  };

  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchText(searchTextTemp);
    setPageNo(1); // pagination
    setStartCount(1); // pagination
  };

  const loadMoreContacts = () => {
    setPageNo(pageNo + 1);

  };

  const assignOrUnassignOpportunityContacts = () => {
    const data = {
      queryParams,
      bodyParams: {
        audienceIds: audienceIds,
        groupsDetail: groupsDetail,
        contactIds: selectedCheckboxes,
        action: action,
        audienceName: audienceName,
      },
    };
    dispatch(Actions.assignOrUnassignOpportunityContactsRequest(data));
  };

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      contacts.forEach((contact) => arr.push(contact._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCloseAddSmartGroup = () => {
    setOpenAddSmartGroup(false);
    setEditData({} as AudienceParent); // Reset to initial stage
  };



  const renderTree = (nodes: AudienceChild) => (
    <>
      <TreeItem
        onClick={() => handleNodeExpand(nodes)}
        key={nodes.id}
        itemId={nodes.id}
        nodeId={nodes.id}
        classes={{
          root: 'MuiTreeItem-root',
          expanded: 'Mui-expanded',
          selected: `Mui-selected ${groupClickObject.groupClick ? 'active' : ''}`,
          group: 'MuiTreeItem-group',
          content: 'MuiTreeItem-content',
          iconContainer: 'MuiTreeItem-iconContainer',
          label: 'MuiTreeItem-label',
        }}
        label={
          <Stack direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'} spacing={1}>
            <Stack direction={'row'} sx={{ width: "fit-content", maxWidth: "calc(100% - 26px)" }} spacing={1}>
              {
                nodes.isSmartGroup ?
                  <img src={SmartGroupIcon} className='group-icon' alt={'icon'} />
                  :
                  <img src={GroupIcon} className='group-icon' alt={'icon'} />

              }
              <Box className="contact-group-name">{nodes.audienceName}</Box>
            </Stack>
            <Box className="options">
              <MoreVertIcon style={{ fontSize: '18px' }} className="options-button" />
              <div className="options-list">
                {!nodes.isSmartGroup && (<>
                  <div onClick={(event) => handleAddGroup(event, nodes)}>New Group</div>
                  <div onClick={(event) => handleAddOrUpdate(event, nodes)}>New Contact</div>
                  <div onClick={(event) => handleAddSmartGroup(event, nodes)}>New Smart Group</div>
                  <div onClick={(event) => openAsignModel(event, nodes.id, 'assign', nodes)}>Assign Contacts</div>
                </>
                )}
                {nodes.isSmartGroup ? (
                  <div onClick={(event) => handleClickOpenAddSmartGroup(event, nodes)}>Edit</div>
                ) : (
                  <div onClick={(event) => handleRename(event, nodes)}>Rename</div>
                )}
                {nodes.children && nodes.children.length > 0 ? (
                  <Tooltip title='Deletion is not allowed for groups that have subgroups.' arrow placement={'right'}>
                    <div
                      style={{
                        color: 'grey',
                        cursor: 'not-allowed'
                      }}
                    >
                      Delete
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    onClick={(event) => handleOpenDeleteConfirmationModel(event, nodes.id)}
                    style={{
                      color: nodes.children && nodes.children.length > 0 ? 'grey' : '#ff4170',
                      cursor: nodes.children && nodes.children.length > 0 ? 'not-allowed' : 'pointer',
                    }}
                  >
                    Delete
                  </div>
                )}
              </div>
            </Box>
          </Stack>
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        {expandedNodes[nodes.id]?.map((node) => renderTree(node))}
      </TreeItem>
    </>
  );
  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeToggle={handleNodeToggle}
      >
        {audienceList && audienceList.length >0 && audienceList.map((node) => renderTree(node))}
      </TreeView>
      {/* {addOrEditAudience && */}
      <AddOrEditAudience
        openModal={addOrEditAudience}
        closeModalFunc={closeAddOrEditAudience}
        editData={editData}
        queryParams={queryParams}
        parentId={groupClickObject.groupId}
      />
      {/* } */}
      <SmartGroup
        openModal={openAddSmartGroup}
        closeModalFunc={handleCloseAddSmartGroup}
        editData={editData}
        queryParams={queryParams}
        parentId={groupClickObject.groupId}
      />

      <Modal
        open={assign}
        onClose={handleClose}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal manage-custom-fields modal-default-close-none',
        }}
      >
        <Grid container justifyContent="end" width={"100%"}>
          <Grid
            size={{
              xs: 12, md: 7,
              lg: 6,
              xl: 4
            }}

            className="full-screen_modal__form"
          >
            <Box component="div" className="full-screen_modal__form_header">
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid >
                  <h3 className="subtitle">Assign Contacts</h3>
                </Grid>
                <Grid >
                  <Box component={Grid} ml={1}>
                    <CloseIcon
                      onClick={handleClose}
                      className="modal-close-button"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              component="div"
              px={3}
              py={2}
              className="full-screen_modal__form_body"
            >
              <Grid
                spacing={1.5}
                container
                justifyContent="between"
                alignItems="center"
              >
                <Grid size={{xs: "grow",}}>
                  <form
                    onSubmit={handleSearchTextSubmit}
                  >
                    <Box
                      component={TextField}
                      mt={0.8}
                      variant="standard"
                      placeholder="Search..."
                      className="search"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      // name="searchText"
                      value={searchTextTemp}
                      onChange={handleSearchChange}
                    />
                  </form>
                </Grid>
                <Grid >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={assignOrUnassignOpportunityContacts}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>

              <Box
                className="custom-fields-table"
                component={TableContainer}
                sx={{
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'scroll',
                }}
                my={2}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="tableBodyCell checkbox"
                        scope="row"
                      >
                        <Checkbox
                          color="primary"
                          onClick={handleSelectAllCheckboxes}
                          checked={
                            contacts.length
                              ? selectedCheckboxes.length === contacts.length
                              : false
                          }
                        />
                      </TableCell>
                      <TableCell
                        className="tableBodyCell email"
                        align="left"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="tableBodyCell"
                        align="left"
                      >
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(contacts) && contacts.length > 0 ? (
                      contacts.map((row) => (
                        <TableRow key={row._id}>

                          <TableCell
                            className="tableBodyCell checkbox"
                            scope="row"
                          >
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                handleSelectCheckBox(e, row._id)
                              }
                              style={{ left: 0 }}
                              checked={selectedCheckboxes.includes(row._id)}
                            />
                          </TableCell>
                          <TableCell
                            className="tableBodyCell email"
                            align="left"
                          >
                            {row?.firstName}  {row?.middleName}  {row?.lastName}
                          </TableCell>
                          <TableCell
                            className="tableBodyCell email"
                            align="left"
                          >
                            {row.email}
                          </TableCell>

                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No contacts available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Box className="full-screen_modal__form_footer">
              <Grid container alignItems="center" justifyContent="center">
                <Grid size={{xs: "grow",}}>
                  <Grid container justifyContent="center">
                    <Grid >
                      {totalUnassignContacts > 20 &&
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={loadMoreContacts}
                          disabled={
                            Array.isArray(contacts) && contacts.length == totalUnassignContacts ? true : false
                          }
                        >
                          Load More
                        </Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={handleDelete}
      />
    </>
  );
  { loading && <Loader />; }

};

export default GroupsTreeView;
