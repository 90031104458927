import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';
import EmailTabs from "./EmailsListContainer";
import EmailView from "./EmailView";
import ComposeEmail from "./ComposeEmail";
import NoData from "./NoData";

import {
  TeamInboxIcon,
  MyInboxIcon,
  SentIcon,
  DraftIcon,
  EmailBookmarkIcon,
  TrashIcon,

} from '../../components/CustomIcons';
import PageTabs from "../Tabs";
import { useLocation, useNavigate } from "react-router";


type  SideTabsProps = {
  children?: React.ReactNode; // Make children optional
}

export type Message = {
  id: string;
  labelIds: string[];
  payload: {
    body: any;
    filename: string;
    headers: { name: string; value: string }[];
    mimeType: string;
    partId: string;
    parts: any[];
  };
  sizeEstimate: number;
  snippet: string;
  threadId: string;
  draftId?: string;
};

export type GmailData = {
  success?: boolean;
  message?: string;
  data?: { _id: string, email: string, messages: Message[] }[];
  totalMailCount?: number;
}

export default function SideTabs({children}: SideTabsProps) {
  const location = useLocation()
  console.log("==========SideTabs")
  const navigate = useNavigate()
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    getValueByPathName()
  }, [location.pathname])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getValueByPathName = () => {
    const currentPath = location.pathname.split('/')[2];
    let currentTab: 'Team Inbox' | 'My Inbox' | 'Draft' | 'Sent' | 'Trash' |'Bookmark';
    switch (currentPath) {
      case 'team-inbox':
        currentTab = 'Team Inbox';
        break;
      case 'my-inbox':
        currentTab = 'My Inbox';
        break;
      case 'drafts':
        currentTab = 'Draft';
        break;
      case 'sent':
        currentTab = 'Sent';
        break;
      case 'trash':
        currentTab = 'Trash';
        break;
      case 'bookmarks':
        currentTab = 'Bookmark';
        break;
      default:
        break;
    }

    const index = emailCategory.findIndex(el => el.name === currentTab)
    setValue((index + 1).toString())
  };

  const emailCategory = [
    { name: 'Team Inbox', icon: <TeamInboxIcon />, route: '/inbox/team-inbox/all'},
    { name: 'My Inbox', icon: <MyInboxIcon />, route: '/inbox/my-inbox/all'},
    { name: 'Sent', icon: <SentIcon />, route: '/inbox/sent'},
    { name: 'Draft', icon: <DraftIcon />, route: '/inbox/drafts' },
    { name: 'Bookmark', icon: <EmailBookmarkIcon />, route: '/inbox/bookmarks' },
    { name: 'Trash', icon: <TrashIcon />, route: '/inbox/trash' },
  ];

  const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
      height: 50,
      marginBottom: 0,
      borderRadius: 0,
      paddingLeft: '21px',
      paddingRight: '21px',
      TransitionDuration: '200ms',
      width: '200px',
      alignItems: 'center',
      justifyContent: 'start',
      gap: '18px',
      transition: 'all 100ms ease-in',
      '&:hover': {
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      '&>.MuiTypography-root': {
        fontWeight: '700',
      }
    },
  
  }));
  
  const ButtonTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
      paddingTop: '21px',
      borderRadius: 0,
      width: '200px',
      alignItems: 'center',
      justifyContent: 'start',
  
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
  
    },
  
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  
  }));

  const handleNavigation = (route) => {
    navigate(route)
  }

  return (
    <Box display={'flex'} height={'100%'}>
      <TabContext value={value}>
        <TabList
          TabIndicatorProps={{ sx: { width: 0, } }}
          variant="standard"
          orientation="vertical"
          onChange={handleChange} aria-label="Page tabs"
          sx={{ backgroundColor: '#F4F6F7', minWidth: 200, }}
        >
          <ButtonTab
            label={
              <Button variant="contained" color="primary" size="large" >
                Create New Email
              </Button>
            }
            value={"0"} />
          {emailCategory.map((item, index) => (
            <StyledTab
              key={index}
              icon={item.icon}
              iconPosition="start"
              label={
                <Typography variant="body2" lineHeight={'16px'} >
                  {item.name}
                </Typography>
              }
              value={`${index + 1}`}
              onClick={()=> handleNavigation(item.route)}
            />
          ))}
        </TabList>
        <TabPanel
          value={"0"}
          sx={{
            p: 0,
            backgroundColor: '#fff',
            height: '100%',
            flexGrow: '1',
            overflow: 'auto',
          }}
        >
          <ComposeEmail />
        </TabPanel>
        {/* {emailCategory.map((item, index) => ( */}
          <TabPanel
            value={value}
            sx={{
              p: 0,
              backgroundColor: '#fff',
              height: '100%',
              flexGrow: '1',
              overflow: 'auto',
            }}
          >
            {children}
          </TabPanel>
        {/* ))} */}
      </TabContext>

    </Box>
  );
}
