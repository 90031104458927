import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
 

export function createNotification(type, message) {
  try { 
    let title = type === 'success' ? 'Success!' : type === 'error' ? 'Error!' : type === 'info' ? 'Info!'
      : type === 'warning' ? 'Warning!' : '';
    switch (type) {
      case 'info':
        NotificationManager.info(message, title, 4000);
        break;
      case 'success':
        NotificationManager.success(message, title, 4000);
        break;
      case 'warning':
        NotificationManager.warning(message, title, 4000);
        break;
      case 'error':
        NotificationManager.error(message, title, 4000);
        break;
      default:
        NotificationManager.success(message, title, 4000);
        break;

    }
  } catch (error) {
    console.log("Error in createNotification:", error);
  }
}

// yourHelperFile.js 
export function redirectTo(navigate, path, currentPath) {
  var role = '1';  // Replace this with your actual logic to determine the role of the user
  if (typeof navigate === 'function') {
    switch (role) {
      case '1':
        navigate(`${path}`, { replace: true });
        break;
      case '2':
        navigate(`${path}`, { replace: true });
        break;
      default:
        navigate(`${path}`, { replace: true });
        break;
    }
  } else {
    console.error('Navigate is not a function');
  }
}


export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function turnCamelCaseToNormalText(camelCaseText) {
  // Replace all occurrences of a lowercase letter followed by an uppercase letter
  // with a space followed by the lowercase letter and then the uppercase letter
  // Use regex and a capturing group to achieve this
  var normalText = camelCaseText && camelCaseText?.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter
  normalText = normalText?.charAt(0).toUpperCase() + normalText?.slice(1);

  return normalText;
}