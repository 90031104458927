import React, { useEffect, useState, useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../../theme/theme';
import CalenderIcon from '../../../../../src/assets/images/icons/meetings.svg';
import SettingIcon from '../../../../../src/assets/images/icons/gamification_configrations.svg';
import Button from '@mui/material/Button';
// import '../../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  IconButton,
} from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CalendarIntegration from './SettingsPanels/CalendarIntegration';
import { getMeetingsIntegrationDetails } from 'ReduxStore/API';
import { useNavigate, useParams } from 'react-router-dom';
import { createNotification } from 'helpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'; 
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../../../Components/Loader/loader';
import moment from 'moment';
import {
  convertTZ,
  getProjectIdFromUrl,
  getEventURL,
  generateUrl,
} from '../../../../helpers/common';
import informationIcon from '../../../../../src/assets/images/icons/informationIcon.svg';
import Tooltip from '@mui/material/Tooltip';

/**
 * @function SettingsLayout
 * @param {Object} props - Component props.
 * @description Functional component for the settings layout, including calendar integrations and general settings.
 */
function SettingsLayout(props) {
  const eventId = getProjectIdFromUrl();
  const prevPropsRef = useRef({
    Meeting: {
      slotsError: false,
      message: '',
      updateSlotsSuccess: false,
      getSlotsSuccess: false
    },
    project: {
      timezone: '',
      endDate: new Date(),
      startDate: new Date()
    }
  });
  const params = useParams();
  const navigate = useNavigate();
  const [googleCalendarIntegration, setGoogleCalendarIntegration] = useState({});
  const [showCalendarIntegrationTab, setShowCalendarIntegrationTab] = useState(false);
  const [openSeeting, setOpenSeeting] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [durationDisable, setDurationDisable] = useState(false);
  const [minutes, setMinutes] = useState();
  const [hours, setHours] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timezone, setTimezone] = useState('');

  /**
  * @function useEffect
  * @description React Hook that runs after the component mounts.
  * Initiates the fetching of meeting integration details.
  */
  useEffect(() => {
    getSlots();
    meetingsIntegrationDetails();
  }, []);

  /**
  * @function meetingsIntegrationDetails
  * @description Fetches and updates meeting integration details, specifically Google Calendar integration.
  */
  const meetingsIntegrationDetails = async () => {
    try {
      const data = {
        eventId: params.eventId
      };
      const integrationRes = await getMeetingsIntegrationDetails(data);

      if (integrationRes.data.success) {
        if (Object.keys(integrationRes.data.integrations).length) {
          if (integrationRes.data.integrations.type === "googleCalendar") {
            const googleCalendarIntegrationObj = integrationRes.data.integrations;
            if (googleCalendarIntegrationObj) {
              setGoogleCalendarIntegration(googleCalendarIntegrationObj);
            }
          }
        }
        setShowCalendarIntegrationTab(true);
      }
    } catch (error) {
      console.log('error');
      createNotification('error', 'Something went wrong');
    }
  };


  useEffect(() => {
    if (
      props.project.getProjectSuccess &&
      !prevPropsRef.current.project.getProjectSuccess &&
      props.project.message !== prevPropsRef.current.project.message
    ) {
      setStartDate(
        moment
          .tz(
            props.project?.project?.startDate,
            props.project?.project?.timezone
          )
          .format('MM/DD/YYYY, hh:mm:ss A')
      );
      setEndDate(moment
        .tz(
          props.project?.project?.endDate,
          props.project?.project?.timezone,
        )
        .format('MM/DD/YYYY, hh:mm:ss A'));
      setTimezone(props.project?.project?.timezone);
    }

    if (
      props.Meeting.slotsError === true &&
      prevPropsRef.current.Meeting.slotsError === false &&
      props.Meeting.message !== prevPropsRef.current.Meeting.message
    ) {
      setLoader(false);
      createNotification('error', props.Meeting.message);
    }

    if (
      props.Meeting.updateSlotsSuccess === true &&
      prevPropsRef.current.Meeting.updateSlotsSuccess === false &&
      props.Meeting.message !== prevPropsRef.current.Meeting.message
    ) {
      createNotification('success', props.Meeting.message);
      setLoader(false);
      props.getSlots({ eventId });
    }

    if (
      props.Meeting.getSlotsSuccess === true &&
      prevPropsRef.current.Meeting.getSlotsSuccess === false &&
      props.Meeting.message !== prevPropsRef.current.Meeting.message
    ) {
      setLoader(false);
      setHours(props.Meeting.getSlots?.duration?.hours);
      setMinutes(props.Meeting.getSlots?.duration?.minutes);
      setDurationDisable(props.Meeting.getSlots?.meetingsCreated ? props.Meeting.getSlots.meetingsCreated : durationDisable);
    }
    prevPropsRef.current = props;
  }, [props.Meeting, props.project]);

  const getSlots = () => {
    props.getSlots({ eventId });
    setLoader(true);
  };

  const updateSlots = () => {
    if (isValid()) {
      const eventId = getProjectIdFromUrl();
      props.updateSlots({
        eventId,
        hours,
        minutes,
      },
        setLoader(true)
      );
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "hours") {
      setHours(event.target.value);
    } else {
      setMinutes(event.target.value);
    }
  };

  const openSeetingTab = (settingTab, calenderTab) => {
    setOpenSeeting(settingTab);
    setShowCalendarIntegrationTab(calenderTab);
  };

  const isValid = () => {
    let error = {};
    let formIsValid = true;
    if (hours <= 0 && minutes <= 0 && hours !== ""&& minutes !=='') {
      formIsValid = false;
      error['duration'] = 'Please enter the valid duration for the slot';
    }

    if (minutes === '' || minutes === null) {
      formIsValid = false;
      error['minutes'] = 'Please enter the minutes';
    }

    if (hours === '' || hours === null) {
      formIsValid = false;
      error['hours'] = 'Please enter the Hours or at least enter as 0';
    }

    if (hours > 23 || hours < 0) {
      formIsValid = false;
      error['hours'] = 'Please enter between 0-23 hours ';
    }

    if (minutes > 59 || minutes < 0) {
      formIsValid = false;
      error['minutes'] = 'Please enter between 0-59  minutes ';
    }

    if (!Number.isInteger(Number(hours))) {
      formIsValid = false;
      error['hours'] = 'Please enter integer value ';
    }

    if (!Number.isInteger(Number(minutes))) {
      formIsValid = false;
      error['minutes'] = 'Please enter integer value ';
    }

    let sd = new Date(startDate);
    let ed = new Date(endDate);
    var timeDifference = ed.getTime() - sd.getTime();
    timeDifference = Math.round(timeDifference / 60000);
    var tMinutes = (parseInt(hours) * 60);
    var totalMinutes = parseInt(tMinutes) + parseInt(minutes);
    if (totalMinutes > timeDifference) {
      formIsValid = false;
      error['duration'] = 'Slot duration is longer than the project duration';
    }
    setErrors(error);
    return formIsValid;
  };
  return (
    <ThemeProvider theme={theme}>
      <Box className="container-with-sidebar" p={0}>
        <Grid container alignItems="center">
          <IconButton size="small" aria-label="delete">
            <ArrowBackIcon style={{ color: theme.palette.common.black }} onClick={() => {
              props.handleCloseSettings();
              navigate(generateUrl(`/project-dashboard/meetings/${params.eventId}`));
            }} />
          </IconButton>
          <Box
            className="subtitle"
            style={{ color: theme.palette.grey[800], marginLeft: 10 }}
          >
            Meeting List
          </Box>
        </Grid>
        <Tabs defaultIndex={0}>
          <Box
            className="project-sidebar"
            sx={{ background: 'transparent !important' }}
          >
            <TabList   >
              <Tab onClick={() => openSeetingTab(false, true)}>
                {' '}
                <img src={CalenderIcon} />{' '}
                <a to={''}> Calender Integrations</a>

              </Tab>
              <Tab onClick={() => openSeetingTab(true, false)}>
                {' '}
                <img src={SettingIcon} /> <a to={''}>Settings</a>
              </Tab>
            </TabList>
          </Box>

          <div className="container-sidebar-right">
            <TabPanel>
              {showCalendarIntegrationTab && <CalendarIntegration googleCalendarIntegration={googleCalendarIntegration} setGoogleCalendarIntegration={setGoogleCalendarIntegration} />}
            </TabPanel>
            {openSeeting &&
              <TabPanel>
                <Stack direction={'row'} mb={1} mt={2} justifyContent={'space-between'}>
                  <Box className="subsecondary" >
                    Settings
                  </Box>
                  <Button variant='contained' color='primary'
                  disabled={props.Meeting.getSlots.meetingsCreated}
                    onClick={() => updateSlots()}
                  >
                    Save
                  </Button>
                </Stack>
                <Box className="paragraph text-primary-light">
                  Adjust your meeting preferences here, and configure other settings for a seamless meeting experience
                </Box>
                <Grid container mt={3}>
                  <Box component={Grid} mb={{ xs: 1, md: 2 }} mt={{ xs: -1, md: 0 }} size={{xs: 12, sm:12, md:4, lg:3 }}className='inputField field-group'>
                    <Stack direction={'row'} justifyContent={'start'} mb={1}>
                          <p className='paragraph'>
                            Slot Duration <span className="required">*</span>
                          </p>
                                  <Tooltip
                                    title={props.Meeting.getSlots.meetingsCreated? 'After a meeting is created, you cannot modify the slot duration':"Define the duration to create the meeting slot"}
                                    placement="right"
                                    arrow
                                  >
                                    <Box
                                      component="img"
                                      sx={{ width: '20px' }}
                                      ml={1}
                                      alt="information Icon"
                                      src={informationIcon}
                                    />
                                  </Tooltip>
                                </Stack>
                    <Grid container alignItems={'start'}>
                      <Grid size={{xs: "grow",}}>
                        <input
                          type="number"
                          max={'23'}
                          style={{ textAlign: 'center' }}
                          class="form-control small"
                          name="hours"
                          placeholder=""
                          value={hours}
                          onChange={handleChange}
                          disabled={props.Meeting.getSlots?.meetingsCreated}
                        />
                        <Box
                          mt={0.3}
                          sx={{
                            fontSize: '10px',
                            color: theme.palette.primary.dark,
                          }}
                        >
                          Hours
                        </Box>
                        <span className="error_mesage required">
                          {' '}
                          {errors?.hours}{' '}
                        </span>
                      </Grid>
                      <Grid >
                        {' '}
                        <Box
                          component={'span'}
                          sx={{ lineHeight: '38px' }}
                          px={1}
                          className="heading"
                        >
                          :
                        </Box>
                      </Grid>
                      <Grid size={{xs: "grow",}}>
                        <input
                          type="number"
                          max={'59'}
                          style={{ textAlign: 'center' }}
                          class="form-control small"
                          placeholder=""
                          name="minutes"
                          value={minutes}
                          onChange={handleChange}
                          disabled={props.Meeting.getSlots.meetingsCreated}
                        />
                        <Box
                          mt={0.3}
                          sx={{
                            fontSize: '10px',
                            color: theme.palette.primary.dark,
                          }}
                        >
                          Minutes
                        </Box>
                        <span className="error_mesage required">
                          {' '}
                          {errors?.minutes}{' '}
                        </span>
                      </Grid>
                    </Grid>
                    <span className="error_mesage required">
                      {' '}
                      {errors?.duration}{' '}
                    </span>
                  </Box>
                </Grid>
              </TabPanel>
            }
          </div>
        </Tabs>
      </Box>
      {loader ? <Loader /> : null}
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  project: state.Projects,
  Meeting: state.Meeting,
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getSlots: Actions.getSlotsRequest,
      updateSlots: Actions.updateSlotsRequest,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SettingsLayout);
