// QuillToolbar.js
import { Box, IconButton, Select } from "@mui/material";
import React from "react";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import AttachFileIcon from "@mui/icons-material/AttachFile";
// Inline styles for the toolbar and editor
const toolbarStyle = {
    '&.ql-toolbar': {
        border: "none",
        display: "flex",
        gap: "2px",
        alignItems: "center"
    }
};

const QuillToolbar = ({ onUploadAttachment }) => {

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            onUploadAttachment(file); // Pass the file to the upload handler
        }
        e.target.value = ""; // Reset the file input value
    };
    return (
        <Box id="toolbar" sx={toolbarStyle} >
            
            <select class="ql-font"></select>
            <select class="ql-size"></select>
           
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <select class="ql-color"></select>
            <select class="ql-background"></select>
           
            <select class="ql-align"></select>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
            <button class="ql-blockquote"></button>
            
            <button className="ql-link"></button>
            <button className="ql-attachment">
                <label style={{ cursor: "pointer", }}>
                    <AttachFileIcon sx={{ fontSize: '18px', }} />
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange} // Handle file upload
                    />
                </label>
            </button>
            <button className="ql-image"></button>

            <button className="ql-clean"></button>

        </Box>
    );
};

export default QuillToolbar;
