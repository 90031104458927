import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ReactQuill from 'react-quill';
//@ts-ignore
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
//@ts-ignore
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useState, ChangeEvent, useEffect } from 'react';
import { generateUrl, isValidEmail } from 'helpers/common';
import { xpAccountIntegrationDownloadAllAttachment, xpAccountIntegrationDownloadAttachment, xpAccountIntegrationGmailGetDraftsDetails, xpAccountIntegrationGmailGetGmailId, xpAccountIntegrationMailSaveAsDraft, xpAccountIntegrationMailSend, xpAccountIntegrationMailSendDraft, xpAccountIntegrationMailUpdateDraft } from 'ReduxStore/API';
import Loader from 'Components/Loader/loader';
import { createNotification } from 'helpers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailsLayout from '.';
import { useLocation, useNavigate, useParams } from 'react-router';

type ComposeSubComponent = {
    // gmailId: string;
    // recipients?: string;
    // subject?: string;
    // editorText?: string;
    // cc: boolean;
    // bcc: boolean;
    // modules: Object;
    // handleOpenCC: () => void;
    // handleCloseCC: () => void;
    // handleOpenBCC: () => void;
    // handleCloseBCC: () => void;
    // setRecipients: () => void;
    // setSubject: () => void;
    // setPayload: () => void;
    // setEditorText: () => void;
    viewCompose?: boolean;
    handleBackFromCompose?: () => void;
    // payload: string;
    messageIdForDraft: string;
    // draftId: string;
    clearAllAttachments: boolean;
};
type Errors = {
    emailBody?: string;
    subject?: string;
    recipients?: string;
    cc?: string;
    bcc?: string;
    general?: string;
    fieldName?: string;
};

const Compose = ({ 
  // gmailId, 
  // recipients, 
  // subject, editorText, 
  // cc, bcc, 
  // modules, 
  // cc, bcc, handleOpenCC, handleCloseCC, handleOpenBCC, handleCloseBCC, 
  // setRecipients, setSubject, setEditorText, 
  viewCompose, handleBackFromCompose, 
  // payload, setPayload, 
  // messageIdForDraft, 
  // draftId, 
   }: ComposeSubComponent) => {
    const params = useParams()
    const navigate = useNavigate()
    console.log("params===========", params)
    const [payload, setPayload] = useState({});

    const [recipients, setRecipients] = useState('');
    const [subject, setSubject] = useState('');
    const [cC, setCc] = useState('');
    const [isCcOpen, setIsCcOpen] = useState(false);
    const [bcC, setBcc] = useState('');
    const [isBccOpen, setIsBccOpen] = useState(false);
    const [removedAttachments, setRemovedAttachments] = useState([]);
    const [editorText, setEditorText] = useState('');
    const [fileNames, setFileNames] = useState<File[]>([]);
    const [errors, setErrors] = useState<Errors>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gmailId, setGmailId] = useState("");
    const [draftId, setDraftId] = useState("");
    const [messageIdForDraft, setMessageIdForDraft] = useState("");
    const [mailType, setMailType] = useState<'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | ''>('');

    useEffect(() => {
      getGmailDetails()
      setMailTypeFromUrl()
      if(params.draftId){
        getDraftDetails(params.draftId)
      } else {
        resetStates()
      }
    }, [location.pathname])

    const resetStates = () => {
      setRecipients('');
      setSubject('');
      setCc('');
      setIsCcOpen(false);
      setBcc('');
      setIsBccOpen(false);
      setRemovedAttachments([]);
      setEditorText('');
      setFileNames([]);
      setErrors({});
      setIsLoading(false);
      setGmailId('');
      setDraftId('');
      setMessageIdForDraft('')
      setPayload({})
    };

    const setMailTypeFromUrl = () => {
      const currentPath = location.pathname.split('/')[3];
      let mailType: 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' = 'INBOX';
      switch (currentPath) {
        case 'inbox':
          mailType = 'INBOX';
          break;
        case 'drafts':
          mailType = 'DRAFT';
          break;
        case 'sent':
          mailType = 'SENT';
          break;
        case 'trash':
          mailType = 'TRASH';
          break;
        case 'bookmark':
          mailType = 'STARRED';
          break;
        default:
          break;
      }
      setMailType(mailType);
    };

    const extractValidAndInvalidEmails = (inputString) => {
      // Regex to match emails (both in "<...>" and as plain emails)
      const emailRegex = /(?:<([^<>]+@[^\s@]+\.[^\s@]+)>)|([^<>\s,]+@[^\s@]+\.[^\s@]+)/g;
    
      const result = [];
      let match;
    
      // Iterate through all matches in the input string
      while ((match = emailRegex.exec(inputString)) !== null) {
        // Select the correct email from the capture groups
        const email = match[1] || match[2]; 
    
        // Only add valid emails
        if (email && !result.includes(email.trim())) {
          result.push(email.trim());  // Add email to result (avoiding duplicates)
        }
      }
    
      // Return the final comma-separated string (empty if no emails are found)
      return result.join(' ');
    };

    const getDraftDetails = async (draftId) => {
      try {
        const data = {
          draftId
        }
        const res = await xpAccountIntegrationGmailGetDraftsDetails(data)
        console.log("res=============", res)
        if(res.data.success && res.status === 200){
          const message  = res.data.data.message
          const subjectDetail = message?.payload?.headers.find(detail => detail.name === 'Subject');
          const sendTo = message?.payload?.headers.find(detail => detail.name === 'To');
          console.log("========sendTo", sendTo)
          if(sendTo){
            console.log("=============validateEmails", extractValidAndInvalidEmails(sendTo.value))
          }
          console.log("=============message", message)
          const { payload } = message;
          setPayload(payload);
          setMessageIdForDraft(message.id);
          
          setDraftId(draftId);
          setSubject(subjectDetail?.value);
          setRecipients(extractValidAndInvalidEmails(sendTo.value));
          setEditorText(message?.snippet);
        } else {
          createNotification("error", "Something went wrong")
        }
      } catch (error) {
        createNotification("error", error.message)
        console.log(error)
      }
    }
    
    const getGmailDetails = async () => {
      try {
        const result: any = await xpAccountIntegrationGmailGetGmailId();
        if (result.status === 200 && result.data.success) {
          setGmailId(result.data.details.appData.user.email);
        }
      } catch (error) {
        console.log("error");
        if (error instanceof Error) {
          createNotification("error", error.message);
        } else {
          createNotification("error", "An unexpected error occurred.");
        }
      }
    };
    
    const handleOpenCC = () => {
      setIsCcOpen(true);
    };
    const handleCloseCC = () => {
      setIsCcOpen(false);
    };
    const handleOpenBCC = () => {
      setIsBccOpen(true);
    };
    const handleCloseBCC = () => {
      setIsBccOpen(false);
    };

  //@ts-ignore
  const modules: QuillToolbarModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }], // Text alignment
        ['link', 'image'], // Default image handler
        ['attachment'], // Custom attachment button
        ['clean'], // Removes formatting
      ],
    },
  };

    const handleMailStatus = async (status: string) => {
      try {
        if (isValid(status)) {
            const allRecipients = recipients.split(",").map((mail) => mail.trim());
            const allCcMails = cC.split(",").map((mail) => mail.trim());
            const allBCcMails = bcC.split(",").map((mail) => mail.trim());
            const formattedRecipients = allRecipients.join(',');
            const formattedCcMails = allCcMails.join(',');
            const formattedBCcMails = allBCcMails.join(',');
            const formData = new FormData();
            formData.append('from', gmailId);
            formData.append('to', formattedRecipients);
            formData.append('cC', formattedCcMails);
            formData.append('bcC', formattedBCcMails);
            formData.append('subject', subject);
            formData.append('htmlContent', editorText);

            fileNames.forEach((file: File) => {
                console.log("file in loop", file);
                formData.append('attachments', file);
            });

            if (status === 'send') {
                setIsLoading(true);
                let response: any = {};
                if (params.draftId) {
                    formData.append('gmailId', gmailId);
                    formData.append('draftId', params.draftId);
                    removedAttachments.forEach(el => {
                        formData.append('removedAttachmentsPartId', el);
                    });
                    response = await xpAccountIntegrationMailSendDraft(formData);
                } else {
                    response = await xpAccountIntegrationMailSend(formData);
                }
                setIsLoading(false);
                if (response?.data?.status == 200) {
                    createNotification('success', response?.data?.message);
                    setRecipients('');
                    setSubject('');
                    setPayload({});
                    setCc('');
                    setBcc('');
                    setEditorText('');
                    setFileNames([]);
                    navigate(-1)
                }

            } else {
                setIsLoading(true);
                console.log("===========messageIdForDraft", messageIdForDraft);
                console.log("===========draftId", params.draftId);
                let response: any = {};
                if (messageIdForDraft) {
                    formData.append('messageId', messageIdForDraft);
                    formData.append('draftId', params.draftId);
                    formData.append('gmailId', gmailId);
                    removedAttachments.forEach(el => {
                        formData.append('removedAttachmentsPartId', el);
                    });
                    response = await xpAccountIntegrationMailUpdateDraft(formData);
                } else {
                    response = await xpAccountIntegrationMailSaveAsDraft(formData);
                    navigate(-1)
                }
                setIsLoading(false);
                if (response?.data?.status == 200) {
                    createNotification('success', response?.data?.message);
                    // setRecipients('');
                    // setSubject('');
                    // setPayload({});
                    // setCc('');
                    // setBcc('');
                    // setEditorText('');
                    // setFileNames([]);
                }
            }
            setIsLoading(false);
        }
      } catch (error) {
        console.log("====err", error)
        createNotification("error", error.message)
      }
    };


    const handleRecipientsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRecipients(event.target.value);
    };
    const handleSubChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };
    const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCc(event.target.value);
    };
    const handleBccChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBcc(event.target.value);
    };
    const handleEditorText = (val: string) => {
        setEditorText(val);
    };



    // Custom handler to upload attachments
    const handleAttachment = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', ''); // Enable multiple file selection
        input.click();

        input.onchange = () => {
            const files = Array.from(input.files || []); // Convert the FileList to an array
            for(let i=0;i<files.length;i++){
                if(files[i].size >= 26214400){
                    createNotification("error", "Please attach files less than 25MB");
                    return;
                }
            }
            if (files.length > 0) {
                console.log('Selected files:', files);
                setFileNames([...fileNames, ...files]);
            }
        };
    };

    

    const handleDelete = (fileToDelete: File) => {
        setFileNames((prevFileNames) =>
            prevFileNames.filter((file) => file.name !== fileToDelete.name)
        );
    };

    const isValid = (status: string) => {
        const error: Errors = {};
        let isValid = true;

        const validateEmailField = (field: string | undefined, fieldName: string): boolean => {
            if (!field) {
                return false; // Immediately return false if field is undefined or empty
            }

            const allMails = field.split(",").map((mail) => mail.trim()); // Split and trim all emails
            const invalidMail = allMails.find((mail) => mail.length > 0 && !isValidEmail(mail)); // Find the first invalid email

            if (invalidMail) {
                error.fieldName = `The field is not valid. Please enter a valid email address for ${fieldName.toUpperCase()}.`;
                return false;
            }

            return true; // All emails are valid
        };

        // Condition to check if editorText is empty or only contains placeholder HTML
        const isBodyEmpty = !editorText || editorText === '<p><br></p>';

        // Validate mandatory fields based on status
        if (status === "send") {
            if (!validateEmailField(recipients, 'recipients')) {
              
                error.recipients = "Please enter the recipients email again";
                isValid = false;
            }
            if (!subject || subject.trim().length === 0) {
                error.subject = "Please enter the subject again";
                isValid = false;
            }
            if (isBodyEmpty) {
                error.emailBody = "Please enter the email body agin";
                isValid = false;
            }

        } else if (status === "draft") {

            // Check if at least one field is valid in "draft"
            const hasValidField =
                validateEmailField(recipients, 'recipients') ||
                (subject && subject.trim().length > 0) ||
                fileNames.length > 0 ||
                validateEmailField(cC, 'cc') ||
                validateEmailField(bcC, 'bcc') ||
                !isBodyEmpty;

            if (!hasValidField) {
                error.general = "For a draft, at least one of recipients, subject, cc, bcc, email body or file must be filled in and valid.";
                isValid = false;
            }

        }
        //Additional validation for cc and bcc fields, if provided
        if (recipients && !validateEmailField(recipients, 'recipients')) {
            error.recipients = "Recepient is not valid. Please enter a valid email address.";
            error.general = "";
        }
        //Additional validation for cc and bcc fields, if provided
        if (cC && !validateEmailField(cC, 'cc')) {
            error.cc = "CC field is not valid. Please enter a valid email address.";
            error.general = "";
        }
        if (bcC && !validateEmailField(bcC, 'bcc')) {
            error.bcc = "BCC field is not valid. Please enter a valid email address.";
            error.general = "";
        }

        setErrors(error);
        return isValid;
    };

    const handleRemovedAttachments = (partId) => {
        setRemovedAttachments(prev => [...prev, partId]);
    };

    const renderAttachments = () => {
        if (!payload) return null;
        const { parts } : any = payload;

        if (
            //@ts-ignore
            payload.mimeType === 'multipart/mixed' &&
            Array.isArray(parts) &&
            parts.length > 1
        ) {
            return parts.map((part, i) => {
              if (i > 0 && !removedAttachments.includes(part.partId)) {
                    return (
                        <Grid>
                            <Box className="attachment-file">
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box className="file-icon">
                                        <img src={DocIcon} alt="file-icon" />
                                    </Box>
                                    <Box>
                                        <Box className="file-name">{part.filename}</Box>
                                        <Box className="file-size">{part.body.size}</Box>
                                    </Box>
                                </Box>
                                <FileDownloadIcon
                                    onClick={() => downloadAttachment(gmailId, part.filename, part.body.attachmentId)}
                                    style={{ fontSize: '18px' }}
                                    className="options-button"
                                />
                                <Box ml={1} className="options">
                                    <ClearIcon onClick={() => handleRemovedAttachments(part.partId)} style={{ fontSize: '18px' }} />
                                </Box>
                            </Box>
                        </Grid>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return null;
        }
    };

    const downloadAttachment = async (messageId, filename, attachmentId) => {
        try {
            const data = {
                messageId,
                gmailId,
                filename,
                attachmentId
            };
            await xpAccountIntegrationDownloadAttachment(data);
        } catch (error) {
            createNotification("error", error.message);
        }
    };

    return (
      <EmailsLayout>
        <Box>
          <Box
            pb={0}
            mt={-5}
            mb={2}
            sx={{ borderBottom: '0px solid #EDECF5' }}
            className="top-filter-area"
          >
            <Grid container width={'100%'} spacing={1}>
              <Grid size={'grow'}>
                <Stack direction={'row'} alignItems={'center'}>
                  {viewCompose && (
                    <IconButton
                      size="medium"
                      title="Back"
                      onClick={handleBackFromCompose}
                    >
                      <KeyboardBackspaceOutlinedIcon />
                    </IconButton>
                  )}
                  <Box className="subtitle">New Message (From: {gmailId})</Box>
                </Stack>
              </Grid>
              <Grid>
                <Grid
                  container
                  width={'100%'}
                  spacing={1.25}
                  className="team-dash-right"
                >
                  <Grid>
                    <Button
                      onClick={() => handleMailStatus('draft')}
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<DraftsIcon />}
                    >
                      Draft
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      onClick={() => handleMailStatus('send')}
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={<SendIcon />}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className="email-composer">
            <Stack spacing={1} sx={{ height: '100%' }}>
              <Grid container width={'100%'} columnGap={1}>
                <Grid size={'grow'}>
                  <TextField
                    name="Recipients"
                    placeholder="Recipients"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={recipients}
                    onChange={handleRecipientsChange}
                  />
                  <span className="error_message required">
                    {errors.recipients}
                  </span>
                </Grid>
                <Grid>
                  {!isCcOpen && (
                    <Button
                      onClick={handleOpenCC}
                      variant="text"
                      color="primary"
                      size="small"
                    >
                      CC
                    </Button>
                  )}
                </Grid>
                <Grid>
                  {!isBccOpen && (
                    <Button
                      onClick={handleOpenBCC}
                      variant="text"
                      color="primary"
                      size="small"
                    >
                      BCC
                    </Button>
                  )}
                </Grid>
              </Grid>

              {isCcOpen && (
                <Grid container columnGap={1}>
                  <Grid size={'grow'}>
                    <TextField
                      name="CC"
                      placeholder="CC"
                      variant="standard"
                      fullWidth
                      size="small"
                      value={cC}
                      onChange={handleCcChange}
                    />
                    <span className="error_message required">{errors.cc}</span>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={handleCloseCC}
                      color="primary"
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {isBccOpen && (
                <Grid container columnGap={1}>
                  <Grid size={'grow'}>
                    <TextField
                      name="BCC"
                      placeholder="BCC"
                      variant="standard"
                      fullWidth
                      size="small"
                      value={bcC}
                      onChange={handleBccChange}
                    />
                    <span className="error_message required">{errors.bcc}</span>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={handleCloseBCC}
                      color="primary"
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              <TextField
                name="Subject"
                placeholder="Subject"
                fullWidth
                variant="standard"
                size="small"
                value={subject}
                onChange={handleSubChange}
              />
              <span className="error_message required">{errors.subject}</span>
              <span className="error_message required">{errors.general}</span>
              <ReactQuill
                theme="snow"
                value={editorText}
                onChange={handleEditorText}
                modules={modules}
              />
              <span className="error_message required">{errors.emailBody}</span>

              <Grid container spacing={1} className="attachments">
                {fileNames?.map((file) => {
                  const imageSizeInBytes = file?.size;
                  let convertedFileSizeKbMb;
                  if (imageSizeInBytes > 1024 * 1024) {
                    // Convert to MB if file size is larger than 1024 KB
                    convertedFileSizeKbMb = `${(
                      imageSizeInBytes /
                      (1024 * 1024)
                    ).toFixed(2)} MB`;
                  } else {
                    convertedFileSizeKbMb = `${(
                      imageSizeInBytes / 1024
                    ).toFixed(2)} KB`;
                  }
                  return (
                    <Grid>
                      <Box className="attachment-file">
                        <Box display={'flex'} alignItems={'center'}>
                          <Box className="file-icon">
                            <img src={DocIcon} alt="file-icon" />
                          </Box>
                          <Box>
                            <Box className="file-name">{file.name}</Box>
                            <Box className="file-size">
                              Size: {convertedFileSizeKbMb}
                            </Box>
                          </Box>
                        </Box>
                        <Box ml={1} className="options">
                          <ClearIcon
                            onClick={() => handleDelete(file)}
                            style={{ fontSize: '18px' }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
                <Grid>
                  <Box
                    className="attachment-file upload"
                    onClick={handleAttachment}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Box className="file-icon" p={1}>
                        <img src={AddFile} alt="add-file" />
                      </Box>
                      <Box>
                        <Box className="file-name secondary-text">
                          Attach file
                        </Box>
                        {/* <Box className="file-size">10 Mb Max.</Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
        <Box className={'attachment-container'} mt={1}>
          <Grid container spacing={1} className="attachments">
            {renderAttachments()}
          </Grid>
        </Box>

        {isLoading && <Loader />}
      </EmailsLayout>
    );
};

export default Compose;