import * as React from 'react';
import Stack from '@mui/material/Stack';
import AppTheme from '../../eijentTheme/AppTheme';
import LoginBanner from './LoginBanner';

// import CssBaseline from '@mui/material/CssBaseline';
// import ColorModeSelect from '../eijentTheme/ColorModeSelect';

export default function AuthLayout({ children, ...props }) {
    return (
        <AppTheme {...props}>
            {/* <CssBaseline enableColorScheme />
            <ColorModeSelect sx={{ position: 'fixed', top: '1rem', right: '1rem' }} /> */}

            <Stack
                direction="row"
                component="main"
                alignItems="center"
                height={{ xl: '100vh' }}
                bgcolor="#DBE0E4"
            >
                <Stack
                    direction={{ md: 'column', lg: 'row' }}
                    sx={{
                        justifyContent: 'center',
                        gap: { xs: 6, sm: 12 },
                        mx: 'auto',
                    }}
                >
                    <Stack
                        direction={{ md: 'column', lg: 'row' }}
                        sx={{
                            justifyContent: 'center',
                            gap: { xs: 1, sm: 1.5 },
                            p: { xs: 2, lg: 4 },
                            m: 'auto',
                        }}
                    >
                        <LoginBanner />
                        {children}
                    </Stack>
                </Stack>
            </Stack>
        </AppTheme>
    );
}
