import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route
import ApplicationRoutes from './ApplicationRoutes';
import EndUserEventRoutes from './EndUserEventRoutes';
import { showEndUserEventRoutesFn } from '../helpers/common';
import EijentRoutes from './EijentRoutes';

const RoutesComponent = () => { 
    const showEndUserEventRoutes = showEndUserEventRoutesFn();


    return (
        <Routes> {/* Wrap your routes in the Routes component */}
        {showEndUserEventRoutes ? (
            <Route path="*" element={<EndUserEventRoutes />} /> // Catch-all route
        ) : (
            <>
                {/* New Design */}
                <Route path="/*" element={<EijentRoutes />} />

                {/* Old Design */}
                <Route path="/xp/*" element={<ApplicationRoutes />} />
               
            </>
        )}
    </Routes>
    );
};

export default RoutesComponent;
