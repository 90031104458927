import React, { Component } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'; 
// import '../../sass/main.scss';
import Button from '@mui/material/Button';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import moment1 from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import { sampleEvent, emailTemplateJson } from './sampleEvent';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gapiClient from 'gapi-client';
import { GetTimezoneAPI } from 'ReduxStore/API';
import OutlinedInput from '@mui/material/OutlinedInput';
import SubIcon from '../../assets/images/icons/sub-icon.svg';
import AddIcon from '../../assets/images/icons/add-icon.svg';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EarlyAccessAddIcon from '../../assets/images/icons/EarlyAccessaddIcon.svg';
import EarlyAccessSubIcon from '../../assets/images/icons/EarlyAccessremoveIcon.svg';
import informationIcon from '../../assets/images/icons/informationIcon.svg';
import FinishPopUp from '../Projects/FinishPopUp';
import ArrowRight from '../../assets/images/icons/arrow-right-btn.svg';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { components } from 'react-select';
import CustomSwitch from '../../Components/customSwitch';
import { Box, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TimerIcon from '@mui/icons-material/Timer';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { convertTZ, generateUrl } from '../../helpers/common';
import tease from 'tease';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationAlertPopUp from '../../Common/ConfirmationAlertPopUp';
import CustomMultiSelectDropdown from '../../Components/CustomDropdowns/CustomMultiSelectDropdown';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '../../assets/images/icons/setting-user-Info.svg';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
import { languageCode } from '../../helpers/common';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CopyToClipBoard from 'Components/CopyToClipBoard';
import { timezonesAllowed } from 'constants/timezones';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import GoogleWorkspace from '../../assets/images/icons/Google_G_Logo.svg';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FILE_SECTION, FILE_TYPE } from "../../constants/constants";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
      padding: '0 8px',
    },
  },
};

const URL = process.env.REACT_APP_API_URL;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const section = FILE_SECTION.images;
const IMAGE_URL2 = process.env.REACT_APP_FILE_MODULE_URL;

const pages = [
  { value: 'About Us', label: 'About Us' },
  { value: 'Home Page', label: 'Home Page' },
  { value: 'Registration Page', label: 'Registration Page' },
  { value: 'Login Page', label: 'Login Page' },
  { value: 'Agenda Page', label: 'Agenda Page' },
];

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 48,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

class EventRegistration extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.chooseFavicon = React.createRef(null);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      initialDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      time: moment().format('HH:mm'),
      endTime: moment().format('HH:mm'),
      currentTime: moment().format('HH:mm'),
      eventName: '',
      eventTags: '',
      eventDomain: '',
      location: '',
      errors: {},
      loading: true,
      editPageRequest: false,
      lat: 0,
      lng: 0,
      domainSuccess: '',
      timerClock: false,
      replyToEmail: '',
      timezones: [],
      timezone: '',
      emailSenderName: '',
      password: '',
      attendees: 0,
      passwordType: 'No_Password',
      chipItems: [],
      chipError: null,
      EarlyAccessToggle: false,
      earlyAccessType: '',
      accessDetailsForAll: [],
      accessDetailsForGroups: [],
      listOfPages: [],
      selectedPages: [],
      listOfGroups: [],
      setHomePageId: '',
      pageOptions: [],
      searchTimezone: '',
      faviconImage: '',
      openRemoveFaviconConfirmationModal: false,
      isProfileVisible: false,
      profilePosition: 'top-right',
      isAccessCode: false,
      openRemoveVanityDomainModal: false,
      vanityDomain: {
        _id: '',
        sslCertificate: {
          _id: '',
          id: '',
          status: '',
          cname: {
            _id: '',
            name: '',
            value: '',
            type: '',
          },
        },
        cloudFrontDistribution: {
          _id: '',
          id: '',
          domain: '',
          status: '',
          enabled: false,
        },
        domainName: '',
      },
      isVanityDomain: false,
      eventId: '',
      isCopied: false,
      isSSOEnabled: false,
      openPasswordProtection: false,
      durationDisable: false,
      description: '',
      projectDescription: '',
      uploadFromFiles: false,
      buttonState: false,
      fileNameInS3: "",
      destinationFolder: "",
      userId: "",
      token: "",
      displayS3Image: false,
      parentId:"",
      parentForPopup:"Edit Project"
    };
  }

  handleClosePasswordProtection = () => {
    this.setState({
      openPasswordProtection: false,
    });
  };

  handleOpenPasswordProtection = () => {
    this.setState({
      openPasswordProtection: true,
    });
  };

  handleTogglePasswordProtection = () => {
    this.setState({
      openPasswordProtection: !this.state.openPasswordProtection,
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        openPasswordProtection: false,
      });
    }
  };

  handleToggleEarlyAccess = () => {
    if (!this.state.EarlyAccessToggle) {
      this.setState({
        EarlyAccessToggle: true,
        earlyAccessType: 'all',
        accessDetailsForAll: [
          {
            accessDate: new Date(),
            accessPages: [],
          },
        ],
        accessDetailsForGroups: [
          {
            accessDate: new Date(),
            accessPages: [],
            groupId: '',
          },
        ],
      });
    } else {
      this.setState({
        EarlyAccessToggle: false,
        earlyAccessType: '',
        accessDetailsForAll: [],
        accessDetailsForGroups: [],
      });
    }
  };

  handleEnableProfile = () => {
    this.setState({ isProfileVisible: !this.state.isProfileVisible });
  };

  handleEnableAccessCode = () => {
    this.setState({ isAccessCode: !this.state.isAccessCode });
  };

  handleEarlyAccessType = (accessType) => {
    this.setState({
      earlyAccessType: accessType,
    });
  };

  handleEarlyAccessGroups = () => {
    this.setState({
      EarlyAccessAll: false,
      EarlyAccessGroups: true,
    });
  };

  handleChipsKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.eventTags.trim();
      if (value && this.isChipValid(value)) {
        this.setState({
          chipItems: [...this.state.chipItems, this.state.eventTags.trim()],
          eventTags: '',
        });
      }
    }
  };
  handleChipsChange = (evt) => {
    this.setState({
      eventTags: evt.target.value,
      chipError: null,
    });
  };

  handleChipDelete = (item) => {
    this.setState({
      chipItems: this.state.chipItems.filter((i) => i !== item),
    });
  };

  handleChipPaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData('text');
    var emails = paste.match('^[a-zA-Z ]*$');
    if (this.state.chipItems && emails) {
      var toBeAdded = emails.filter(
        (email) => !this.isInChipsList(email) && email.trim() !== '',
      );
      this.setState({
        chipItems: [...this.state.chipItems, ...toBeAdded],
      });
    }
  };

  bottomLeft = () => {
    this.setState({ profilePosition: 'bottom-left' });
  };

  bottomRight = () => {
    this.setState({ profilePosition: 'bottom-right' });
  };

  topLeft = () => {
    this.setState({ profilePosition: 'top-left' });
  };

  topRight = () => {
    this.setState({ profilePosition: 'top-right' });
  };

  isChipValid(value) {
    let chipError = null;
    if (this.isInChipsList(value)) {
      chipError = `${value} has already been added.`;
    }
    if (chipError) {
      this.setState({ chipError });
      return false;
    }
    return true;
  }

  isInChipsList(value) {
    return this.state.chipItems.map(v => v.toLowerCase()).includes(value.toLowerCase());
  }

  onChangeDate = (date) => {
    this.setState({ startDate: date });
  };

  onEndChangeDate = (date) => {
    this.setState({ endDate: date });
  };

  onChangeEarlyDateForAll = (date) => {
    let accessDetailsForAll = [
      {
        ...this.state.accessDetailsForAll[0],
        accessDate: date,
      },
    ];
    this.setState({ accessDetailsForAll: accessDetailsForAll });
  };

  onChangeEarlyDateForGroups = (date, i) => {
    let accessDetailsForGroups = this.state.accessDetailsForGroups;
    accessDetailsForGroups[i]['accessDate'] = date;
    this.setState({ accessDetailsForGroups: accessDetailsForGroups });
  };

  onChangeGroupForGroups = (e, i) => {
    let accessDetailsForGroups = this.state.accessDetailsForGroups;
    accessDetailsForGroups[i]['groupId'] = e.target.value;
    this.setState({ accessDetailsForGroups: accessDetailsForGroups });
  };

  onTimeChange = (time) => {
    this.setState({ time: time });
  };

  onEndTimeChange = (time) => {
    this.setState({ endTime: time });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onProjectNameChange = (e) => {
    const domain = /^[a-zA-Z0-9-]+$/;
    let formIsValid = true;
    let error = {};
    this.setState(
      {
        eventName: e.target.value,
        eventDomain: e.target.value.replace(/\s+/g, '').toLowerCase(),
      },
      () => {
        if (this.validDomain()) {
          this.checkDomain(e);
        }
      },
    );
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  isValid = () => {
    const {
      eventName,
      editPageRequest,
      eventDomain,
      location,
      startDate,
      endDate,
      openEarlyAccess,
      attendees,
      passwordType,
      time,
      replyToEmail,
      timezone,
      emailSenderName,
      accessDetailsForAll,
      accessDetailsForGroups,
      earlyAccessType,
      password,
      projectTypeId,
      projectDescription
    } = this.state;
    let error = {};
    let formIsValid = true;
    const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    const domain = /^[a-zA-Z0-9-]+$/;
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const currentDa = moment().format('MM/DD/YYYY');
    const cDate = currentDa + ' ' + '00:00:00';
    const currentDateOnly = moment(cDate).format('X');
    const currentDate = moment().format('X');
    const startDate1 = moment(startDate).format('MM/DD/YYYY');
    const startD = startDate1 + ' ' + time;
    const startDOnly = startDate1 + ' ' + '00:00:00';
    const startDateSeconds = moment(startD).format('X');
    const startDateOnly = moment(startDOnly).format('X');

    if (replyToEmail === '' || replyToEmail.trim().length === 0) {
      formIsValid = false;
      error['replyToEmail'] = this.props.users.languageStateForRedux?.validations?.replyToEmail;
    }

    if (replyToEmail && regexTest.test(replyToEmail) === false) {
      formIsValid = false;
      error['replyToEmail'] = this.props.users.languageStateForRedux?.validations?.emailAdd;
    }

    if (
      timezone?.timezone === '' ||
      timezone?.timezone === undefined ||
      timezone?.timezone?.trim().length === 0 ||
      timezone?.timezone === null
    ) {
      if (
        timezone === '' ||
        timezone === undefined ||
        timezone === null
      ) {
        formIsValid = false;
        error['timezoneNew'] = this.props.users.languageStateForRedux?.validations?.timezone;
      }
    }

    if (eventName === '' || eventName.trim().length === 0) {
      formIsValid = false;
      error['eventName'] = this.props.users.languageStateForRedux?.validations?.eventName;
    }

    if (eventName && eventName.trim().length > 25) {
      formIsValid = false;
      error['eventName'] = this.props.users.languageStateForRedux?.validations?.only25;
    }

    if (eventDomain === '' || eventDomain.trim().length === 0) {
      formIsValid = false;
      error['eventDomain'] = this.props.users.languageStateForRedux?.validations?.eventDomain;
    }

    if (eventDomain && domain.test(eventDomain) === false) {
      formIsValid = false;
      error['eventDomain'] = this.props.users.languageStateForRedux?.validations?.validDomain;
    }

    if (startDate === '' || startDate === null) {
      formIsValid = false;
      error['startDate'] = this.props.users.languageStateForRedux?.validations?.startDate;
    }

    if (endDate === '' || endDate === null) {
      formIsValid = false;
      error['endDate'] = this.props.users.languageStateForRedux?.validations?.endDate;
    }

    let sd = new Date(startDate);
    let ed = new Date(endDate);

    if (sd.getTime() > ed.getTime()) {
      formIsValid = false;
      error['endDate'] = this.props.users.languageStateForRedux?.validations?.endGreaterThanStart;
    }

    if (openEarlyAccess === '' || openEarlyAccess === null) {
      formIsValid = false;
      error['openEarlyAccess'] = this.props.users.languageStateForRedux?.validations?.earlyAccessDate;
    }

    if (attendees === '' || attendees <= 0) {
      formIsValid = false;
      error['attendees'] = this.props.users.languageStateForRedux?.validations?.greaterThanOne;
    }

    if (!Number.isInteger(Number(attendees))) {
      formIsValid = false;
      error['attendees'] = this.props.users.languageStateForRedux?.validations?.integer;
    }

    if (passwordType === '' || passwordType === null) {
      formIsValid = false;
      error['passwordType'] = this.props.users.languageStateForRedux?.validations?.enterPassType;
    }

    if (
      passwordType === 'Common_Password' &&
      (password === '' || password === null || password.trim().length === 0)
    ) {
      formIsValid = false;
      error['password'] = this.props.users.languageStateForRedux?.validations?.enterPass;
    }

    if (time === '' || time.trim().length === 0) {
      formIsValid = false;
      error['time'] = this.props.users.languageStateForRedux?.validations?.time;
    }

    if (time && timeRegex.test(time) === false) {
      formIsValid = false;
      error['time'] = this.props.users.languageStateForRedux?.validations?.validTime;
    }

    if (emailSenderName === '' || emailSenderName.trim().length === 0) {
      formIsValid = false;
      error['emailSenderName'] = this.props.users.languageStateForRedux?.validations?.senderName;
    }

    if (emailSenderName && emailSenderName.trim().length > 78) {
      formIsValid = false;
      error['emailSenderName'] = this.props.users.languageStateForRedux?.validations?.only78;
    }

    if (earlyAccessType === 'all') {
      if (accessDetailsForAll[0].accessPages.length == 0) {
        formIsValid = false;
        error['earlyAccessPagesForAll'] = this.props.users.languageStateForRedux?.validations?.atleastOnePage;
      }
    }
    if (earlyAccessType === 'groups') {
      if (
        accessDetailsForGroups.length !== 1 &&
        accessDetailsForGroups[accessDetailsForGroups.length - 1].accessPages
          .length == 0 &&
        (accessDetailsForGroups[accessDetailsForGroups.length - 1].groupId ===
          '' ||
          accessDetailsForGroups[accessDetailsForGroups.length - 1].groupId ===
          'Select Group')
      ) {
        let lv_accessDetailsForGroups = [...accessDetailsForGroups];
        lv_accessDetailsForGroups.splice(accessDetailsForGroups.length - 1, 1);
        this.setState({ accessDetailsForGroups: lv_accessDetailsForGroups });
      } else {
        if (
          accessDetailsForGroups[accessDetailsForGroups.length - 1].accessPages
            .length == 0
        ) {
          formIsValid = false;
          error[
            `earlyAccessPagesForGroups${accessDetailsForGroups.length - 1}`
          ] = this.props.users.languageStateForRedux?.validations?.atleastOnePage;
        }
        if (
          accessDetailsForGroups[accessDetailsForGroups.length - 1].groupId ===
          '' ||
          accessDetailsForGroups[accessDetailsForGroups.length - 1].groupId ===
          'Select Group'
        ) {
          formIsValid = false;
          error[
            `earlyAccessGroupsForGroups${accessDetailsForGroups.length - 1}`
          ] = this.props.users.languageStateForRedux?.validations?.selectGroup;
        }
      }
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  validDomain = () => {
    let error = {};
    let formIsValid = true;
    const domain = /^[a-zA-Z0-9-]+$/;

    if (
      this.state.eventDomain &&
      domain.test(this.state.eventDomain) === false
    ) {
      formIsValid = false;
      error['eventDomain'] = this.props.users.languageStateForRedux?.validations?.validDomain;
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      let {
        eventName,
        eventDomain,
        eventTags,
        chipItems,
        location,
        password,
        startDate,
        endDate,
        time,
        endTime,
        lat,
        lng,
        openEarlyAccess,
        replyToEmail,
        timezone,
        emailSenderName,
        attendees,
        passwordType,
        setHomePageId,
        isProfileVisible,
        profilePosition,
        faviconImage,
        isAccessCode,
        isSSOEnabled,
        projectTypeId,
        projectDescription,
      } = this.state;
      const emailTemplate = sampleEvent;
      const { projectTemplateId } = this.props.saveDetails;
      startDate = convertTZ(startDate, timezone.timezone);
      endDate = convertTZ(endDate, timezone.timezone);
      const requestbody = {
        eventName,
        eventDomain: eventDomain.toLowerCase(),
        eventTags: JSON.stringify(chipItems),
        location: location.trim(),
        startDate,
        endDate,
        time,
        endTime,
        eventType: this.props.events.projectType,
        emailTemplate,
        emailTemplateJson,
        lat,
        lng,
        replyToEmail,
        isSSOEnabled: isSSOEnabled,
        timezone: timezone.timezone,
        password,
        emailSenderName: emailSenderName.trim(),
        openEarlyAccess,
        attendees,
        projectTypeId,
        projectTemplateId,
        passwordType,
        isProfileVisible,
        profilePosition,
        faviconImage,
        isAccessCode,
        projectDescription,
      };

      let formData = new FormData();

      for (let key in requestbody) {
        if (requestbody[key] !== undefined) {
          formData.append(key, requestbody[key]);
        }
      }
      this.props.createEvent(formData);
      this.handleLoading();
    }
  };

  handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      let {
        eventName,
        eventDomain,
        eventTags,
        chipItems,
        location,
        password,
        startDate,
        endDate,
        time,
        endTime,
        lat,
        lng,
        openEarlyAccess,
        replyToEmail,
        timezone,
        emailSenderName,
        attendees,
        passwordType,
        accessDetailsForAll,
        accessDetailsForGroups,
        earlyAccessType,
        setHomePageId,
        faviconImage,
        destinationFolder,
        fileNameInS3,
        isProfileVisible,
        profilePosition,
        isAccessCode,
        isSSOEnabled,
        projectTypeId,
        projectDescription
      } = this.state;
      const emailTemplate = sampleEvent;
      const { projectTemplateId } = this.props.saveDetails;
      let lv_earlyAccess = {};
      if (earlyAccessType !== '') {
        lv_earlyAccess.accessType = earlyAccessType;
        if (earlyAccessType === 'all') {
          let lv_accessDetailsForAll = JSON.parse(
            JSON.stringify(accessDetailsForAll),
          );
          lv_accessDetailsForAll[0].accessPages =
            lv_accessDetailsForAll[0].accessPages.map((page) => page.value);
          lv_accessDetailsForAll[0].accessDate = convertTZ(
            lv_accessDetailsForAll[0].accessDate,
            timezone,
          );
          lv_earlyAccess.accessDetails = lv_accessDetailsForAll;
        } else if (earlyAccessType === 'groups') {
          let lv_accessDetailsForGroups = JSON.parse(
            JSON.stringify(accessDetailsForGroups),
          );
          lv_accessDetailsForGroups?.forEach((accessDetail) => {
            accessDetail.accessDate = convertTZ(
              accessDetail.accessDate,
              timezone,
            );
            accessDetail.accessPages = accessDetail?.accessPages?.map(
              (page) => page.value,
            );
          });
          lv_earlyAccess.accessDetails = lv_accessDetailsForGroups;
        }
      }

      startDate = convertTZ(startDate, timezone);
      endDate = convertTZ(endDate, timezone);

      let requestbody = {
        projectId: this.props.project.project._id,
        eventName,
        location: location.trim(),
        startDate,
        endDate,
        time,
        endTime,
        emailTemplate,
        emailTemplateJson,
        lat,
        lng,
        replyToEmail,
        password,
        emailSenderName: emailSenderName.trim(),
        openEarlyAccess,
        attendees,
        projectTypeId,
        projectTemplateId,
        passwordType,
        earlyAccess: JSON.stringify(lv_earlyAccess),
        isProfileVisible,
        setHomePageId,
        faviconImage,
        fileNameInS3,
        destinationFolder,
        eventTags: JSON.stringify(chipItems),
        timezone: timezone.timezone !== undefined ? timezone.timezone : timezone,
        profilePosition,
        eventDomain,
        isAccessCode,
        isSSOEnabled: isSSOEnabled,
        lngCode: this.props.lngCode,
        projectDescription
      };
      let formData = new FormData();

      for (let key in requestbody) {
        if (requestbody[key] !== undefined) {
          formData.append(key, requestbody[key]);
        }
      }
      this.props.handleSetErrorToFalse();
      this.props.updateProjectRequest(formData);
      this.handleLoading();
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    if (
      this.props.saveDetails.projectId != undefined &&
      this.props.saveDetails.projectId != ''
    ) {
      this.props.getProjectRequest(this.props.saveDetails.projectId);
      this.setState({ loading: true });
    }

    if (
      this.props.saveDetails.projectTypeId != undefined &&
      this.props.saveDetails.projectTypeId != ''
    ) {
      this.setState({ projectTypeId: this.props.saveDetails.projectTypeId });
    }
    if (
      this.props.saveDetails.oldPasswordType != undefined &&
      this.props.saveDetails.oldPasswordType != ''
    ) {
      this.setState({ passwordType: this.props.saveDetails.oldPasswordType });
    }

    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords.latitude) {
      }
    });

    GetTimezoneAPI()
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({
            timezones: result.data.timezone,
            timezonesComplete: result.data.timezone,
          });
        } else {
          createNotification('error', result.data.message);
        }
      })
      .catch(console.log);
    this.setState({ loading: false });
    const arr = window.location.href.split('/');
    const projectId = arr[arr.length - 1];
    const apiUrl = process.env.REACT_APP_API_URL;
    this.setState({ eventId: projectId });
    this.listgroups();
  }

  async getProjectRequest(eventId) {
    this.setState({ loading: true });
    const res = this.props.getProjectRequest(eventId);
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.vanityState?.actionType !==
      prevProps.vanityState?.actionType &&
      (this.props.vanityState?.actionType === 'CREATE_VANITY_DOMAIN_SUCCESS'
        || this.props.vanityState?.actionType === 'REMOVE_VANITY_DOMAIN_SUCCESS')
    ) {
      this.setState({
        vanityDomain: { ...this.props.vanityState.vanityDomain },
      });
    }
    if (
      this.props.project.getProjectSuccess &&
      !prevProps.project.getProjectSuccess &&
      this.props.project.message !== prevProps.project.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false, lngCode: this.props.project.project.language }) : null;
      let lv_accessDetails =
        this.props.project.project.earlyAccess?.accessDetails;
      if (lv_accessDetails) {
        lv_accessDetails?.forEach((accessDetail) => {
          accessDetail.accessDate = moment
            .tz(accessDetail.accessDate, this.props.project.project.timezone)
            .format('MM/DD/YYYY h:mm a');
          accessDetail.accessPages = accessDetail?.accessPages
            ?.filter((page) => page.isHomePage === false)
            .map((page) => {
              return { value: page._id, label: page.pageName };
            });
          accessDetail.groupId = accessDetail?.groupId?._id;
        });
      }



      this.setState(
        {
          editPageRequest: true,
          projectTypeId: this.props.project.project?.projectTypeId[0],
          eventName: this.props.project.project.eventName,
          eventId: this.props.project.project._id,
          eventDomain: this.props.project.project.eventDomain,
          chipItems: this.props.project.project.eventTags,
          startDate: moment
            .tz(
              this.props.project.project.startDate,
              this.props.project.project.timezone,
            )
            .format('MM/DD/YYYY, hh:mm:ss A'),
          endDate: moment
            .tz(
              this.props.project.project.endDate,
              this.props.project.project.timezone,
            )
            .format('MM/DD/YYYY, hh:mm:ss A'),
          timezone: this.props.project.project.timezone,
          projectDescription: this.props.project.project.projectDescription,
          location: this.props.project.project.location,
          openEarlyAccess: this.props.project.project.openEarlyAccess,
          attendees: this.props.project.project.attendees,
          replyToEmail: this.props.project.project.replyToEmail,
          emailSenderName: this.props.project.project.emailSenderName,
          passwordType: this.props.project.project.passwordType,
          password: this.props.project.project.password,
          EarlyAccessToggle: this.props.project.project.earlyAccess?.accessType
            ? true
            : false,
          earlyAccessType: this.props.project.project.earlyAccess?.accessType,
          isProfileVisible: this.props.project.project?.isProfileVisible,
          profilePosition: this.props.project.project?.profilePosition,
          accessDetailsForAll:
            this.props.project.project.earlyAccess?.accessType == 'all'
              ? lv_accessDetails
              : [{ accessDate: new Date(), accessPages: [] }],
          accessDetailsForGroups:
            this.props.project.project.earlyAccess?.accessType == 'groups'
              ? lv_accessDetails
              : [{ groupId: '', accessDate: new Date(), accessPages: [] }],
          faviconImage: this.props.project.project.faviconImage,
          isAccessCode: this.props.project?.project?.isAccessCode,
          editFaviconImage: true,
          vanityDomain: this.props.project?.project?.vanityDomain
            ? this.props.project?.project?.vanityDomain
            : this.state.vanityDomain,
          isSSOEnabled: this.props.project.project.isSSOEnabled,
          durationDisable: this.props.project.project?.meetingsCreated ? this.props.project.project.meetingsCreated : this.state.durationDisable
        },
        () => this.getProjectTemplate(),
      );
    }

    if (this.props.project.updateSuccess && !prevProps.project.updateSuccess) {
      setTimeout(() => {
        let st = prevState.loading ? this.setState({ loading: false }) : null;
        createNotification('success', this.props.project.message);
        this.props.getProjectRequest(this.props.saveDetails.projectId);
        window.location.href = generateUrl(`/project-dashboard/overview/${this.props.saveDetails.projectId}`);
      }, 100);
    }

    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        createNotification('success', this.props.events.message);
        this.setState({
          chipError: null
        });
        const startDate1 = moment(this.props.events.event.startDate).format(
          'MM/DD/YYYY',
        );
        const startD = startDate1 + ' ' + this.props.events.event.time;
        const endDate1 = moment(this.props.events.event.startDate).format(
          'MM/DD/YYYY',
        );
        const endDa = endDate1 + ' ' + this.props.events.event.time;
        setTimeout(() => {
          document.getElementById('finishPopup').click();
          this.setState({ loading: false });
        }, 500);
        this.props.handleProjectType('');
      }

      if (
        this.props.events.error &&
        this.props.events.error !== prevProps.events.error
      ) {
        createNotification('error', this.props.events.message);
        if (this.state.loading) { this.setState({ loading: false }); }
      }
      if (
        this.props.events.domainError !== prevProps.events.domainError
      ) {
        if (this.state.loading) { this.setState({ loading: false }); }
      }

      if (
        this.props.events.domainSuccess === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        this.setState({ domainSuccess: this.props.events.message, errors: {} });
      }
      if (
        this.props.events.domainError &&
        this.props.events.domainError !== prevProps.events.domainError
      ) {
        let error = {};
        error['eventDomain'] = this.props.events.message;
        this.setState({ errors: error, domainSuccess: '' });
      }
    }
    if (
      this.props.attendees.getAttendeesGroupsSuccess &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        listOfGroups: this.props.attendees.attendeesGroups,
      });
    }
  }

  listgroups = () => {
    try {
      const arr = window.location.href.split('/');
      const projectId = arr[arr.length - 1];
      const body = {
        projectId,
        page_no2: 1,
        searchText: '',
      };
      if (projectId) {
        this.props.listAttendeesGroups(body);
        this.handleLoading();
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  async getProjectTemplate() {
    const arr = window.location.href.split('/');
    const projectId = arr[arr.length - 1];
    const users = await this.UserData();
    this.setState({ userId: users._id, token: users.token });
    let requestFrom = "project";
    let requestCheck = "editProject";
    try {
      const response = await axios.get(
        `${URL}/getProjectTemplate?projectId=${projectId}&requestFrom=${requestFrom}&requestCheck=${requestCheck}`,
        {
          headers: {
            token: `bearer ${users.token}`,
          },
        },
      );
      if (response.data && response.data.status == 200) {
        let listOfPages = [];
        response.data.templates.forEach((page) => {
          listOfPages.push({
            value: page._id,
            label: page.pageName,
            isLoginPage: page.isLoginPage,
            isHomePage: page.isHomePage,
          });
        });
        listOfPages = listOfPages.filter((page) => !page.isLoginPage);

        let homePage = response.data.templates.filter(
          (page) => page.isHomePage === true,
        );
        if (homePage.length) {
          this.setState({
            setHomePageId: homePage[0]._id,
          });
        } else {
          this.setState({
            setHomePageId: response.data.templates[0]._id,
          });
        }
        let pageOptions = response.data.templates.map((page) => {
          return {
            value: page._id,
            label: page.pageName,
            isLoginPage: page.isLoginPage,
            isHomePage: page.isHomePage,
          };
        });
        this.setState({
          listOfPages,
          pageOptions: [...pageOptions],
        });
      } else {
        throw Error(response.data.error);
      }
    } catch (err) {
      createNotification('error', err.message);
    }
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  handleChangeLocation = (location) => {
    this.setState({ location: location });
  };
  handleSelect = (location) => {
    this.setState({ location });

    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch((error) => console.error('Error', error));
  };

  addEventToCalendars = (eventData, date) => {
    let startTime = moment(`${eventData.startDate}:00.000Z`)
      .add('8', 'hours')
      .format();
    let endTime = moment(`${eventData.endDate}:00.000Z`)
      .add('8', 'hours')
      .add('30', 'minutes')
      .format();

    var gapi = window.gapi;
    var CLIENT_ID =
      '711204863226-kg8eoih74guqc93bm4rpfchnpe37p3hh.apps.googleusercontent.com';
    var API_KEY = 'yBmczulIMZE2ShABqGi5vPOt';
    var SCOPES = 'https://www.googleapis.com/auth/calendar';
    var DISCOVERY_DOCS = [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    ];

    gapi.load('client:auth2', () => {
      gapiClient.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
              'HH:mm',
            ]).format('hh:mm A')} PST`,
            description: `${eventData.google_meet}`,
            start: {
              dateTime: startTime,
              timeZone: moment1.tz.guess(),
            },
            end: {
              dateTime: endTime,
              timeZone: moment1.tz.guess(),
            },
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 },
                { method: 'popup', minutes: 10 },
              ],
            },
          };

          var request = gapiClient.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          request.execute((event) => {
            if (event.status == 'confirmed') {
              createNotification('success', 'Event added to google calendar.');
            } else {
              createNotification(
                'danger',
                'Unable to add event to calendar. Please try again later.',
              );
            }
            window.open(event.htmlLink);
          });
        });
    });
  };

  checkDomain = (e) => {
    if (e.target.value.length > 0 && e.target.value.trim()) {
      if (
        this.props.project &&
        this.state.editPageRequest == true &&
        this.props.project.project._id
      ) {
        let result = this.props.checkEventDomain({
          eventDomain: e.target.value.replace(/\s+/g, ''),
          eventId: this.props.project.project._id,
        });
        setTimeout(() => {
          this.setState({ domainSuccess: '' });
          this.props.project.success = true;
          this.props.events.domainSuccess = false;
        }, 1000);
      } else {
        this.props.checkEventDomain({
          eventDomain: e.target.value.replace(/\s+/g, ''),
        });
      }
    } else {
      this.setState({ errors: {}, domainSuccess: '' });
    }
  };

  IncrementItem = () => {
    let attendees = parseInt(this.state.attendees);
    this.setState({ attendees: attendees + 1 });
    if (this.state.attendees === '' || this.state.attendees === undefined) {
      this.setState({
        attendees: 0,
      });
    }
  };

  decrementCount = () => {
    let { attendees } = this.state;
    if (attendees > 0) {
      this.setState({ attendees: this.state.attendees - 1 });
    }
    if (this.state.attendees === '' || this.state.attendees === undefined) {
      this.setState({
        attendees: 0,
      });
    }
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value
    }, this.passwordType);
    this.handleClosePasswordProtection();
  };

  getSelectedOptionsFromCustomSelectForAll = (selectedOptions) => {
    let accessDetailsForAll = [
      {
        ...this.state.accessDetailsForAll[0],
        accessPages: selectedOptions,
      },
    ];
    this.setState({ accessDetailsForAll: accessDetailsForAll });
  };

  getSelectedOptionsFromCustomSelectForGroups = (selectedOptions, i) => {
    let accessDetailsForGroups = this.state.accessDetailsForGroups;
    accessDetailsForGroups[i] = {
      ...accessDetailsForGroups[i],
      accessPages: selectedOptions,
    };
    this.setState({ accessDetailsForGroups: accessDetailsForGroups });
  };

  addEarlyAccessGroupSection = (i) => {
    let error = {};
    let formIsValid = true;
    let accessDetailsForGroups = this.state.accessDetailsForGroups;
    if (
      accessDetailsForGroups[i].groupId === '' ||
      accessDetailsForGroups[i].groupId === 'Select Group'
    ) {
      error[`earlyAccessGroupsForGroups${i}`] = 'Please select a group.';
      formIsValid = false;
    }
    if (accessDetailsForGroups[i].accessPages.length == 0) {
      error[`earlyAccessPagesForGroups${i}`] =
        'Please select at least one page.';
      formIsValid = false;
    }

    this.setState({
      errors: error,
    });

    if (!formIsValid) {
      return;
    }

    accessDetailsForGroups.push({
      accessDate: new Date(),
      accessPages: [],
      groupId: '',
    });
    this.setState({
      accessDetailsForGroups: accessDetailsForGroups,
    });
  };

  removeEarlyAccessGroupSection = (i) => {
    let accessDetailsForGroups = this.state.accessDetailsForGroups;
    accessDetailsForGroups.splice(i, 1);
    this.setState({
      accessDetailsForGroups: accessDetailsForGroups,
    });
  };

  selectHomePage = (e) => {
    this.setState({
      setHomePageId: e.target.value,
    });
  };

  searchTimezoneFromList = (e) => {
    this.setState(
      {
        searchTimezone: e.target.value,
      },
      () => {
        let timezones;
        if (this.state.searchTimezone !== '') {
          timezones = this.state.timezonesComplete.filter((el) =>
            el.timezone
              .toLowerCase()
              .includes(this.state.searchTimezone.toLowerCase()),
          );
          this.setState({
            timezones,
            timezone: timezones[0]?.timezone && timezones[0]?.timezone,
          });
        }
      },
    );
  };

  handleSearchTimezone = (e, value) => {
    this.setState({
      timezone: value,
    });
  };

  onFaviconImageChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|ico)$/)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 2000000) {
        //5 mb
        createNotification(
          'error',
          'Please select image that size is less than 2MB.',
        );
        return false;
      }

      this.setState({
        uploadFromFiles: true
      });

      let formData = new FormData();

      formData.append('imageFile', event.target.files[0]);
      formData.append('section', section);
      formData.append('type', FILE_TYPE.file);
      formData.append('parent', this.state.parentId);
      this.props.addImagesRequest((formData));

    }
  };

  openRemoveFaviconConfirmationModal = () => {
    this.setState({
      openRemoveFaviconConfirmationModal: true,
    });
  };

  closeRemoveFaviconConfirmationModal = () => {
    this.setState({
      openRemoveFaviconConfirmationModal: false,
    });
  };

  uploadFromFiles = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };

  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  removeFavicon = () => {
    this.setState(
      {
        faviconImage: '',
      },
      () => {
        this.closeRemoveFaviconConfirmationModal();
      },
    );
  };

  chooseFaviconImageClick = (id) => {
    this.setState({
      parentId:id
    })
    this.chooseFavicon.current.value = null;
    this.chooseFavicon.current.click();
    
  };
  

  handleChoosedFile = (file) => {
    if(file?.size > 2097152){ // if more than 2 mb
      createNotification('error', "Please choose image less than 2Mb");
      return false;
    }
    this.setState({
      faviconImage: file,
      destinationFolder: "faviconImage",
      fileNameInS3: file.fileNameInS3,
      uploadFromFiles: false,
      displayS3Image: true
    });

  };


  renderStatusStep = (isCompleted, stepNumber, label) => {
    // if (isCompleted) {
    return (
      <Stack
        className="steps"
        mb={2}
        direction="row"
        justifyContent={'space-between'}
      >
        <Box component={Stack} direction="row">
          {isCompleted ? (
            <Box className="step done">
              <CheckCircleIcon className="icon" color="primary" />
            </Box>
          ) : (
            <Box className="step in-process">{stepNumber}</Box>
          )}
          <Box className="small-subtitle">{label}</Box>
        </Box>
      </Stack>
    );
  };

  renderVanityDomainUI = () => {
    const { isVanityDomain, vanityDomain, eventDomain } = this.state;
    if (!vanityDomain) {
      return null;
    }
    const showVanityData =
      vanityDomain?.sslCertificate?.id &&
      vanityDomain?.cloudFrontDistribution?.id &&
      vanityDomain.isActive;

    const isCloudFrontVerificationPending =
      vanityDomain?.cloudFrontDistribution?.id && true;

    const isCloudFrontVerified =
      vanityDomain?.cloudFrontDistribution?.id &&
      vanityDomain?.cloudFrontDistribution?.status === 'Deployed';

    const isSSLVerified =
      vanityDomain?.sslCertificate?.id &&
      vanityDomain?.sslCertificate?.status === 'ISSUED';
    return (
      <Box>
        {/* -----Enable Vanity Domain----- */}
        {!showVanityData ? (
          <Stack spacing={3} ml={-1.5} mb={1}>
            <Grid container alignItems="center" mb={3}>
              <Grid >
                <CustomSwitch
                  checked={isVanityDomain}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      isVanityDomain: e.target.checked,
                    })
                  }
                  name="isVanityDomain"
                  color="primary"
                />
              </Grid>

              <Grid >
                <Stack direction={'row'}>
                  <p className="paragraph secondary-text">
                    <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.enableVan}</strong>
                  </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Stack>
            <br />
          </Stack>
        )}
        {/* ----- Vanity Domain-details ---- */}
        <Box>
          {(isVanityDomain || showVanityData) && (
            <>
              <Box class="field-group width-100 inputField">
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.overview?.vanDom}
                  {!showVanityData && <span className="required">*</span>}
                </label>
                {!showVanityData ? (
                  <>
                    <input
                      type="text"
                      placeholder={this.props.users.languageStateForRedux?.formfields?.overview?.domainHolderText}
                      class="form-control"
                      name="eventName"
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          vanityDomain: {
                            ...this.state.vanityDomain,
                            domainName: e.target.value?.toLowerCase(),
                          },
                        })
                      }
                      value={vanityDomain?.domainName}
                    />
                    <br></br>
                    <Button
                      style={{ textTransform: 'capitalize' }}
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={this.props?.vanityState?.loading}
                      onClick={(e) => {
                        if (!vanityDomain?.domainName) {
                          return;
                        }
                        this.props.createVanityDomain({
                          vanityDomain,
                          eventId: this.props.project.project._id,
                          eventName: this.props.project.project.eventName,
                        });
                      }}
                    >
                      {this.props.users.languageStateForRedux?.overviewTab?.verifyButton}
                    </Button>
                  </>
                ) : (
                  <label>
                    {vanityDomain?.domainName}
                    {isSSLVerified && isCloudFrontVerified ? (
                      <Tooltip title="Domain Verified" arrow placement="right">
                        <Box ml={1} component={'span'}>
                          <VerifiedUserIcon
                            className="icon"
                            style={{ color: '#0addb4' }}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Waiting for verification"
                        arrow
                        placement="right"
                      >
                        <Box ml={1} component={'span'}>
                          <TimerIcon
                            className="icon"
                            style={{ color: '#f26135' }}
                          />
                        </Box>
                      </Tooltip>
                    )
                    }
                    {<Box onClick={() => this.setState({ openRemoveVanityDomainModal: true })}
                      style={{ marginLeft: "25px" }}
                      ml={1} component={'span'}>

                      <Tooltip
                        title="Remove domain from project"
                        arrow
                        placement="right"
                      >
                        <Box ml={1} component={'span'}>
                          <RemoveCircleOutlineIcon
                            className="icon"
                            style={{ color: '#ff0000' }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>}
                  </label>
                )}
                <p className="required small-subtitle"></p>
              </Box>
            </>
          )}
          <Box>
            {/* --- if loading/done loading -- */}
            {this.props?.vanityState?.loading && (
              <Stack
                component={Box}
                mt={2}
                direction="row"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <LoadingButton
                  style={{ minWidth: '16px' }}
                  loading="true"
                  loadingIndicator={
                    <CircularProgress color="inherit" size={18} />
                  }
                />
                {/* <CheckCircleIcon className="icon" color="primary" /> */}
                <Box
                  ml={1.2}
                  sx={{ color: '#000000' }}
                  className="small-subtitle"
                >
                  Processing domain please wait.
                </Box>
              </Stack>
            )}
            {/* --- after loaded -- */}
            {showVanityData && (!isCloudFrontVerified || !isSSLVerified) && (
              <Box>
                <Box mt={2}>
                  <Box mb={2} className="small-subtitle">
                    Add the following CName records data into the DNS
                    configuration for your domain provider settings and waiting
                    until domain will be verified.
                  </Box>
                  <Box className="cname-table" component={TableContainer}>
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="tableBodyCell short-text"
                            align="left"
                          >
                            Record Type
                          </TableCell>
                          <TableCell className="tableBodyCell" align="left">
                            Record Data
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!isSSLVerified && (
                          <TableRow>
                            <TableCell
                              className="tableBodyCell short-text"
                              align="left"
                            >
                              CNAME
                            </TableCell>
                            <TableCell className="tableBodyCell" align="left">
                              <Stack spacing={1}>
                                <Box>
                                  <strong>Record Name: &nbsp; &nbsp;</strong>
                                  {vanityDomain?.sslCertificate?.cname.name}

                                  <CopyToClipBoard
                                    value={
                                      vanityDomain?.sslCertificate?.cname.name
                                    }
                                  />
                                </Box>
                                <hr />
                                <Box>
                                  <strong>Record value: &nbsp; &nbsp;</strong>
                                  {vanityDomain?.sslCertificate?.cname.value}

                                  <CopyToClipBoard
                                    value={
                                      vanityDomain?.sslCertificate?.cname.value
                                    }
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )}
                        {!isCloudFrontVerified && (
                          <TableRow>
                            <TableCell
                              className="tableBodyCell short-text"
                              align="left"  
                            >
                              A
                            </TableCell>
                            <TableCell className="tableBodyCell" align="left">
                              <Stack spacing={1}>
                                <Box>
                                  <strong>Record Name: &nbsp; &nbsp;</strong>
                                  {vanityDomain.domainName}

                                  <CopyToClipBoard
                                    value={
                                      vanityDomain.domainName
                                    }
                                  />
                                </Box>
                                <hr />
                                <Box>
                                  <strong>Record Value: &nbsp; &nbsp;</strong>
                                  {vanityDomain?.cloudFrontDistribution?.domain}

                                  <CopyToClipBoard
                                    value={
                                      vanityDomain?.cloudFrontDistribution
                                        ?.domain
                                    }
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
                <Box className="domain-verification" mt={4} py={2.5} px={2}>
                  <Box className="paragraph">
                    Vanity domain verification status
                  </Box>

                  <Box mb={2.5} className="small-subtitle">
                    You have to follow the below steps to verify your vanity
                    domain
                  </Box>
                  {this.renderStatusStep(
                    vanityDomain.domainName,
                    1,
                    'Provide your own vanity domain',
                  )}
                  {this.renderStatusStep(
                    isCloudFrontVerificationPending,
                    2,
                    'Waiting for DNS configuration update & verify',
                  )}
                  {this.renderStatusStep(
                    isCloudFrontVerified,
                    3,
                    'Vanity domain verified successfully. It may take up to 30 mins for your site to appear worldwide.',
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <ConfirmationAlertPopUp
          openModal={this.state.openRemoveVanityDomainModal}
          closeModalFunc={() => this.setState({ openRemoveVanityDomainModal: false })}
          title="Confirmation" text="Are you sure you want to remove the domain from this event?"
          confirmationButtonText="Remove"
          confirmationButtonColor="primary"
          closeButtonText="Cancel"
          functionality={() => {
            this.props.removeVanityDomain(this.props.project.project._id);
            this.setState({ openRemoveVanityDomainModal: false });
          }} />
      </Box>
    );
  };

  handleSSOLoginCheckbox = (e) => {
    this.setState({
      isSSOEnabled: e.target.checked
    });
  };

  filteredListOfGroups = () => {
    const { listOfGroups } = this.state;
    let arr = [];
    Array.isArray(listOfGroups) && listOfGroups.length && listOfGroups?.forEach(g => {
      if (g.source !== "GoogleWorkspace") {
        arr.push(g);
      } else {
        if (g.showToGroup) {
          arr.push(g);
        }
      }
    });
    return arr;
  };

  render() {
    const {
      startDate,
      endDate,
      openEarlyAccess,
      time,
      endTime,
      eventDomain,
      eventTags,
      eventName,
      location,
      errors,
      loading,
      initialDate,
      password,
      domainSuccess,
      attendees,
      replyToEmail,
      timezones,
      emailSenderName,
      passwordType,
      editPageRequest,
      EarlyAccessToggle,
      EarlyAccessAll,
      EarlyAccessGroups,
      earlyAccessType,
      accessDetailsForAll,
      accessDetailsForGroups,
      listOfPages,
      listOfGroups,
      setHomePageId,
      pageOptions,
      faviconImage,
      displayS3Image,
      userId,
      token,
      fileNameInS3,
      editFaviconImage,
      vanityDomain,
      isSSOEnabled,
      projectDescription,
    } = this.state;

    const startDate1 = moment(startDate).format('MM/DD/YYYY');
    const startD = startDate1 + ' ' + time;

    const endDate1 = moment(endDate).format('MM/DD/YYYY');
    const endD = endDate1 + ' ' + time;

    let timezonesArr = timezonesAllowed.map((el) => {
      return {
        ...el,
        label:
          tease.dst(el.label) !== undefined
            ? `(UTC${tease.dst(el.label)}) ${el.label}`
            : el.label,
      };
    });

    let timezone =
      typeof this.state.timezone === 'string' &&
        this.state.timezone !== undefined &&
        this.state.timezone !== '' &&
        !this.state.timezone.includes('UTC')
        ? tease.dst(this.state.timezone)
          ? `(UTC${tease.dst(this.state.timezone)}) ${this.state.timezone}`
          : this.state.timezone
        : this.state.timezone !== ''
          ? this.state.timezone
          : '';

    const showVanityData =
      vanityDomain?.sslCertificate?.id &&
      vanityDomain?.cloudFrontDistribution?.id;
    return (
      <ThemeProvider theme={theme}>
        <div className="CPTabsDetails">
          {this.state.editPageRequest == true && (
            <>
              <div className="finishButton">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUpdateSubmit}
                >
                  {this.props.users.languageStateForRedux?.overviewTab?.saveButton}
                </Button>
              </div>
            </>
          )}

          {editPageRequest == false && (
            <>
              <div className="finishButton">
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  // endIcon={<img alt="ArrowRight" src={ArrowRight} />}
                  onClick={this.handleSubmit}
                >
                  {this.props.users.languageStateForRedux?.newProject?.finish}
                </Button>
              </div>
            </>
          )}
          <div className="rsvpEventfieldBox">
            <div className="formSection p-0">
              <form
                onSubmit={
                  editPageRequest == false
                    ? this.handleSubmit
                    : this.handleUpdateSubmit
                }
              >
                <div className="completeRegisterboxes eventRegistrationPage">
                  <div className="eventFullInput CRCircularBox">
                    <Box
                      class={
                        this.state.editPageRequest
                          ? 'field-group width-100 inputField'
                          : 'field-group width-50 inputField'
                      }
                    >
                      <label>
                        {this.props.users.languageStateForRedux?.formfields?.overview?.projName} <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="eventName"
                        value={eventName}
                        maxLength="63"
                        onChange={(e) => this.onProjectNameChange(e)}
                      />
                      <p className="required small-subtitle">
                        {errors.eventName}{' '}
                      </p>
                    </Box>
                    <Box
                      mb={2}
                      sx={
                        this.state.editPageRequest
                          ? { flex: '0 0 100%' }
                          : { flex: '0 0 48%' }
                      }
                    >
                      <Box className="inputField">
                        <label>
                          {this.props.users.languageStateForRedux?.formfields?.overview?.event}{' '}
                          {!this.state.eventId && (
                            <span className="required">*</span>
                          )}
                        </label>
                        {this.state.eventId && showVanityData ? (
                          <label>{eventDomain + '.illumeetxp.com'}</label>
                        ) : (
                          <Box
                            component={OutlinedInput}
                            sx={{
                              marginTop: '2px',
                              padding: '3px 0px',
                            }}
                            notched={false}
                            size="medium"
                            fullWidth
                            id="outlined-adornment-amount"
                            name="eventDomain"
                            className="emailLoweCase"
                            value={eventDomain}
                            onChange={this.handleChange}
                            onKeyUp={this.checkDomain}
                            inputProps={{ maxLength: 63 }}
                            endAdornment={
                              <Box
                                className="paragraph"
                                style={{
                                  backgroundColor: '#EDECF5',
                                  padding: '4px 13px',
                                  borderRadius: '4px',
                                  marginLeft: '10px',
                                }}
                              >
                                {'.illumeetxp.com'}
                              </Box>
                            }
                          />
                        )}

                        <p className="required small-subtitle">
                          {' '}
                          {errors.eventDomain}{' '}
                        </p>
                        {domainSuccess !== '' && !errors.eventDomain ? (
                          <p className="success_message"> {domainSuccess} </p>
                        ) : null}
                      </Box>

                      {this.state.editPageRequest &&
                        this.renderVanityDomainUI()}
                    </Box>

                    {this.state.editPageRequest == true ? (
                      <div class="field_group75Both">
                        <div class="field-group inputField ">
                          <Box
                            component="label"
                          >
                            {this.props.users.languageStateForRedux?.formfields?.overview?.addTags}{' '}
                          </Box>
                          <input
                            className={
                              'form-control input ' +
                              (this.state.chipError && ' has-chipError')
                            }
                            name="eventTags"
                            value={eventTags}
                            placeholder={this.props.users.languageStateForRedux?.formfields?.overview?.tagsPlacedholder}
                            onKeyDown={this.handleChipsKeyDown}
                            onChange={this.handleChipsChange}
                            onPaste={this.handleChipPaste}
                          />
                          {this.state.chipItems.map((item) => (
                            <div
                              style={{ display: 'inline' }}
                              className="tag-item"
                              key={item}
                            >
                              {item}
                              <button
                                type="button"
                                className="button"
                                onClick={() => this.handleChipDelete(item)}
                              >
                                &times;
                              </button>
                            </div>
                          ))}
                          {this.state.chipError && (
                            <p className="error_mesage required">
                              {this.state.chipError}
                            </p>
                          )}
                        </div>

                        <div class="field-group inputField ">
                          <label>
                            {this.props.users.languageStateForRedux?.formfields?.overview?.home} <span className="required">*</span>
                          </label>
                          <FormControl fullWidth size="small">
                            <Select
                              class="form-control select"
                              name="homePage"
                              MenuProps={MenuProps}
                              style={{ padding: '4px 0px', }}
                              displayEmpty
                              value={setHomePageId}
                              onChange={this.selectHomePage}
                            >
                              {pageOptions
                                .filter((temp) => temp.isLoginPage === false)
                                .map((page) => {
                                  return (
                                    <MenuItem value={page.value}>
                                      {page.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    ) : (
                      <div class="field-group inputField width-100">
                        <label>{this.props.users.languageStateForRedux?.formfields?.overview?.addTags} </label>
                        <input
                          type="text"
                          className={
                            'form-control input ' +
                            (this.state.chipError && ' has-chipError')
                          }
                          name="eventTags"
                          value={this.state.eventTags}
                          placeholder="Type or paste tags and press `Enter`..."
                          onKeyDown={this.handleChipsKeyDown}
                          onChange={this.handleChipsChange}
                          onPaste={this.handleChipPaste}
                        />
                        {this.state.chipItems.map((item) => (
                          <div
                            style={{ display: 'inline' }}
                            className="tag-item"
                            key={item}
                          >
                            {item}
                            <button
                              type="button"
                              className="button"
                              onClick={() => this.handleChipDelete(item)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                        {this.state.chipError && (
                          <p className="error_mesage required">
                            {this.state.chipError}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="field_group75Both">
                      <div class="field-group inputField">
                        <label>
                          {this.props.users.languageStateForRedux?.overviewTab?.startDateTime} <span className="required">*</span>
                        </label>
                        <div class="formDate">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              selected={startDate}
                              startDate={startDate}
                              value={startDate}
                              onChange={(newValue) => {
                                this.onChangeDate(newValue);
                              }}
                              inputFormat="MMM, dd yyyy, hh:mm a"
                            />
                          </LocalizationProvider>
                          <p className="required small-subtitle">
                            {' '}
                            {errors.startDate}{' '}
                          </p>
                        </div>
                      </div>

                      <div class="field-group inputField">
                        <label>
                          {this.props.users.languageStateForRedux?.overviewTab?.endDateTime} <span className="required">*</span>{' '}
                        </label>
                        <div class="formDate">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              selected={endDate}
                              endDate={endDate}
                              value={endDate}
                              onChange={(newValue) => {
                                this.onEndChangeDate(newValue);
                              }}
                              inputFormat="MMM, dd yyyy, hh:mm a"
                            />
                          </LocalizationProvider>
                          <p className="required small-subtitle">
                            {' '}
                            {errors.endDate}{' '}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field_group75Both">
                      <div class="field-group inputField timezone">
                        <label>
                          {this.props.users.languageStateForRedux?.formfields?.overview?.zone} <span className="required">*</span>
                        </label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={timezonesArr}
                          fullWidth
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, value) =>
                            this.handleSearchTimezone(e, value)
                          }
                          value={timezone}
                        />
                        <p className="required small-subtitle">
                          {errors?.timezoneNew}{' '}
                        </p>
                      </div>

                      <div class="field-group inputField">
                        <label>{this.props.users.languageStateForRedux?.overviewTab?.loc} </label>
                        <PlacesAutocomplete
                          value={location}
                          onChange={this.handleChangeLocation}
                          onSelect={this.handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="loc1">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Places ...',
                                  className: 'location-search-input ',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                    : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>

                    <div className="field_group75Both">
                      <Box
                        component="div"
                        style={{ position: 'relative' }}
                        className="field-group inputField"
                      >
                        <label>
                          {this.props.users.languageStateForRedux?.formfields?.overview?.passPro}{' '}
                          <span className="required">*</span>
                        </label>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            padding: '10px 15px',
                            borderRadius: '7px',
                            justifyContent: 'space-between',
                            textTransform: 'capitalize',
                          }}
                          size="large"
                          disableElevation
                          ref={this.anchorRef}
                          aria-controls={
                            this.state.openPasswordProtection
                              ? 'menu-list-grow'
                              : undefined
                          }
                          aria-haspopup="true"
                          onClick={this.handleTogglePasswordProtection}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {this.state.passwordType === "Common_Password" ? this.props.users.languageStateForRedux?.formfields?.overview?.commonPass
                            : this.state.passwordType === "No_Password" ? this.props.users.languageStateForRedux?.formfields?.overview?.nopass
                              : this.props.users.languageStateForRedux?.formfields?.overview?.privatePass}
                        </Button>
                        <Popper
                          open={this.state.openPasswordProtection}
                          style={{ right: 0, zIndex: 2 }}
                          placement="bottom-start"
                          transition
                          anchorEl={this.anchorRef.current}
                          role={undefined}
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Fade {...TransitionProps} timeout={250}>
                              <Box
                                sx={{
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '8px',
                                  marginTop: '8px',
                                  paddingLeft: '8px',
                                  paddingRight: '8px',
                                }}
                              >
                                <ClickAwayListener
                                  onClickAway={
                                    this.handleClosePasswordProtection
                                  }
                                >
                                  <MenuList
                                    autoFocusItem={
                                      this.state.openPasswordProtection
                                    }
                                    id="menu-list-grow"
                                    onKeyDown={this.handleListKeyDown}
                                  >

                                    <MenuItem value={'Common_Password'}
                                      onClick={() => this.handleOnChange("passwordType", "No_Password")}
                                      style={{ whiteSpace: 'normal', padding: '8px 8px 14px', borderRadius: '4px', }}>
                                      <Stack>
                                        <Box component={'span'} className=' primary-text'>{this.props.users.languageStateForRedux?.formfields?.overview?.nopass}</Box>
                                        <Box component={'span'} className='small-subtitle'>{this.props.users.languageStateForRedux?.formfields?.overview?.nopassMsg}</Box>
                                      </Stack>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => this.handleOnChange("passwordType", "Common_Password")}
                                      style={{ whiteSpace: 'normal', padding: '8px 8px 14px', borderRadius: '4px', }}>
                                      <Stack>
                                        <Box component={'span'} className=' primary-text'>{this.props.users.languageStateForRedux?.formfields?.overview?.commonPass}</Box>
                                        <Box component={'span'} className='small-subtitle'>{this.props.users.languageStateForRedux?.formfields?.overview?.commonPassMsg} </Box>
                                      </Stack>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => this.handleOnChange("passwordType", "Private_Password")}
                                      style={{ whiteSpace: 'normal', padding: '8px 8px 14px', borderRadius: '4px', }}>
                                      <Stack>
                                        <Box component={'span'} className=' primary-text'>{this.props.users.languageStateForRedux?.formfields?.overview?.privatePass}</Box>
                                        <Box component={'span'} className='small-subtitle'>{this.props.users.languageStateForRedux?.formfields?.overview?.privatePassMsg}</Box>
                                      </Stack>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Box>
                            </Fade>
                          )}
                        </Popper>
                      </Box>
                      <div class="field-group inputField">
                        <div class="row">
                          {this.state.passwordType === 'Common_Password' && (
                            <div class="col-md-6">
                              <label>
                                {this.props.users.languageStateForRedux?.formfields?.overview?.selectPass}{' '}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                              />
                              <p className="error_mesage">
                                {' '}
                                {errors.password}{' '}
                              </p>
                            </div>
                          )}

                          <div class="col-md-6">
                            <label>
                              {this.props.users.languageStateForRedux?.formfields?.overview?.attendees}{' '}
                              <span className="required">*</span>
                            </label>
                            <div className="sub-add-outer">
                              <img
                                alt="sub-icon"
                                className="subicon"
                                src={SubIcon}
                                onClick={this.decrementCount}
                              />
                              <img
                                alt="add-icon"
                                className="addicon"
                                src={AddIcon}
                                onClick={this.IncrementItem}
                              />
                              <div className="sub-add-outer-inner">
                                <input
                                  type="number"
                                  class="form-control text-center"
                                  name="attendees"
                                  value={this.state.attendees}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <p className="required small-subtitle">
                                {' '}
                                {errors.attendees}{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Box mb={1.75}>
                        <Grid style={{ display: "flex" }}>
                          <FormControlLabel
                            control={
                              <Checkbox style={{ padding: 0, marginRight: '8px' }} color="primary" />
                            }
                            label={this.props.users.languageStateForRedux?.formfields?.overview?.checkBox}
                            style={{ padding: 0, margin: 0, }}
                            onClick={(e) => this.handleSSOLoginCheckbox(e)}
                            checked={isSSOEnabled}
                          />
                          <Tooltip title={this.props.users.languageStateForRedux?.formfields?.overview?.hoverMsg} arrow placement="right">
                            <img style={{ display: "inline", marginLeft: "5px" }} src={InfoIcon} alt="info" />
                          </Tooltip>
                        </Grid>
                      </Box>

                    </div>
                    <br />
                    <Grid container width={'100%'} className="field-group width-100 projectDescription field_group75Both">
                      <Grid size={{xs: 12,}}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.formfields?.overview?.projectDesc}</label>
                          {/* <label>{this.props.events.languageCodeForRedux?.unlayer?.modalSettings?.customCSS}</label> */}
                          <textarea
                            type="code"
                            rows="8"
                            name="projectDescription"
                            value={projectDescription}
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder={this.props.users.languageStateForRedux?.formfields?.overview?.projectDesc}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <div className="section-divider">
                      <span>
                        {this.props.users.languageStateForRedux?.formfields?.overview?.mailOptions}
                      </span>
                    </div>
                    <div className="field_group75Both">
                      <div class="field-group inputField">
                        <label>
                          {this.props.users.languageStateForRedux?.formfields?.overview?.replyToEmail}  <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="replyToEmail"
                          value={replyToEmail}
                          maxLength="78"
                          onChange={this.handleChange}
                        />
                        <p className="required small-subtitle">
                          {' '}
                          {errors.replyToEmail}{' '}
                        </p>
                      </div>
                      <div class="field-group inputField">
                        <label>
                          {this.props.users.languageStateForRedux?.overviewTab?.emailSenderName} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="emailSenderName"
                          value={emailSenderName}
                          maxLength="78"
                          onChange={this.handleChange}
                        />
                        <p className="required small-subtitle">
                          {' '}
                          {errors.emailSenderName}{' '}
                        </p>
                      </div>
                    </div>

                    {/* Favicon */}
                    <>
                      <div className="section-divider">
                        <span> {this.props.users.languageStateForRedux?.formfields?.overview?.favIcon} </span>
                      </div>
                      <Box >
                        <p className="paragraph">
                          <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.favIcon}</strong>
                        </p>
                      </Box>

                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid size={{xs: 12,  md:6}}>
                          <Grid container spacing={1}>
                            {faviconImage && (
                              <Grid size={{xs: 12, sm:6}}>
                                <Grid container>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    
                                    component={Grid}
                                    size={{xs: 'grow',}}
                                    sx={{
                                      height: '100px',
                                      border: '1px solid #cccccc',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    <img
                                      style={{
                                        maxHeight: '90%',
                                        maxWidth: '50%',
                                      }}
                                      alt="favIcon"
                                      src={displayS3Image ?
                                        IMAGE_URL2 + '/' + userId + '/Images' + '/' + fileNameInS3 + '?jwt=' + token :
                                        faviconImage !== '' &&
                                          faviconImage !== undefined &&
                                          !editFaviconImage
                                          ? window.URL.createObjectURL(
                                            faviconImage,
                                          )
                                          : faviconImage !== '' &&
                                            faviconImage !== undefined &&
                                            editFaviconImage
                                            ? `${IMAGE_URL}/favIcons/${faviconImage}`
                                            : ''
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                            <Grid size={{xs: 6,}}>
                              <Stack spacing={1}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  //onClick={this.chooseFaviconImageClick}
                                  onClick={this.uploadFromFiles}
                                >
                                  {faviconImage ? 'Change favicon' : this.props.users.languageStateForRedux?.overviewTab?.favIconButton}
                                </Button>
                                <input
                                  type="file"
                                  onChange={this.onFaviconImageChange}
                                  accept="image/*"
                                  ref={this.chooseFavicon}
                                  style={{ display: 'none' }}
                                />
                                {faviconImage && (
                                  <Button
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    onClick={
                                      this.openRemoveFaviconConfirmationModal
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.overviewTab?.removeIcon}
                                  </Button>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid size={{xs: 12, md:6}} >
                          <Box className="small-subtitle">
                            {this.props.users.languageStateForRedux?.formfields?.overview?.longText}
                          </Box>
                          <Box mt={1} className="small-subtitle">
                            {this.props.users.languageStateForRedux?.formfields?.overview?.allowedTypes} :{' '}
                            <span className="primary-text">
                              {this.props.users.languageStateForRedux?.formfields?.overview?.format}
                            </span>
                          </Box>
                          <Box mt={1} className="small-subtitle">
                            {this.props.users.languageStateForRedux?.formfields?.overview?.desc}  :{' '}
                            <span className="primary-text">{this.props.users.languageStateForRedux?.formfields?.overview?.fixSize} </span>
                          </Box>
                        </Grid>
                      </Grid>
                    </>

                    {/* My Profile */}
                    <>
                      <div className="section-divider">
                        <span> {this.props.users.languageStateForRedux?.formfields?.overview?.myProfile} </span>
                      </div>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center"
                        width={'100%'}
                      >
                        <Grid size={{xs: 12, md:4}}>
                          <Stack spacing={3}>
                            <Grid container alignItems="center" mb={3}>
                              <Grid >
                                <Stack direction={'row'}>
                                  <Tooltip
                                    title={this.props.users.languageStateForRedux?.formfields?.overview?.hoverMsgOne}
                                    placement="top"
                                    arrow
                                  >
                                    <Box
                                      component="img"
                                      sx={{ width: '20px' }}
                                      mr={1}
                                      alt="information Icon"
                                      src={informationIcon}
                                    />
                                  </Tooltip>
                                  <p className="paragraph">
                                    <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.enProfile}</strong>
                                  </p>
                                </Stack>
                              </Grid>
                              <Grid >
                                <CustomSwitch
                                  name="isProfileVisible"
                                  checked={this.state.isProfileVisible}
                                  color="primary"
                                  onChange={this.handleEnableProfile}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid size={{xs: 12, md:6}} >
                          {/* ---profile icon position-- */}
                          {this.state.isProfileVisible && (
                            <Grid container alignItems="center">
                              <Grid size={{xs: 12,}}>
                                <Box
                                  component={'p'}
                                  mb={1}
                                  className="paragraph"
                                >
                                  <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.profileIcon}</strong>
                                </Box>
                                <div className="layout__possition">
                                  <span
                                    onClick={() => this.bottomLeft()}
                                    className={`layout__possition_bottom-left ${this.state.profilePosition ===
                                      'bottom-left'
                                      ? 'active'
                                      : ''
                                      }`}
                                  ></span>
                                  <span
                                    onClick={() => this.bottomRight()}
                                    className={`layout__possition_bottom-right ${this.state.profilePosition ===
                                      'bottom-right'
                                      ? 'active'
                                      : ''
                                      }`}
                                  ></span>
                                  <span
                                    onClick={() => this.topLeft()}
                                    className={`layout__possition_top-left ${this.state.profilePosition === 'top-left'
                                      ? 'active'
                                      : ''
                                      }`}
                                  ></span>
                                  <span
                                    onClick={() => this.topRight()}
                                    className={`layout__possition_top-right ${this.state.profilePosition === 'top-right'
                                      ? 'active'
                                      : ''
                                      }`}
                                  ></span>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          {/* ---profile icon position end-- */}
                        </Grid>
                      </Grid>
                    </>

                    <>
                      <div className="section-divider">
                        <span> {this.props.users.languageStateForRedux?.column_names?.accessCodes?.code} </span>
                      </div>
                      <Grid container alignItems="center" mb={3}>
                        <Grid >
                          <Stack direction={'row'}>
                            <Tooltip
                              title={this.props.users.languageStateForRedux?.formfields?.overview?.hoverMsgTwo}
                              placement="top"
                              arrow
                            >
                              <Box
                                component="img"
                                sx={{ width: '20px' }}
                                mr={1}
                                alt="information Icon"
                                src={informationIcon}
                              />
                            </Tooltip>
                            <p className="paragraph">
                              <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.encode}</strong>
                            </p>
                          </Stack>
                        </Grid>
                        <Grid >
                          <CustomSwitch
                            name="isAccessCode"
                            checked={this.state.isAccessCode}
                            color="primary"
                            onChange={this.handleEnableAccessCode}
                          />
                        </Grid>
                      </Grid>
                    </>

                    {this.state.editPageRequest == true && (
                      <>
                        <div className="section-divider">
                          <span>{this.props.users.languageStateForRedux?.formfields?.overview?.earlyAccess} </span>
                        </div>
                        <Grid container spacing={2} alignItems="center">
                          <Grid size={{xs: 12, md:4}}>
                            <Grid container alignItems="center">
                              <Grid >
                                <Stack direction={'row'}>
                                  <Tooltip
                                    title={this.props.users.languageStateForRedux?.formfields?.overview?.hoverMsgThree}
                                    placement="top"
                                    arrow
                                  >
                                    <Box
                                      component="img"
                                      sx={{ width: '20px' }}
                                      mr={1}
                                      alt="information Icon"
                                      src={informationIcon}
                                    />
                                  </Tooltip>
                                  <p className="paragraph">
                                    <strong>{this.props.users.languageStateForRedux?.formfields?.overview?.enEarly}</strong>
                                  </p>
                                </Stack>
                              </Grid>
                              <Grid >
                                <CustomSwitch
                                  name="checked"
                                  checked={EarlyAccessToggle}
                                  onChange={this.handleToggleEarlyAccess}
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {EarlyAccessToggle && (
                            <Grid size={{xs: "grow",}} justifyContent="end">
                              <RadioGroup
                                row
                                style={{ flexWrap: 'nowrap' }}
                                aria-label="early-access"
                                name="early-access"
                                defaultValue="all"
                              >
                                <Box
                                  component={FormControlLabel}
                                  m={0}
                                  value="all"
                                  name="all"
                                  onChange={() =>
                                    this.handleEarlyAccessType('all')
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        earlyAccessType === 'all' ? true : false
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={this.props.users.languageStateForRedux?.formfields?.overview?.labelAll}
                                />
                                <Box
                                  component={FormControlLabel}
                                  m={0}
                                  value="groups"
                                  name="groups"
                                  onChange={() =>
                                    this.handleEarlyAccessType('groups')
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        earlyAccessType === 'groups'
                                          ? true
                                          : false
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={this.props.users.languageStateForRedux?.formfields?.overview?.labelGroups}
                                />
                              </RadioGroup>
                            </Grid>
                          )}
                        </Grid>
                        {EarlyAccessToggle && (
                          <>
                            {earlyAccessType === 'all' && (
                              <Box mt={4} className="outlined-section">
                                <p className="paragraph">
                                  {this.props.users.languageStateForRedux?.formfields?.overview?.acessAll}
                                </p>
                                <div className="field_group75Both">
                                  <div class="field-group inputField">
                                    <label>
                                      {this.props.users.languageStateForRedux?.formfields?.overview?.accessDateTime}{' '}
                                      <span className="required">*</span>{' '}
                                    </label>
                                    <div class="formDate formIcon">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <MobileDateTimePicker
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          selected={
                                            accessDetailsForAll[0]?.accessDate
                                          }
                                          value={
                                            accessDetailsForAll[0]?.accessDate
                                          }
                                          onChange={(newValue) => {
                                            this.onChangeEarlyDateForAll(
                                              newValue,
                                            );
                                          }}
                                          inputFormat="MMM, dd yyyy, hh:mm a"
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div class="inputField">
                                    {listOfPages?.length > 0 && (
                                      <CustomMultiSelectDropdown
                                        options={listOfPages}
                                        label={this.props.users.languageStateForRedux?.formfields?.overview?.selectPages}
                                        placeholder="Select..."
                                        getSelectedOptionsFromCustomSelect={
                                          this
                                            .getSelectedOptionsFromCustomSelectForAll
                                        }
                                        value={
                                          accessDetailsForAll[0]?.accessPages
                                        }
                                      />
                                    )}
                                    <p className="required small-subtitle">
                                      {' '}
                                      {errors.earlyAccessPagesForAll}{' '}
                                    </p>
                                  </div>
                                </div>
                              </Box>
                            )}

                            {earlyAccessType === 'groups' && (
                              <Box mt={4} className="outlined-section">
                                <p className="paragraph">
                                  {this.props.users.languageStateForRedux?.formfields?.overview?.accessGroups}
                                </p>
                                {/* --- repeat start-- */}
                                {accessDetailsForGroups.map(
                                  (accessDetail, i) => {
                                    return (
                                      <Box
                                        key={accessDetail.groupId._id}
                                        className="early-access-groups"
                                      >
                                        <Grid container spacing={2}>
                                          <Grid size={{xs: "grow",}}>
                                            <div className="field_group75Both">
                                              <div class="field-group inputField ">
                                                <label>{this.props.users.languageStateForRedux?.formfields?.overview?.selectGroup}</label>
                                                <FormControl fullWidth size="small">
                                                  <Select
                                                    value={
                                                      accessDetailsForGroups[i]
                                                        .groupId
                                                    }
                                                    onChange={(e) =>
                                                      this.onChangeGroupForGroups(
                                                        e,
                                                        i,
                                                      )
                                                    }
                                                    class="form-control select"
                                                    name="homePage"
                                                    style={{ padding: '4px 0px', }}
                                                    displayEmpty
                                                    MenuProps={MenuProps}
                                                  >
                                                    <MenuItem value="" disabled>
                                                      {this.props.users.languageStateForRedux?.formfields?.overview?.selectGroup}
                                                    </MenuItem>
                                                    {Array.isArray(listOfGroups) && this.filteredListOfGroups()
                                                      .filter(
                                                        (group) =>
                                                          !accessDetailsForGroups.some(
                                                            (el) =>
                                                              el.groupId !==
                                                              accessDetailsForGroups[
                                                                i
                                                              ].groupId &&
                                                              el.groupId ===
                                                              group._id,
                                                          ),
                                                      )
                                                      .map((group) => {
                                                        return (
                                                          <MenuItem
                                                            value={group._id}
                                                          >
                                                            {group?.showToGroup && group?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                                            {' '}
                                                            <span style={{ maxWidth: '400px' }} className='long-text'>
                                                              {group.attendeesGroupName}
                                                            </span>
                                                          </MenuItem>
                                                        );
                                                      })}
                                                  </Select>
                                                  <p className="required small-subtitle">
                                                    {' '}
                                                    {
                                                      errors[
                                                      `earlyAccessGroupsForGroups${i}`
                                                      ]
                                                    }{' '}
                                                  </p>
                                                </FormControl>
                                              </div>
                                              <div class="field-group inputField">
                                                <label>
                                                  {this.props.users.languageStateForRedux?.formfields?.overview?.accessDateTime}
                                                  <span className="required">
                                                    *
                                                  </span>{' '}
                                                </label>
                                                <div class="formDate formIcon">
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                  >
                                                    <MobileDateTimePicker
                                                      renderInput={(props) => (
                                                        <TextField {...props} />
                                                      )}
                                                      selected={
                                                        accessDetail.accessDate
                                                      }
                                                      value={
                                                        accessDetail.accessDate
                                                      }
                                                      onChange={(newData) => {
                                                        this.onChangeEarlyDateForGroups(
                                                          newData,
                                                          i,
                                                        );
                                                      }}
                                                      inputFormat="MMM, dd yyyy, hh:mm a"
                                                    />
                                                  </LocalizationProvider>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="inputField w-100">
                                              {listOfPages?.length > 0 && (
                                                <CustomMultiSelectDropdown
                                                  options={listOfPages}
                                                  label={this.props.users.languageStateForRedux?.formfields?.overview?.selectPages}
                                                  placeholder="Select..."
                                                  getSelectedOptionsFromCustomSelect={
                                                    this
                                                      .getSelectedOptionsFromCustomSelectForGroups
                                                  }
                                                  value={
                                                    accessDetailsForGroups[i]
                                                      ?.accessPages
                                                  }
                                                  index={i}
                                                />
                                              )}
                                              <p className="required small-subtitle">
                                                {' '}
                                                {
                                                  errors[
                                                  `earlyAccessPagesForGroups${i}`
                                                  ]
                                                }{' '}
                                              </p>
                                            </div>
                                          </Grid>
                                          <Grid >
                                            <Box mt={5.5}>
                                              {i + 1 < listOfGroups.length &&
                                                accessDetailsForGroups.length -
                                                1 ===
                                                i ? (
                                                <img
                                                  alt="add-icon"
                                                  className="addicon"
                                                  src={EarlyAccessAddIcon}
                                                  onClick={() =>
                                                    this.addEarlyAccessGroupSection(
                                                      i,
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  alt="add-icon"
                                                  className="addicon"
                                                  src={EarlyAccessSubIcon}
                                                  onClick={() =>
                                                    this.removeEarlyAccessGroupSection(
                                                      i,
                                                    )
                                                  }
                                                />
                                              )}
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    );
                                  },
                                )}
                                {/* --- repeat end-- */}
                              </Box>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ConfirmationAlertPopUp
            openModal={this.state.openRemoveFaviconConfirmationModal}
            closeModalFunc={this.closeRemoveFaviconConfirmationModal}
            title={this.props.users.languageStateForRedux?.common?.confirmReplicate}
            text={this.props.users.languageStateForRedux?.formfields?.overview?.removeIconDesc}
            confirmationButtonText={this.props.users.languageStateForRedux?.buttons?.common?.yes}
            confirmationButtonColor="secondary"
            closeButtonText={this.props.users.languageStateForRedux?.common.cancel}
            functionality={this.removeFavicon}
          />
          <UploadFromFilesPopUp
            uploadFromFiles={this.state.uploadFromFiles}
            handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
            chooseFaviconImageClick={this.chooseFaviconImageClick}
            onFaviconImageChange={this.onFaviconImageChange}
            chooseFavicon={this.chooseFavicon}
            buttonState={this.state.buttonState}
            handleChoosedFile={this.handleChoosedFile}
            parentForPopup={this.state.parentForPopup}
          />
          <FinishPopUp
            eventId={
              this.props.events && this.props.events.event
                ? this.props.events.event._id
                : null
            }
            projectTemplateId={this.props.saveDetails.projectTemplateId}
          />
          <NotificationContainer />
          {loading || this.props.users.isLoading ? <Loader /> : null}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  project: state.Projects,
  attendees: state.Attendees,
  vanityState: state.VanityDomain,
  users: state.Users,
  files: state.Files
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      updateProjectRequest: Actions.updateProjectRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
      getProjectRequest: Actions.getProjectRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      createVanityDomain: Actions.createVanityDomainRequest,
      handleSetErrorToFalse: Actions.handleSetErrorToFalse,
      removeVanityDomain: Actions.removeVanityDomainRequest,
      handleProjectType: Actions.handleProjectType,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );
 
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(props => (
  <EventRegistration 
    {...props} 
    router={{ 
      navigate: useNavigate(), 
      params: useParams(), 
      location: useLocation() 
    }} 
  />
));