import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { NotificationContainer } from 'react-notifications';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationManager } from 'react-notifications';
import '../../sass/main.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import AuthLayout from '../../Layouts/AuthLayout';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Stack } from '@mui/material';

import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import MicrosoftIcon from '../../assets/icons/microsoftIcon.svg';
import GoogleIcon from '../../assets/icons/googleIcon.svg';

import Card from '../../Layouts/AuthLayout/SignInCard';
import { generateUrl } from 'helpers/common';

const URL = process.env.REACT_APP_API_URL;
const REACT_BACKEND_URL = process.env.REACT_BACKEND_URL;
const REACT_GOOGLE_CLIENT_ID = process.env.REACT_GOOGLE_CLIENT_ID;
const REACT_SSO_REDIRECT_URI = process.env.REACT_SSO_REDIRECT_URI;
const REACT_GOOGLE_AUTH_URL = process.env.REACT_GOOGLE_AUTH_URL;

class Login extends Component {
  constructor(props) {
    super(props);
    // window.location.href = Url.wixUrl;
    this.state = {
      email: '',
      password: '',
      isRemember: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    if (localStorage.isRemember && localStorage.email !== '') {
      this.setState({
        isRemember: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const login = this.props.login;
    let path = window.location?.pathname;
    let currentPath = path.split('/')[1];
    if (login && login.success && login.token) {
      if (login.user.email && login.user.email !== '') {
        let st = prevState.loading ? this.setState({ loading: false }) : null;
        let userDetails = JSON.parse(localStorage.getItem("user_details"));

        if (!userDetails?.servicePlanId) {
          this.getServicePlanId();
          if (currentPath == 'xp') {
            this.props.navigate(generateUrl('/my-events'));
          } else {
            this.props.navigate('/dashboard');
          }
        } else {
          if (currentPath == 'xp') {
            this.props.navigate(generateUrl('/my-events'));
          } else {
            this.props.navigate('/dashboard');
          }
        }
      }
      //this.props.navigate("/profile")
      /* if(login.user.isComplete)
            {
                if(login.user.role === 1)
                {
                    this.props.navigate("/profile");
                }else{
                    this.props.navigate("/profile");
                }
             
            }else{
                if(login.user.role === 1)
                {
                    this.props.navigate("/complete-registration");
                }else{
                    this.props.navigate("/profile");
                }
            }*/
    } else if (login && login.error && this.state.loading) {
      createNotification('error', login.message, '');
      this.handleLoading();
    }
  }

  UserData = async () => {
    return JSON.parse(localStorage.getItem('user_details'));
  };

  getServicePlanId = async () => {
    try {
      const users = await this.UserData();
      const res = await axios.get(`${URL}/addServicePlanInIllumeetXP`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      let servicePlanId;
      if (res.status === 200) {
        if (res.data.status === 200) {
          servicePlanId = res.data?.servicePlanId;
          let user_details = JSON.parse(localStorage.getItem('user_details'));
          user_details.servicePlanId = servicePlanId;
          localStorage.setItem("user_details", JSON.stringify(user_details));
        } else {
          console.log('Something went wrong');
        }
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      return error;
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleCheckBox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  sendToaster = () => {
    console.log('~~~~~~~~~~~~~~~~sendToaster~~~~~~~~~~~~~');
    NotificationManager.success('Here is a success notification', "Success", 4000);
    NotificationManager.error('Here is a success notification', "Error", 4000);

  };

  isLoginValid = () => {
    const { email, password } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (email && regexTest.test(email) === false) {
      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    if (password === '' || password.trim().length === 0) {
      formIsValid = false;
      error['password'] = '*Please enter password.';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isLoginValid()) {
      const { email, password, isRemember } = this.state;
      if (isRemember) {
        localStorage.email = email;
        localStorage.password = password;
        localStorage.isRemember = isRemember;
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('isRemember');
      }

      const requestbody = { email, password };
      this.props.userLogin(requestbody);
      this.handleLoading();
    }
  };



  handleGoogleLogin_22Jan = () => {
    console.log("REACT_GOOGLE_CLIENT_ID=====", REACT_GOOGLE_CLIENT_ID);
    console.log("REACT_SSO_REDIRECT_URI=====", REACT_SSO_REDIRECT_URI);
    console.log("REACT_GOOGLE_AUTH_URL=====", REACT_GOOGLE_AUTH_URL);
    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: 'unique-random-string',
      access_type: 'offline',
      prompt: 'consent',
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;

    // Popup dimensions
    const popupWidth = 600;
    const popupHeight = 600;

    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    // Open the popup in the center of the screen
    const popup = window.open(
      authUrl,
      'Google SSO Login',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        console.log('Popup closed by user');
      }
    }, 500);

    // Remove existing listener to prevent duplicate actions
    // window.removeEventListener('message', this.handleSSOMessage);

    // Add the message listener
    // window.addEventListener('message', this.handleSSOMessage);
  };

  // Message handler as a separate method
  handleSSOMessage_22Jan = (event) => {
    // Allow only messages from the backend origin
    if (event.origin !== REACT_BACKEND_URL) {
      console.error('Invalid origin:', event.origin);
      return;
    }

    if (event.data.type === 'sso-login-success') {
      console.log('SSO Login Successful:', event.data);

      // Extract user details and save to localStorage
      const userDetails = event?.data?.user;
      console.log('SSO User Details:', userDetails);

      localStorage.setItem('user_role', userDetails?.role);
      localStorage.setItem('user_details', JSON.stringify(userDetails));

      // Redirect to the home page
      window.location.href = '/xp/my-events'; // Change this to your desired redirect path
    } else if (event.data.type === 'sso-login-error') {
      const errorMessage = event.data.message || 'An unexpected error occurred.';
      // Display the error message in a toaster
      createNotification('error', errorMessage); // Replace with your toaster function 
    }
  };


  handleGoogleLoginX = () => {
    console.log("REACT_GOOGLE_CLIENT_ID=====", REACT_GOOGLE_CLIENT_ID);
    console.log("REACT_SSO_REDIRECT_URI=====", REACT_SSO_REDIRECT_URI);
    console.log("REACT_GOOGLE_AUTH_URL=====", REACT_GOOGLE_AUTH_URL);

    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: 'unique-random-string',
      access_type: 'offline',
      prompt: 'consent',
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;

    const popupWidth = 600;
    const popupHeight = 600;

    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    const popup = window.open(
      authUrl,
      'Google SSO Login',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    let messageReceived = false;

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        if (!messageReceived) {
          console.log('Popup closed by user before completing login.');
          createNotification('error', 'Popup closed before completing login.'); // Notify user
        }
      }
    }, 500);

    //window.removeEventListener('message', this.handleSSOMessage);

    window.addEventListener('message', (event) => {
      this.handleSSOMessage(event, popupTimer, () => (messageReceived = true));
    });
  };

  handleSSOMessageX = (event, popupTimer, setMessageReceived) => {
    if (event.origin !== REACT_BACKEND_URL) {
      console.error('Invalid origin:', event.origin);
      return;
    }

    if (event.data.type === 'sso-login-success') {
      console.log('SSO Login Successful:', event.data);

      const userDetails = event?.data?.user;
      console.log('SSO User Details:', userDetails);

      localStorage.setItem('user_role', userDetails?.role);
      localStorage.setItem('user_details', JSON.stringify(userDetails));

      setMessageReceived();
      clearInterval(popupTimer); // Clear the timer if login is successful

      window.location.href = '/xp/my-events'; // Redirect to home page
    } else if (event.data.type === 'sso-login-error') {
      const errorMessage = event.data.message || 'An unexpected error occurred.';
      createNotification('error', errorMessage); // Show error notification
      setMessageReceived();
      clearInterval(popupTimer); // Clear the timer in case of error
    }
  };


  handleGoogleLoginY = () => {
    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: 'unique-random-string',
      access_type: 'offline',
      prompt: 'consent',
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;

    const popupWidth = 600;
    const popupHeight = 600;
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    const popup = window.open(
      authUrl,
      'Google SSO Login',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    let messageReceived = false;

    // Listen for messages from the popup
    const messageListener = (event) => {
      if (event.origin !== REACT_BACKEND_URL) {
        console.error('Invalid origin:', event.origin);
        return;
      }

      if (event.data.type === 'sso-login-success') {
        console.log('SSO Login Successful:', event.data);

        const userDetails = event?.data?.user;
        localStorage.setItem('user_role', userDetails?.role);
        localStorage.setItem('user_details', JSON.stringify(userDetails));

        messageReceived = true;
        clearInterval(popupTimer); // Stop the polling timer
        // window.removeEventListener('message', messageListener);

        // Redirect to the landing page
        window.location.href = '/xp/my-events';
      } else if (event.data.type === 'sso-login-error') {
        const errorMessage = event.data.message || 'An unexpected error occurred.';
        createNotification('error', errorMessage);

        messageReceived = true;
        clearInterval(popupTimer);
        // window.removeEventListener('message', messageListener);
      }
    };

    window.addEventListener('message', messageListener);

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        if (!messageReceived) {
          console.log('Popup closed by user before completing login.');
          createNotification('error', 'Popup closed before completing login.');
        }
      }
    }, 500);
  };

  handleGoogleLogin = () => {
    console.log("REACT_GOOGLE_CLIENT_ID=====", REACT_GOOGLE_CLIENT_ID);
    console.log("REACT_SSO_REDIRECT_URI=====", REACT_SSO_REDIRECT_URI);
    console.log("REACT_GOOGLE_AUTH_URL=====", REACT_GOOGLE_AUTH_URL);

    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: 'unique-random-string',
      access_type: 'offline',
      prompt: 'consent',
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;

    // Popup dimensions
    const popupWidth = 600;
    const popupHeight = 600;

    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    // Open the popup in the center of the screen
    const popup = window.open(
      authUrl,
      'Google SSO Login',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        console.log('Popup closed by user');
      }
    }, 500);

    // Remove existing listener to prevent duplicate actions
    // window.removeEventListener('message', this.handleSSOMessage);

    // Add the message listener
    window.addEventListener('message', (event) => {
      this.handleSSOMessage(event);

      // If the message has been acknowledged, close the timer
      if (event.data === 'acknowledged') {
        clearInterval(popupTimer);
      }
    });
  };


  handleSSOMessage = (event) => {
    console.log('Received event:', event); // Log the event object for debugging

    // Validate the origin
    if (event.origin !== REACT_BACKEND_URL) {
      console.error('Invalid origin:', event.origin);
      return;
    }

    console.log('Event data:', event.data);

    if (event.data.type === 'sso-login-success') {
      console.log('SSO Login Successful:', event.data);

      // Extract user details and save to localStorage
      const userDetails = event?.data?.user;
      console.log('SSO User Details:', userDetails);

      localStorage.setItem('user_role', userDetails?.role);
      localStorage.setItem('user_details', JSON.stringify(userDetails));

      // Redirect to the home page
      window.location.href = '/xp/my-events';
    } else if (event.data.type === 'sso-login-error') {
      console.error('SSO Login Error:', event.data.error);
    }
  };

  handleSSOMessage_mm = (event) => {
    // Allow only messages from the backend origin
    if (event.origin !== REACT_BACKEND_URL) {
      console.error('Invalid origin:', event.origin);
      return;
    }

    if (event.data.type === 'sso-login-success') {
      console.log('SSO Login Successful:', event.data);

      // Extract user details and save to localStorage
      const userDetails = event?.data?.user;
      console.log('SSO User Details:', userDetails);

      localStorage.setItem('user_role', userDetails?.role);
      localStorage.setItem('user_details', JSON.stringify(userDetails));

      // Send acknowledgment to the popup
      event.source.postMessage('acknowledged', event.origin);

      // Redirect to the home page
      window.location.href = '/xp/my-events'; // Change this to your desired redirect path
    } else if (event.data.type === 'sso-login-error') {
      const errorMessage = event.data.message || 'An unexpected error occurred.';
      createNotification('error', errorMessage); // Replace with your toaster function
      event.source.postMessage('acknowledged', event.origin); // Acknowledge error to close popup
    }
  };


  render() {
    const { email, password, errors, loading, isRemember } = this.state;
    let path = window.location?.pathname;
    let currentPath = path.split('/')[1];
    return (
      <>
        <Helmet>
          <title>Eijent | Login</title>
        </Helmet>
        <AuthLayout>
          <Card>
            <Typography
              component="h1"
              variant="h1"
              textAlign={'center'}
              sx={{ width: '100%', }}
            >
              Login
              {loading ? <Loader /> : null}
            </Typography>

            {/* ---Form--- */}
            <Stack spacing={3}>
              <Box
                component="form"
                onSubmit={this.handleSubmit}
                noValidate
                sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}
              >
                <FormControl>
                  <FormLabel htmlFor="email">E-MAIL</FormLabel>
                  <TextField
                    error={errors.email}
                    helperText={errors.email}
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="your@email.com"
                    onChange={this.handleChange}
                    autoComplete="email"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.email ? 'error' : 'primary'}
                  />
                </FormControl>
                <FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormLabel htmlFor="password">PASSWORD</FormLabel>
                    <Link
                      component="button"
                      type="button"
                      onClick={
                        currentPath === 'xp'
                          ? () => this.props.navigate(generateUrl('/forgotPassword'))
                          : () => this.props.navigate('/forgotPassword')
                      }
                      variant="body2"
                      sx={{ alignSelf: 'baseline' }}
                    >
                      Forgot your password?
                    </Link>
                  </Box>
                  <TextField
                    error={errors.password}
                    helperText={errors.password}
                    name="password"
                    placeholder="••••••"
                    type="password"
                    id="password"
                    value={password}
                    autoComplete="current-password"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.password ? 'error' : 'primary'}
                    onChange={this.handleChange}
                    sx={{ fontFamily: 'monospace !important' }}
                  />
                </FormControl>

                <Button
                  type="submit"
                  size='large'
                  fullWidth
                  variant="contained"
                  color='primary'
                  endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                  sx={{
                    justifyContent: 'space-between',
                    marginTop: '16px'
                  }} 
                >
                  Sign in  
                </Button>

               
              </Box>
              <Divider> <Typography variant="body2" >or</Typography></Divider>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Button
                  fullWidth
                  size='large'
                  variant="outlined"
                  color='secodary'
                  // onClick={this.handleGoogleLogin}
                  onClick={() => alert('Sign in with Google account')}
                  startIcon={<img src={GoogleIcon} alt="Google-icon" />}
                  sx={{
                    justifyContent: 'start'
                  }}
                >
                  Continue with Google
                </Button>
                {/*  <Button
                  fullWidth
                  size='large'
                  variant="outlined"
                  color='secodary'
                  onClick={() => alert('Sign in with Microsoft account')}
                  startIcon={<img src={MicrosoftIcon} alt="Microsoft-icon" />}
                  sx={{
                    justifyContent: 'start'
                  }}
                >
                  Continue with Microsoft account
                </Button> */}
              </Box>
            </Stack>

            {/* ---Button--- */}
            <Stack spacing={1.5} alignItems={'center'}>
              <Box>
                <Button size='large' color='secondary'
                  onClick={
                    currentPath === 'xp'
                      ? () => this.props.navigate(generateUrl('/register'))
                      : () => this.props.navigate('/register')
                  }
                  variant='contained'>CREATE YOUR ACCOUNT</Button>
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                By continuing, you are agreeing with &nbsp;
                <span>
                  <Link
                    href="#"
                    variant="body2"
                    sx={{ alignSelf: 'center', color: '#000000' }}
                  >
                    EIJENT Terms & <br /> Conditions &nbsp;
                  </Link>
                  and &nbsp;
                  <Link
                    href="#"
                    variant="body2"
                    sx={{ alignSelf: 'center', color: '#000000' }}
                  >
                    Privacy Policy.

                  </Link>
                </span>
              </Typography>
            </Stack>
          </Card>
          <NotificationContainer />
        </AuthLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,

  // loading: state.Loader.loaderOne,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,

      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(Login);
