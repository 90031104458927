import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';

import DashboardLayout from '../../Layouts/DashboardLayout';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EventRegistartion3 from '../CreateEvent/eventRegistration';
import Loader from '../../Components/Loader/loader';
import { theme } from '../../theme/theme';
import { ThemeProvider } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { withStyles } from '@mui/styles';
import withCustomRouter from '../../Common/withCustomRouter';  
import { generateUrl } from 'helpers/common';

const styles = theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: '8px',
  }
});

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: 0,
      projectData: {}
    };
  }

  async componentDidMount() {

    if ( this.props.params.projectId !== '') {
      this.setState({ projectId:  this.props.params?.projectId });
      this.handleLoading();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let st = (prevState.loading ? this.setState({ loading: false }) : null);
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  async getProjectRequest(eventId) {
    const res = this.props.getProjectRequest(eventId);
  }


  async redirectRouteFunction(id) {
    window.location.href = generateUrl(`/project-dashboard/overview/${id}`);
  }

  render() {
    let {
      loading,
    } = this.state;
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <DashboardLayout title="My Projects" pageTitle="My Projects">
          <div className="dashboard-containor">
            <div className="steper-outer edit-project">
              <div className='edit-step-wrapper'>
                <Box px={{ xs: (2), lg: (3) }} className="step3-outer stepper-outer-common">
                  <EventRegistartion3 saveDetails={{ projectId:  this.props.params.projectId }} lngCode={this.props.users.newLangState} />
                </Box>
              </div>
              <Grid container className="next-back-stepper-btns-one">
                <Grid alignItems='center' display="flex">
                  <Box sx={{ display: 'flex', }}>
                    <Button onClick={() => this.redirectRouteFunction( this.props.params.projectId)} className={classes.backButton} variant="outlined">
                      <KeyboardBackspaceIcon />
                    </Button>
                    <Box component="h2" ml={2} className='heading'>{this.props.users.languageStateForRedux?.overviewTab?.edit}</Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {loading ? <Loader /> : null}
        </DashboardLayout>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  project: state.Projects,
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getProjectRequest: Actions.getProjectRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(withCustomRouter(EditProject)));
