import React from "react";
import { Box, Stack, Typography, Avatar, Button, AvatarGroup } from "@mui/material";

export default function NotificationItem({ notification }) {
  const { user, avatar, message, date, details, action } = notification;
  return (
    <Box
      // key={index}
      sx={{
        maxWidth: 537,
        minWidth: 300,
      }}
      py={2}
      px={0.5}
      className={'eijent-notification'}
    >
      <Stack
        direction="row"
        sx={{
          gap: 1,
          alignItems: 'center',
        }}
      >
        <AvatarGroup max={2}>
          <Avatar
            sizes="small"
            alt="Riley Carter"
            sx={{ width: 32, height: 32, fontSize: '10px', fontWeight: 400, border: 'none !important', }}
          >
            {avatar}
          </Avatar>
        </AvatarGroup>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Typography variant="body2">
            <Box component="span" sx={{ fontWeight: 500 }}>
              {user}{" "}
            </Box>
            {message}
          </Typography>
          <Typography variant="caption">{date}</Typography>
        </Stack>
      </Stack>
      <Stack spacing={1.4} alignItems={'start'} sx={{ pl: 4, }}>
        <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }} >
          💻Einjent – UI&UX
        </Typography>
        <Typography sx={{ fontSize: '13px', fontWeight: 400, }} >
          {details}
          {/* AI need to ask the followup question if user idle more than. After the leave chat the feedback. Remove the tack.to from production. Wrong username Comments User. Under IT, write an “how to” article for new users. */}
        </Typography>
        {/* {action && (
        <Button size="small" variant="contained" sx={{ mt: 1, ml: 4 }}>
          {action}
        </Button>
      )} */}
        {/* <Button variant="contained" color="white" size="small">
                    Reply
                  </Button> */}

      </Stack>
    </Box>
  );
}
