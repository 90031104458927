import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../theme/theme';
import Button from '@mui/material/Button';
// import '../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Loader from '../../../Components/Loader/loader';
import {
  convertTZ,
  generateUrl,
  getProjectIdFromUrl,
  UserData,
} from '../../../helpers/common';
import MeetingRecipients from './CreateMeetingComponents/MeetingRecipients';
import MeetingContents from './CreateMeetingComponents/MeetingContents';
import MeetingEmailPreview from './CreateMeetingComponents/MeetingEmailPreview';
import BackArrow from '../../../assets/images/icons/backArrow.svg';
import { createNotification } from '../../../helpers';
import { Modal } from 'react-responsive-modal';
// import ReviewNotification from '../../../assets/images/review-notification.svg';
import { ReactComponent as ReviewNotification } from '../../../assets/images/review-notification.svg';

import successMettingPopupImage from '../../../assets/images/icons/Calendar-filled.svg';
import axios from 'axios';
import { getMeetingsIntegrationDetails } from 'ReduxStore/API';
import moment, { duration } from 'moment';
import Tooltip from '@mui/material/Tooltip';
import withCustomRouter from '../../../Common/withCustomRouter';
/**
 * @class CreateMeeting
 * @extends Component
 * @description Parent component for creating and managing meetings.
 */
class CreateMeeting extends Component {
  constructor(props) {
    // State initialization and default values
    super(props);
    this.state = {
      recipients: {},
      selectedEmail: [],
      emailSubject: '',
      senderName: '',
      replyToEmail: '',
      meetingTitle: '',
      meetingOrganizer: '',
      dateTime: new Date(),
      buttonText: '',
      description: '',
      hours: '',
      minutes: '',
      errors: '',
      loading: false,
      editData: '',
      isEdit: false,
      openReviewModal: false,
      slots: {},
      availableSlot: new Date(),
      meetingStartDateTime: '',
      openBackeConfifmationModal: false,
      status: '',
      openNewGuestModal: false,
      newGuest: [],
      newGuestCount: 0,
      json: '',
      html: '',
      meetingData: [],
      meetingTemplate: [],
      eventData: [],
      clickedCustomiseEmailBuilder: false,
      addSuccess: false,
      meetingOrganizer: '',
      integrations: '',
      meetingEndDateTime: '',
      openSuccessPopup: false,
      updateMeetingData: [],
      editButtonClick: false
    };
  }

  /**
     * @function componentDidMount
     * @description Lifecycle method invoked after the component is mounted.
     * Fetches data and sets the initial component state.
     */
  componentDidMount() {
    const projectId = getProjectIdFromUrl();
    this.getCalendarIntegrationForMeeting();
    this.setState(
      {
        meetingId: this.props.isEdit
          ? this.props?.editData?._id
          : this.props.meetingId,
        projectId,
        editData: this.props.editData,
        eventData: this.props.Meeting.eventData,

      },
      () => {
        if (this.props.isEdit) {
          this.setState({
            loading: true,
          });
          this.handleGetTemplate('Project');
        } else {
          this.handleGetTemplate('Default');
        }
      },
    );
  }

  /**
* @function componentDidUpdate
* @param {object} prevProps - Previous properties.
* @param {object} prevState - Previous state.
* @description Lifecycle method called after the component updates.
*   Handles updates in Meeting-related actions and updates state accordingly.
*/
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.Meeting.checkMeetingTitleSuccess !==
      prevProps.Meeting.checkMeetingTitleSuccess &&
      this.props.Meeting.checkMeetingTitleSuccess === true
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          meetingTitle: this.props.Meeting.message,
        },
      });
    }

    if (
      this.props.Meeting.checkMeetingTitleError !==
      prevProps.Meeting.checkMeetingTitleError &&
      this.props.Meeting.checkMeetingTitleError === true
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          meetingTitle: this.props.Meeting.message,
        },
      });
    }
    if (
      this.props.Meeting.error === true &&
      prevProps.Meeting.error === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
      prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState({
        meetingTemplate: this.props.Meeting.meetingTemplate,
        eventData: this.props.Meeting.eventData,
        loading: false,
      });
    }

    if (
      this.props.Meeting.addMeetingSuccess !==
      prevProps.Meeting.addMeetingSuccess &&
      this.props.Meeting.addMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);
      this.setState(
        {
          meetingTemplate: this.props.Meeting.meetingData,
          loading: false,
          openBackeConfifmationModal: false,
          addSuccess: true,
          eventData: this.props.Meeting.eventData,
          openSuccessPopup: this.props.Meeting.meetingData?.status !== "Draft" ? true : false,
          openReviewModal: false,
        },
        () => {
          if (this.props.Meeting.meetingData && this.props.Meeting.meetingData[0]?.status == "Draft") {
            if (!this.state.clickedCustomiseEmailBuilder) {
              this.goback();
            } else { 
              this.props.navigate(`/project-dashboard/meeting-edior/tid/${this.state.meetingTemplate[0]._id}/eid/${this.state.projectId}`, {
                state: {
                  meetingId: this.state.meetingTemplate[0]._id,
                },
              });
              this.setState({ clickedCustomiseEmailBuilder: false });
            }
          }
        },
      );
    }

    if (
      this.props.Meeting.updateMeetingSuccess !==
      prevProps.Meeting.updateMeetingSuccess &&
      this.props.Meeting.updateMeetingSuccess === true
    ) {
      console.log("this.state.editButtonClick--->", this.state.editButtonClick);
      if (!this.state.editButtonClick) {
        createNotification('success', this.props.Meeting.message);
      }
      this.setState(
        {
          loading: false,
          openSuccessPopup: !this.state.clickedCustomiseEmailBuilder ? (this.props.Meeting.updateMeetingData?.status !== "Draft" ? true : false) : false,
          openReviewModal: false,
        },
        () => {
          if (this.props.Meeting.updateMeetingData?.status === "Draft") {
            if (!this.state.clickedCustomiseEmailBuilder) {
              this.goback();
            } else {
              this.props.navigate({
                pathname: generateUrl(`/project-dashboard/meeting-edior/tid/${this.state.meetingTemplate[0]._id}/eid/${this.state.projectId}`),
                state: {
                  meetingId: this.state.meetingTemplate[0]._id,
                },
              });
              this.setState({ clickedCustomiseEmailBuilder: false });
            }
          } else {
            if (this.state.clickedCustomiseEmailBuilder) {
              this.props.navigate({
                pathname: generateUrl(`/project-dashboard/meeting-edior/tid/${this.state.meetingTemplate[0]._id}/eid/${this.state.projectId}`),
                state: {
                  meetingId: this.state.meetingTemplate[0]._id,
                },
              });
              this.setState({ clickedCustomiseEmailBuilder: false });
            }
          }
        },
      );
    }
  }

  /**
    * @function getCalendarIntegrationForMeeting
    * @description A function to get calendar integrations.
    * @returns {Promise} Result of the integration request.
    */
  async getCalendarIntegrationForMeeting() {
    const projectId = getProjectIdFromUrl();
    const paramsData = {
      eventId: projectId
    };
    await getMeetingsIntegrationDetails(paramsData).then(async (result) => {
      if (result.data.integrations) {
        this.setState({
          integrations: result.data.integrations,
        });
      }
    });
  };


  /**
   * @method getDataFromMeetingQuickFilter
   * @param {object} data - The data from the quick filter component.
   * @description Updates the selected email in the component's state.
   */
  getDataFromMeetingQuickFilter = (data) => {
    this.setState({
      selectedEmail: data,
    });
  };

  /**
   * @method getDataFromMeetingContent
   * @param {object} data - The data from the meeting content component.
   * @description Updates various meeting-related state properties based on the provided data.
   */
  getDataFromMeetingContent = (data) => {
    this.setState({
      emailSubject: data.emailSubject || '',
      senderName: data.senderName || '',
      replyToEmail: data.replyToEmail || '',
      meetingTitle: data.meetingTitle || '',
      meetingOrganizer: data.meetingOrganizer || '',
      meetingOrganizer: data.meetingOrganizer || '',
      dateTime: data.dateTime || '',
      description: data.description || '',
      meetingLocation: data.meetingLocation || '',
      availableSlot: data?.availableSlot,
      meetingStartDateTime: data?.meetingTime || '',
    });
  };

  getDataFromMeetingEmailPreview = (data) => {
    this.setState({
      errors: data.errors,
      html: data.html,
      json: data.json,
      editButtonClick: data.editButtonClick
    });
  };

  /**
 * @method isValid
 * @param {object} data - Meeting data for validation.
 * @param {string} savingMethod - Method for saving the meeting.
 * @returns {boolean} - Returns true if the data is valid, otherwise false.
 * @description Validates meeting data based on saving method and sets errors in the state.
 */
  isValid = (data, savingMethod) => {
    const {
      emailSubject,
      senderName,
      replyToEmail,
      dateTime,
      description,
      meetingTitle,
      meetingOrganizer,
      meetingStartDateTime,
      meetingTemplate,
    } = data;
    let formIsValid = true;
    let error = {};
    let err = {};

    if (dateTime === '' || dateTime === null) {
      formIsValid = false;
      error['dateTime'] = this.props.users.languageStateForRedux?.validations?.selectDateTime;
    }

    if (
      savingMethod !== 'Draft' &&
      (meetingStartDateTime === '' ||
        meetingStartDateTime === null ||
        meetingStartDateTime === undefined)
    ) {
      formIsValid = false;
      error['meetingStartDateTime'] = this.props.users.languageStateForRedux?.validations?.slotsStartingTime;
    }

    if (
      savingMethod !== 'Draft' &&
      (emailSubject.trim() === '' || emailSubject === undefined)
    ) {
      formIsValid = false;
      error['emailSubject'] = this.props.users.languageStateForRedux?.validations?.enterEmailSub;
    }
    if (meetingTitle.trim() === '' || meetingTitle === undefined) {
      formIsValid = false;
      error['meetingTitle'] = this.props.users.languageStateForRedux?.validations?.meetingTitle;
    }

    if (meetingOrganizer.trim() === '' || meetingOrganizer === undefined) {
      formIsValid = false;
      error['meetingOrganizer'] = this.props.users.languageStateForRedux?.validations?.meetingOrganizer;
    }

    if (
      savingMethod !== 'Draft' &&
      (description.trim() === '' || description === undefined)
    ) {
      formIsValid = false;
      error['description'] = this.props.users.languageStateForRedux?.validations?.desc;
    }

    if (
      savingMethod !== 'Draft' &&
      (senderName.trim() === '' || senderName === undefined)
    ) {
      formIsValid = false;
      error['senderName'] = this.props.users.languageStateForRedux?.validations?.enterSendersName;
    }
    if (
      savingMethod !== 'Draft' &&
      (replyToEmail.trim() === '' || replyToEmail === undefined)
    ) {
      formIsValid = false;
      error['replyToEmail'] = this.props.users.languageStateForRedux?.validations?.enterReply;
    }

    this.setState({
      errors: error,
      errs: err,
    });
    return formIsValid;
  };


  /**
   * @method isQuickFilterValid
   * @param {string} savingMethod - Method for saving the meeting.
   * @returns {boolean} - Returns true if the quick filter data is valid, otherwise false.
   * @description Validates quick filter data based on saving method and sets errors in the state.
   */
  isQuickFilterValid = (savingMethod) => {
    let error = {};
    let formIsValid = true;
    const { selectedEmail } = this.state;
    if (savingMethod !== 'Draft') {
      if (selectedEmail.length === 0) {
        formIsValid = false;
        error['selectedEmail'] = this.props.users.languageStateForRedux?.validations?.selectEmail;
      }
    }
    this.setState({
      errors: error,
    });
    return formIsValid;
  };

  /**
 * @method addToCalendar
 * @description Opens a new window with the Google Calendar URL for the meeting.
 */
  addToCalendar = async () => {
    let {
      meetingTitle,
      description,
      meetingLocation,
      meetingStartDateTime,
      eventData
    } = this.state;

    let meetingDurationHours = 0;
    let meetingDurationMinutes = 30;
    let projectTimezones = eventData?.timezone;
    if (eventData?.duration) {
      meetingDurationHours = eventData.duration.hours;
      meetingDurationMinutes = eventData.duration.minutes;
    }

    let parmsEndDateTime = {
      "meetingStartDateTime": meetingStartDateTime,
      "meetingDurationHours": meetingDurationHours,
      "meetingDurationMinutes": meetingDurationMinutes,
      "projectTimezones": projectTimezones
    };
    let meetingEndtDateTime = this.getMeetingEndDateTime(parmsEndDateTime);
    let eventDetails = {
      title: meetingTitle,
      location: meetingLocation,
      description: description,
      startDate: new Date(meetingStartDateTime),
      endDate: new Date(meetingEndtDateTime)
    };
    const calendarUrl = this.generateGoogleCalendarUrl(eventDetails);
    window.open(calendarUrl, '_blank');

  };

  /**
 * @method generateGoogleCalendarUrl
 * @param {object} event - Meeting event details.
 * @returns {string} - Returns the Google Calendar URL for the event.
 * @description Generates a Google Calendar URL based on the provided event details.
 */
  generateGoogleCalendarUrl = (event) => {
    const start = this.formatDate(event.startDate);
    const end = this.formatDate(event.endDate);
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${start}/${end}&details=${event.description}&location=${event.location}`;
    return googleCalendarUrl;
  };

  /**
 * @method getMeetingEndDateTime
 * @param {object} params - Parameters for calculating meeting end date and time.
 * @returns {string} - Returns the formatted meeting end date and time.
 * @description Calculates and formats the meeting end date and time based on the provided parameters.
 */
  getMeetingEndDateTime = (params) => {
    const tzone = params?.projectTimezones;
    const startDateTime = params?.meetingStartDateTime;
    const hours = params?.meetingDurationHours;
    const minutes = params.meetingDurationMinutes;
    let myDateTime = new Date(startDateTime);
    myDateTime.setHours(myDateTime.getHours() + hours);
    myDateTime.setMinutes(myDateTime.getMinutes() + minutes);
    const formattedDate = myDateTime.toLocaleString('en-US', { tzone });
    return formattedDate;
  };

  /**
 * @method formatDate
 * @param {Date} date - Date to be formatted.
 * @returns {string} - Returns the formatted date.
 * @description Formats the provided date into a string.
 */
  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  };

  /**
   * @method createMeeting
   * @param {string} sendingMethod - The method used for sending the meeting.
   * @param {string} buttonType - The type of button clicked.
   * @description Creates or updates a meeting based on the provided parameters and validates the form data.
   */

  createMeeting = (sendingMethod, buttonType) => {
    let {
      selectedEmail,
      emailSubject,
      meetingTitle,
      meetingOrganizer,
      description,
      meetingLocation,
      projectId,
      recipients,
      senderName,
      replyToEmail,
      meetingStartDateTime,
      newGuest,
      json,
      html,
      integrations
    } = this.state;

    if (this.isQuickFilterValid(sendingMethod)) {
      let requestBody = {
        eventId: projectId,
        meetingTitle: meetingTitle.trim(),
        meetingOrganizer,
        status: sendingMethod,
        recipients,
        senderName,
        replyToEmail,
        emailSubject,
        meetingStartDateTime: meetingStartDateTime
          ? convertTZ(meetingStartDateTime, this.props.project.project.timezone)
          : meetingStartDateTime,
        description,
        meetingLocation,
        selectedEmail: selectedEmail,
        meetingType: 'Project',
        buttonType: buttonType,
        lngCode: this.props.users.newLangState,
        integrationId: integrations._id
      };

      if (!this.props.isEdit) {
        requestBody.json = json;
        requestBody.html = html;
      }

      if (sendingMethod === 'Sent') {
        requestBody = {
          ...requestBody,
          sentAt: new Date()
        };
      }

      if (this.props.isEdit === true) {
        requestBody = {
          ...requestBody,
          meetingId: this.props?.editData?._id,
          calendarEventId: this.state.meetingTemplate[0]?.calendarEventData?.id,
        };
      }

      if (this.props.isEdit === true && buttonType === 'newGuest') {
        requestBody = {
          ...requestBody,
          newGuest: newGuest,
        };
      }
      if (this.isValid(this.state, sendingMethod)) {
        this.props.isEdit === true
          ? this.props.updateMeeting(requestBody)
          : this.props.addMeeting(requestBody);
        this.handleLoading();
      }
    }
  };

  /**
  * @method openSendModal
  * @description Opens the send modal based on the validity of the form data.
  */
  openSendModal = () => {
    this.checkNewGuest();
    if (this.isValid(this.state) && this.isQuickFilterValid()) {
      if (
        this.props.isEdit === true &&
        this.state.meetingTemplate[0].status !== 'Draft'
      ) {
        this.setState({
          openNewGuestModal: true,
          openReviewModal: false,
        });
      } else {
        this.setState({
          openReviewModal: true,
          openNewGuestModal: false,
        });
      }
    }
  };

  /**
   * @method handleCloseNewGuestModal
   * @description Closes the new guest modal.
   */
  handleCloseNewGuestModal = () => {
    this.setState({
      openNewGuestModal: false,
    });
  };

  /**
 * @method handleLoading
 * @description Toggles the loading state between true and false.
 */
  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  /**
 * @method goback
 * @description Navigates back to the previous screen, resetting state and handling navigation props.
 */
  goback = () => {
    this.props.handleNavigationFromEditorToCreateMeeting(false);
    this.props.getDataFromCreateMeeting(false, this.state.isEdit);
    this.setState({
      meetingTitle: '',
      meetingOrganizer: '',
      selectedEmail: '',
      senderName: '',
      replyToEmail: '',
      description: '',
      emailSubject: '',
      projectId: '',
    });
  };


  /**
   * @method openBackeConfifmationModal
   * @description Opens the confirmation modal for going back.
   */
  openBackeConfifmationModal = () => {
    this.setState({
      openBackeConfifmationModal: true,
    });
  };

  /**
 * @method handleSetMeetingTitle
 * @description Placeholder method (description needed).
 */
  handleSetMeetingTitle = () => {
    this.setState({

    });
  };

  /**
 * @method saveAndExit
 * @description Validates meeting title before saving and triggers the createMeeting method.
 */
  saveAndExit = () => {
    if (
      this.state.meetingTitle.trim() === '' ||
      this.state.meetingTitle === undefined
    ) {
      let error = {};
      error['meetingTitle'] = this.props.users.languageStateForRedux?.validations?.meetingTitle;
      this.setState({
        errors: error,
      });
    } else {
      let savingMethod = this.state.meetingTemplate[0]?.status;
      this.createMeeting(savingMethod);
    }
  };

  /**
 * @method handleCloseReviewModal
 * @description Closes the review modal.
 */
  handleCloseReviewModal = () => {
    this.setState({
      openReviewModal: false,
    });
  };

  /**
 * @method handleCloseSucessModal
 * @description Closes the success modal and handles navigation based on conditions.
 */
  handleCloseSucessModal = () => {
    this.setState({
      openSuccessPopup: false,
    }, () => {
      if (!this.state.clickedCustomiseEmailBuilder) {
        this.goback();
      } else {
        this.props.navigate({
          pathname: generateUrl(`/project-dashboard/meeting-edior/tid/${this.state.meetingTemplate[0]._id}/eid/${this.state.projectId}`),
          state: {
            meetingId: this.state.meetingTemplate[0]._id,
          },
        });
        this.setState({ clickedCustomiseEmailBuilder: false });
      }
    }
    );
  };

  /**
 * @method closeBackeConfifmationModal
 * @description Closes the confirmation modal for going back.
 */
  closeBackeConfifmationModal = () => {
    this.setState({
      openBackeConfifmationModal: false,
    });
  };

  /**
   * @method checkNewGuest
   * @description Checks for new guests based on certain parameters.
   */
  checkNewGuest = () => {
    try {
      this.setState({
        loading: true
      });
      const params = {
        eventId: this.state.projectId,
        meetingId: this.props?.editData?._id,
        selectedEmail: this.state.selectedEmail,
      };
      const users = UserData();
      const URL = process.env.REACT_APP_API_URL;
      axios
        .post(`${URL}/checkNewGuest`, params, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          if (data.data.status == 200) {
            this.setState({
              newGuest: data.data.newGuest,
              newGuestCount: data.data.newGuestCount,
              loading: false
            });
          } else {
            createNotification('error', data.data.error);
          }
        })
        .catch((err) => {
          createNotification('error', 'Something went wrong');
        });
    } catch (err) {
      createNotification('error', err.message);
    }
  };

  /**
 * @method handleGetTemplate
 * @param {string} meetingType - Type of meeting template.
 * @description Retrieves the meeting template based on the meeting type.
 */
  handleGetTemplate = (meetingType) => {
    let params = '';
    const projectId = getProjectIdFromUrl();
    if (meetingType === 'Default') {
      params = {
        eventId: projectId,
        meetingType: meetingType,
      };
    } else {
      params = {
        meetingType: meetingType,
        meetingId: this.state.editData._id,
        eventId: this.state.editData.eventId,
        type: 'getTemplate',
      };
    }
    this.props.getMeetingTemplate(params);
  };

  /**
  * @method handleClickedCustomiseEmailBuilder
  * @param {boolean} param - Indicates whether the email builder is clicked.
  * @description Handles the state change when the email builder is clicked.
  */
  handleClickedCustomiseEmailBuilder = (param) => {
    this.setState({ clickedCustomiseEmailBuilder: param });
    if (this.props.Meeting.addMeetingSuccess === true) {
      return this.props.Meeting.meetingTemplate;
    }
  };

  /**
   * @function render
   * @description Renders the CreateMeeting component UI.
   * @returns {JSX.Element} Rendered component.
   */
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid >
            <Grid container spacing={3} alignItems="center">
              <Grid >
                <Box
                  component={'img'}
                  mb={0.5}
                  alt=""
                  src={BackArrow}
                  onClick={() => this.openBackeConfifmationModal()}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid >
                <Box component={'span'} className="subtitle">
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.meeting}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid >
            <Stack spacing={1} direction="row">
              {this.props.isEdit !== true ? (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => this.createMeeting('Draft')}
                  disabled={this.state.errors?.meetingTitle === "Meeting Title already exists" ? true : false}
                >
                  {this.props.users.languageStateForRedux?.buttons?.meeting?.saveAsDraft}
                </Button>
              ) : (
                this.props.isEdit === true &&
                this.state.meetingTemplate[0]?.status === 'Draft' && (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => this.createMeeting('Draft')}
                    disabled={this.state.errors?.meetingTitle === "Meeting Title already exists" ? true : false}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.meeting?.saveAsDraft}
                  </Button>
                )
              )}
              <Button
                color="primary"
                variant="contained"
                disableElevation
                disabled={this.state.errors?.meetingTitle === "Meeting Title already exists" ? true : false}
                onClick={() => {
                  this.openSendModal();
                }}
              >
                {this.props.users.languageStateForRedux?.buttons?.meeting?.sendInvite}
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <MeetingRecipients
            getDataFromMeetingQuickFilter={this.getDataFromMeetingQuickFilter}
            isQuickFilterValid={this.isQuickFilterValid}
            errorsFromCreateMeeting={this.state.errors}
            selectedEmail={this.state.meetingTemplate?.selectedEmail}
            isEdit={this.props.isEdit}
            searchAdd={this.props.editData?.searchAdd}
          />
          <MeetingContents
            getDataFromMeetingContent={this.getDataFromMeetingContent}
            errors={this.state.errors}
            contents={this.state.meetingTemplate}
            isEdit={this.props.isEdit}
          />
          <MeetingEmailPreview
            getDataFromMeetingEmailPreview={this.getDataFromMeetingEmailPreview}
            meetingTemplate1={this.state.meetingTemplate}
            isEdit={this.props.isEdit}
            templateType={this.props.templateType}
            errors={this.state.errors}
            addMeetingStateData={{
              meetingTitle: this.state.meetingTitle,
            }}
            createMeeting={this.createMeeting}
            addSuccess={this.state.addSuccess}
            handleClickedCustomiseEmailBuilder={
              this.handleClickedCustomiseEmailBuilder
            }
          />
        </Grid>

        <Modal
          open={this.state.openReviewModal}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal modal-default-close-none',
          }}
          center
        >
          <Box
            component={Grid}
            container
            alignItems={'center'}
            justifyContent="center"
            pb={{ xs: 2, md: 3, lg: 0 }}
          >
            <Grid size={{xs: 12,}} className="">
              <Box textAlign={'center'} mt={{ xs: 0, lg: -2.5 }}>
                {/* <img alt="" src={ReviewNotification} /> */}
                <ReviewNotification />
              </Box>
              <Box component="p" mb={1} textAlign="center" className="subtitle">
                {this.props.users.languageStateForRedux?.formfields?.meetings?.reviewMeeting}
              </Box>
              <Box textAlign={'center'} className="small-subtitle">
                {this.props.users.languageStateForRedux?.formfields?.meetings?.meetingSentTo}{' '}
                <u>
                  {this.state.selectedEmail
                    ? this.state.selectedEmail.length
                    : 0}
                </u>{' '}
                {this.props.users.languageStateForRedux?.formfields?.meetings?.guests}
              </Box>{' '}
            </Grid>
            <Grid size={{xs: 10,}} className="">
              <Stack spacing={1} alignItems="center" class="" fullWidth>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="my-2"
                  disableElevation
                  onClick={() => this.createMeeting('Sent')}
                >
                  {this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}
                </Button>
                <Button
                  fullWidth
                  variant="text"
                  className=""
                  disableElevation
                  onClick={this.handleCloseReviewModal}
                >
                  {this.props.users.languageStateForRedux?.common?.cancel}
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openSuccessPopup}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal modal-default-close-none',
          }}
          center
        >
          <Box
            component={Grid}
            container
            alignItems={'center'}
            justifyContent="center"
            pb={{ xs: 2, md: 3, lg: 0 }}
          >
            <Grid size={{xs: 12,}} className="">
              <Box textAlign={'center'} mt={{ xs: 0, lg: -2.5 }} mb={2.5}>
                <img width="105" alt="" src={successMettingPopupImage} />
              </Box>
              <Box component="p" mb={1} textAlign="center" className="secondary">Congratulations!</Box>
              <Box component="p" mb={1} textAlign="center" className="subtitle-regular">
                Your meeting has been successfully created!
              </Box>

            </Grid>
            <Grid size={{xs: 10,}} className="">
              <Stack spacing={1} alignItems="center" class="" fullWidth>
                <Tooltip title={"By selecting 'Add to Calendar' you can effortlessly synchronize this event with your personal Google Calendar. Any updates to the meeting will not be automatically reflected in your calendar. To enable automatic updates, please add your Google account in the settings"}
                  arrow placement="bottom">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="my-2"
                    disableElevation
                    onClick={() => this.addToCalendar()}
                  >
                    Add to calendar
                  </Button>
                </Tooltip>

                <Button
                  fullWidth
                  variant="text"
                  className=""
                  disableElevation
                  onClick={this.handleCloseSucessModal}
                >
                  Close
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openBackeConfifmationModal}
          onClose={this.closeBackeConfifmationModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmation}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.exitWithoutSave}
              </p>
            </Box>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevationon
                      onClick={() => this.saveAndExit()}
                    >
                      {this.props.users.languageStateForRedux?.unlayer?.saveExit}
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() => this.goback()}
                    >
                      {this.props.users.languageStateForRedux?.unlayer?.exit}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openNewGuestModal}
          onClose={this.handleCloseNewGuestModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Send Invite
                </Box>
                {' '}
                <p className="small-subtitle">
                  {this.state.newGuestCount > 0 ?
                    "Would you like to send update emails to new & existing guests?"
                    : "Would you like to resend the updated email to recipients?"
                  }
                </p>
              </Box>
            </Box>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    {this.state.newGuestCount > 0 ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={() => this.createMeeting('Sent', 'newGuest')}
                      >
                        NEW GUESTS
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={() => this.createMeeting('Sent', 'save')}
                      >
                        SAVE
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.createMeeting('Sent', 'all')}
                    >
                      SEND ALL
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => ({
  project: state.Projects,
  Meeting: state.Meeting,
  events: state.Events,
  users: state.Users,
});

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getMeetingTemplate: Actions.getMeetingTemplateRequest,
      addMeeting: Actions.addMeetingRequest,
      updateMeeting: Actions.updateMeetingRequest,
      handleNavigationFromEditorToCreateMeeting:
        Actions.handleNavigationFromEditorToCreateMeeting,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(CreateMeeting));
