import * as React from 'react';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const SignInCard = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  height: 'auto',
  justifyContent: 'space-between',
  padding: theme.spacing(5),
  gap: theme.spacing(2),
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    // width: `calc(612px - (${theme.spacing(5)} * 2))`,
    // height: `calc(791px - (${theme.spacing(5)} * 2))`,
    width: '612px',
    minHeight: '791px',
    height: 'auto',
  },
  ...theme.applyStyles('dark', {}),
}));

const Card = ({ children }) => {
  return <SignInCard variant="outlined">{children}</SignInCard>;
};

export default Card;
