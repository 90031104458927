import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Liveicon from '../../assets/images/live-icon.svg';
import Link from '@mui/material/Link';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Linkicon from '../../assets/images/icons/link.svg';
import Calendericon from '../../assets/images/icons/calender.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function Settings () {

    return (
      <DashboardLayout title="My Projects" pageTitle="My Projects">

      <div className="dashboard-containor">
      <div className="add-new-project-btn">
        <Link href="CreateProject" >
          Add New Project sdsdfsdf
        </Link>
      </div>
      <Tabs>

        <div className="project-main">
          <div className="project-main-tabs">
            <TabList>
              <Tab> All Projects (12)</Tab>
              <Tab> Hybrid (6) </Tab>
              <Tab> Registration (4) </Tab>
              <Tab> Email Marketing (0) </Tab>
              <Tab> Sales Enablement (0) </Tab>
              <Tab> In Person (2) </Tab>
            </TabList>
          </div>
          <div className="projects-search-grid">
            <div className="">
              <TextField label="Search" size="small" />
            </div>
            <div className="grid-list-view">
              <div className="grid-view"></div>
              <div className="list-view"></div>
            </div>
          </div>
        </div>

        <TabPanel>            
          <div className="project-block-listing">
            <div className="project-block">

              <div className="live-padding">

                <div className="live-outer">
                  <div className="live-outer-left">
                    <span> Hybrid </span>
                    <p> <img src={Liveicon} /> Live Now </p>
                  </div>
                  <div className="live-outer-right">
                    <MoreHorizIcon />
                  </div>
                </div>

                <div className="live-heading">
                  <h2 className="secondary"> Introduction to the Therapeutic Value of Visual Arts </h2>
                </div>

                <div className="live-link long-url">
                  <img src={Linkicon} />
                  <Link href="/#" className="primary-text">
                    https://softobiz.illumeetxp.com
                  </Link>
                </div>
                <div className="live-link">
                  <img src={Calendericon} />
                  <p className="primary-text"> Friday, Sep, 17th 2021, 4:15 PM (UTC−05) </p>
                </div>
                <div className="live-location">
                  <p className="primary-text"> 2 Sessions </p>
                  <FiberManualRecordIcon />
                  <span> Location: India </span>
                </div>

              </div>

              <div className="live-records">
                <List>
                  <ListItem>
                    <ListItemText>
                      <h2 className="secondary orange-color"> --- </h2>
                      <p> Contacts (in 2 Campaigns) </p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <h2 className="secondary blue-color"> -- </h2>
                      <p> Conversion Rate </p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <h2 className="secondary purple-color"> --- </h2>
                      <p> Attendees </p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <h2 className="secondary green-color"> --- </h2>
                      <p> Checked-in Attendees </p>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>

              <div className="live-tags">
                <div className="project-main-tabs">
                  <List>
                    <ListItem>
                      <ListItemText> #Marketing </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText> #Launch </ListItemText>
                    </ListItem>
                  </List>
                </div>
              </div>

            </div>
          </div>
        </TabPanel>

      </Tabs>

        <div className="pagination-outer">
          <List>
            <ListItem>
              <ListItemText> <a href="#"> <ArrowBackIosIcon /> </a> </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText> <a href="#"> 1 </a> </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText> <a href="#"> 2 </a> </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText> <a href="#"> <ArrowForwardIosIcon /> </a> </ListItemText>
            </ListItem>
          </List>
        </div>

      </div>

      </DashboardLayout>
    )
  
}

export default Settings
