import React, { useEffect, useState } from 'react';
import Sidebar from '../../Components/Sidebar';
import MobileMenu from '../../Components/Menu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { generateUrl, getProjectIdFromUrl } from '../../helpers/common';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createNotification } from 'helpers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { xpAccountIntegrationGmailGetGmailId } from 'ReduxStore/API';

const DashboardComponent = (props) => { 
  const [barMessage, setBarMessage] = useState('');
  const navigate = useNavigate(); // Use navigate instead of history

  useEffect(() => {
    getBarMessageBasedOnUserRole();
    findIfAllowedFromSuperadminRequest();
  }, []);


  const getUserDetailsFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('user_details'));
  };

  const getBarMessageBasedOnUserRole = () => {
    let userDetails = getUserDetailsFromLocalStorage();
    let userRole = userDetails.role;
    let barMessage = '';
    if (userRole === 1 || userRole === 3) {
      barMessage = (
        <div className="bar-message">
          <span>
            You have reached the maximum number of resources of your current plan. Please contact our Support Team.
          </span>
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={naviateToBilling}
          >
            Check plan status
          </span>
        </div>
      );
    } else if (userRole === 2) {
      barMessage = (
        <div className="bar-message">
          You reached the maximum number of resources of your current plan. Please contact your application Administrator.
        </div>
      );
    }
    setBarMessage(barMessage);
  };

  const findIfAllowedFromSuperadminRequest = () => {
    if (!window.location.href.includes('/project-dashboard')) {
      let userDetails = getUserDetailsFromLocalStorage();
      let servicePlanId = userDetails.servicePlanId;
      let reqData = {
        servicePlanId,
      };
      if (servicePlanId) {
        props.findIfAllowedFromSuperadminOuterRequest(reqData);
      }
    }
  };

  const naviateToBilling = () => {
    navigate(generateUrl('/yourPlan')); // Use navigate to redirect
  };

  return (
    <div className="dashboardMainPage">
      <Sidebar />

      <Box component="div" className="dashboardContentArea">
        {!props?.servicePlan?.allowedFromSuperadmin ? barMessage : null}

        <div className="dashboardHeader">
          {/*   <div className="dbProfile">
            <MobileMenu
              title={props.title}
              pageTitle={props.pageTitle}
              backTo={props.backTo}
              showProgress={props && props.showProgress}
              totalSent={props && props.totalSent}
              totalRecord={props && props.totalRecord}
              showImportProgress={props && props.showImportProgress}
              totalImported={props && props.totalImported}
            />
          </div> */}
        </div>
        {props.children}
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  servicePlan: state.ServicePlan,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      findIfAllowedFromSuperadminOuterRequest: Actions.findIfAllowedFromSuperadminOuterRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapActionsToProps)(DashboardComponent);
