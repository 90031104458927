import * as React from 'react';
import { alpha } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import SvgIcon, { svgIconClasses } from '@mui/material/SvgIcon';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { gray, brand, colorSchemes } from '../themePrimitives';
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';

export const inputsCustomizations = {
  MuiButtonBase: {
    defaultProps: {
      disableTouchRipple: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxSizing: 'border-box',
        transition: 'all 100ms ease-in',
        '&:focus-visible': {
          outline: `3px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          outlineOffset: '2px',
        },
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        borderRadius: (theme.vars || theme).shape.borderRadius,
        textTransform: 'none',
        fontWeight: '400',
        variants: [
          {
            props: {
              size: 'small',
            },
            style: {
              height: '2.1875rem', //35px
              padding: '8px 12px',
            },
          },
          {
            props: {
              size: 'medium',
            },
            style: {
              height: '2.75rem', // 44px
            },
          },
          {
            props: {
              size: 'large',
            },
            style: {
              height: '3.25rem', // 52px
            },
          },
          {
            props: {
              color: 'primary',
              variant: 'contained',
            },
            style: {
              color: 'white',
              '&:hover': {
                backgroundColor: brand[400],
              },
              '&:active': {
                backgroundColor: brand[400],
              },
              ...theme.applyStyles('dark', {
                color: 'black',
                backgroundColor: gray[50],
                '&:hover': {
                  backgroundColor: gray[300],
                },
                '&:active': {
                  backgroundColor: gray[400],
                },
              }),
            },
          },
          {
            props: {
              color: 'secondary',
              variant: 'contained',
            },
            style: {
              color: '#000000',
              backgroundColor: colorSchemes.light.palette.primary.light,
              // backgroundImage: `linear-gradient(to bottom, ${alpha(brand[400], 0.8)}, ${brand[500]})`,
              // boxShadow: `inset 0 2px 0 ${alpha(brand[200], 0.2)}, inset 0 -2px 0 ${alpha(brand[700], 0.4)}`,
              // border: `1px solid ${brand[500]}`,
              '&:hover': {
                backgroundColor: brand[100],
              },
              '&:active': {
                backgroundColor: brand[200],
              },
            },
          },

          {
            props: {
              color: 'white',
              variant: 'contained',
            },
            style: {
              color: '#000000',
              backgroundColor: theme.palette.common.white,
              // backgroundImage: `linear-gradient(to bottom, ${alpha(brand[400], 0.8)}, ${brand[500]})`,
              // boxShadow: `inset 0 2px 0 ${alpha(brand[200], 0.2)}, inset 0 -2px 0 ${alpha(brand[700], 0.4)}`,
              border: 'none',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
              '&:active': {
                backgroundColor: theme.palette.common.white,
              },
            },
          },

          {
            props: {
              color: 'primary',
              variant: 'outlined',
            },
            style: {
              color: brand[700],
              border: '1px solid',
              borderColor: brand[200],
              backgroundColor: brand[50],
              '&:hover': {
                backgroundColor: brand[100],
                borderColor: brand[400],
              },
              '&:active': {
                backgroundColor: alpha(brand[200], 0.7),
              },
              ...theme.applyStyles('dark', {
                color: brand[50],
                border: '1px solid',
                borderColor: brand[900],
                backgroundColor: alpha(brand[900], 0.3),
                '&:hover': {
                  borderColor: brand[700],
                  backgroundColor: alpha(brand[900], 0.6),
                },
                '&:active': {
                  backgroundColor: alpha(brand[900], 0.5),
                },
              }),
            },
          },
          {
            props: {
              color: 'secondary',
              variant: 'outlined',
            },
            style: {
              color: (theme.vars || theme).palette.text.primary,
              border: '1px solid',
              borderColor: (theme.vars || theme).palette.secondary.main,
              backgroundColor: '#ffffff',
              '&:hover': {
                backgroundColor: gray[50],
                // borderColor: gray[300],
              },
              '&:active': {
                backgroundColor: gray[50],
              },
              ...theme.applyStyles('dark', {
                backgroundColor: gray[800],
                borderColor: gray[700],
                '&:hover': {
                  backgroundColor: gray[900],
                  borderColor: gray[600],
                },
                '&:active': {
                  backgroundColor: gray[900],
                },
              }),
            },
          },

          {
            props: {
              variant: 'text',
            },
            style: {
              color: gray[600],
              '&:hover': {
                backgroundColor: gray[100],
              },
              '&:active': {
                backgroundColor: gray[200],
              },
              ...theme.applyStyles('dark', {
                color: gray[50],
                '&:hover': {
                  backgroundColor: gray[700],
                },
                '&:active': {
                  backgroundColor: alpha(gray[700], 0.7),
                },
              }),
            },
          },
          {
            props: {
              color: 'secondary',
              variant: 'text',
            },
            style: {
              color: brand[700],
              '&:hover': {
                backgroundColor: alpha(brand[100], 0.5),
              },
              '&:active': {
                backgroundColor: alpha(brand[200], 0.7),
              },
              ...theme.applyStyles('dark', {
                color: brand[100],
                '&:hover': {
                  backgroundColor: alpha(brand[900], 0.5),
                },
                '&:active': {
                  backgroundColor: alpha(brand[900], 0.3),
                },
              }),
            },
          },
        ],
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        borderRadius: (theme.vars || theme).shape.borderRadius,
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: 0,
        color: (theme.vars || theme).palette.text.primary,
        border: '1px solid ',
        borderColor: gray[200],
        backgroundColor: alpha(gray[50], 0.3),
        '&:hover': {
          backgroundColor: gray[100],
          borderColor: gray[300],
        },
        '&:active': {
          backgroundColor: gray[200],
        },
        ...theme.applyStyles('dark', {
          backgroundColor: gray[800],
          borderColor: gray[700],
          '&:hover': {
            backgroundColor: gray[900],
            borderColor: gray[600],
          },
          '&:active': {
            backgroundColor: gray[900],
          },
        }),
        variants: [
          {
            props: {
              size: 'small',
            },
            style: {
              width: '2.25rem',
              height: '2.25rem',
              padding: '0.25rem',
              [`& .${svgIconClasses.root}`]: { fontSize: '1rem' },
            },
          },
          {
            props: {
              size: 'medium',
            },
            style: {
              width: '2.75rem',
              height: '2.75rem',
            },
          },
          {
            props: {
              size: 'large',
            },
            style: {
              width: '3.25rem', // 52px
              height: '3.25rem', // 52px
            },
          },
          {
            props: {
              color: 'primary',
              variant: 'contained',
            },
            style: {
              color: 'white',
              '&:hover': {
                backgroundColor: gray[900],
              },
              '&:active': {
                backgroundColor: gray[900],
              },
              ...theme.applyStyles('dark', {
                color: 'black',
                backgroundColor: gray[50],
                '&:hover': {
                  backgroundColor: gray[300],
                },
                '&:active': {
                  backgroundColor: gray[400],
                },
              }),
            },
          },
          {
            props: {
              color: 'secondary',
              variant: 'contained',
            },
            style: {
              color: '#000000',
              backgroundColor: colorSchemes.light.palette.primary.light,
              // backgroundImage: `linear-gradient(to bottom, ${alpha(brand[400], 0.8)}, ${brand[500]})`,
              // boxShadow: `inset 0 2px 0 ${alpha(brand[200], 0.2)}, inset 0 -2px 0 ${alpha(brand[700], 0.4)}`,
              // border: `1px solid ${brand[500]}`,
              '&:hover': {
                backgroundColor: brand[100],
              },
              '&:active': {
                backgroundColor: brand[200],
              },
            },
          },
          {
            props: {
              color: 'white',
              variant: 'contained',
            },
            style: {
              color: '#000000',
              backgroundColor: theme.palette.common.white,
              // backgroundImage: `linear-gradient(to bottom, ${alpha(brand[400], 0.8)}, ${brand[500]})`,
              // boxShadow: `inset 0 2px 0 ${alpha(brand[200], 0.2)}, inset 0 -2px 0 ${alpha(brand[700], 0.4)}`,
              border: 'none',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
              '&:active': {
                backgroundColor: theme.palette.common.white,
              },
            },
          },

          {
            props: {
              color: 'transparent',
              variant: 'contained',
              size: "small",
            },
            style: {
              color: '#000000',
              backgroundColor: '#ffffff00',
              border: 'none',
              padding: '0px',
              width: 'auto',
              height: 'auto',
              '&:hover': {
                backgroundColor: '#ffffff00',
              },
              '&:active': {
                backgroundColor: '#ffffff00',
              },
            },
          },

        ],
      }),
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '10px',
        boxShadow: `0 4px 16px ${alpha(gray[400], 0.2)}`,
        [`& .${toggleButtonGroupClasses.selected}`]: {
          color: brand[500],
        },
        ...theme.applyStyles('dark', {
          [`& .${toggleButtonGroupClasses.selected}`]: {
            color: '#fff',
          },
          boxShadow: `0 4px 16px ${alpha(brand[700], 0.5)}`,
        }),
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '12px 16px',
        textTransform: 'none',
        borderRadius: '10px',
        fontWeight: 500,
        ...theme.applyStyles('dark', {
          color: gray[400],
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
          [`&.${toggleButtonClasses.selected}`]: {
            color: brand[300],
          },
        }),
      }),
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      icon: (
        <SvgIcon sx={{ width: '13px', height: '13px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
            <g id="Rectangle_3553" data-name="Rectangle 3553" fill="none" stroke="#000" stroke-width="1">
              <rect width="13" height="13" rx="2" stroke="none" />
              <rect x="0.5" y="0.5" width="12" height="12" rx="1.5" fill="none" />
            </g>
          </svg>
        </SvgIcon>
      ),
      checkedIcon: (<SvgIcon sx={{ width: '13px', height: '13px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
          <g id="CheckedIcon" transform="translate(-270 -392.5)">
            <g id="Rectangle_3553" data-name="Rectangle 3553" transform="translate(270 392.5)" fill="none" stroke="#000" stroke-width="1">
              <rect width="13" height="13" rx="2" stroke="none" />
              <rect x="0.5" y="0.5" width="12" height="12" rx="1.5" fill="none" />
            </g>
            <path id="Path_10648" data-name="Path 10648" d="M856.238,105.189l2.228,2.318,1.671-1.671,2.367-2.367" transform="translate(-582.652 293.73)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </svg>
      </SvgIcon>),
      indeterminateIcon: <RemoveRoundedIcon sx={{ height: 13, width: 13 }} />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        // margin: 10,
        height: 13,
        width: 13,
        borderRadius: 2,
        // border: '1px solid ',
        borderColor: '#000',
        boxShadow: 'none',
        // backgroundColor: '#fff',
        transition: 'border-color, background-color, 120ms ease-in',
        '&:hover': {
          borderColor: '#000',
        },
        '&.Mui-focusVisible': {
          outline: '#000',
          outlineOffset: '2px',
          borderColor: '#000',
        },
        '&.Mui-checked': {
          color: '#000',
          // backgroundColor: "#fff",
          borderColor: '#000',
          boxShadow: `none`,
          '&:hover': {
            // backgroundColor: '#fff',
          },
        },
        ...theme.applyStyles('dark', {
          borderColor: alpha(gray[700], 0.8),
          boxShadow: '0 0 0 1.5px hsl(210, 0%, 0%) inset',
          backgroundColor: alpha(gray[900], 0.8),
          '&:hover': {
            borderColor: brand[300],
          },
          '&.Mui-focusVisible': {
            borderColor: brand[400],
            outline: `3px solid ${alpha(brand[500], 0.5)}`,
            outlineOffset: '2px',
          },
        }),
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        border: 'none',
        variants: [

          {
            props: {
              size: 'small',
            },
            style: {
              height: '2.25rem',
            },
          },
          {
            props: {
              size: 'medium',
            },
            style: {
              height: '2.75rem',
            },
          },
          {
            props: {
              size: 'large',
            },
            style: {
              height: '3.25rem', // 52px
            },
          },
        ],

      },
      input: {
        fontWeight: '400',
        '&::placeholder': {
          opacity: 0.7,
          color: gray[500],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: 0,
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0px 1000px #fff inset", // Set the background
          WebkitTextFillColor: "#000", // Set text color 
          caretColor: "#000", // Set text color 
        },
      },
      root: ({ theme }) => ({
        padding: '8px 12px',
        color: (theme.vars || theme).palette.text.primary,
        borderRadius: (theme.vars || theme).shape.borderRadius,
        border: `1px solid ${(theme.vars || theme).palette.secondary.main}`,
        backgroundColor: (theme.vars || theme).palette.background.default,
        transition: 'border 120ms ease-in',
        '&:hover': {
          borderColor: gray[900],
        },
        [`&.${outlinedInputClasses.focused}`]: {
          // outline: `3px solid ${alpha(brand[500], 0.5)}`,
          borderColor: brand[400],
        },
        ...theme.applyStyles('dark', {
          '&:hover': {
            borderColor: gray[500],
          },
        }),
        variants: [
          {
            props: {
              type: 'password',
            },
            style: {
              fontFamily: 'monospace',
            },
          },
          {
            props: {
              size: 'small',
            },
            style: {
              height: '2.25rem',
            },
          },
          {
            props: {
              size: 'medium',
            },
            style: {
              height: '2.75rem',
            },
          },
          {
            props: {
              size: 'large',
            },
            style: {
              height: '3.25rem', // 52px
            },
          },
        ],

      }),
      notchedOutline: {
        border: 'none',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: (theme.vars || theme).palette.grey[500],
        ...theme.applyStyles('dark', {
          color: (theme.vars || theme).palette.grey[400],
        }),
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        typography: theme.typography.body1,
        marginBottom: 8,
        color: theme.colorSchemes.light.palette.text.primary,
      }),
    },
  },
};
