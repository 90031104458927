//@ts-nocheck
import React, { Dispatch, SetStateAction } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
import Typography from '@mui/material/Typography';
import BackButtonOutlined from '../../../src/assets/images/icons/backButtonOutlined.svg';
import Button from '@mui/material/Button';
// import '../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
//@ts-ignore
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Updated import
import GeneralDashboard from './Tabs/General/GeneralDashboard';
import SettingsDashboard from './Tabs/Settings/SettingsDashboard';
import Dashboard from './Tabs/Dashboard/DashboardDashboard';
import ChatWiew from './Tabs/ChatWidget/ChatView';
import { generateUrl } from 'helpers/common';

interface SxProps {
  background: string;
  marginTop: string;
  marginBottom: string;
}

interface AiChatbotDashboardLayoutProps {
  userId: string;
  agentId: string | undefined;
  setOpenEdit: Dispatch<SetStateAction<boolean>>;
  setIdToEdit: Dispatch<SetStateAction<string | undefined>>;
  searchText: string;
}

const AiChatbotDashboardLayout = ({
  userId,
  agentId,
  setOpenEdit,
  setIdToEdit,
  searchText,
}: AiChatbotDashboardLayoutProps) => {
  const navigate = useNavigate();  
  const params: {
    id: string;
  } = useParams();

  const handleBackButton = () => {
    setOpenEdit(false);
    setIdToEdit('');
    navigate(generateUrl('/aichatbot'));  
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} mb={2} className="top-filter-area">
        <Grid >
          <Grid container alignItems={'center'} spacing={2}>
            <Grid >
              <Button
                size="small"
                style={{ padding: '0', minWidth: '0' }}
                variant="text"
                color="primary"
                onClick={handleBackButton}
              >
                <img src={BackButtonOutlined} alt="back" />
              </Button>
            </Grid>
            <Grid >
              <Box className="heading"> AI Agent </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className="container-with-sidebar" p={0}>
        <Tabs defaultIndex={0}>
          <Box
            className="project-sidebar"
            sx={
              {
                background: 'transparent !important',
                marginTop: '0 !important',
                marginBottom: '0 !important',
              } as SxProps
            }
            my={0}
          >
            <TabList>
              <Tab>
                {' '}
                <Link
                  to={`/aichatbot/general${params?.id ? '/' + params?.id : ''}`}
                >
                  {' '}
                  General{' '}
                </Link>
              </Tab>
              {params.id ? (
                <>
                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/settings/${params.id}`}>
                      Settings
                    </Link>
                  </Tab>

                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/chatwidget/${params.id}`}>
                      Chat Widget
                    </Link>
                  </Tab>

                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/dashboard/${params.id}`}>
                      Dashboard
                    </Link>
                  </Tab>
                </>
              ) : (
                <>
                  <Tab disabled>
                    {' '}
                    <a>Settings</a>
                  </Tab>
                  <Tab disabled>
                    <a>Chat Widget</a>
                  </Tab>

                  <Tab disabled>
                    {' '}
                    <a>Dashboard</a>
                  </Tab>
                </>
              )}
            </TabList>
          </Box>

          <Box className="container-sidebar-right">
            <TabPanel index={0}>
              <GeneralDashboard
                userId={userId}
                agentIdFromParent={agentId}
                searchText={searchText}
              />
            </TabPanel>

            <TabPanel index={1}>
              <SettingsDashboard />
            </TabPanel>

            <TabPanel index={2}>
              <ChatWiew agentId={agentId} />
            </TabPanel>

            <TabPanel index={3}>
              <Dashboard />
            </TabPanel>
          </Box>
        </Tabs>
      </Box>
    </ThemeProvider>
  );
};

export default AiChatbotDashboardLayout;
