import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
// import '../../sass/main.scss';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { Button } from '@mui/material';
import SearchIconBar from '../../assets/images/searchIco.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Pagination from 'react-js-pagination';
import ImportModal from './importModal';
import ContactModal from './contactModal';
import UpdateGroupModal from './updateGroupModal';
import DeleteGroupModal from './deleteGroupModal';
import AssignGroupModal from './assignGroup';
import UpdateModal from './updateModal';
import GroupModal from '../Contact/groupModal';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DeleteAllContactAPI,
  DeleteManyGroupApi,
  getProgressCountOfAudienceAPI,
} from 'ReduxStore/API';
import ImportStatusModal from '../Contact/importStatusModal';
import { generateCsv, mkConfig } from 'export-to-csv';
import NoDataFound from '../../assets/images/no-data-found.png';

import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';

import AudienceIcon from '../../assets/images/icons/audience.svg';
import ExportIcon from '../../assets/images/icons/export.svg';
import ImportIcon from '../../assets/images/icons/import.svg';
import LogsIcon from '../../assets/images/icons/agenda.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
import MoreIcon from '../../assets/images/icons/more.svg';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Checkbox from '@mui/material/Checkbox';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { getWeekYearWithOptions } from 'date-fns/fp';
import SuccessPopup from '../../assets/images/successPopup.svg';
import { UserData, generateUrl, getSystemLevelPermissions } from '../../helpers/common';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import ProgresBar from 'Common/ProgressBar';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Link } from 'react-router-dom';
import withCustomRouter from '../../Common/withCustomRouter'; 

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};


class ContactGroups extends Component {
  allowed = getSystemLevelPermissions();
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'Audience',
      pageTitle: 'Audience',
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: '',
      groupOpen: false,
      importOpen: false,
      assignGroupOpen: false,
      updateOpen: false,
      updateGroupOpen: false,
      deleteGroupOpen: false,
      singleData: {},
      contacts: [],
      allChecked: false,
      list: [],
      showDelete: false,
      searchText: '',
      group_id: '',
      listAll: [],
      imported: 0,
      rejected: 0,
      importOpenModal: false,
      ids: [],
      importContactProgressId: 0,
      openDeleteContactModel: false,
      audienceIdForDelete: false,
      openDeleteAllAudienceModel: false,
      limit: 10,
      startCount: 1,
      endCount: 0,
      itemCount: 10,
      totalResultsGroups: '',
      audienceCreated: false,
      searching: false,
      order: 'desc',
      sort_by: 'updatedAt',
      openSuccess: false,
      openValidation: false,
      copyCheck: false,
      importCheck: false,
      showProgress: false,
      progressPercentage: 0,
      import: false,
      customFields: [],
      openCustomFieldsList: false,
      openAddCustomField: false,
      listValue: '',
      fieldLabel: '',
      fieldType: '',
      fieldName: '',
      fieldId: '',
      checkboxValue: '',
      isEdit: false,
      isSaveAndExit: false,
      fieldId: '',
      isDeleteCustomField: false,
    };
  }

  componentDidMount() {
    this.groupRequest();
    this.handleLoading();

    if (this.state.itemCount < this.state.totalResultsGroups) {
      this.setState({
        endCount: parseInt(this.state.itemCount),
      });
    }
    this.setState({
      isTotalRecords: true,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.groups.deleteAudienceCustomFieldsSuccess === true &&
      prevProps.groups.deleteAudienceCustomFieldsSuccess === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('success', this.props.groups.message);
      this.listAudienceCustomFields();
      this.setState(
        {
          loading: false,
        },
        () => this.handleCloseOpenDeleteContactModel(),
      );
    }

    if (
      this.props.groups.listAudienceCustomFieldsSuccess === true &&
      prevProps.groups.listAudienceCustomFieldsSuccess === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState(
        {
          customFields: this.props.groups.customFields,
          loading: false,
        },
        () => this.handleCloseOpenDeleteContactModel(),
      );
    }

    if (
      this.props.groups.addAudienceCustomFieldsSuccess === true &&
      prevProps.groups.addAudienceCustomFieldsSuccess === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('success', this.props.groups.message, '');
      if (!this.state.isSaveAndExit) {
        this.setState({
          fieldLabel: '',
          fieldName: '',
          fieldType: '',
          listValue: '',
          checkboxValue: '',
          errors: {},
          isEdit: false,
        });
      } else {
        this.handleCloseForm();
      }
      this.listAudienceCustomFields();
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.groups.updateAudienceCustomFieldsSuccess === true &&
      prevProps.groups.updateAudienceCustomFieldsSuccess === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('success', this.props.groups.message);
      this.listAudienceCustomFields();
      this.setState(
        {
          loading: false,
        },
        () => this.handleCloseForm(),
      );
    }

    if (
      this.props.contacts.importGroupContactValidation === true &&
      prevProps.contacts.importGroupContactValidation === false &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        loading: false,
        openValidation: true,
      });
    }
    if (this.state.searching && !prevState.searching) {
      this.setState(
        {
          page_no: 1,
          startCount: 1,
          searching: false,
        },
        () => this.groupRequest(),
      );
    }

    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResultsGroups) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      } else {
        this.setState({
          endCount: parseInt(this.props.groups.totalResults),
        });
      }
    }

    if (
      this.state.isTotalRecords ||
      (this.props.groups.message === 'Audience list get successfully' &&
        prevProps.groups.message !== 'Audience list get successfully')
    ) {
      this.setState(
        {
          isTotalRecords: false,
        },
        () => {
          this.setState(
            {
              totalResultsGroups: this.props.groups.totalResults,
            },
            () => {
              if (this.state.itemCount >= this.state.totalResultsGroups) {
                this.setState({
                  endCount: parseInt(this.state.totalResultsGroups),
                });
              } else if (
                this.state.itemCount < this.state.totalResultsGroups &&
                this.state.page_no === 1
              ) {
                this.setState({
                  endCount: parseInt(this.state.itemCount),
                });
              }
            },
          );
          this.props.groups.success = false;
        },
      );
    }

    if (
      this.props.groups.success === true &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState({
        totalResultsGroups: this.props?.groups?.totalResults,
      });

      if (this.state.itemCount >= this.props.groups.totalResults) {
        this.setState({
          endCount: this.props.groups.totalResults,
        });
      } else {
        this.setState({
          endCount:
            this.state.totalResultsGroups >
            this.state.itemCount * this.state.page_no
              ? this.state.itemCount * this.state.page_no
              : this.state.totalResultsGroups,
        });
      }
    }
    if (
      this.props.contacts.success === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      // createNotification('success', this.props.contacts.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
      this.setState((prevState) => {
        let { list } = prevState;
        list = list.map((item) => ({ ...item, isChecked: false }));

        return { list };
      });
      this.setState({ showDelete: false, allChecked: false });
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no };
    }

    if (
      this.props.contacts.listSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
    }

    if (
      this.props.contacts.createGroupSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts?.message);
      this.setState(
        {
          loading: false,
          groupOpen: false,
        },
        () => this.groupRequest(),
      );
    }

    if (
      this.props.contacts.error === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }

    if (
      this.props.contacts.searchError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }

    if (
      this.props.groups.error === true &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('error', this.props.groups.message);
      let st = prevState.loading
        ? this.setState({ loading: false, list: [] })
        : null;
    }

    if (
      this.props.contacts.importSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        importOpen: false,
        imported: this.props.contacts.imported,
        rejected: this.props.contacts.rejected,
        importOpenModal: true,
      });
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no };
      this.handleLoading();
    }

    if (
      this.props.contacts.contactSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        open: false,
      });
      this.groupRequest();
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no };
    }
    if (
      this.props.contacts.groupSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      /**for copy contacts from project's attendees or another audience group
       *
       * show progress bar
       */
      if (this.props.contacts?.copyCheck) {
        //In backend we will send importContactId
        if (this.props.contacts?.importContactId) {
          this.getProgressCountOfAudience(
            this.props.contacts.importContactId,
            'copy',
          );
        }
      }

      if (this.props.contacts?.importCheck) {
        if (this.props.contacts?.importContactId) {
          this.setState({
            showProgress: true,
            import: true,
          });
          this.getProgressCountOfAudience(
            this.props.contacts.importContactId,
            'import',
          );
        }
      }
    }

    if (
      this.props.groups.updateGroupSuccess === true &&
      this.props.groups.updateGroupSuccess !==
        prevProps.groups.updateGroupSuccess
    ) {
      createNotification('success', this.props.groups.message);
      this.setState(
        {
          updateGroupOpen: false,
        },
        () => this.groupRequest(),
      );
    }

    if (
      this.props.groups.deleteGroupSuccess === true &&
      this.props.groups.deleteGroupSuccess !==
        prevProps.groups.deleteGroupSuccess
    ) {
      createNotification('success', this.props.groups.message);
      this.setState(
        {
          openDeleteContactModel: false,
          openDeleteAllAudienceModel: false,
          page_no: 1,
          startCount: 1,
        },
        () => this.groupRequest(),
      );
    }

    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        updateOpen: false,
        assignGroupOpen: false,
        showDelete: false,
        allChecked: false,
        page_no: 1,
      });
      createNotification('success', this.props.contacts.message);
    }

    if (
      this.props.groups.success &&
      this.props.groups.message === 'Audience created successfully' &&
      !prevProps.groups.message === 'Audience created successfully'
    ) {
      this.setState({
        page_no: 1,
      });
    }
    if (
      this.props.groups.success &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState({
        deleteGroupOpen: false,
        assignGroupOpen: false,
        loading: false,
        showDelete: false,
        allChecked: false,
        openDeleteAllAudienceModel: false,
      });
      let data = await this.f1();
      this.setState({ list: data });
    }
  }

  groupRequest = () => {
    try {
      this.setState({ loading: true });
      const { searchText, page_no, itemCount, sort_by, order } = this.state;
      const requestBody = {
        searchText: searchText.trim(),
        limit: itemCount,
        page_no,
        sort_by,
        order,
        permission: 'View Audience',
      };
      this.props.groupRequest(requestBody);
      const totalResults = this.props.groups.totalResults;
      this.setState({
        totalResults,
      });
    } catch (error) {
      createNotification('error', 'Something went wrong in audience listing');
    }
  };

  /**
   * to get percentage of progress
   *
   * we are using EventSource to get continous response from backend
   *
   * @param {ObjectId} importContactProgressId
   * @param {string} importedType //import|copy
   */
  getProgressCountOfAudience = (importContactProgressId, importedType) => {
    const users = UserData();
    const eventSource = new EventSource(
      `${BACKEND_API_URL}/groups/import-progress/${importContactProgressId}?token=bearer ${users.token}`,
    );
    eventSource.onmessage = (event) => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        createNotification('error', 'Error occured in the progress!');
        //hiding progress bar popup
        this.setState({
          showProgress: false,
          import: false,
          progressPercentage: 0,
        });
      } else {
        const progress = parseInt(event.data);
        this.setState({
          progressPercentage: progress,
        });

        //if the progress completed
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          setTimeout(() => {
            this.setState({
              showProgress: false,
              import: false,
              openSuccess: true,
              progressPercentage: 0,
            });

            if (importedType === 'copy') {
              this.setState({
                copyCheck: true,
              });
            }
            if (importedType === 'import') {
              this.setState({
                importCheck: true,
              });
            }
          }, 500);
          eventSource.close();
        }
      }
    };
  };

  f1 = async () => {
    let data =
      this.props.groups.groups &&
      this.props.groups.groups.map((data, index) => {
        return {
          groupName: data.groupName,
          _id: data._id,
          isChecked: false,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          audienceCount: data.audienceCount,
        };
      });

    return await data;
  };

  handleChangeCheck = async (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    await this.setState((prevState) => {
      let { allChecked, list } = prevState;
      if (itemName === 'checkAll') {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.groupName === itemName ? { ...item, isChecked: checked } : item,
        );
      }

      return { list, allChecked };
    });

    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    ids.length > 0
      ? this.setState({ showDelete: true })
      : this.setState({ showDelete: false });
  };

  onOpenImportStatusModal = () => {
    this.setState({ importOpenModal: true });
  };

  onCloseImportStatusModal = () => {
    this.setState({ importOpenModal: false });
  };
  onOpenAssignGroup = () => {
    this.setState({ assignGroupOpen: true });
  };

  onCloseAssignGroup = () => {
    this.setState({ assignGroupOpen: false });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenGroupModal = () => {
    this.setState({ groupOpen: true });
  };

  onCloseGroupModal = () => {
    this.setState({ groupOpen: false });
  };

  onOpenUpdateGroupModal = (data) => {
    this.setState({
      updateGroupOpen: true,
      group_id: data._id ? data._id : '',
      dataForEdit: data,
    });
  };

  onCloseUpdateGroupModal = () => {
    this.setState({ updateGroupOpen: false });
  };

  onOpenDeleteGroupModal = () => {
    this.setState({ deleteGroupOpen: true });
  };

  onCloseDeleteGroupModal = () => {
    this.setState({ deleteGroupOpen: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  FilterOpen = () => {
    this.setState((prevState) => ({
      isFilterVisible: !prevState.isFilterVisible,
    }));
  };
  ImportsOpen = () => {
    this.setState((prevState) => ({
      isFilterImports: !prevState.isFilterImports,
    }));
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleClickOpenDeleteContactModal = (_id) => {
    this.setState({
      openDeleteContactModel: true,
      audienceIdForDelete: _id,
    });
  };
  handleClickopenDeleteAllAudienceModel = () => {
    this.setState({
      openDeleteAllAudienceModel: true,
    });
  };

  handleCloseOpenDeleteContactModel = () => {
    this.setState({
      isDeleteCustomField: false,
      openDeleteContactModel: false,
      openDeleteAllAudienceModel: false,
      fieldId: '',
    });
  };

  handleDeleteConfirm = () => {
    try {
      if (this.state.isDeleteCustomField) {
        const data = {
          fieldId: this.state.fieldId,
          lngCode: this.props.users.newLangState
        };
        this.props.deleteAudienceCustomFields(data);
      } else {
        const { audienceIdForDelete } = this.state;
        const body = {
          audienceIdForDelete,
        };
        this.props.deleteGroup({
          id: audienceIdForDelete,
          permission: 'Delete Audience',
          lngCode: this.props.users.newLangState
        });
      }
      this.handleLoading();
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openImportModal = () => {
    this.setState({ importOpen: true });
  };

  onCloseImportModal = () => {
    this.setState({ importOpen: false });
  };

  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false });
  };
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data });
  };

  deleteAll = () => {
    this.handleLoading();
    DeleteAllContactAPI()
      .then((result) => {
        this.handleLoading();
        if (result.data.status === 200) {
          this.setState({
            contacts: [],
            allContactsList: [],
            totalResults: 0,
            list: [],
            listAll: [],
          });
          createNotification('success', result.data.message);
          let requestbody =
            this.state.group_id !== ''
              ? { page_no: this.state.page_no, group_id: this.state.group_id }
              : { page_no: this.state.page_no };
          this.props.listContactRequest(requestbody);
        } else {
          createNotification('error', result.data.message);
        }
      })
      .catch(console.log);
  };

  deleteCheck = async () => {
    this.handleLoading();
    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    let requestbody = { ids: ids, lngCode: this.props.users.newLangState };
    DeleteManyGroupApi(requestbody).then((result) => {
      if (result.data.status === 200) {
        createNotification('success', result.data.message);
        this.setState({
          loading: false,
          openDeleteAllAudienceModel: false,
          page_no: 1,
          startCount: 1,
        });
        this.groupRequest();
      } else {
        createNotification('error', result.data.message);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.searchText !== '' &&
      this.state.searchText.trim().length > 0
    ) {
      const { searchText, limit } = this.state;
      const requestbody = {
        searchText: searchText.trim(),
        order: 'desc',
        page_no: 1,
        limit,
        permission: 'View Audience',
      };
      this.setState(
        {
          searching: true,
        },
        () => this.props.groupRequest(requestbody),
      );

      this.handleLoading();
    } else {
      this.groupRequest();
      this.handleLoading();
    }
  };
  onDeleteGroup = (id) => {
    const requestbody = { id, permission: 'Delete Audience' };
    this.props.deleteGroupRequest(requestbody);
    this.handleLoading();
  };

  viewContacts = (id) => {
    this.props.navigate({
      pathname: generateUrl(`/audience-contacts/${id}`),
      state: { isAudienceContactsOpen: true },
    });
  }; 
  sampleCsv = () => {
    var data = [
      {
        firstName: 'value',
        lastName: 'value',
        email: 'value',
        company: 'value',
      },
    ];

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Sample Csv',
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: 'sample',
    };

    const csvExporter = new generateCsv(options);
    if (data.length > 0) {
      csvExporter.generateCsv(data);
    }
  };

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount } = this.state;
      let totalResults = this.props.groups.totalResults;
      this.setState(
        (prevState, props) => ({
          startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
          endCount:
            totalResults > itemCount * pageNumber
              ? itemCount * pageNumber
              : totalResults,
          page_no: pageNumber,
          pageHasChanged: true,
        }),
        () => {
          this.groupRequest();
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.groups.totalResults) {
      this.setState({
        endCount: this.props.groups.totalResults,
      });
    } else {
      this.setState({
        endCount:
          this.props.groups.totalResults >
          this.state.itemCount * this.state.page_no
            ? this.state.itemCount * this.state.page_no
            : this.props.groups.totalResults,
      });
    }
  }

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.groupRequest(),
    );
  };

  checkIfAudienceCreated = () => {
    this.setState({
      audienceCreated: true,
    });
  };

  sortAudience = (sort_by) => {
    this.setState(
      {
        sort_by,
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      },
      () => this.groupRequest(),
    );
  };

  handleClickCloseSuccess = () => {
    /**
     * after clicked done call api to get updated list groups
     */
    this.groupRequest();
    this.setState({
      openSuccess: false,
      groupOpen: false,
      copyCheck: false,
      importCheck: false,
    });
  };

  handleClickReteyValidation = () => {
    this.setState({
      openValidation: false,
      loading: false,
    });
  };

  handleClickCloseValidation = () => {
    this.setState({
      openValidation: false,
      groupOpen: false,
    });
  };

  hideProgress = () => {
    this.setState({
      showProgress: false,
    });
  };

  /**
   * to show progress bar popup model
   */
  showProgressPopup = () => {
    this.setState({
      showProgress: true,
    });
  };

  handleClickOpen = () => {
    this.setState(
      {
        openCustomFieldsList: true,
      },
      () => this.listAudienceCustomFields(),
    );
  };

  handleClose = () => {
    this.setState(
      {
        openCustomFieldsList: false,
      },
      () => this.listAudienceCustomFields(),
    );
  };

  handleClickOpenForm = () => {
    this.setState({
      isEdit: false,
      openAddCustomField: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      openAddCustomField: false,
      fieldLabel: '',
      fieldName: '',
      fieldType: '',
      listValue: '',
      checkboxValue: '',
      errors: {},
      isEdit: false,
    });
  };

  handleLabel = (e) => {
    try {
      this.setState({
        fieldLabel: e.target.value,
        fieldName: e.target.value.toLowerCase().trim().replace(/ /g, ''),
      });
    } catch (err) {
      createNotification('error', 'something went wrong');
    }
  };

  saveNewCustomFieldAndExit = () => {
    try {
      this.setState(
        {
          isSaveAndExit: true,
        },
        () => this.addNewCustomField(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveNewCustomField = () => {
    try {
      this.setState(
        {
          isSaveAndExit: false,
        },
        this.addNewCustomField(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  addNewCustomField = () => {
    try {
      const { fieldLabel, fieldName, fieldType, listValue, checkboxValue } =
        this.state;        
        if (this.isValid()) {
        let filteredList = Array.from(new Set(listValue.split("\n").filter(value => value.trim() !== "").map(value => value.trim())));
        let filteredCheckBox = Array.from(new Set(checkboxValue.split("\n").filter(value => value.trim() !== "").map(value => value.trim())));
        const data = {
          lngCode: this.props.users.newLangState,
          fieldData: {
            fieldLabel,
            fieldName,
            fieldType,
            listValue: filteredList,
            checkboxValue: filteredCheckBox,
          },
        };
        this.props.addAudienceCustomFields(data);
        this.handleLoading();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  listAudienceCustomFields = () => {
    try {
      this.props.listAudienceCustomFields();
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  opendeleteCustomField = (customField) => {
    try {
      this.setState({
        isDeleteCustomField: true,
        openDeleteContactModel: true,
        fieldId: customField._id,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  deleteCustomField = () => {
    try {
      const data = {
        fieldId: this.state.fieldId,
      };
      this.props.deleteCustomField(data);
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  updateAudienceCustomFields = () => {
    try {
      const {
        fieldLabel,
        fieldName,
        fieldType,
        fieldId,
        listValue,
        checkboxValue,
      } = this.state;
      if (this.isValid()) {
        let filteredList = Array.from(new Set(listValue.split("\n").filter(value => value.trim() !== "").map(value => value.trim())));
        let filteredCheckBox = Array.from(new Set(checkboxValue.split("\n").filter(value => value.trim() !== "").map(value => value.trim())));

        const data = {
          fieldId,
          lngCode: this.props.users.newLangState,
          fieldData: {
            fieldLabel,
            fieldName,
            fieldType,
            listValue: filteredList,
            checkboxValue:filteredCheckBox,
          },
        };
        this.props.updateAudienceCustomFields(data);
        this.handleCloseForm();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openEditCustomFieldForm = (customField) => {
    try {
      const { fieldName, fieldType, fieldLabel, listValue, checkboxValue } =
        customField.fieldData;
      const { _id } = customField;
      this.setState({
        openAddCustomField: true,
        fieldName,
        fieldType,
        fieldLabel,
        fieldId: _id,
        isEdit: true,
        listValue:
          listValue === '' ? listValue?.split('') : listValue?.join('\n'),
        checkboxValue:
          checkboxValue === ''
            ? checkboxValue?.split('')
            : checkboxValue?.join('\n'),
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  isValid = () => {
    try {
      const { fieldLabel, fieldType, checkinDate, listValue, checkboxValue } =
        this.state;
      let formIsValid = true;
      let error = {};
      if (
        fieldLabel.trim().length === 0 ||
        fieldLabel === '' ||
        fieldLabel === undefined
      ) {
        formIsValid = false;
        error['fieldLabel'] = 'Please enter the field name';
      }
      if (fieldType === 'dropdown') {
        if (
          listValue === '' ||
          listValue === null ||
          !listValue ||
          listValue.trim().length === 0
        ) {
          formIsValid = false;
          error['listValue'] = 'Please enter at least one value.';
        }
      }

      if (fieldType === 'checkbox') {
        if (
          checkboxValue === '' ||
          checkboxValue === null ||
          !checkboxValue ||
          checkboxValue.trim().length === 0
        ) {
          formIsValid = false;
          error['checkbox'] = 'Please enter at least one value.';
        }
      }
      if (checkinDate === '' || checkinDate === null) {
        formIsValid = false;
        error['checkinDate'] = 'Please select checkin date.';
      }
      if (
        fieldType.trim().length === 0 ||
        fieldType === '' ||
        fieldType === undefined ||
        fieldType === 'Select'
      ) {
        formIsValid = false;
        error['fieldType'] = 'Please select the field type';
      }
      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  render() {
    const {
      isEdit,
      checkboxValue,
      listValue,
      fieldLabel,
      fieldType,
      fieldName,
      fieldId,
      customFields,
      isFilterImports,
      title,
      pageTitle,
      open,
      loading,
      page_no,
      totalResults,
      groupOpen,
      importOpen,
      updateOpen,
      singleData,
      showDelete,
      updateGroupOpen,
      deleteGroupOpen,
      group_id,
      errors,
      searchText,
      importOpenModal,
      imported,
      rejected,
      assignGroupOpen,
      ids,
      list,
      showImportProgress,
      totalImported,
      totalRecord,
      startCount,
      endCount,
      itemCount,
      showProgress,
      progressPercentage,
    } = this.state;

    let checkedAudienceCount =
      Array.isArray(list) &&
      list?.length > 0 &&
      list.filter((item) => item.isChecked === true).length;
    const styles = {
      avatar: {
        backgroundColor: '#FF4170',
        width: '48px',
        height: '48px',
        borderRadius: 8,
      },
    };

    let dataCharArray = [];
    return (
      <DashboardLayout
        title={title}
        pageTitle={pageTitle}
        showImportProgress={showImportProgress}
        totalImported={totalImported}
        totalRecord={totalRecord}
      >
        <ThemeProvider theme={theme}>
          <div className="dashboardMiddleArea">
            <Box
              component={Grid}
              container
              pt={1.3}
              p={0}
              mb={4}
              className="header-common"
            >
              <Grid >
                <h1 className="heading"> {this.props.users.languageStateForRedux?.overviewTab?.audience} </h1>
              </Grid>

              <Grid >
                <Grid container spacing={1}>
                  <Grid >
                    <Button
                      onClick={this.handleClickOpen}
                      variant="outlined"
                      color="primary"
                    >
                      {this.props.users.languageStateForRedux?.dropdowns?.attendees?.custom}
                    </Button>
                  </Grid>

                  {this.allowed['Create Audience'] && (
                    <>
                      <Grid >
                        <Button
                          onClick={this.onOpenModal}
                          variant="outlined"
                          color="primary"
                        >
                          {this.props.users.languageStateForRedux?.audienceTab?.addContact}
                        </Button>
                      </Grid>
                      <Grid >
                        <Button
                          onClick={this.onOpenGroupModal}
                          variant="contained"
                          color="primary"
                        >
                          {this.props.users.languageStateForRedux?.audienceTab?.addNewAudience}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} mb={3} className="top-filter-area">
              <Grid >
                <Grid container spacing={1}>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      size="small"
                      disableElevation
                      aria-haspopup="true"
                    >
                      {this.props.users.languageStateForRedux?.overviewTab?.audience} (
                      {this.props.groups?.groups
                        ? this.props.groups?.totalResults
                        : 0}
                      )
                    </Box>
                  </Grid>
                  <Grid >
                    {showDelete ? (
                      <Grid >
                        <Button
                          size="small"
                          onClick={() =>
                            this.handleClickopenDeleteAllAudienceModel()
                          }
                          variant="contained"
                          color="secondary"
                        >
                          {this.props.users.languageStateForRedux?.common?.deleteChecked}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className="active"
                      size="small"
                      disableElevation
                      aria-haspopup="true"
                    >
                      {checkedAudienceCount} {this.props.users.languageStateForRedux?.dropdowns?.attendees?.selected}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid >
                <Grid container spacing={1.25} className="team-dash-right">
                  <Grid className="">
                    <form onSubmit={this.handleSubmit}>
                      <TextField
                        name="searchText"
                        variant='standard'
                        placeholder={this.props.users.languageStateForRedux?.common?.search}
                        className="search"
                        size="small"
                        onChange={this.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <button
                        type="submit"
                        style={{ display: 'none' }}
                      ></button>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer
              className="list-table"
              style={{ maxHeight: 'calc(100vh - 330px)' }}
            >
              <Table className="table">
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell className="checkbox">
                        <Checkbox
                          color="primary"
                          p={0}
                          name="checkAll"
                          checked={this.state.allChecked}
                          onChange={this.handleChangeCheck}
                        />
                      </TableCell>
                      <TableCell
                        className={`name-avatar ${
                          this.state.sort_by === 'groupName'
                            ? this.state.order === 'asc'
                              ? 'sort-asc'
                              : 'sort-des'
                            : null
                        }`}
                        onClick={() => this.sortAudience('groupName')}
                      >
                        {this.props.users.languageStateForRedux?.overviewTab?.audience}
                      </TableCell>
                      <TableCell className="text" style={{ maxWidth: '150px' }}>
                      {this.props.users.languageStateForRedux?.eventList?.contacts}
                      </TableCell>
                      <TableCell
                        className={`date-time ${
                          this.state.sort_by === 'updatedAt'
                            ? this.state.order === 'asc'
                              ? 'sort-asc'
                              : 'sort-des'
                            : null
                        }`}
                        style={{ maxWidth: '250px' }}
                        onClick={() => this.sortAudience('updatedAt')}
                      >
                        {this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated}
                      </TableCell>
                      <TableCell className="options"></TableCell>
                    </TableRow>
                  </TableHead>
                  {Array.isArray(list) && list?.length > 0 ? (
                    <TableBody>
                      {list &&
                        Array.isArray(list) &&
                        list.map((data, index) => {
                          let dataChar = data.groupName.charAt(0);
                          dataCharArray.push(dataChar);
                          return (
                            <TableRow>
                              <TableCell className="checkbox">
                                <Checkbox
                                  color="primary"
                                  key={data._id}
                                  name={data.groupName}
                                  value={data.groupName}
                                  checked={data.isChecked}
                                  onChange={this.handleChangeCheck}
                                />
                              </TableCell>
                              <TableCell className="name-avatar">
                                <Grid container spacing={2} alignItems="center">
                                  <Grid >
                                    <Avatar style={styles.avatar}>
                                      <img alt="" src={AudienceIcon} />
                                    </Avatar>
                                  </Grid>
                                  <Grid size={{xs: "grow",}}>
                                    <Box
                                      component="p"
                                      mb={0}
                                      className="paragraph"
                                    >
                                      <div
                                        onClick={() =>
                                          this.viewContacts(data._id)
                                        }
                                      >
                                        <strong style={{ cursor: 'pointer' }}>
                                          {data.groupName}
                                        </strong>
                                      </div>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                className="text"
                                style={{ maxWidth: '150px' }}
                              >
                                <strong>{data.audienceCount}</strong>{' '}
                              </TableCell>
                              <TableCell
                                className="date-time"
                                style={{ maxWidth: '250px' }}
                              >
                                {data.updatedAt
                                  ? moment(data.updatedAt).format(
                                      'MMM, DD YYYY, h:mm A',
                                    )
                                  : '--'}
                              </TableCell>
                              <TableCell className="options">
                                {' '}
                                <div className="options-button">
                                  <img alt="" src={MoreIcon} />{' '}
                                </div>
                                <div className="options-list">
                                  <div
                                    onClick={() => this.viewContacts(data._id)}
                                  >
                                    {this.props.users.languageStateForRedux?.audienceTab?.viewContacts}
                                  </div>
                                  {this.allowed['Edit Audience'] && (
                                    <div
                                      onClick={() =>
                                        this.onOpenUpdateGroupModal(data)
                                      }
                                    >
                                      {this.props.users.languageStateForRedux?.settingsSidebar?.users?.editAudience}
                                    </div>
                                  )}
                                  {this.allowed['Delete Audience'] && (
                                    <>
                                      <hr />
                                      <div
                                        onClick={() =>
                                          this.handleClickOpenDeleteContactModal(
                                            data._id,
                                          )
                                        }
                                        style={{
                                          color: theme.palette.secondary.main,
                                        }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.delete}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                    !this.state.loading &&
                    list?.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }}>
                          {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  )}
                </>
              </Table>
            </TableContainer>

            {Array.isArray(list) && list?.length > 0 && (
              <div className="tablePagination">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className="tablePagination"
                >
                  <Grid >
                    <Box component="span" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of}{' '}
                      {this.props.groups.totalResults}
                    </Box>
                  </Grid>
                  <Grid >
                    <Grid container alignItems="center">
                      <Grid >
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                        <select
                          value={itemCount}
                          onChange={this.selectItemCount}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                      </Grid>
                      <Grid >
                        <Pagination
                          activePage={page_no}
                          itemsCountPerPage={this.state.itemCount}
                          totalItemsCount={this.props.groups.totalResults}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                          prevPageText={
                            <NavigateBeforeIcon style={{ fontSize: 18 }} />
                          }
                          nextPageText={
                            <NavigateNextIcon style={{ fontSize: 18 }} />
                          }
                          itemClassFirst="first d-none"
                          itemClassLast="last d-none"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>

          <Modal
            open={open}
            onClose={this.onCloseModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <ContactModal
              page_no={page_no}
              groups={this.props.groups.groups}
              group_id={group_id}
            />
          </Modal>

          <Modal
            open={this.state.openDeleteContactModel}
            onClose={this.handleCloseOpenDeleteContactModel}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.common?.deleteMsg}
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => this.handleDeleteConfirm()}
                        >
                          {this.props.users.languageStateForRedux?.common?.delete}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            this.handleCloseOpenDeleteContactModel()
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.cancel}
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={this.state.openDeleteAllAudienceModel}
            onClose={this.handleCloseOpenDeleteContactModel}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.common?.deleteMsg}
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => this.deleteCheck()}
                        >
                          {this.props.users.languageStateForRedux?.common?.delete}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            this.handleCloseOpenDeleteContactModel()
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.cancel}
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
            <UpdateModal
              page_no={page_no}
              singleData={singleData}
              groups={this.props.groups.groups}
            />
          </Modal>

          <Modal open={importOpen} onClose={this.onCloseImportModal} center>
            <ImportModal
              page_no={page_no}
              groups={this.props.groups.groups}
              group_id={group_id}
            />
          </Modal>

          <Modal
            open={updateGroupOpen}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            onClose={this.onCloseUpdateGroupModal}
            center
          >
            <UpdateGroupModal
              page_no={page_no}
              group_id={group_id}
              data={this.state.dataForEdit}
            />
          </Modal>

          <Modal
            open={deleteGroupOpen}
            onClose={this.onCloseDeleteGroupModal}
            center
          >
            <DeleteGroupModal page_no={page_no} group_id={group_id} />
          </Modal>
          <Modal
            open={importOpenModal}
            onClose={this.onCloseImportStatusModal}
            center
          >
            <ImportStatusModal imported={imported} rejected={rejected} />
          </Modal>

          <Modal
            open={groupOpen}
            onClose={this.onCloseGroupModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <GroupModal
              page_no={page_no}
              checkIfAudienceCreated={this.checkIfAudienceCreated}
              showProgressPopup={this.showProgressPopup}
            />
          </Modal>
          {this.state.copyCheck && (
            <SuccessAlertPopUp
              openModal={this.state.openSuccess}
              closeModalFunc={this.handleClickCloseSuccess}
              title={this.props.users.languageStateForRedux?.audienceTab?.copySuccessfull}
              description={(this.props.users.languageStateForRedux?.audienceTab?.copyMsg)  + ` <strong> ${this.props.contacts.copyCount} </strong> ` + ( this.props.users.languageStateForRedux?.audienceTab?.importContactsDesc)}
              buttonColor="primary"
              buttonFunc={this.handleClickCloseSuccess}
              buttonText={this.props.users.languageStateForRedux?.buttons?.common?.done}
            />
          )}

          {this.state.importCheck && (
            <SuccessAlertPopUp
              openModal={this.state.openSuccess}
              closeModalFunc={this.handleClickCloseSuccess}
              title={this.props.users.languageStateForRedux?.formfields?.attendees?.importSuccessfull}
              description={(this.props.users.languageStateForRedux?.formfields?.attendees?.importMsgOne)  + ` <strong> ${this.props.contacts?.importlength} </strong> ` + ( this.props.users.languageStateForRedux?.audienceTab?.importContactsDesc)}
              buttonColor="primary"
              buttonFunc={this.handleClickCloseSuccess}
              buttonText={this.props.users.languageStateForRedux?.buttons?.common?.done}
            />
          )}
          <Modal
            open={this.state.openValidation}
            onClose={this.handleClickCloseValidation}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component="div" container className="modal-wrapper">
              <Box
                component={Grid}
                mt={-4}
                size={{xs: 12,}}
                sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                mb={4}
              >
                <Box component="p" mb={1} className="subtitle">
                  Validation Result!
                </Box>
                <p className="small-subtitle">
                  Please make changes in sheet and try again
                </p>
              </Box>
              <Grid size={{xs: 12,}} pb={4}>
                <Box
                  component="div"
                  sx={{
                    border: '1px solid rgba(0, 0, 86, 0.23)',
                    borderRadius: '4px',
                  }}
                >
                  <Box
                    component={Grid}
                    p={1.25}
                    sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                    container
                  >
                    <Grid pl={2}></Grid>
                    <Grid >
                      <Box
                        pl={1}
                        component={'strong'}
                        className=""
                        color={theme.palette.common.black}
                      >
                        Invalid ({this.props?.contacts?.rejectedGroupEmails})
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    className="custom-fields-table"
                    p={1.25}
                    sx={{ maxHeight: '200px' }}
                    component={TableContainer}
                  >
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Duplicate Emails in file</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.duplicateGroupContactsInCsv}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid Email</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.invalidGroupEmailsRejection}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Mandatory Missing</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.mandetoryMissingInCsvGroup}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Grid>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={this.handleClickReteyValidation}
                    >
                      Retry
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.handleClickCloseValidation}
                    >
                      close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            open={showProgress}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal modal-default-close-none',
            }}
            center
          >
            <ProgresBar
              progressPercentage={progressPercentage}
              import={this.state.import}
            />
          </Modal>

          <Modal
            open={this.state.openCustomFieldsList}
            onClose={this.handleClose}
            classNames={{
              overlay: 'full-screen_overlay',
              modal: 'full-screen_modal manage-custom-fields',
            }}
            center
          >
            <Grid container justifyContent="end" width={"100%"}>
              <Grid
                size={{xs: 12, md: 6, lg: 5, xl: 4}}
                className="full-screen_modal__form"
              >
                <Box component="div" className="full-screen_modal__form_header">
                  <h3 className="subtitle">{this.props.users.languageStateForRedux?.column_names?.attendees?.manage}</h3>
                </Box>
                <Box
                  component="div"
                  px={3}
                  py={2}
                  className="full-screen_modal__form_body"
                >
                  <Grid container justifyContent="between" alignItems="center">
                    <Grid size={{xs: "grow",}}>
                      <Box component="p" className="paragraph">
                      {this.props.users.languageStateForRedux?.dropdowns?.attendees?.custom} (
                        {this.state.customFields &&
                        this.state.customFields.length
                          ? this.state.customFields.length
                          : 0}
                        )
                      </Box>
                    </Grid>
                    <Grid >
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => this.handleClickOpenForm()}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.newField}
                      </Button>
                    </Grid>
                  </Grid>
                  <Box
                    className="custom-fields-table"
                    component={TableContainer}
                    py={3}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeadCell">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.fieldName}
                          </TableCell>
                          <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.type}
                          </TableCell>
                          <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.common?.action}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.customFields &&
                      this.state.customFields.length ? (
                        <TableBody>
                          {this.state.customFields &&
                            this.state.customFields.map(
                              (customField, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    className="tableBodyCell"
                                    scope="row"
                                  >
                                    {customField.fieldData.fieldLabel}
                                  </TableCell>
                                  <TableCell
                                    className="tableBodyCell"
                                    align="left"
                                  >
                                    {customField.fieldData.fieldType ===
                                    'dropdown'
                                      ? 'List'
                                      : customField.fieldData.fieldType ===
                                        'checkbox'
                                      ? 'Boolean'
                                      : customField.fieldData.fieldType
                                          .charAt(0)
                                          .toUpperCase() +
                                        customField.fieldData.fieldType.slice(
                                          1,
                                        )}
                                  </TableCell>
                                  <TableCell
                                    className="tableBodyCell"
                                    align="left"
                                  >
                                    <Box
                                      className="action-button"
                                      component="span"
                                      mr={2.5}
                                    >
                                      <EditIcon
                                        style={{
                                          fontSize: 18,
                                          color: '#777777',
                                        }}
                                        onClick={() =>
                                          this.openEditCustomFieldForm(
                                            customField,
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box
                                      className="action-button"
                                      component="span"
                                    >
                                      <DeleteIcon
                                        style={{
                                          fontSize: 18,
                                          color: '#ff4170',
                                        }}
                                        onClick={() => {
                                          this.opendeleteCustomField(
                                            customField,
                                          );
                                        }}
                                      />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className="tableBodyCell"
                              colSpan={3}
                              style={{ border: 'none', textAlign: 'center' }}
                            >
                              There are no records to display
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            open={this.state.openAddCustomField}
            onClose={this.handleCloseForm}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component="form" container className="modal-wrapper">
              <Grid size={{xs: 12,}} pb={4}>
                {!isEdit ? (
                  <>
                    <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.newField}
                    </Box>
                    <Box component="p" mb={0} className="small-subtitle">
                    {this.props.users.languageStateForRedux?.audienceTab?.addCustomFieldDesc}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.editField}
                    </Box>
                    <Box component="p" mb={0} className="small-subtitle">
                    {this.props.users.languageStateForRedux?.audienceTab?.editCustomFieldDesc}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="div" className="inputField" pb={2}>
                  <label>
                  {this.props.users.languageStateForRedux?.column_names?.attendees?.fieldName}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={'40'}
                    className="form-control small"
                    placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.enterName}
                    value={fieldLabel}
                    name="fieldLabel"
                    onChange={this.handleLabel}
                  />
                  <span className="error_mesage required">
                    {' '}
                    {errors.fieldLabel}{' '}
                  </span>
                </Box>
                <Box component="div" className="inputField" pb={2}>
                  <label>
                  {this.props.users.languageStateForRedux?.column_names?.attendees?.type}
                    <span className="required">*</span>
                  </label>
                  <FormControl size="small" fullWidth>
                    <Select
                      type="text"
                      className="form-control select small"
                      placeholder="select"
                      value={fieldType}
                      name="fieldType"
                      onChange={this.handleChange}
                      style={{ padding: '4px 0px' }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem value="" disabled hidden>
                        {this.props.users.languageStateForRedux?.common?.select}
                      </MenuItem>
                      <MenuItem value="text">{this.props.users.languageStateForRedux?.common?.text}</MenuItem>
                      <MenuItem value="number">{this.props.users.languageStateForRedux?.common?.num}</MenuItem>
                      <MenuItem value="date">{this.props.users.languageStateForRedux?.column_names?.integration?.date}</MenuItem>
                      <MenuItem value="url">{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.url}</MenuItem>
                      <MenuItem value="email">{this.props.users.languageStateForRedux?.column_names?.teams?.email}</MenuItem>
                      <MenuItem value="dropdown">{this.props.users.languageStateForRedux?.common?.list}</MenuItem>
                      <MenuItem value="checkbox">{this.props.users.languageStateForRedux?.common?.boolean}</MenuItem>
                    </Select>
                    <span className="error_mesage required">
                      {' '}
                      {errors.fieldType}{' '}
                    </span>
                  </FormControl>
                </Box>
                {fieldType === 'dropdown' && (
                  <Box component="div" className="inputField" pb={1}>
                    <label>
                      Option per line <span className="required">*</span>
                    </label>
                    <textarea
                      rows={'4'}
                      className="form-control"
                      value={listValue}
                      name="listValue"
                      onChange={this.handleChange}
                    >
                      {' '}
                    </textarea>
                    <span className="error_mesage required">
                      {' '}
                      {errors.listValue}{' '}
                    </span>
                  </Box>
                )}

                {fieldType === 'checkbox' && (
                  <Box component="div" className="inputField" pb={1}>
                    <label>
                      Option per line <span className="required">*</span>{' '}
                    </label>
                    <textarea
                      rows={'4'}
                      className="form-control"
                      value={checkboxValue}
                      name="checkboxValue"
                      onChange={this.handleChange}
                    >
                      {' '}
                    </textarea>
                    <span className="error_mesage required">
                      {' '}
                      {errors.checkbox}{' '}
                    </span>
                  </Box>
                )}
              </Grid>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {!isEdit ? (
                      <>
                        {' '}
                        <Button
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={this.saveNewCustomField}
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.savenew}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={this.saveNewCustomFieldAndExit}
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                        </Button>{' '}
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={this.updateAudienceCustomFields}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.updateSave}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          <NotificationContainer />
          {loading || this.props.users.isLoading ? <Loader /> : null}
        </ThemeProvider>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteGroup: Actions.deleteGroupRequest,
      groupRequest: Actions.groupRequest,
      searchGroupRequest: Actions.searchGroupRequest,
      listContactRequest: Actions.listContactRequest,
      listAllContactRequest: Actions.listAllContactRequest,
      deleteContactRequest: Actions.deleteContactRequest,
      deleteManyContactRequest: Actions.deleteManyContactRequest,
      searchContactRequest: Actions.searchContactRequest,
      csvRequest: Actions.csvRequest,
      xslRequest: Actions.xslRequest,
      deleteGroupRequest: Actions.deleteGroupRequest,
      addAudienceCustomFields: Actions.addAudienceCustomFieldsRequest,
      listAudienceCustomFields: Actions.listAudienceCustomFieldsRequest,
      deleteAudienceCustomFields: Actions.deleteAudienceCustomFieldsRequest,
      updateAudienceCustomFields: Actions.updateAudienceCustomFieldsRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(ContactGroups));