import BaseAPI from '../baseAPI';


class OpportunityAccountsAPI extends BaseAPI {
    
    fetchAllOpportunityAccounts =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/fetchAllAccounts', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    fetchAllAccountsWhileSearchInDropdown = async (params: object) => {
        return this.API('opportunityManagement/accounts/fetchAllAccountsWhileSearchInDropdown', {
            method: 'GET',
            params
        })
    }

    fetchOpportunityAccount =  async (params: object) => {
        return this.API('opportunityManagement/accounts/fetchById', {
            method: 'GET',
            params
        })
    }
    fetchAccountWithProperCustomFieldsValue =  async (params: object) => {
        return this.API('opportunityManagement/accounts/fetchAccountWithProperCustomFieldsValue', {
            method: 'GET',
            params
        })
    }

    createOpportunityAccount =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => { 
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/createAccount', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    
    updateOpportunityAccount =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/updateAccount', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    createAccountForOpportunityTab =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/createAccountForOpportunityTab', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }
    
    updateOpportunityAccountForAccountTab =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/updateOpportunityAccountForAccountTab', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }

    deleteOpportunityAccount =  async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/delete', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        })
    }

    export = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/accounts/export', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        })
    }

    downloadSampleCSVFile = async () => {
        return this.API('opportunityManagement/accounts/downloadSampleCSVFile', {
            method: 'POST',
        });
    };

    checkValidationForCsvImportFile = async (data: FormData, queryParams : {
        keyFieldForAccounts: string,
        keyFieldForContacts: string,
      }) => {
        return this.API('opportunityManagement/accounts/checkValidationForCsvImportFile', {
            method: 'POST',
            data: data,
            params: queryParams,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    importCsvDataAfterValidationIsDone = async (queryParams: {
        keyFieldForAccounts: string,
        keyFieldForContacts: string,
        skipExistingAccounts: boolean,
        skipExistingContacts: boolean,
        idToAccessTheCorrectDataOfContact: string,
        idToAccessTheCorrectDataOfAccount: string,
    }) => {
        return this.API('opportunityManagement/accounts/importCsvDataAfterValidationIsDone', {
            method: 'POST',
            params: queryParams
        });
    }
    
    updateContactDataTypeCustomField = async (data: object) => {
        return this.API('opportunityManagement/accounts/updateContactDataTypeCustomField', {
            method: 'POST',
            data: data
        });
    }

    checkIfNameAlreadyExists = async (data: object) => {
        return this.API('/opportunityManagement/accounts/checkIfNameAlreadyExists', {
            method: 'POST',
            data: data
        });
    }
}

export default OpportunityAccountsAPI