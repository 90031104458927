import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';

import { NotificationContainer } from 'react-notifications';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../theme/theme';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OverviewIcon from '../../../assets/images/icons/overview.svg';
import managePagesIcon from '../../../assets/images/icons/managePages.svg';
import campaignsIcon from '../../../assets/images/icons/campaigns.svg';
import agendaIcon from '../../../assets/images/icons/agenda.svg';
import accessCodeIcon from '../../../assets/images/icons/accessCode.svg';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import {
  GetAttendeeDetail,
  GetCustomFieldsProfileAPI,
  configrationsChallenge,
  UpdateProfilePic,
  UpdateAttendeeProfileAPI,
  getAgendaProfile,
  deleteProfileImageAPI,
  updateAgendaSecurity,
  setMyAgendaFavourite,
  getMyAgendaProfile,
} from 'ReduxStore/API';
import { createNotification } from '../../../helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import userImageIcon from '../../../../src/assets/images/icons/userImageIcon.svg';
import UploadIcon from '../../../../src/assets/images/icons/upload.svg';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import agendaBanner from '../../../../src/assets/images/agenda-banner.png';
import logOutIcon from '../../../../src/assets/images/icons/logOutIcon.svg';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DurationIcon from '../../../../src/assets/images/icons/duration.svg';
import LocationIcon from '../../../../src/assets/images/icons/locationGrey.svg';
import CalenderIcon from '../../../../src/assets/images/icons/calender.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import gamificationIcon from '../../../assets/images/icons/profile-gamificationIcon.svg';
import TextField from '@mui/material/TextField';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import parse from 'html-react-parser';
import Loader from '../../../Components/Loader/loader';
import ImgCrop from './Crop';
import { result } from 'lodash';
import Gamification from './gamificationProfile';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import AccessCodeMYProfile from './accessCodeMyprofile';
import { getRemaningTime } from '../../../Common/getRemaningTime';
import WarningAmberIcon from '@mui/icons-material/ReportProblemOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import withCustomRouter from '../../../Common/withCustomRouter';
import { generateUrl } from 'helpers/common';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
      border: 0,
    },
  },
};
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const [eventDomain] = window.location.hostname.split('.');
const inputFile = React.createRef(null);
const chooseProfileImage = React.createRef(null);
const onButtonClick = () => {
  inputFile.current.click();
};

const responsive = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 12,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  mediumDesktop: {
    breakpoint: { max: 1400, min: 1024 },
    items: 11,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  smallDesktop: {
    breakpoint: { max: 1024, min: 700 },
    items: 5,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  largeTablet: {
    breakpoint: { max: 699, min: 600 },
    items: 3,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  tablet: {
    breakpoint: { max: 599, min: 450 },
    items: 6,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 4,
    partialVisibilityGutter: 8, // this is needed to tell the amount of px that should be visible.
  },
};

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.permData = {};
    this.timer = null;
    this.child = React.createRef(null);
    this.tabs = [
      'update-profile',
      'security',
      'my-agenda',
      'agenda',
      'gamification',
      'access-code',
    ];
    const seg = window.location.href.split('/');
    const mid = seg[seg.length - 1];
    const strValue = (this.tabs.indexOf(mid) + 1).toString();
    this.unloader = true;
    this.state = {
      email: '',
      password: '',
      isAuthenticated: true,
      user: {},
      signInProps: undefined,
      eventId: props.eventId,
      onReadPic: '',
      cropPic: '',
      errors: {},
      attendeeEmail: '',
      projectId: '',
      loginPage: '',
      logOutUrl: '',
      homePageUrl: '',
      id: '',
      value: strValue,
      attendeeData: JSON.parse(localStorage.getItem('attendee_details')),
      customFields: '',
      passwordType: '',
      customFieldData: '',
      isFavorite: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      profileImage: '',
      currentPass: '',
      newPass: '',
      rePass: '',
      agendaData: [],
      attendeeId: '',
      reduceDate: [],
      reduceFavDate: [],
      searchAgendaText: '',
      myFavAgendaData: [],
      searchfavAgendaText: '',
      toggleColor: false,
      selectedAgendaId: '',
      setUnfavorite: false,
      favId: '',
      selectedFavAgendaId: '',
      toggleFavColor: false,
      setSeeAll: false,
      setAll: false,
      selectedLikeKey: '',
      selecteddateData: [],
      myFavSelectedDate: [],
      myAgendaDate: false,
      myFavAgendaDate: false,
      nextAgendaDate: '',
      nextFavAgendaDate: '',
      timeZone: '',
      loading: false,
      isCompleteAgenda: false,
      isCompleteFavAgenda: false,
      open: false,
      agendaDate: '',
      favAgendaDate: '',
      openDeleteSessionModal: false,
      i: '',
      currDate: false,
      currFavDate: false,
      groupName: '',
      groupsArr: [],
      grp: [],
      myDate: '',
      customNewData: '',
      backConfirmationModal: false,
      nextFDate: '',
      nextADate: '',
      next: [],
      selectedDate: '',
      favIcon: '',
      topBarMyAgenda: [],
      topBarAgenda: [],
      uploadImage: '',
      nextDateS: '',
      isSearchingAgenda: false,
      isAccessCode: false,
      pagePath: '',
      remainingHours: '',
      department: '',
      customeJsProfile: '',
    };
  }

  componentDidMount(prevProps, prevState) { 
    if (this.state?.attendeeData?.isSystemUser == true) {
      window.location.href = "/kiosk";
    }

    let pathlocation = this.props?.location?.state?.pathlocation;
    let domainUrl = window.location.href;
    let domain = new URL(domainUrl);
    var getLoginPageUrl = `${domain.protocol}//${domain.host}/login`;
    var getHomeageUrl = `${domain.protocol}//${domain.host}${this.state.pagePath}`;
    let getLogOutUrl = `${domain.protocol}//${domain.host}/logout`;
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr(); 
      this.setState(
        {
          loginPage: getLoginPageUrl,
          logOutUrl: getLogOutUrl,
          homePageUrl: getHomeageUrl,
          timeZone: timezone,
        },
        () => {
          if (this.state.attendeeData && this.state.attendeeData !== null) {
            this.getAttendeeDetails();
          } else {
            window.location = this.state.loginPage;
          }
        },
      );

      moment().format('DD/MM/YYYY');
     
  }

  handleOpenTabByDefault = () => {
    this.tabs = [
      'update-profile',
      'security',
      'my-agenda',
      'agenda',
      'gamification',
    ];
    const seg = window.location.href.split('/');
    const mid = seg[seg.length - 1];
    if (mid === 'my-agenda') {
      this.getMyAgendaDetails();
    }
    if (mid === 'agenda') {
      this.getAgendaDetail(this.state.projectId);
    }
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.profile.getAccessCodeSuccess !==
      prevProps.profile.getAccessCodeSuccess &&
      this.props.profile.getAccessCodeSuccess === true
    ) {
      this.setState({
        remainingHours: this.props.profile?.remainingHours,
        loading: false,
      });
    }

    if (
      this.props.profile.getCustomFieldsProfileSuccess !==
      prevProps.profile.getCustomFieldsProfileSuccess &&
      this.props.profile.getCustomFieldsProfileSuccess === true
    ) {
      this.setState({
        customFields: this.props.profile?.customFields,
        passwordType: this.props.profile?.eventData?.passwordType,
        favIcon: this.props.profile?.eventData?.faviconImage,
        isAccessCode: this.props.profile?.eventData?.isAccessCode,
        pagePath: this.props.profile?.pagePath[0]?.path,
        loading: false
      });

      if (!this.props.profile?.eventData?.isAccessCode) {
        const TabValue = this.getTabDataFromUrl();
        if (TabValue === "access-code") {
          this.handleRouting('update-profile');
        }
      }
    }
  }


  getTabDataFromUrl = () => {
    const arr = window.location.pathname.split('/');
    let URL = arr[arr.length - 1];
    if (URL.indexOf('?') !== -1) {
      URL = URL.split('?')[0];
    }
    return URL;
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  async getAttendeeDetails() {
    let data = {};
    try {
      this.setState({
        loading: true
      });
      let getData = { id: this.state.attendeeData.id, eventId: this.props.eventId, eventDomain };
      await GetAttendeeDetail(getData).then((result) => {
        if (result?.data?.status === 200) {
          this.setState(
            {
              projectId: result.data.attendee.projectId,
              attendeeId: result.data.attendee._id,
              attendeeEmail: result?.data?.attendee?.email,
              customFieldData: result?.data?.attendee?.attendeesData,
              profileImage: result?.data?.attendee?.profileImage,
              attendeeSource: result?.data?.attendee?.source,
              googleWorkspaceData: result?.data?.googleWorkspaceDataFineTuned,
              i: result.data.attendee.profileImage,
              department: result?.data?.attendee?.department,
            }, () => {
              data = {
                eventDomain,
                projectId: this.state.projectId,
                attendeeId: result.data.attendee._id
              };
              this.props.getCustomFieldsProfile(data);
              this.getAccessCode();
              this.setState({
                loading: true
              });
              this.handleOpenTabByDefault();
            }
          );
          if (result.data.attendee.group !== null) {
            this.setState({
              groupsArr: result?.data?.attendee?.groups,
            });
          }
        }
      });
      this.getAgendaDetail(this.state.projectId);
    } catch (error) {
      return error;
    }
  }

  test = (data) => {
    let matchFound;
    data.visibility.groups.forEach((group) => {
      if (this.state.groupsArr.includes(group._id)) {
        matchFound = true;
      }
    });
    return matchFound ? data : false;
  };

  checkIfAgendaIsComplete = (type, type2) => {
    if (type === 'today') {
      // if todays date is present for sure. Future date may or may not be present
      this.setState({ currDate: true });
      if (type2 !== 'future') {
        this.setState({
          nextADate: '',
        });
      } else {
        this.setState({
          nextADate: this.state.nextDateS,
        });
      }

      let arr1 = [];
      this.state.agendaData.length > 0 &&
        this.state.agendaData.forEach((data) => {
          // (agenda time + agenda duration) < current time
          new Date(data.dateTime).getTime() +
            60 * 60 * 1000 * data.duration.hours <
            new Date().getTime()
            ? arr1.push('true')
            : arr1.push('false');
        });

      if (arr1.includes('false')) {
        this.setState({ isCompleteAgenda: false });
      } else {
        this.setState({ isCompleteAgenda: true });
      }
    } else if (type === 'future') {
      // if todays date is not there but future date is present
      this.setState({
        currDate: false,
        isCompleteAgenda: false,
        nextADate: this.state.nextDateS,
      });
    } else {
      // when neither present date not future date is present. Only Past dates are there.
      this.setState({
        currDate: false,
        nextADate: '',
        isCompleteAgenda: true,
      });

      this.state.agendaData.length > 0 &&
        this.state.agendaData.forEach((data) => {
          new Date(data.dateTime).getTime() +
            60 * 60 * 1000 * data.duration.hours <
            new Date().getTime()
            ? this.setState({ isCompleteAgenda: true })
            : this.setState({ isCompleteAgenda: false });
        });

      this.state.agendaData.length > 0 &&
        this.state.agendaData.forEach((data) => {
          new Date().getDate() === new Date(data.dateTime).getDate()
            ? this.setState({ currDate: true })
            : this.setState({ currDate: false });
        });
    }
  };

  getAgendaDetail = async (id) => {
    var attendeeArr = [];
    this.setState({
      isSearchingAgenda: false,
      searchAgendaText: '',
    });
    if (this.state.groupsArr.length) {
      this.setState({ loading: true, agendaData: [] });
      await getAgendaProfile({ eventDomain, projectId: id }).then((result) => {
        const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

        this.setState(
          {
            selecteddateData: agendaSortedByDates,
            topBarAgenda: agendaSortedByDates,
            loading: false, // selectedDateData has all the agendas

          },
          async () => {
            this.state.selecteddateData.forEach((data) => {
              data?.visibility?.groups?.map((data) => {
                attendeeArr.push(data?._id);
              });
            });
            const result = this.state.selecteddateData.map((data) =>
              data.visibility.visibilityType == 'Groups'
                ? this.test(data)
                : data.visibility.visibilityType == 'All'
                  ? data
                  : false,
            );
            const allData = result?.filter((res) => res !== false); // allData contains all the agendas
            const todayData = result?.filter(
              (data) =>
                new Date().getDate() === new Date(data.dateTime).getDate() &&
                new Date().getMonth() === new Date(data.dateTime).getMonth() &&
                new Date().getFullYear() ===
                new Date(data.dateTime).getFullYear(),
            );
            const tap = todayData?.filter((res) => res !== false);
            const reduceData = allData.sort((a, b) => {
              return new Date(a.dateTime) - new Date(b.dateTime);
            });
            let checkNearestFutureDate = [];
            let reducedToDateTimeArr = reduceData.map((el) =>
              new Date(el.dateTime).toDateString(),
            );
            for (const data of reduceData) {
              if (
                new Date().toDateString() ===
                new Date(data.dateTime).toDateString()
              ) {
                this.setState({ selectedAgendaId: '' });
              } else if (
                new Date().getTime() < new Date(data.dateTime).getTime()
              ) {
                if (
                  checkNearestFutureDate.length === 0 ||
                  checkNearestFutureDate.includes(
                    new Date(data.dateTime).toDateString(),
                  )
                ) {
                  checkNearestFutureDate.push(
                    new Date(data.dateTime).toDateString(),
                  );
                  let arr2 = this.state.next.map((el) => el._id);

                  if (!arr2.includes(data._id)) {
                    this.state.next.push(data);
                    this.setState({
                      nextDateS: data.dateTime,
                    });

                    if (
                      !reducedToDateTimeArr.includes(new Date().toDateString())
                    ) {
                      // if today's date is not present
                      this.setState({
                        selectedAgendaId: moment(data.dateTime).format(
                          'DD MMM YYYY',
                        ),
                      });
                    }
                  }
                }
              }
            }
            let today = new Date().toISOString();
            let checkDate = [];
            for (let data of reduceData) {
              // Reduce data contains all the agendas in order
              if (
                new Date().toDateString() ===
                new Date(data.dateTime).toDateString()
              ) {
                checkDate = [...checkDate, 'today'];
              } else if (
                new Date().getTime() < new Date(data.dateTime).getTime()
              ) {
                checkDate = [...checkDate, 'future'];
              } else {
                checkDate = [...checkDate, 'all'];
              }
            }

            // for green bar message we will call 'checkIfAgendaIsComplete' function to check 1.if agenda is complete. 2.Current date is there. 3. Next date is there

            if (checkDate.includes('today')) {
              this.setState(
                {
                  agendaData: tap,
                  setAll: false,
                  myAgendaDate: true,
                  loading: false,
                  myDate: today,
                },
                () => {
                  if (checkDate.includes('future')) {
                    this.checkIfAgendaIsComplete('today', 'future');
                    this.getAgendaDate(false); // we dont want to check agendaIsCompleted from this func, that's why we will call this func partially.
                  } else {
                    this.checkIfAgendaIsComplete('today');
                    this.getAgendaDate(false);
                  }
                },
              );
            } else if (
              !checkDate.includes('today') &&
              checkDate.includes('future')
            ) {
              this.setState(
                {
                  agendaData: this.state.next,
                  setAll: false,
                  myAgendaDate: true,
                  loading: false,
                  myDate: today,
                },
                () => {
                  this.checkIfAgendaIsComplete('future');
                  this.getAgendaDate(false);
                },
              );
            } else if (
              !checkDate.includes('today') &&
              !checkDate.includes('future') &&
              checkDate.includes('all')
            ) {
              this.setState(
                {
                  agendaData: allData,
                  setAll: true,
                  myAgendaDate: true,
                  loading: false,
                  myDate: today,
                },
                () => {
                  this.checkIfAgendaIsComplete('all');
                  this.getAgendaDate(false);
                },
              );
            }
          },
        );
      });
    } else {
      this.setState({
        loading: true,
        agendaData: [],
        agendaDate: '',
        nextAgendaDate: '',
      });
      await getAgendaProfile({ eventDomain, projectId: id }).then((result) => {
        const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        this.setState({
          selecteddateData: agendaSortedByDates,
          topBarAgenda: agendaSortedByDates,
          loading: false,
        });
      });
      this.getAgendaDate();
      this?.state?.selecteddateData?.length > 0 &&
        this?.state?.selecteddateData?.map((data) => {
          // selecteddateData has all the agendas
          if (
            new Date(data.dateTime).toDateString() === new Date().toDateString()
          ) {
            let today = new Date().toISOString();
            this.setState({ nextAgendaDate: today });
          }
        });
      if (this.state.nextAgendaDate == '') {
        this.setState({ setAll: false });
      }
      let today = new Date().toISOString();
      const result2 = this.state.selecteddateData?.map((data) =>
        data?.visibility?.visibilityType == 'All' ? data : false,
      );
      const allData = result2?.filter((res) => res !== false);
      const todayData = result2?.filter(
        (data) => new Date().getDate() === new Date(data.dateTime).getDate(),
      );
      const tap = todayData?.filter((res) => res !== false);
      const reduceData = allData?.sort((a, b) => {
        return new Date(a.dateTime) - new Date(b.dateTime);
      });
      if (Array.isArray(reduceData)) {
        let arr = [];
        let d1 = '';
        for (const data of reduceData) {
          if (new Date().getDate() < new Date(data.dateTime).getDate()) {
            if (d1 === '') {
              d1 = new Date(data.dateTime).getDate();
            }
            if (new Date(data.dateTime).getDate() === d1) {
              arr.push(data);
              this.state.next = [...arr];
            }
          }
        }
        for (const data of reduceData) {
          if (new Date().getDate() === new Date(data.dateTime).getDate()) {
            this.setState(
              {
                agendaData: tap,
                setAll: false,
                myAgendaDate: true,
                loading: false,
                myDate: today,
                selectedAgendaId: moment(data.dateTime).format('DD MMM YYYY'),
              },
              () => this.getAgendaDate(),
            );
            break;
          } else if (new Date().getDate() < new Date(data.dateTime).getDate()) {
            this.setState({
              agendaData: this.state.next,
              setAll: false,
              myAgendaDate: true,
              loading: false,
              myDate: today,
            });
            this.setState({
              selectedAgendaId: moment(data.dateTime).format('DD MMM YYYY'),
            });
            break;
          } else {
            this.setState({
              agendaData: allData,
              setAll: true,
              myAgendaDate: true,
              loading: false,
              myDate: today,
            });
          }
        }
      }

      let arr1 = [];
      this.state.agendaData.length > 0 &&
        this.state.agendaData.forEach((data) => {
          // (agenda time + agenda duration) < current time
          new Date(data.dateTime).getTime() +
            60 * 60 * 1000 * data.duration.hours <
            new Date().getTime()
            ? arr1.push('true')
            : arr1.push('false');
        });

      if (arr1.includes('false')) {
        this.setState({ isCompleteAgenda: false });
      } else {
        this.setState({ isCompleteAgenda: true });
      }

      this.state.agendaData.length > 0 &&
        this.state.agendaData.forEach((data) => {
          new Date().getDate() === new Date(data.dateTime).getDate()
            ? this.setState({ currDate: true })
            : this.setState({ currDate: false });
          return;
        });
      if (this.state.nextAgendaDate == '') {
        this.setState({ setAll: true });
      }
    }
  };

  seeAllAgendaDetails = async () => {
    var attendeeArr = [];
    this.setState(
      { loading: true, isSearchingAgenda: false, searchAgendaText: '' },
      async () => {
        if (this.state.groupsArr.length) {
          this.setState({ loading: true });
          await getAgendaProfile({
            eventDomain,
            projectId: this.state.projectId,
          }).then((result) => {
            const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

            this.setState({
              selecteddateData: agendaSortedByDates,
              loading: false,
            });
          });
          if (this.state.nextFavAgendaDate) {
            var today = this.state.nextFavAgendaDate;
          } else {
            var today = new Date().toISOString();
          }
          if (
            this.state.selecteddateData.filter(
              (data) =>
                moment(data.dateTime).format('ddd MMM') ==
                moment(new Date().toISOString()).format('ddd MMM'),
            ).length > 0
          ) {
            await getAgendaProfile({
              eventDomain,
              projectId: this.state.projectId,
              date: today,
            }).then((result) => {
              const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
              this.setState({
                agendaData: agendaSortedByDates,
                myAgendaDate: true,
                loading: false,
                isCompleteAgenda: false,
              });
            }
            );
          }
          this.state.selecteddateData.forEach((data) => {
            data?.visibility?.groups?.map((data) => {
              attendeeArr.push(data?._id);
            });
          });
          const result = this.state.selecteddateData.map((data) =>
            data.visibility.visibilityType == 'Groups'
              ? this.test(data)
              : data.visibility.visibilityType == 'All'
                ? data
                : false,
          );
          const tap = result?.filter((res) => res !== false);
          this.setState({
            agendaData: tap,
            setAll: true,
            isCompleteAgenda: false,
            nextAgendaDate: '',
          });
        } else {
          await getAgendaProfile({
            eventDomain,
            projectId: this.state.projectId,
          }).then((result) => {
            const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
            this.setState(
              {
                selecteddateData: agendaSortedByDates,
                topBarAgenda: agendaSortedByDates,
                loading: false,
              },
              () => {
                const result = this.state.selecteddateData.map((data) =>
                  data.visibility.visibilityType == 'All' ? data : false,
                );
                const tap = result?.filter((res) => res !== false);
                this.setState({
                  agendaData: tap,
                  setAll: true,
                  loading: false,
                  isCompleteAgenda: false,
                  nextAgendaDate: '',
                });
              },
            );
          });
        }
      },
    );
  };

  handleRouting = (route) => {
    this.props.navigate(generateUrl(`/profile/${route}`));
  };
  getMyAgendaDetails = async () => {
    this.handleRouting('my-agenda');
    this.setState({ loading: true });

    await getMyAgendaProfile({ eventDomain, eventId: this.props.eventId }).then((result) => {
      setTimeout(() => {
        this.getAllButtonByClassName();
      }, 2500);
      const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
      this.setState({
        myFavSelectedDate: agendaSortedByDates,
        topBarMyAgenda: agendaSortedByDates,
        loading: false,
      });
    });
    this.getFavAgendaDate();
    this?.state?.myFavSelectedDate?.length > 0 &&
      this?.state?.myFavSelectedDate?.map((data) => {
        if (new Date(data.dateTime).getDate() === new Date().getDate()) {
          let today = new Date().toISOString();
          this.setState({ nextFavAgendaDate: today });
        }
      });
    if (
      this?.state?.myFavSelectedDate?.length > 0 &&
      this?.state?.myFavSelectedDate?.filter(
        (data) =>
          moment(data.dateTime).format('ddd MMM') ==
          moment(new Date().toISOString()).format('ddd MMM'),
      ).length > 0
    ) {
      await getMyAgendaProfile({
        eventDomain,
        date: this.state.nextFavAgendaDate,
        eventId: this.props.eventId
      }).then((result) => {
        setTimeout(() => {
          this.getAllButtonByClassName();
        }, 2500);
        const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        this.setState({
          myFavAgendaData: agendaSortedByDates,
          setSeeAll: false,
          myFavAgendaDate: true,
          loading: false,
          selectedFavAgendaId: '',
        });
      }
      );
    } else {
      await getMyAgendaProfile({
        eventDomain,
        date: this.state.nextFavAgendaDate,
        eventId: this.props.eventId
      }).then((result) => {
        setTimeout(() => {
          this.getAllButtonByClassName();
        }, 1500);
        const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        this.setState({
          myFavAgendaData: agendaSortedByDates,
          setSeeAll: false,
          myFavAgendaDate: true,
          loading: false,
          selectedFavAgendaId: '',
        });
      }
      );
    }

    this.state.myFavAgendaData.length > 0 &&
      this.state.myFavAgendaData.forEach((data) => {
        this.setState({
          selectedFavAgendaDate: data.dateTime,
        });
        new Date(data.dateTime).getTime() +
          60 * 60 * 1000 * data.duration.hours <
          new Date().getTime()
          ? this.setState({ isCompleteFavAgenda: true })
          : this.setState({ isCompleteFavAgenda: false });
        return;
      });

    this.state.myFavAgendaData.length > 0 &&
      this.state.myFavAgendaData.forEach((data) => {
        new Date().getDate() === new Date(data.dateTime).getDate()
          ? this.setState({ currFavDate: true })
          : this.setState({ currFavDate: false });
        return;
      });


    if (this.state.nextFavAgendaDate == '') {
      this.setState({ setSeeAll: true });
    }
  };

  seeAllFavAgendaDetails = async () => {
    this.setState({ loading: true });
    await getMyAgendaProfile({ eventDomain, eventId: this.props.eventId }).then((result) => {
      const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
      setTimeout(() => {
        this.getAllButtonByClassName();
      }, 1500);
      this.setState({
        myFavAgendaData: agendaSortedByDates,
        setSeeAll: true,
        loading: false,
        isCompleteFavAgenda: true,
        nextFavAgendaDate: '',
      });
    }
    );
  };


  outlookDateWithDesiredTimeZone = (date, timeZone) => {
    const formattedDate = moment.tz(date, timeZone).format('YYYY-MM-DDTHH:mm');
    return formattedDate;
  };

  generateCalendarUrl = (event, calendarType) => {
    const start = this.formatDate(event.startDate);
    const end = this.formatDate(event.endDate);
    if (calendarType === "googleCalendar") {
      const googleCalendarUrl = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(event.title) + "&dates=" + start + "/" + end + "&details=" + encodeURIComponent(event.description) + "&location=" + encodeURIComponent(event.location);
      return googleCalendarUrl;
    } else if (calendarType === "outlookComleCalendar") {
      let outlookStartDate = this.outlookDateWithDesiredTimeZone(event.startDate, event.timezone);
      let outlookEndDate = this.outlookDateWithDesiredTimeZone(event.endDate, event.timezone);
      const outlookURL = `https://outlook.office.com/calendar/action/compose?subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}&subject=${encodeURIComponent(event.title)}&startdt=${encodeURIComponent(outlookStartDate)}&enddt=${encodeURIComponent(outlookEndDate)}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;
      return outlookURL;
    } else if (calendarType === "outlookCalendar") {
      const outlookURL = this.downloadICS(event);
      return outlookURL;
    } else if (calendarType === "yahooCalendar") {
      const yahooCal = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(event.title)}&desc=${encodeURIComponent(event.description)}&st=${encodeURIComponent(start)}&et=${encodeURIComponent(end)}&in_loc=${encodeURIComponent(event.location)}`;
      return yahooCal;
    } else if (calendarType === "officeCalendar") {
      const office365 = `https://outlook.office.com/calendar/0/deeplink/compose&subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(event.description)}&startdt=${encodeURIComponent(start)}&enddt=${encodeURIComponent(end)}&location=${encodeURIComponent(event.location)}&path=/calendar/view/WorkWeek&rru=addevent`;
      return office365;
    }
  };

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    let vales = year + month + day + "T" + hours + minutes + seconds;
    return vales;
  };

  generateICS = (event) => {
    // Format event start and end dates in UTC format
    const formatDatenw = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, '');
    };

    // Construct the ICS content
    const icsContent = `BEGIN:VCALENDAR
        VERSION:2.0
        PRODID:-//Your Organization//Your App//EN
        BEGIN:VEVENT
        UID:${Date.now()}@softobiz.com
        DTSTAMP:${formatDatenw(new Date())}
        DTSTART:${formatDatenw(event.startDate)}
        DTEND:${formatDatenw(event.endDate)}
        SUMMARY:${event.title}
        DESCRIPTION:${event.description || ''}
        LOCATION:${event.location || ''}
        METHOD:REQUEST
        END:VEVENT
        END:VCALENDAR`;
    return icsContent;
  };

  downloadICS = (event) => {
    const icsContent = this.generateICS(event);
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'event.ics');
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  getAllButtonByClassName = () => {
    // Close options if user clicks outside the button or options 
    document.addEventListener('click', function (event) {
      var buttons = document.getElementsByClassName('addToCalendarButtonProfile');
      var options = document.getElementsByClassName('calendar-options');
      for (var i = 0; i < buttons.length; i++) {
        if (event.target !== buttons[i] && event.target.parentNode !== options[i]) {
          options[i].classList.remove('show');
        }
      }
    });
    let addToCalendar = document.querySelectorAll(".addToCalendar");

    addToCalendar.forEach((div) => {
      div.addEventListener('click', () => {
        // Inside this callback function, `this` refers to the component instance
        let ul = div.querySelector('.calendar-options');
        ul.classList.toggle('show');
        ul.querySelectorAll('li').forEach((li) => {
          li.addEventListener('click', (e) => {
            // Call `addToCalendarByWidget` with the correct context
            this.addToCalendarByWidget(e, li.className);
          });
        });
      });
    });
  };


  addToCalendarByWidget = (e, lielement) => {
    const parentDiv = e.target.closest('.addCalendarEvent');
    const titleDiv = parentDiv.querySelector('.title');
    const descriptionDiv = parentDiv.querySelector('.description');
    const locationDiv = parentDiv.querySelector('.location');
    const startDateDiv = parentDiv.querySelector('.start');
    const endDateDiv = parentDiv.querySelector('.end');
    const timezoneDiv = parentDiv.querySelector('.timezone');

    const titleVal = titleDiv.textContent;
    const descVal = descriptionDiv.textContent;
    const locationVal = locationDiv.textContent;
    const startDate = startDateDiv.textContent;
    const endDate = endDateDiv.textContent;
    const timezoneVal = timezoneDiv.textContent;
    let eventDetails = {
      "title": titleVal,
      "description": descVal,
      "location": locationVal,
      "startDate": new Date(startDate),
      "endDate": new Date(endDate),
      "timezone": timezoneVal,
    };
    if (lielement === "googleCalendar") {
      const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
      window.open(calendarUrl, '_blank');
    } else if (lielement === "outlookComleCalendar") {
      const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
      window.open(calendarUrl, '_blank');
    } else if (lielement === "outlookCalendar") {
      const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
      window.open(calendarUrl);
    } else if (lielement === "yahooCalendar") {
      const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
      window.open(calendarUrl, '_blank');
    } else if (lielement === "officeCalendar") {
      const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
      window.open(calendarUrl, '_blank');
    } else {
      console.log(lielement + " Not implemented yet!");
    }
  };

  getAgendaDate = (shouldRun = true) => {
    if (this.state.groupsArr.length) {
      const result2 = this.state.selecteddateData.map((data) =>
        data.visibility.visibilityType == 'Groups'
          ? this.test(data)
          : data.visibility.visibilityType == 'All'
            ? data
            : false,
      );
      const tap = result2?.filter((res) => res !== false);

      var result =
        tap?.length > 0 &&
        tap.reduce((unique, o) => {
          if (
            !unique.some(
              (obj) =>
                moment(obj.dateTime).format('dddd, MMM, Do') ===
                moment(o.dateTime).format('dddd, MMM, Do'),
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);
      const reduceData = result.sort((a, b) => {
        return new Date(a.dateTime) - new Date(b.dateTime);
      });

      this.setState({ reduceDate: reduceData });
      reduceData.forEach((data) => {
        if (
          new Date(data.dateTime).getTime() > new Date().getTime() &&
          !this.state.nextAgendaDate
        ) {
          const nextDate = data.dateTime;
          this.setState({
            nextAgendaDate: nextDate,
          });
          if (shouldRun) {
            this.setState({ nextADate: nextDate });
          }
          return;
        }
      });
      const complete =
        this.state.agendaData?.length > 0 &&
        this.state.agendaData.every(
          (data) =>
            new Date(data.dateTime).getTime() +
            60 * 60 * 1000 * data.duration.hours >
            new Date().getTime(),
        );

      if (shouldRun) {
        let arr1 = [];
        this.state.agendaData.length > 0 &&
          this.state.agendaData.forEach((data) => {
            // (agenda time + agenda duration) < current time
            new Date(data.dateTime).getTime() +
              60 * 60 * 1000 * data.duration.hours <
              new Date().getTime()
              ? arr1.push('true')
              : arr1.push('false');
          });

        if (arr1.includes('false')) {
          this.setState({ isCompleteAgenda: false });
        } else {
          this.setState({ isCompleteAgenda: true });
        }
      }

      this.state.agendaData?.length > 0 &&
        this.state.agendaData.forEach((data) => {
          new Date().getDate() === new Date(data.dateTime).getDate()
            ? this.setState({ currDate: true })
            : this.setState({ currDate: false });
          return;
        });
    } else {
      const result2 = this.state.selecteddateData?.map((data) =>
        data.visibility.visibilityType == 'All' ? data : false,
      );

      const tap = result2?.filter((res) => res !== false);
      var result =
        tap?.length > 0 &&
        tap?.reduce((unique, o) => {
          if (
            !unique.some(
              (obj) =>
                moment(obj.dateTime).format('dddd, MMM, Do') ===
                moment(o.dateTime).format('dddd, MMM, Do'),
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);
      if (result) {
        const reduceData =
          result &&
          result?.sort((a, b) => {
            return new Date(a.dateTime) - new Date(b.dateTime);
          });
        this.setState({ reduceDate: reduceData });
        reduceData?.forEach((data) => {
          if (
            new Date(data.dateTime)?.getTime() > new Date().getTime() &&
            !this.state.nextAgendaDate
          ) {
            const nextDate = data.dateTime;
            this.setState({
              nextAgendaDate: nextDate,
              nextADate: nextDate,
              nextDateS: nextDate,
            });
            return;
          }
        });
        const complete =
          this.state.agendaData?.length > 0 &&
          this.state.agendaData?.every(
            (data) =>
              new Date(data.dateTime).getTime() +
              60 * 60 * 1000 * data.duration.hours >
              new Date().getTime(),
          );

        let arr1 = [];
        this.state.agendaData.length > 0 &&
          this.state.agendaData.forEach((data) => {
            // (agenda time + agenda duration) < current time
            new Date(data.dateTime).getTime() +
              60 * 60 * 1000 * data.duration.hours <
              new Date().getTime()
              ? arr1.push('true')
              : arr1.push('false');
          });

        if (arr1.includes('false')) {
          this.setState({ isCompleteAgenda: false });
        } else {
          this.setState({ isCompleteAgenda: true });
        }

        this.state.agendaData?.length > 0 &&
          this.state.agendaData.forEach((data) => {
            new Date().getDate() === new Date(data.dateTime).getDate()
              ? this.setState({ currDate: true })
              : this.setState({ currDate: false });
            return;
          });
      }
    }
  };

  getFavAgendaDate = () => {
    var result = this.state.myFavSelectedDate.reduce((unique, o) => {
      if (
        !unique.some(
          (obj) =>
            moment(obj.dateTime).format('dddd, MMM, Do') ===
            moment(o.dateTime).format('dddd, MMM, Do'),
        )
      ) {
        unique.push(o);
      }
      return unique;
    }, []);
    const reduceData = result.sort((a, b) => {
      return new Date(a.dateTime) - new Date(b.dateTime);
    });
    this.setState({ reduceFavDate: reduceData });
    reduceData.forEach((data) => {
      if (
        new Date(data.dateTime).getTime() > new Date().getTime() &&
        !this.state.nextFavAgendaDate
      ) {
        const nextDate = data.dateTime;
        this.setState({ nextFavAgendaDate: nextDate, nextFDate: nextDate });
        return;
      }
    });
    this.state.myFavAgendaData?.length > 0 &&
      this.state.myFavAgendaData.forEach((data) => {
        new Date(data.dateTime).getTime() +
          60 * 60 * 1000 * data.duration.hours <
          new Date().getTime()
          ? this.setState({ isCompleteFavAgenda: true })
          : this.setState({ isCompleteFavAgenda: false });
        return;
      });

    this.state.myFavAgendaData?.length > 0 &&
      this.state.myFavAgendaData.forEach((data) => {
        new Date().getDate() === new Date(data.dateTime).getDate()
          ? this.setState({ currFavDate: true })
          : this.setState({ currFavDate: false });
        return;
      });
  };

  dateFilter = async (eventID, date, id) => {
    this.setState({ loading: true, isSearchingAgenda: false });
    await getAgendaProfile({
      eventDomain,
      projectId: this.state.projectId,
      date,
    }).then((result) => {
      const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
      this.setState({
        selecteddateData: agendaSortedByDates,
        selectedAgendaId: id,
        setAll: false,
        myAgendaDate: false,
        loading: false,
        agendaDate: date,
      });
    });
    if (this.state.groupsArr.length) {
      const result = this.state.selecteddateData.map((data) =>
        data.visibility.visibilityType == 'Groups'
          ? this.test(data)
          : data.visibility.visibilityType == 'All'
            ? data
            : false,
      );
      const tap = result?.filter((res) => res !== false);
      this.setState({
        agendaData: tap,
        selectedAgendaId: id,
        setAll: false,
        myAgendaDate: false,
        loading: false,
        agendaDate: date,
      });
      if (
        !tap.every(
          (el) =>
            new Date(el.dateTime).toDateString() === new Date().toDateString(),
        )
      ) {
        this.setState({ nextADate: '' });
      } else {
        this.setState({
          nextADate: this.state.nextDateS,
        });
      }
    } else {
      const result = this.state.selecteddateData.map((data) =>
        data.visibility.visibilityType == 'All' ? data : false,
      );
      const tap = result?.filter((res) => res !== false);
      this.setState({
        agendaData: tap,
        setAll: true,
        loading: false,
        selectedAgendaId: id,
        setAll: false,
        myAgendaDate: false,
        agendaDate: date,
      });

      if (
        !tap.every(
          (el) =>
            new Date(el.dateTime).toDateString() === new Date().toDateString(),
        )
      ) {
        this.setState({ nextADate: '' });
      } else {
        this.setState({
          nextADate: this.state.nextDateS,
        });
      }
    }

    if (this.state.selectedAgendaId) {
      this.setState({ toggleColor: true });
    }

    let arr1 = [];
    this.state.agendaData.length > 0 &&
      this.state.agendaData.forEach((data) => {
        // (agenda time + agenda duration) < current time
        new Date(data.dateTime).getTime() +
          60 * 60 * 1000 * data.duration.hours <
          new Date().getTime()
          ? arr1.push('true')
          : arr1.push('false');
      });

    if (arr1.includes('false')) {
      this.setState({ isCompleteAgenda: false });
    } else {
      this.setState({ isCompleteAgenda: true });
    }

    this.state.agendaData?.length > 0 &&
      this.state.agendaData.forEach((data) => {
        new Date().getDate() === new Date(data.dateTime).getDate()
          ? this.setState({ currDate: true })
          : this.setState({ currDate: false });
        return;
      });
  };

  dateFavFilter = (date, id) => {
    this.setState({
      loading: true,
      nextFavAgendaDate: '',
      selectedFavAgendaDate: '',
    });

    if (this.state.nextFavAgendaDate) {
      this.setState({ setSeeAll: false });
    }

    getMyAgendaProfile({ eventDomain, date, eventId: this.props.eventId }).then((result) => {
      if (result.data.myAgenda.length > 0) {
        setTimeout(() => {
          this.getAllButtonByClassName();
        }, 700);
        const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        this.setState({
          myFavAgendaData: agendaSortedByDates,
          selectedFavAgendaId: id,
          myFavAgendaDate: false,
          setSeeAll: false,
          loading: false,
          favAgendaDate: date
        });
      } else {
        this.getMyAgendaDetails();
      }
    });

    new Date(date) < new Date().getTime()
      ? this.setState({ isCompleteFavAgenda: true })
      : this.setState({ isCompleteFavAgenda: false });
    new Date().getDate() === new Date(date).getDate()
      ? this.setState({ currFavDate: true })
      : this.setState({ currFavDate: false });
  };

  handleClickIsUnfavorite = async (id, getKey, date) => {
    const favData = {
      agendaId: id,
      agendaAction: 'removed',
    };
    this.setState({ setUnfavorite: false, selectedLikeKey: getKey });
    await setMyAgendaFavourite(favData).then((response) => {
      if (response.data.status == 200) {
        createNotification('success', response.data.message);
      } else {
        createNotification('error', 'something went wrong!');
      }
    });
    if (this.state.setSeeAll == false) {
      this.dateFavFilter(
        date,
        this.state.selectedFavAgendaId ? this.state.selectedFavAgendaId : 0,
      );
    } else {
      await this.seeAllFavAgendaDetails();
    }
    await this.getAgendaDetail(this.state.projectId);
  };

  handleClickIsFavorite = async (id, action, isfav, date) => {
    const favData = {
      agendaId: id,
      agendaAction: action,
    };
    await setMyAgendaFavourite(favData).then((response) => {
      if (response.data.status == 200) {
        createNotification('success', response.data.message);
      } else {
        createNotification('error', 'something went wrong!');
      }
    });
    this.setState({
      isFavorite: isfav,
    });
    if (this.state.setAll == false) {
      this.dateFilter(
        this.state.projectId,
        date,
        this.state.selectedAgendaId ? this.state.selectedAgendaId : 0,
      );
    } else {
      await this.seeAllAgendaDetails(this.state.projectId);
    }
    if (this.state.agendaData?.length == 0) {
      this.setState({ agendaDate: '' });
    }
  };

  searchAgendaDetail = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ isSearchingAgenda: true });

    if (
      this.state.searchAgendaText?.trim() !== '' &&
      this.state.searchAgendaText !== undefined
    ) {
      getAgendaProfile({
        eventDomain,
        projectId: this.state.projectId,
        search: this.state.searchAgendaText.trim(),
      }).then((result) => {
        const agendaSortedByDates = result?.data?.Agenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        this.setState(
          {
            selecteddateData: agendaSortedByDates,
            loading: false,
            setAll: false,
            myAgendaDate: false,
          },
          () => {
            if (this.state.groupsArr.length) {
              const result = this.state.selecteddateData.map((data) =>
                data.visibility.visibilityType == 'Groups'
                  ? this.test(data)
                  : data.visibility.visibilityType == 'All'
                    ? data
                    : false,
              );
              const allData = result?.filter((res) => res !== false);
              this.setState({
                agendaData: allData,
              });
            } else {
              const result = this.state.selecteddateData.map((data) =>
                data?.visibility?.visibilityType == 'All' ? data : false,
              );
              const allData = result?.filter((res) => res !== false);

              this.setState({
                agendaData: allData,
              });
              this.setState({ loading: false });
            }
          }
        );
      }
      );
    } else {
      this.seeAllAgendaDetails();
      this.setState({ loading: false });
      this.setState({
        searchAgendaText: '',
      });
    }
  };

  searchFavAgendaDetail = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ searchfavAgendaText: e.target.value });
    getMyAgendaProfile({
      eventDomain,
      search: this.state.searchfavAgendaText.trim(),
      eventId: this.props.eventId
    }).then((result) => {
      setTimeout(() => {
        this.getAllButtonByClassName();
      }, 2500);
      const agendaSortedByDates = result?.data?.myAgenda.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
      this.setState({
        myFavAgendaData: agendaSortedByDates,
        loading: false,
      });
    }
    );
  };

  debounce = async (fn, d) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(fn, d);
  };

  logOut = () => {
    window.location = this.state.logOutUrl;
  };

  goBack = () => {
    console.log("this.state.homePageUrl=================>>>>", this.state.homePageUrl);
    if (this.state.customNewData) {
      this.setState({ backConfirmationModal: true });
    } else {
      window.location = this.state.homePageUrl;
    }
  };

  openBackModal = () => {
    if (this.state.backConfirmationModal) {
      window.location = this.state.homePageUrl;
      this.saveEditAttendee();
    }
  };

  closeBackModal = () => {
    this.setState({ backConfirmationModal: false });
    window.location = this.state.homePageUrl;
  };

  crossCloseModal = () => {
    this.setState({ backConfirmationModal: false });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleChange = (e) => {
    try {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } catch (error) {
      return error;
    }
  };

  handleCustomFieldChange = (e) => {
    if (e.target.value !== 'Select a data') {
      this.setState({
        [e.target.name]: {
          value: e.target.value,
          type: e.target.type,
        },
        customFieldData: {
          ...this.state.customFieldData,
          [e.target.name]: e.target.value,
        },
        customNewData: e.target.value,
      });
    }
  };

  handleChangeCheck = (event) => {
    const { value, checked } = event.target;
    let checkValue = this.state.customFieldData[event.target.name]
      ? this.state.customFieldData[event.target.name]
      : [];
    if (checked) {
      let checkValues = [];
      checkValues.push(event.target.value);
      checkValue = checkValue.concat(checkValues);

      this.setState({
        [event.target.name]: {
          value: event.target.value,
          type: event.target.type,
        },
        customFieldData: {
          ...this.state.customFieldData,
          [event.target.name]: checkValue,
        },
      });
    } else {
      let check = checkValue?.filter((event) => event !== value);
      if (check.length === 0) {
        let customFieldData = this.state.customFieldData;
        delete customFieldData[event.target.name];
        this.setState({
          customFieldData,
        });
      } else {
        this.setState({
          customFieldData: {
            ...this.state.customFieldData,
            [event.target.name]: check,
          },
        });
      }
    }
  };

  updateSecurity = async (e) => {
    let curIsValid = true;
    let newIsValid = true;
    let newRegex = false;
    let reIsValid = true;
    let equalIsValid = false;
    let error = {};
    const { currentPass, newPass, rePass, projectId } = this.state;
    try {
      if (
        currentPass.trim().length === 0 ||
        currentPass === '' ||
        currentPass === undefined
      ) {
        curIsValid = false;
        error['currentPass'] = 'Please enter the current password';
      }
      if (
        newPass.trim().length === 0 ||
        newPass === '' ||
        newPass === undefined
      ) {
        newIsValid = false;
        error['newPass'] = 'Please enter the new password';
      } else if (
        /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(newPass)
      ) {
        newRegex = true;
      } else {
        newRegex = false;
        error['newPass'] = 'Please enter password as mentioned in the rules';
      }
      if (rePass.trim().length === 0 || rePass === '' || rePass === undefined) {
        reIsValid = false;
        error['rePass'] = 'Please re-enter the password';
      } else if (rePass === newPass) {
        equalIsValid = true;
      } else {
        equalIsValid = false;
        error['rePass'] = "Password doesn't matched ";
      }
      if (curIsValid && equalIsValid && newRegex && newIsValid && reIsValid) {
        const data = {
          currentPassword: currentPass,
          newPassword: newPass,
          projectId: projectId,
        };
        await updateAgendaSecurity(data).then((res) => {
          if (res.data.status == 200) {
            createNotification('success', res.data.message);
            this.setState({
              currentPass: '',
              newPass: '',
              rePass: '',
            });
          } else {
            createNotification('error', res.data.error);
          }
        });
      }
      this.setState({
        errors: error,
      });
    } catch (err) {
      createNotification(
        'error',
        err + 'something went wrong while saving the edited attendees',
      );
    }
  };

  saveEditAttendee = async () => {
    try {
      const { attendeeEmail, customFields, customFieldData } = this.state;
      const customField = customFields.map((item) => item._id);
      const attendeesData = {};
      for (const item of customField) {
        attendeesData[item] = customFieldData[item];
      }

      this.isValid = () => {
        const regexUrl =
          /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        const regexForEmail =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let formIsValid = true;
        let error = {};
        if (
          attendeeEmail.trim().length === 0 ||
          attendeeEmail === '' ||
          attendeeEmail === undefined
        ) {
          formIsValid = false;
          error['attendeeEmail'] = 'Please enter the email';
        } else if (
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(attendeeEmail)
        ) {
          formIsValid = true;
        } else {
          formIsValid = false;
          error['attendeeEmail'] = 'Please enter a valid email';
        }

        for (let data of customFields) {
          let attendeesDataValid = {};
          attendeesDataValid[data._id] = customFieldData[data._id];

          const { fieldData } = data;
          const { fieldName, editByAttendees, mandatoryAttendeesProfile } =
            fieldData;
          const currentFieldData = this.state.attendeeSource === "Google Workspace" ? (this.state.googleWorkspaceData[data._id] || customFieldData[data._id]) : customFieldData[data._id];
          if (mandatoryAttendeesProfile || editByAttendees) {
            if (
              mandatoryAttendeesProfile &&
              (currentFieldData === undefined || currentFieldData === '')
            ) {
              formIsValid = false;
              error[fieldName] = `Please enter the field`;
            } else {
              const { fieldType } = fieldData || {};
              if (formIsValid === true && fieldType === 'email') {
                if (
                  mandatoryAttendeesProfile &&
                  (currentFieldData === undefined ||
                    currentFieldData?.length === 0 ||
                    currentFieldData === '')
                ) {
                  formIsValid = false;
                  error['validEmail'] = `Please enter a email`;
                } else if (
                  mandatoryAttendeesProfile &&
                  regexForEmail.test(currentFieldData) === false
                ) {
                  formIsValid = false;
                  error['validEmail'] = `Please enter a vaid email`;
                } else if (
                  !mandatoryAttendeesProfile &&
                  currentFieldData !== undefined &&
                  currentFieldData?.length !== 0 &&
                  currentFieldData !== '' &&
                  regexForEmail.test(currentFieldData) === false
                ) {
                  formIsValid = false;
                  error['validEmail'] = `Please enter a valid email`;
                }
              }

              if (formIsValid === true && fieldType === 'url') {
                if (
                  mandatoryAttendeesProfile &&
                  (currentFieldData === undefined ||
                    currentFieldData?.length === 0 ||
                    currentFieldData === '')
                ) {
                  formIsValid = false;
                  error['validUrl'] = `Please enter a URL`;
                } else if (
                  mandatoryAttendeesProfile &&
                  regexUrl.test(currentFieldData) === false
                ) {
                  formIsValid = false;
                  error['validUrl'] = `Please enter a vaid URL`;
                } else if (
                  !mandatoryAttendeesProfile &&
                  currentFieldData !== undefined &&
                  currentFieldData?.length !== 0 &&
                  currentFieldData !== '' &&
                  regexUrl.test(currentFieldData) === false
                ) {
                  formIsValid = false;
                  error['validUrl'] = `Please enter a valid URL`;
                }
              }
            }
          }
        }

        this.setState({
          errors: error,
          open: false,
          profileImage: this.state.cropPic,
        });
        return formIsValid;
      };
      if (this.isValid()) {
        let newObj = {};

        for (const element in attendeesData) {
          newObj[element] = Array.isArray(attendeesData[element])
            ? attendeesData[element]
            : attendeesData[element]?.trim();
        }

        const formData = new FormData();
        formData.append('eventDomain', eventDomain);
        formData.append('projectId', this.state.projectId);
        formData.append('department', this.state.department);
        formData.append('email', attendeeEmail);
        formData.append('attendeeId', this.state.attendeeData.id);
        formData.append('attendeeData', JSON.stringify(newObj));
        this.setState({
          loading: true,
        });
        await UpdateAttendeeProfileAPI(formData).then((response) => {
          if (response) {
            if (response.data.status == 200) {
              this.setState({
                loading: false,
              });
              createNotification('success', response.data.message);

            } else {
              this.setState({
                loading: false,
              });
              createNotification('error', 'something went wrong!');
            }
          }
        });
      }
      let getData = { id: this.state.attendeeData.id, eventId: this.props.eventId };
      localStorage.setItem('image', JSON.stringify(this.state.i));

      await GetAttendeeDetail(getData).then((result) => {
        if (result?.data?.status === 200) {
          this.setState({ i: result.data.attendee.profileImage });
        }
      });
    } catch (error) {
      createNotification(
        'error',
        error + 'something went wrong while saving the edited attendees',
      );
    }
  };

  saveAttendeeProfile = async () => {
    const formData = new FormData();
    formData.append('projectId', this.state.projectId);
    formData.append('attendeeId', this.state.attendeeId);
    formData.append(
      'profileImage',
      this.state.cropPic ? this.state.cropPic : this.state.i,
    );
    this.setState({ open: false, loading: true });
    await UpdateProfilePic(formData).then((response) => {
      if (response.data.status == 200) {

        this.setState({
          i: response.data.profileImageTemp,
          profileImage: response.data.profileImageTemp,
        }, () => {
          setTimeout(() => {
            createNotification('success', response.data.message);
          }, 2000);
        });


      } else {
        createNotification('error', response.data.message);
      }
      this.setState({ loading: false });
    });
    let getData = { id: this.state.attendeeData.id, eventDomain, eventId: this.props.eventId };
    this.setState({ loading: true });
    await GetAttendeeDetail(getData).then((result) => {
      if (result?.data?.status === 200) {
        this.setState({
          i: result.data.attendee.profileImage,
          profileImage: result.data.attendee.profileImage,
        });
      }
      this.setState({
        loading: false
      });
    }
    );
  };

  handleClickShowCurrentPassword = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword,
    });
  };
  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  onProfileImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5000000) {
        //5 mb
        createNotification(
          'error',
          'Please select image that size is less than 5MB.',
        );
        return false;
      }

      this.setState({ uploadImage: event.target.files[0], open: true });
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  deleteProfileImageOnClick = async (e) => {
    let paramsData = {
      attendeeId: this.state.attendeeId,
      profileImage: this.state.i,
      projectId: this.state.projectId,
    };
    this.setState({
      loading: true
    });
    await deleteProfileImageAPI(paramsData).then((response) => {
      if (response.data.status == 200) {
        createNotification('success', response.data.message);
        this.setState({
          profileImage: null,
        });
      } else {
        createNotification('error', response.data.message);
      }
    });
    this.setState({ openDeleteSessionModal: false, loading: false });
  };

  handleCloseOpenDeleteSessionModal = () => {
    this.setState({
      openDeleteSessionModal: false,
    });
  };

  handleOpenDeleteSessionModal = () => {
    this.setState({
      openDeleteSessionModal: true,
    });
  };

  getData = (val) => {
    this.setState({ cropPic: val });
  };

  chooseProfileImageClick = () => {
    chooseProfileImage.current.click();
  };

  getAccessCode = () => {
    const { projectId, attendeeId } = this.state;
    const body = {
      projectId,
      attendeeId,
    };
    this.props.getAccessCode(body);
    this.handleLoading();
  };

  render() {
    const {
      remainingHours,
      value,
      customFields,
      customFieldData,
      attendeeEmail,
      selectedLikeKey,
      errors,
      showConfirmPassword,
      setSeeAll,
      setAll,
      myAgendaDate,
      myFavAgendaDate,
      timeZone,
      showNewPassword,
      showCurrentPassword,
      setUnfavorite,
      selectedFavAgendaId,
      open,
      passwordType,
      agendaData,
      reduceDate,
      reduceFavDate,
      profileImage,
      currentPass,
      newPass,
      rePass,
      homePageUrl,
      projectId,
      searchAgendaText,
      searchfavAgendaText,
      myFavAgendaData,
      loading,
      isCompleteAgenda,
      isCompleteFavAgenda,
      attendeeData,
      agendaDate,
      favAgendaDate,
      customeJsProfile,
    } = this.state;
    const styles = {
      customTabList: {
        padding: '34px 0px 0',
        background: '#F9F9F9',
        borderRadius: '8px 8px 0 0',
      },
      customTab: {
        minHeight: 42,
        padding: '12px 16px',
        background: '#FFFFFF',
      },
      customTabPanel: {
        height: 'calc(100vh - 148px)',
        overflow: 'auto',
        borderTop: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)'
      },

      avatar: {
        // backgroundColor: '#FF4170',
        width: '200px',
        height: '200px',
      },
      avatarAdded: {
        backgroundColor: '#FFF',
        width: '200px',
        height: '200px',
      },
      attendeeEmail: {
        cursor: 'not-allowed:',
      },
      speaker: {
        backgroundColor: '#FF4170',
        width: '48px',
        height: '48px',
      },
    };

    return (
      this.state.attendeeData && (
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>My Profile</title>
            {this.state.favIcon && (
              <link
                rel="icon"
                href={IMAGE_URL + `/favIcons/${this.state.favIcon}`}
              />
            )}
            <script>{customeJsProfile ? customeJsProfile : ''}</script>
          </Helmet>
          <NotificationContainer />
          <Box
            className="my-profile"
            sx={{
              background: '#f3f3f3',
              height: '100vh',
            }}
          >
            <Box
              px={1}
              sx={{
                background: '#5B33F1',
                height: '350px',
              }}
            >
              <Box
                component={Grid}
                container
                spacing={2}
                mt={0}
                mb={2}
                px={1}
                justifyContent="space-between"
              >
                <Grid >
                  {this.props.profile?.eventData?.isAccessCode ?
                    <>
                      {remainingHours !== 'Expired' &&
                        remainingHours !== 'Disabled' &&
                        remainingHours !== 'No access code' &&
                        remainingHours !== 0 && (
                          <IconButton size="small" aria-label="delete">
                            <ArrowBackIcon
                              onClick={this.goBack}
                              style={{ color: theme.palette.common.white }}
                            />
                          </IconButton>
                        )}
                    </>
                    :
                    <IconButton size="small" aria-label="delete">
                      <ArrowBackIcon
                        onClick={this.goBack}
                        style={{ color: theme.palette.common.white }}
                      />
                    </IconButton>
                  }
                </Grid>
              </Box>
            </Box>
            <Box component={Grid} mt={-35} container justifyContent={'center'}>
              <Grid size={{xs: 12, md:11, lg:9, xl:8}} >
                <Box
                  sx={{
                    background: theme.palette.common.white,
                    borderRadius: '8px',
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ position: 'relative' }}>
                      {this.props.profile?.eventData?.isAccessCode &&
                        <>
                          {(remainingHours === 'Expired' ||
                            remainingHours === 'Disabled' ||
                            remainingHours === 'No access code' ||
                            remainingHours === 0) && (
                              <Box className='warning-bar'>
                                <WarningAmberIcon style={{ fontSize: '16px', marginRight: '5px' }} />
                                There is no access code attached to your account. Please use the Add New Code button to attach one or contact our Support Team for more information
                              </Box>
                            )}
                        </>
                      }

                      <TabList
                        style={styles.customTabList}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        onChange={this.handleChangeTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          style={styles.customTab}
                          icon={<img alt="" src={OverviewIcon} />}
                          iconPosition="start"
                          label="My Profile"
                          value="1"
                          onClick={() => this.handleRouting('update-profile')}
                        ></Tab>

                        {passwordType == 'Private_Password' && (
                          <Tab
                            style={styles.customTab}
                            icon={<img alt="" src={managePagesIcon} />}
                            iconPosition="start"
                            label="Security"
                            value="2"
                            onClick={() => this.handleRouting('security')}
                          >
                            {' '}
                          </Tab>
                        )}
                        <Tab
                          style={styles.customTab}
                          icon={<img alt="" src={campaignsIcon} />}
                          iconPosition="start"
                          label="My Agenda"
                          value="3"
                          onClick={this.getMyAgendaDetails}
                        >
                          {' '}
                        </Tab>
                        <Tab
                          style={styles.customTab}
                          icon={<img alt="" src={agendaIcon} />}
                          iconPosition="start"
                          label="Agenda"
                          value="4"
                          onClick={() => {
                            this.handleRouting('agenda');
                            this.getAgendaDetail(this.state.projectId);
                          }
                          }
                        >
                          {' '}
                        </Tab>
                        {this.props.profile?.challengeData && (
                          <Tab
                            style={styles.customTab}
                            icon={<img alt="" src={gamificationIcon} />}
                            iconPosition="start"
                            label="Gamification"
                            value="5"
                            onClick={() => this.handleRouting('gamification')}
                          >
                            {' '}
                          </Tab>
                        )}
                        {this.props.profile?.eventData?.isAccessCode && (
                          <Tab
                            style={styles.customTab}
                            icon={<img alt="" src={accessCodeIcon} />}
                            iconPosition="start"
                            label="Access code"
                            value="6"
                            onClick={() => this.handleRouting('access-code')}
                          >
                            {' '}
                          </Tab>
                        )}
                      </TabList>

                    </Box>
                    <TabPanel style={styles.customTabPanel} value="1">
                      <Box
                        px={{ xs: 2, sm: 4, xl: 6 }}
                        py={4}
                        sx={{ border: '1px solid #EDEBFF' }}
                      >
                        <Grid container spacing={{ xs: 2, sm: 4, xl: 6 }}>
                          <Grid
                            size={{xs: 12, md:3}}
                            display="flex"
                            justifyContent="center"
                          >
                            <Stack spacing={3} alignItems="center">
                              <Avatar style={this.state.i && this.state.profileImage ? styles.avatarAdded : styles.avatar}>
                                {this.state.i ? (
                                  <img
                                    id="imgPreview"
                                    style={{ height: '100%', width: '100%' }}
                                    alt="profile image"
                                    src={
                                      typeof profileImage === 'string'
                                        ? IMAGE_URL +
                                        '/profilePictures/' +
                                        this.state.i
                                        : profileImage
                                          ? window.URL.createObjectURL(
                                            this.state.profileImage,
                                          )
                                          : userImageIcon
                                    }
                                  />
                                ) : (
                                  !profileImage && (
                                    <img
                                      alt="profile image"
                                      src={userImageIcon}
                                    />
                                  )
                                )}

                                { }
                                <Box
                                  className="update-avtar"
                                  display="flex"
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  <Stack direction={'row'} spacing={2}>
                                    {profileImage ? (
                                      <>
                                        <IconButton
                                          size="small"
                                          style={{
                                            backgroundColor: '#42AD46',
                                          }}
                                          aria-label="edit"
                                        >
                                          <EditIcon
                                            onClick={this.onOpenModal}
                                            style={{
                                              color: theme.palette.common.white,
                                            }}
                                          />
                                          <input
                                            type="file"
                                            id="profileImage"
                                            name="profileImage"
                                            onChange={(e) =>
                                              this.onProfileImageChange(
                                                e,
                                                'add',
                                              )
                                            }
                                            accept="image/*"
                                            ref={chooseProfileImage}
                                            style={{ display: 'none' }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          style={{
                                            backgroundColor: '#FF4646',
                                          }}
                                          aria-label="delete"
                                        >
                                          {
                                            <DeleteIcon
                                              style={{
                                                color:
                                                  theme.palette.common.white,
                                              }}
                                              onClick={
                                                this
                                                  .handleOpenDeleteSessionModal
                                              }
                                            />
                                          }
                                        </IconButton>
                                      </>
                                    ) : (
                                      <IconButton
                                        style={{
                                          backgroundColor: '#42AD46',
                                        }}
                                        aria-label="edit"
                                      >
                                        <img
                                          onClick={this.chooseProfileImageClick}
                                          src={UploadIcon}
                                        />

                                        <input
                                          type="file"
                                          id="profileImage"
                                          name="profileImage"
                                          onChange={(e) =>
                                            this.onProfileImageChange(e, 'add')
                                          }
                                          accept="image/*"
                                          ref={chooseProfileImage}
                                          style={{ display: 'none' }}
                                        />
                                      </IconButton>
                                    )}
                                  </Stack>
                                </Box>
                              </Avatar>

                              <Button
                                onClick={this.logOut}
                                variant="outlined"
                                color="primary"
                                startIcon={<img alt="" src={logOutIcon} />}
                                style={{
                                  color: '#000056',
                                  borderColor: '#000056',
                                }}
                              >
                                Log Out
                              </Button>
                            </Stack>
                            {/* <ImageCrop/> */}
                          </Grid>
                          <Grid size={{xs: 12, md:9}} >
                            <Grid container spacing={{ xs: 2, lg: 4 }}>
                              <Grid size={{xs: 12, md:12}} >
                                <Box component="div" className="inputField">
                                  <label>
                                    Email Address{' '}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    style={styles.attendeeEmail}
                                    type="text"
                                    placeholder="Enter email addresses"
                                    className="form-control small attendeeEmail"
                                    name="attendeeEmail"
                                    value={attendeeEmail}
                                    onChange={this.handleChange}
                                    disabled="disabled"
                                  />
                                  <span className="required">
                                    {' '}
                                    {errors.attendeeEmail}{' '}
                                  </span>
                                </Box>
                              </Grid>
                              {<Grid size={{xs: 12, md:12}} >
                                <Box component="div" className="inputField">
                                  <label>
                                    Department{' '}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter department"
                                    className="form-control small"
                                    name="department"
                                    value={this.state.department}
                                    onChange={this.handleChange}
                                    disabled={this.state.attendeeSource === "Google Workspace" ? true : false}
                                  />
                                  <span className="required">
                                    {' '}
                                    {errors.attendeeEmail}{' '}
                                  </span>
                                </Box>
                              </Grid>}

                              {this.state.attendeeSource !== "Google Workspace" ? (customFields &&
                                Array.isArray(customFields) &&
                                customFields?.length > 0 &&
                                customFields.map((customField, id) => {
                                  return (
                                    customField.fieldData
                                      .showAttendeesProfile == true && (
                                      <Grid key={id} size={{xs: 12, md:12}} >
                                        {customField.fieldData
                                          .showAttendeesProfile == true ? (
                                          <Box
                                            key={customField._id}
                                            component="div"
                                            className="inputField"
                                          >
                                            <label>
                                              {customField.fieldData.fieldLabel}{' '}
                                              {customField.fieldData
                                                .mandatoryAttendeesProfile &&
                                                customField.fieldData
                                                  .editByAttendees && (
                                                  <span className="required">
                                                    *
                                                  </span>
                                                )}
                                            </label>
                                            {customField.fieldData.fieldType ===
                                              'dropdown' ? (
                                              <FormControl
                                                size="small"
                                                fullWidth
                                              >
                                                <Select
                                                  disabled={
                                                    !customField.fieldData
                                                      .editByAttendees
                                                  }
                                                  value={
                                                    customFieldData &&
                                                    customFieldData[
                                                    customField._id
                                                    ]
                                                  }
                                                  onChange={
                                                    this.handleCustomFieldChange
                                                  }
                                                  placeholder={
                                                    customField.fieldData
                                                      .fieldType !== 'date'
                                                      ? `Enter ${customField.fieldData.fieldLabel}`
                                                      : ``
                                                  }
                                                  className="form-control small select"
                                                  name={customField._id}
                                                  style={{ padding: '4px 0px', }}
                                                  MenuProps={MenuProps}
                                                  displayEmpty
                                                >
                                                  <MenuItem disabled hidden
                                                    label={`Select a ${customField.fieldData.fieldLabel}`}
                                                  ></MenuItem>
                                                  {customField.fieldData.listValue.map(
                                                    (data, id) => (

                                                      <MenuItem
                                                        key={id}
                                                        value={data}
                                                      >
                                                        {data}
                                                      </MenuItem>

                                                    ),
                                                  )}
                                                </Select>
                                              </FormControl>
                                            ) : customField.fieldData
                                              .fieldType === 'checkbox' ? (
                                              customField.fieldData?.checkboxValue?.map(
                                                (item) => (
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      name={customField._id}
                                                      value={item}
                                                      checked={
                                                        customFieldData[
                                                          customField._id
                                                        ]?.indexOf(item) > -1
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={
                                                        this.handleChangeCheck
                                                      }
                                                    />{' '}
                                                    {item}
                                                  </label>
                                                ),
                                              )
                                            ) : (
                                              <input
                                                disabled={
                                                  !customField.fieldData
                                                    .editByAttendees
                                                }
                                                placeholder={
                                                  customField.fieldData
                                                    .fieldType !== 'date'
                                                    ? `Enter ${customField.fieldData.fieldLabel}`
                                                    : null
                                                }
                                                className="form-control small"
                                                name={customField._id}
                                                value={
                                                  customFieldData &&
                                                  customFieldData[
                                                  customField._id
                                                  ]
                                                }
                                                type={
                                                  customField.fieldData
                                                    .fieldType
                                                }
                                                onChange={
                                                  this.handleCustomFieldChange
                                                }
                                              />
                                            )}
                                            {customField.fieldData
                                              .mandatoryAttendeesProfile && (
                                                <span className="required">
                                                  {' '}
                                                  {errors[
                                                    customField.fieldData
                                                      .fieldName
                                                  ] &&
                                                    `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                                                </span>
                                              )}
                                            {customField.fieldData.fieldType ===
                                              'email' && (
                                                <span className="required">
                                                  {' '}
                                                  {errors.validEmail}{' '}
                                                </span>
                                              )}
                                            {customField.fieldData.fieldType ===
                                              'url' && (
                                                <span className="required">
                                                  {' '}
                                                  {errors.validUrl}{' '}
                                                </span>
                                              )}
                                          </Box>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                    )
                                  );
                                })) : (customFields &&
                                  Array.isArray(customFields) &&
                                  customFields?.length > 0 &&
                                  customFields.map((customField, id) => {
                                    return (
                                      customField.fieldData
                                        .showAttendeesProfile == true && (
                                        <Grid key={id} size={{xs: 12, md:12}}>
                                          {customField.fieldData
                                            .showAttendeesProfile == true ? (
                                            <Box
                                              key={customField._id}
                                              component="div"
                                              className="inputField"
                                            >
                                              <label>
                                                {customField.fieldData.fieldLabel}{' '}
                                                {customField.fieldData
                                                  .mandatoryAttendeesProfile &&
                                                  customField.fieldData
                                                    .editByAttendees && (
                                                    <span className="required">
                                                      *
                                                    </span>
                                                  )}
                                              </label>
                                              {customField.fieldData.fieldType ===
                                                'dropdown' ? (
                                                <FormControl
                                                  size="small"
                                                  fullWidth
                                                >
                                                  <Select
                                                    disabled={
                                                      !customField.fieldData
                                                        .editByAttendees
                                                    }
                                                    value={
                                                      customFieldData &&
                                                      customFieldData[
                                                      customField._id
                                                      ]
                                                    }
                                                    onChange={
                                                      this.handleCustomFieldChange
                                                    }
                                                    placeholder={
                                                      customField.fieldData
                                                        .fieldType !== 'date'
                                                        ? `Enter ${customField.fieldData.fieldLabel}`
                                                        : ``
                                                    }
                                                    className="form-control small select"
                                                    name={customField._id}
                                                    style={{ padding: '4px 0px', }}
                                                    MenuProps={MenuProps}
                                                    displayEmpty
                                                  >
                                                    <MenuItem disabled hidden
                                                      label={`Select a ${customField.fieldData.fieldLabel}`}
                                                    ></MenuItem>
                                                    {customField.fieldData.listValue.map(
                                                      (data, id) => (

                                                        <MenuItem
                                                          key={id}
                                                          value={data}
                                                        >
                                                          {data}
                                                        </MenuItem>

                                                      ),
                                                    )}
                                                  </Select>
                                                </FormControl>
                                              ) : customField.fieldData
                                                .fieldType === 'checkbox' ? (
                                                customField.fieldData?.checkboxValue?.map(
                                                  (item) => (
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        name={customField._id}
                                                        value={item}
                                                        checked={
                                                          customFieldData[
                                                            customField._id
                                                          ]?.indexOf(item) > -1
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={
                                                          this.handleChangeCheck
                                                        }
                                                      />{' '}
                                                      {item}
                                                    </label>
                                                  ),
                                                )
                                              ) : (
                                                <>
                                                  <input
                                                    disabled={this.state.googleWorkspaceData[customField._id] || !customField.fieldData.editByAttendees}
                                                    placeholder={
                                                      customField.fieldData
                                                        .fieldType !== 'date'
                                                        ? `Enter ${customField.fieldData.fieldLabel}`
                                                        : null
                                                    }
                                                    className="form-control small"
                                                    name={customField._id}

                                                    value={
                                                      this.state.googleWorkspaceData[customField._id] ?
                                                        this.state.googleWorkspaceData[customField._id] :
                                                        (this.state.customFieldData &&
                                                          this.state.customFieldData[customField._id])
                                                    }
                                                    type={
                                                      customField.fieldData
                                                        .fieldType
                                                    }
                                                    onChange={
                                                      this.handleCustomFieldChange
                                                    }
                                                  />
                                                </>
                                              )}
                                              {customField.fieldData
                                                .mandatoryAttendeesProfile && (
                                                  <span className="required">
                                                    {' '}
                                                    {errors[
                                                      customField.fieldData
                                                        .fieldName
                                                    ] &&
                                                      `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                                                  </span>
                                                )}
                                              {customField.fieldData.fieldType ===
                                                'email' && (
                                                  <span className="required">
                                                    {' '}
                                                    {errors.validEmail}{' '}
                                                  </span>
                                                )}
                                              {customField.fieldData.fieldType ===
                                                'url' && (
                                                  <span className="required">
                                                    {' '}
                                                    {errors.validUrl}{' '}
                                                  </span>
                                                )}
                                            </Box>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                      )
                                    );
                                  }))
                              }
                              <Grid size={{xs: 12,}}>
                                <Stack spacing={1} direction="row" fullWidth>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={this.saveEditAttendee}
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    {passwordType == 'Private_Password' && (
                      <TabPanel style={styles.customTabPanel} value="2">
                        <Box
                          py={0}
                          sx={{
                            border: '1px solid #EDEBFF',
                          }}
                        >
                          <Grid container colSpacing={{ xs: 2, sm: 3, xl: 3 }}>
                            <Box
                              component={Grid}
                              size={{xs: 12, lg:6, }}
                              mt={3}
                              sx={{
                                borderRight: '1px solid #EDEBFF',
                                marginTop: '24ox !important',
                                paddingTop: '0px !important',
                              }}
                            >
                              <Box
                                px={{ xs: 2, sm: 2.5, xl: 2.5 }}
                                py={{ xs: 1, sm: 1.5, xl: 1.5 }}
                                sx={{ borderBottom: '1px solid #EDEBFF' }}
                              >
                                <Box className="subtitle">Change Password</Box>
                              </Box>
                              <Box p={{ xs: 2, sm: 2.5, xl: 2.5 }}>
                                <Grid container spacing={{ xs: 2, lg: 4 }}>
                                  <Grid size={{xs: 12, md:12}} >
                                    <Box component="div" className="inputField">
                                      <label>
                                        Current Password{' '}
                                        <span className="required">*</span>
                                      </label>
                                      <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        name="currentPass"
                                        value={currentPass}
                                        onChange={this.handleChange}
                                        pr={0}
                                        notched={false}
                                        type={
                                          showCurrentPassword
                                            ? 'text'
                                            : 'password'
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={
                                                this
                                                  .handleClickShowCurrentPassword
                                              }
                                              onMouseDown={
                                                this.handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showCurrentPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                      <span className="required">
                                        {' '}
                                        {errors.currentPass}{' '}
                                      </span>
                                    </Box>
                                  </Grid>
                                  <Grid size={{xs: 12, md:12}} >
                                    <Box component="div" className="inputField">
                                      <label>
                                        New Password{' '}
                                        <span className="required">*</span>
                                      </label>
                                      <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        pr={0}
                                        name="newPass"
                                        value={newPass}
                                        onChange={this.handleChange}
                                        notched={false}
                                        type={
                                          showNewPassword ? 'text' : 'password'
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={
                                                this.handleClickShowNewPassword
                                              }
                                              onMouseDown={
                                                this.handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showNewPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                      <span className="required">
                                        {' '}
                                        {errors.newPass}{' '}
                                      </span>
                                    </Box>
                                  </Grid>
                                  <Grid size={{xs: 12, md:12}} >
                                    <Box component="div" className="inputField">
                                      <label>
                                        Re-enter Password{' '}
                                        <span className="required">*</span>
                                      </label>
                                      <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        name="rePass"
                                        value={rePass}
                                        onChange={this.handleChange}
                                        pr={0}
                                        notched={false}
                                        type={
                                          showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={
                                                this
                                                  .handleClickShowConfirmPassword
                                              }
                                              onMouseDown={
                                                this.handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showConfirmPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                      <span className="required">
                                        {' '}
                                        {errors.rePass}{' '}
                                      </span>
                                    </Box>
                                  </Grid>
                                  <Grid size={{xs: 12,}}>
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                      fullWidth
                                    >
                                      <Button
                                        onClick={this.updateSecurity}
                                        variant="contained"
                                        color="primary"
                                      >
                                        Change
                                      </Button>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            <Box
                              component={Grid}
                              size={{xs: 12, lg:6}}
                              display="flex"
                              justifyContent="center"
                              p={{ xs: 2, sm: 2.5, xl: 2.5 }}
                            >
                              <Box
                                sx={{
                                  background: '#EDEBFF',
                                  borderRadius: '12px',
                                }}
                                px={5}
                                py={4}
                              >
                                <h3 className="subsecondary">
                                  Rules for passwords
                                </h3>
                                <p className="paragraph">
                                  To Create a new password, you have to meet all
                                  of the following requirements:
                                </p>

                                <ul className="paragraph">
                                  <li>At least six (6) digits</li>
                                  <li>
                                    Passwords must include at least one digit
                                    (e.g., 0-9).
                                  </li>
                                  <li>
                                    Passwords must contain at least one
                                    character (e.g., a-z and A-Z).
                                  </li>
                                  <li>
                                    Uses uppercase and lowercase letters,
                                    numbers and special symbols.{' '}
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                          </Grid>
                        </Box>
                      </TabPanel>
                    )}
                    <TabPanel style={styles.customTabPanel} value="3">
                      <Box
                        sx={{
                          // border: '1px solid #EDEBFF',
                          position: 'reletive',
                        }}
                      >
                        {this.state.topBarMyAgenda?.length > 0 && (
                          <Box
                            px={{ xs: 2, sm: 3.5, xl: 5 }}
                            pt={{ xs: 1, sm: 1, xl: 1 }}
                            pb={{ xs: 2, sm: 3, xl: 4 }}
                            sx={{ borderBottom: '1px solid #EDEBFF' }}
                          >
                            <Grid
                              container
                              justifyContent={'space-between'}
                              alignItems="center"
                              spacing={2}
                              className="top-filter-area"
                            >
                              <Grid >
                                <Button
                                  variant="contained"
                                  size="small"
                                  color={
                                    myFavAgendaData?.length > 0 &&
                                      setSeeAll != false
                                      ? 'primary'
                                      : ''
                                  }
                                  onClick={() => this.seeAllFavAgendaDetails()}
                                >
                                  See All
                                </Button>
                              </Grid>
                              <Grid
                                size={{xs: 12, sm: 'grow'}}
                                order={{ xs: 3, sm: 0 }}
                              
                                sx={{
                                  width: 'calc(100% - 72px - 200px - 16px)',
                                }}
                              >
                                <Carousel
                                  responsive={responsive}
                                  containerClass="agenda-filter"
                                  infinite={true}
                                  centerMode={true}
                                >
                                  {reduceFavDate?.length > 0 &&
                                    reduceFavDate?.map((aggendaDate, id) => (
                                      <Box
                                        component={Button}
                                        key={id}
                                        variant="contained"
                                        className={
                                          myFavAgendaData?.length > 0 &&
                                            setSeeAll == false
                                            ? myFavAgendaDate &&
                                              moment(
                                                aggendaDate.dateTime,
                                              ).format('DD MMM YYYY') ==
                                              moment(
                                                new Date().toISOString(),
                                              ).format('DD MMM YYYY')
                                              ? 'date-btn active'
                                              : (selectedFavAgendaId ||
                                                selectedFavAgendaId === 0) &&
                                                id == selectedFavAgendaId
                                                ? 'date-btn active'
                                                : moment(
                                                  this.state
                                                    .selectedFavAgendaDate,
                                                ).format('DD MMM YYYY') ===
                                                  moment(
                                                    aggendaDate.dateTime,
                                                  ).format('DD MMM YYYY')
                                                  ? 'date-btn active'
                                                  : 'date-btn'
                                            : 'date-btn'
                                        }
                                        size="small"
                                        onClick={() =>
                                          this.dateFavFilter(
                                            aggendaDate.dateTime,
                                            id,
                                          )
                                        }
                                      >
                                        <Stack>
                                          <Box className="date">
                                            {moment(
                                              aggendaDate.dateTime,
                                            ).format('D')}
                                          </Box>
                                          <Box className="month">
                                            {moment(
                                              aggendaDate.dateTime,
                                            ).format('MMM')}
                                          </Box>
                                        </Stack>
                                      </Box>
                                    ))}
                                </Carousel>
                              </Grid>
                              <Grid sx={{ width: '200px' }}>
                                <form onSubmit={this.searchFavAgendaDetail}>
                                  <TextField
                                    name="searchfavAgendaText"
                                    placeholder="Search..."
                                    className="search"
                                    size="small"
                                    onChange={this.handleChange}
                                    value={searchfavAgendaText}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img alt="" src={SearchIcon} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </form>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                        {/* filter end */}
                        {myFavAgendaData?.length == 0 ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            {myFavAgendaData?.length != 0 &&
                              this.state.nextFavAgendaDate == ''
                              ? 'There are no sessions available today. Next sessions will start from' +
                              '' +
                              moment(this.state.nextFavAgendaDate).format(
                                'dddd, MMM, Do',
                              )
                              : 'There are no sessions available today'}
                          </Box>
                        ) : favAgendaDate &&
                          isCompleteFavAgenda &&
                          this.state.currFavDate == false ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            All sessions are completed for selected date{' '}
                            {moment(favAgendaDate).format(' MMM, Do YYYY')}
                          </Box>
                        ) : this.state.nextFDate &&
                          isCompleteFavAgenda &&
                          this.state.currFavDate ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            All the sessions are completed today. Next sessions
                            will start from{' '}
                            {moment(this.state.nextFDate).format(
                              ' MMM, Do YYYY',
                            )}
                          </Box>
                        ) : this.state.nextFavAgendaDate &&
                          isCompleteFavAgenda == false &&
                          this.state.currFavDate == false ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            There are no sessions available today. Next sessions
                            will start from{' '}
                            {moment(this.state.nextFDate).format(
                              ' MMM, Do YYYY',
                            )}
                          </Box>
                        ) : this.state.nextFDate == '' &&
                          isCompleteFavAgenda &&
                          this.state.currFavDate ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            All the sessions are completed today
                          </Box>
                        ) : isCompleteFavAgenda == true &&
                          this.state.currFavDate == false ? (
                          <Box
                            mb={3}
                            p={0.5}
                            sx={{
                              backgroundColor: '#009C7E',
                              textAlign: 'center',
                              fontSize: '12px',
                              color: '#ffffff',
                            }}
                          >
                            All sessions are completed
                          </Box>
                        ) : (
                          ''
                        )}
                        {/* agenda */}

                        {/* agenda new design*/}
                        {myFavAgendaData?.length > 0 &&
                          myFavAgendaData?.map((favList, id) => (
                            <Box
                              className="agenda-list"
                              p={{ xs: 2, sm: 2, xl: 3 }}
                              pt={{ xs: 3, sm: 3, xl: 4 }}
                              sx={{ borderBottom: '1px solid #EDEBFF' }}
                              key={id}
                            >
                              <Grid
                                colSpacing={4}
                                rowSpacing={2}
                                container
                                justifyContent={'space-between'}
                              >
                                <Grid
                                  order={{ xs: 1, md: 0 }}
                                  size={{xs: 12, md:8}}
                                  
                                >
                                  <Box
                                    component={Grid}
                                    container
                                    // mt={-1.5}
                                    ml={0}
                                    alignItems="center"
                                    // justifyContent={'space-between'}
                                    spacing={1}
                                  >
                                    <Stack
                                      direction={'row'}
                                      alignItems="center"
                                      spacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                      <Box
                                        className="small-subtitle bold"
                                        sx={{ color: '#F26135' }}
                                      >
                                        {moment(favList.dateTime).format(
                                          'dddd, MMM, Do YYYY, h:mm A',
                                        )}{' '}
                                        <span style={{ color: '#000056' }}>
                                          ({timeZone})
                                        </span>
                                      </Box>

                                      <Stack
                                        direction={'row'}
                                        alignItems="center"
                                        spacing={1}
                                        borderLeft="1px solid #9A92FF"
                                        borderRight="1px solid #9A92FF"
                                        px={2}
                                      >
                                        {new Date(favList.dateTime).getTime() +
                                          60 *
                                          60 *
                                          1000 *
                                          favList.duration.hours >
                                          new Date().getTime() && (
                                            <Box ml={1}>
                                              {/* add to calendar*/}
                                              {/*  <div
                                                title="Add to Calendar"
                                                class="addeventatc"
                                              >
                                                Add to Calendar
                                                <span class="start">
                                                  {moment(favList.dateTime)
                                                    .tz(favList.eventId.timezone)
                                                    .format(
                                                      'MM/DD/YYYY hh:mm:ss',
                                                    )}
                                                </span>
                                                <span class="end">
                                                  {moment(favList.dateTime)
                                                    .tz(favList.eventId.timezone)
                                                    .add(
                                                      favList.duration.hours,
                                                      'hours',
                                                    )
                                                    .format(
                                                      'MM/DD/YYYY hh:mm:ss',
                                                    )}
                                                </span>
                                                <span class="timezone">
                                                  {favList.eventId.timezone}
                                                </span>
                                                <span class="title">
                                                  {favList.title}
                                                </span>
                                                <span class="description">
                                                  {favList.description}
                                                </span>
                                                <span class="location">
                                                  {favList.location}
                                                </span>
                                              </div> */}
                                              <div title='Add to Calendar' class='addCalendarEvent'>
                                                <div class='hidden-area'>
                                                  <span class='start'>{moment(favList.dateTime).tz(favList.eventId.timezone).format('YYYY-MM-DD h:mm A')}</span>
                                                  <span class='end'>{moment(favList.dateTime).tz(favList.eventId.timezone).add(parseInt(favList.duration.hours), 'hours').add(parseInt(favList.duration.minutes), 'minutes').format('YYYY-MM-DD h:mm A')}</span>
                                                  <span class='title'>{favList.title}</span>
                                                  <span class='location'> {favList.location}</span>
                                                  <span class='description'>{favList.description}</span>
                                                  <span class='timezone'>{favList.eventId.timezone}</span></div>
                                                <div class='addToCalendar'>
                                                  <button class='addToCalendarButtonProfile'><span class='button-icon'></span>Add to Calendar</button>
                                                  <ul class='calendar-options'>
                                                    <li class='googleCalendar'><span class='google'>Google <em>(Online)</em></span></li>
                                                    <li class='appleleCalendar'><span class='apple'>Apple</span></li>
                                                    <li class='outlookComleCalendar'><span class='outlookcom'>Outlook.com <em>(Online)</em></span></li>
                                                    <li class='outlookCalendar'><span class='outlook'>Outlook</span></li>
                                                    {/* <li class='officeCalendar'><span class='office365'>Office 365 <em>(Online)</em></span></li> */}
                                                    <li class='yahooCalendar'><span class='yahoo'>Yahoo <em>(Online)</em></span></li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </Box>
                                          )}
                                        <Box>
                                          <IconButton
                                            size="small"
                                            aria-label="toggle favorite"
                                            onClick={() =>
                                              this.handleClickIsUnfavorite(
                                                favList._id,
                                                id,
                                                favList.dateTime,
                                              )
                                            }
                                            edge="end"
                                          >
                                            {setUnfavorite == false ? (
                                              <FavoriteIcon color="primary" />
                                            ) : (
                                              <FavoriteBorderIcon color="primary" />
                                            )}
                                          </IconButton>
                                        </Box>
                                      </Stack>

                                      <Box className="agenda-status">
                                        {' '}
                                        {new Date(favList.dateTime).getTime() +
                                          60 *
                                          60 *
                                          1000 *
                                          favList.duration.hours <
                                          new Date().getTime()
                                          ? 'Completed'
                                          : new Date(
                                            favList.dateTime,
                                          ).getTime() +
                                            60 *
                                            60 *
                                            1000 *
                                            favList.duration.hours <
                                            new Date().getTime() &&
                                            new Date().getTime() >
                                            new Date(
                                              favList.dateTime,
                                            ).getTime()
                                            ? 'In Progress'
                                            : new Date(
                                              favList.dateTime,
                                            ).getTime() > new Date().getTime()
                                              ? 'Not Started'
                                              : 'In Progress'}
                                      </Box>
                                    </Stack>
                                  </Box>

                                  <Box className="subtitle bolder">
                                    {favList.navigateTo.navigateType ==
                                      'projectPages' ? (
                                      <a
                                        href={`${window.location.origin
                                          }/${favList.navigateTo.pageId.path.toLowerCase()}`}
                                      >
                                        {favList.title}
                                      </a>
                                    ) : favList.navigateTo.agendaURL &&
                                      favList.navigateTo.navigateType ==
                                      'redirectExternalURL' ? (
                                      <a
                                        target="_blank"
                                        href={favList.navigateTo.agendaURL}
                                      >
                                        {favList.title}
                                      </a>
                                    ) : (
                                      favList.title
                                    )}
                                  </Box>

                                  <Grid container spacing={1}>
                                    <Box component={Grid} pr={3} >
                                      <Stack
                                        direction={'row'}
                                        alignItems="center"
                                        spacing={1.5}
                                      >
                                        <Box>
                                          <img
                                            alt="duration"
                                            src={DurationIcon}
                                          />
                                        </Box>
                                        <Box
                                          className="small-subtitle"
                                          pt={0.63}
                                        >
                                          {favList.duration.hours} hours
                                        </Box>
                                        <Box
                                          className="small-subtitle"
                                          pt={0.63}
                                        >
                                          {favList.duration.minutes} minutes
                                        </Box>
                                      </Stack>
                                    </Box>
                                    <Box component={Grid} pr={3} >
                                      {favList.location != '' ? (
                                        <Stack
                                          direction={'row'}
                                          alignItems="center"
                                          spacing={1.5}
                                        >
                                          <Box>
                                            <img
                                              alt="location"
                                              src={LocationIcon}
                                            />
                                          </Box>
                                          <Box
                                            pt={0.63}
                                            className="small-subtitle"
                                          >
                                            {favList.location}
                                          </Box>
                                        </Stack>
                                      ) : (
                                        ''
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                {favList.agendaImage && (
                                  <Grid size={{xs: 12, md:4}} >
                                    <Stack
                                      justifyContent={{
                                        xs: 'center',
                                        md: 'end',
                                      }}
                                      direction={'row'}
                                    >
                                      <img
                                        style={{
                                          maxWidth: '100%',
                                          maxHeight: '100px',
                                        }}
                                        alt="agenda-banner"
                                        src={`${IMAGE_URL}/agendaImages/${favList.agendaImage}`}
                                      />
                                    </Stack>
                                  </Grid>
                                )}

                                <Grid size={{xs: 12,}} order={2}>
                                  <Box mt={1} className="ql-snow primary-text">
                                    <Box className="ql-editor" p={0}>
                                      {parse(favList.description)}
                                    </Box>
                                  </Box>
                                  {favList?.speakers && (
                                    <Box
                                      component={Grid}
                                      container
                                      spacing={1}
                                      mt={1}
                                    // sx={{ borderTop: '1px solid #EDEBFF' }}
                                    >
                                      <Box
                                        component={Grid}
                                        size={{xs: 12,}}
                                        className="small-subtitle"
                                      >
                                        Speakers
                                      </Box>

                                      {favList?.speakers?.speakersList.map(
                                        (speak, id) => (
                                          <Grid
                                          size={{xs: 12, sm:6, md:3}}
                                            
                                            key={id}
                                          >
                                            <Grid
                                              container
                                              spacing={2}
                                              alignItems="center"
                                            >
                                              <Grid >
                                                <Avatar style={styles.speaker}>
                                                  {speak.imageUrl ? (
                                                    <img
                                                      alt=""
                                                      style={{
                                                        maxHeight: '48px',
                                                      }}
                                                      src={`${IMAGE_URL}/agendaImages/${speak.imageUrl}`}
                                                    />
                                                  ) : (
                                                    speak.firstName
                                                      .substring(0, 1)
                                                      .toUpperCase() +
                                                    speak.lastName
                                                      .substring(0, 1)
                                                      .toUpperCase()
                                                  )}
                                                </Avatar>
                                              </Grid>
                                              <Grid size={{xs: "grow",}}>
                                                <Box
                                                  component="p"
                                                  mb={0}
                                                  className="paragraph bold"
                                                >
                                                  {speak.firstName}{' '}
                                                  {speak.lastName}
                                                </Box>
                                                <Box
                                                  component="p"
                                                  mb={0}
                                                  className="small-subtitle"
                                                >
                                                  {speak.jobTitle}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ),
                                      )}
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        {/* agenda end */}
                      </Box>
                    </TabPanel>
                    <TabPanel style={styles.customTabPanel} value="4">
                      <Box
                        sx={{
                          // border: '1px solid #EDEBFF',
                          position: 'reletive',
                        }}
                      >
                        {/* filter */}
                        {Array.isArray(this.state.topBarAgenda) &&
                          this.state.topBarAgenda?.length > 0 ? (
                          <Box
                            px={{ xs: 2, sm: 3.5, xl: 5 }}
                            pt={{ xs: 1, sm: 1, xl: 1 }}
                            pb={{ xs: 2, sm: 3, xl: 4 }}
                            sx={{ borderBottom: '1px solid #EDEBFF' }}
                          >
                            <Grid
                              container
                              justifyContent={'space-between'}
                              alignItems="center"
                              spacing={2}
                              className="top-filter-area"
                            >
                              <Grid >
                                <Button
                                  variant="contained"
                                  size="small"
                                  color={setAll == true ? 'primary' : ''}
                                  onClick={() => this.seeAllAgendaDetails()}
                                >
                                  See All
                                </Button>
                              </Grid>
                              <Grid
                                size={{xs: 12,sm:'grow'}}
                                order={{ xs: 3, sm: 0 }}
                        
                                sx={{
                                  width: 'calc(100% - 72px - 200px - 16px)',
                                }}
                              >
                                <Carousel
                                  responsive={responsive}
                                  containerClass="agenda-filter"
                                  infinite={true}
                                  centerMode={true}
                                >
                                  {reduceDate?.length > 0 &&
                                    reduceDate?.map((aggendaDate, id) => (
                                      <Box
                                        component={Button}
                                        key={id}
                                        variant="contained"
                                        className={
                                          agendaData?.length > 0 &&
                                            setAll == false &&
                                            !this.state.isSearchingAgenda
                                            ? myAgendaDate &&
                                              moment(
                                                aggendaDate.dateTime,
                                              ).format('DD MMM YYYY') ==
                                              moment(
                                                new Date().toISOString(),
                                              ).format('DD MMM YYYY')
                                              ? 'date-btn active'
                                              : (this.state.selectedAgendaId ||
                                                this.state
                                                  .selectedAgendaId === 0) &&
                                                id ===
                                                this.state.selectedAgendaId
                                                ? 'date-btn active'
                                                : this.state.selectedAgendaId &&
                                                  this.state.selectedAgendaId ===
                                                  moment(
                                                    aggendaDate.dateTime,
                                                  ).format('DD MMM YYYY')
                                                  ? 'date-btn active'
                                                  : 'date-btn '
                                            : 'date-btn'
                                        }
                                        size="small"
                                        onClick={() =>
                                          this.dateFilter(
                                            aggendaDate.eventId,
                                            aggendaDate.dateTime,
                                            id,
                                          )
                                        }
                                      >
                                        <Stack>
                                          <Box className="date">
                                            {moment(
                                              aggendaDate.dateTime,
                                            ).format('D')}
                                          </Box>
                                          <Box className="month">
                                            {moment(
                                              aggendaDate.dateTime,
                                            ).format('MMM')}
                                          </Box>
                                        </Stack>
                                      </Box>
                                    ))}
                                </Carousel>
                              </Grid>
                              <Grid sx={{ width: '200px' }}>
                                <form onSubmit={this.searchAgendaDetail}>
                                  <TextField
                                    name="searchAgendaText"
                                    placeholder="Search..."
                                    className="search"
                                    size="small"
                                    onChange={this.handleChange}
                                    value={searchAgendaText}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img alt="" src={SearchIcon} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </form>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : null}
                        {/* filter end */}
                        {agendaData?.length < 1 ? (
                          this.state.isSearchingAgenda ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              There are no sessions available
                            </Box>
                          ) : (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              {!this.state.nextAgendaDate
                                ? 'There are no sessions available'
                                : 'There are no sessions available today. Next sessions will start from' +
                                ' ' +
                                moment(this.state.nextAgendaDate).format(
                                  'dddd, MMM, Do YYYY',
                                )}
                            </Box>
                          )
                        ) : !this.state.isSearchingAgenda ? (
                          agendaDate &&
                            isCompleteAgenda &&
                            this.state.currDate == false ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              All sessions are completed for selected date{' '}
                              {moment(agendaDate).format(' MMM, Do YYYY')}
                            </Box>
                          ) : this.state.nextADate &&
                            isCompleteAgenda &&
                            this.state.currDate ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              All the sessions are completed today. Next
                              sessions will start from{' '}
                              {moment(this.state.nextADate).format(
                                ' MMM, Do YYYY',
                              )}
                            </Box>
                          ) : this.state.nextADate &&
                            isCompleteAgenda == false &&
                            this.state.currDate == false ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              There are no sessions available today. Next
                              sessions will start from{' '}
                              {moment(this.state.nextADate).format(
                                ' MMM, Do YYYY',
                              )}
                            </Box>
                          ) : this.state.nextADate == '' &&
                            isCompleteAgenda &&
                            this.state.currDate ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              All the sessions are completed today
                            </Box>
                          ) : isCompleteAgenda == true &&
                            this.state.currDate == false ? (
                            <Box
                              mb={3}
                              p={0.5}
                              sx={{
                                backgroundColor: '#009C7E',
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#ffffff',
                              }}
                            >
                              All sessions are completed
                            </Box>
                          ) : (
                            ''
                          )
                        ) : null}
                        {/* agenda */}
                        {agendaData?.length > 0 &&
                          agendaData?.map((aggendaList, key) => (
                            <Box
                              className="agenda-list"
                              p={{ xs: 2, sm: 2, xl: 3 }}
                              pt={{ xs: 3, sm: 3, xl: 4 }}
                              key={key}
                            // sx={{ borderBottom: '1px solid #EDEBFF' }}
                            >
                              <Grid
                                colSpacing={4}
                                rowSpacing={2}
                                container
                                justifyContent={'space-between'}
                              >
                                <Grid
                                  order={{ xs: 1, md: 0 }}
                                  size={{xs: 12, md:8}}
                                >
                                  <Box
                                    component={Grid}
                                    container
                                    // mt={-1.5}
                                    ml={0}
                                    alignItems="center"
                                    // justifyContent={'space-between'}
                                    spacing={1}
                                  >
                                    <Stack
                                      direction={'row'}
                                      alignItems="center"
                                      spacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                      <Box
                                        className="small-subtitle bold"
                                        sx={{ color: '#F26135' }}
                                      >
                                        {moment(aggendaList.dateTime).format(
                                          'dddd, MMM, Do YYYY, h:mm A',
                                        )}{' '}
                                        <span style={{ color: '#000056' }}>
                                          ({timeZone})
                                        </span>
                                      </Box>
                                      <Stack
                                        direction={'row'}
                                        alignItems="center"
                                        spacing={1}
                                        borderLeft="1px solid #9A92FF"
                                        borderRight="1px solid #9A92FF"
                                        px={2}
                                      >
                                        <Box>
                                          {aggendaList.isFav == true && (
                                            <IconButton
                                              aria-label="toggle favorite"
                                              onClick={() =>
                                                this.handleClickIsFavorite(
                                                  aggendaList._id,
                                                  'removed',
                                                  aggendaList.isFav,
                                                  aggendaList.dateTime,
                                                )
                                              }
                                              edge="end"
                                              size="small"
                                            >
                                              <FavoriteIcon color="primary" />
                                            </IconButton>
                                          )}
                                          {aggendaList.isFav == false && (
                                            <IconButton
                                              size="small"
                                              aria-label="toggle favorite"
                                              onClick={() =>
                                                this.handleClickIsFavorite(
                                                  aggendaList._id,
                                                  'added',
                                                  aggendaList.isFav,
                                                  aggendaList.dateTime,
                                                )
                                              }
                                              edge="end"
                                            >
                                              <FavoriteBorderIcon color="primary" />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Stack>
                                      <Box className="agenda-status">
                                        {' '}
                                        {new Date(
                                          aggendaList?.dateTime,
                                        ).getTime() +
                                          60 *
                                          60 *
                                          1000 *
                                          aggendaList?.duration?.hours <
                                          new Date().getTime()
                                          ? 'Completed'
                                          : new Date(
                                            aggendaList?.dateTime,
                                          ).getTime() +
                                            60 *
                                            60 *
                                            1000 *
                                            aggendaList?.duration?.hours <
                                            new Date().getTime() &&
                                            new Date().getTime() >
                                            new Date(
                                              aggendaList?.dateTime,
                                            ).getTime()
                                            ? 'In Progress'
                                            : new Date(
                                              aggendaList.dateTime,
                                            ).getTime() > new Date().getTime()
                                              ? 'Not Started'
                                              : 'In Progress'}
                                      </Box>
                                    </Stack>
                                  </Box>

                                  <Box className="subtitle bolder">
                                    {aggendaList?.navigateTo?.navigateType ==
                                      'projectPages' ? (
                                      <a
                                        href={`${window.location?.origin
                                          }/${aggendaList?.navigateTo?.pageId?.path?.toLowerCase()}`}
                                      >
                                        {aggendaList?.title}
                                      </a>
                                    ) : aggendaList?.navigateTo?.agendaURL &&
                                      aggendaList?.navigateTo?.navigateType ==
                                      'redirectExternalURL' ? (
                                      <a
                                        target="_blank"
                                        href={
                                          aggendaList?.navigateTo?.agendaURL
                                        }
                                      >
                                        {aggendaList?.title}
                                      </a>
                                    ) : (
                                      aggendaList?.title
                                    )}
                                  </Box>

                                  <Grid container spacing={1}>
                                    <Box component={Grid} pr={3} >
                                      <Stack
                                        direction={'row'}
                                        alignItems="center"
                                        spacing={1.5}
                                      >
                                        <Box>
                                          <img
                                            alt="duration"
                                            src={DurationIcon}
                                          />
                                        </Box>
                                        <Box
                                          className="small-subtitle"
                                          pt={0.63}
                                        >
                                          {aggendaList?.duration?.hours} hours
                                        </Box>
                                        <Box
                                          className="small-subtitle"
                                          pt={0.63}
                                        >
                                          {aggendaList.duration.minutes} minutes
                                        </Box>
                                      </Stack>
                                    </Box>
                                    <Box component={Grid} pr={3} >
                                      {aggendaList.location != '' ? (
                                        <Stack
                                          direction={'row'}
                                          alignItems="center"
                                          spacing={1.5}
                                        >
                                          <Box>
                                            <img
                                              alt="duration"
                                              src={LocationIcon}
                                            />
                                          </Box>
                                          <Box
                                            pt={0.63}
                                            className="small-subtitle"
                                          >
                                            {aggendaList?.location}
                                          </Box>
                                        </Stack>
                                      ) : (
                                        ''
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                {aggendaList.agendaImage && (
                                  <Grid size={{xs: 12, md:4}} >
                                    <Stack
                                      justifyContent={{
                                        xs: 'center',
                                        md: 'end',
                                      }}
                                      direction={'row'}
                                    >
                                      <img
                                        style={{
                                          maxWidth: '100%',
                                          maxHeight: '100px',
                                        }}
                                        alt="agenda-banner"
                                        src={`${IMAGE_URL}/agendaImages/${aggendaList?.agendaImage}`}
                                      />
                                    </Stack>
                                  </Grid>
                                )}

                                <Grid size={{xs: 12,}} order={2}>
                                  <Box mt={1} className="ql-snow primary-text">
                                    <Box className="ql-editor" p={0}>
                                      {parse(aggendaList?.description)}
                                    </Box>
                                  </Box>
                                  {aggendaList?.speakers && (
                                    <Box
                                      component={Grid}
                                      container
                                      spacing={1}
                                      mt={1}
                                    // sx={{ borderTop: '1px solid #EDEBFF' }}
                                    >
                                      <Box
                                        component={Grid}
                                        size={{xs: 12,}}
                                        className="small-subtitle"
                                      >
                                        Speakers
                                      </Box>

                                      {aggendaList?.speakers?.speakersList.map(
                                        (speak, id) => (
                                          <Grid
                                          size={{xs: 12,sm:6,
                                            md:3}}
                                            
                                            key={id}
                                          >
                                            <Grid
                                              container
                                              spacing={2}
                                              alignItems="center"
                                            >
                                              <Grid >
                                                <Avatar style={styles.speaker}>
                                                  {speak.imageUrl ? (
                                                    <img
                                                      alt=""
                                                      style={{
                                                        maxHeight: '48px',
                                                      }}
                                                      src={`${IMAGE_URL}/agendaImages/${speak.imageUrl}`}
                                                    />
                                                  ) : (
                                                    speak.firstName
                                                      .substring(0, 1)
                                                      .toUpperCase() +
                                                    speak.lastName
                                                      .substring(0, 1)
                                                      .toUpperCase()
                                                  )}
                                                </Avatar>
                                              </Grid>
                                              <Grid size={{xs: "grow",}}>
                                                <Box
                                                  component="p"
                                                  mb={0}
                                                  className="paragraph bold"
                                                >
                                                  {speak.firstName}{' '}
                                                  {speak.lastName}
                                                </Box>
                                                <Box
                                                  component="p"
                                                  mb={0}
                                                  className="small-subtitle"
                                                >
                                                  {speak.jobTitle}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ),
                                      )}
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        {/* agenda end */}
                        </Box>
                    </TabPanel>
                    <TabPanel style={styles.customTabPanel} value="5">
                      {this.state.projectId && (
                        <Gamification
                          attendeeId={this.state.attendeeId}
                          projectId={this.state.projectId}
                          profilePic={profileImage}
                          attendee={attendeeEmail}
                          attendeeImage={this.state.i}
                        />
                      )}
                    </TabPanel>

                    {this.props.profile?.eventData?.isAccessCode && (
                      <TabPanel style={styles.customTabPanel} value="6">
                        {this.state.projectId && this.state.attendeeId && (
                          <AccessCodeMYProfile
                            attendeeId={this.state.attendeeId}
                            projectId={this.state.projectId}
                            attendeeEmail={attendeeEmail}
                          />
                        )}
                      </TabPanel>
                    )}
                  </TabContext>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Modal
            open={open}
            onClose={this.onCloseModal}
            center
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal change-profile-image',
            }}
          >
            <Grid component="form" container className="modal-wrapper">
              <Grid size={{xs: 12,}} mt={0}  pb={4}>
                <Box component="p" mb={0} className="subtitle">
                  Profile Picture
                </Box>
                <p className="small-subtitle">
                  Select the image you wish to upload and crop the image as per your needs. Only .jpg, .jpeg, .png files are accepted
                </p>
              </Grid>
              <ImgCrop
                profilePic={this.getData}
                pic={this.state.i}
                uploadImage={this.state.uploadImage}
                langState={this.props.users.languageStateForRedux}
              />
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      onClick={this.onCloseModal}
                      variant="contained"
                      // color="primary"
                      disableElevation
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.saveAttendeeProfile}
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      Save As Profile Picture
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          {/* confirmation modal */}
          <Modal
            open={this.state.openDeleteSessionModal}
            onClose={this.handleCloseOpenDeleteSessionModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Delete Confirmation!
                </Box>
                <p className="small-subtitle">
                  Are you sure you want to delete it?
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => this.deleteProfileImageOnClick()}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            this.handleCloseOpenDeleteSessionModal()
                          }
                        >
                          Cancel
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={this.state.backConfirmationModal}
            onClose={this.crossCloseModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Confirmation!
                </Box>
                <p className="small-subtitle">
                  Do you want to exit without saving?
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevationon
                          onClick={() => this.openBackModal()}
                        >
                          SAVE AND EXIT
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => this.closeBackModal()}
                        >
                          DISCARD CHANGES
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {loading ? <Loader /> : null}
        </ThemeProvider>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.Projects,
  profile: state.Profile,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccessCode: Actions.getAccessCodeRequest,
      assignAccessCodeProfile: Actions.assignAccessCodeProfileRequest,
      getCustomFieldsProfile: Actions.getCustomFieldsProfileRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(MyProfile));
