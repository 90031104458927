import React from "react";
import { Stack, Divider } from "@mui/material";
import NotificationItem from "./NotificationItem";

const notificationList = [
    {
      user: "Ajay Seth",
      avatar: "AS",
      type: "comment",
      date: "09/31/24",
      message: "commented on the design document.",
      details: "Updated the color scheme for the landing page design.",
      action: "Reply",
    },
    {
      user: "Soumya Khatri",
      avatar: "SK",
      type: "like",
      date: "09/30/24",
      message: "liked your presentation.",
      details: "The presentation on the project timeline was impressive!",
      action: "View",
    },
    {
      user: "Arjun Saini",
      avatar: "AS",
      type: "mention",
      date: "09/29/24",
      message: "mentioned you in a comment.",
      details: "Don't forget to review the backend architecture.",
      action: "View Comment",
    },
    {
      user: "Parvesh Kumar",
      avatar: "PK",
      type: "reply",
      date: "09/28/24",
      message: "replied to your query.",
      details: "The API endpoint will be updated by next week.",
      action: "Check Reply",
    },
  ];

export default function NotificationList() {
  return (
    <Stack
      sx={{
        overflow: "auto",
        maxHeight: "calc(100vh - 150px)",
        px: 1.5,
      }}
    >
      {notificationList.map((item, index) => (
        <React.Fragment key={index}>
          <NotificationItem notification={item} />
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}
