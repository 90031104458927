import React, { useState, useEffect } from 'react';
import { createNotification } from '../../helpers';
import { confirmAccountAPI } from '../../ReduxStore/API';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../sass/main.scss';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import Loader from '../../Components/Loader/loader';
import { generateUrl } from 'helpers/common';

const ConfirmAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // Extract email and code from query parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryEmail = searchParams.get('email');
        const queryCode = searchParams.get('code'); 
        if (queryEmail) setEmail(queryEmail);
        if (queryCode) setConfirmationCode(queryCode);
    }, [location.search]);

    const isValidForm = () => {
        let error = {};
        let formIsValid = true; 
        const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email === '' || email.trim().length === 0) {
            formIsValid = false;
            error['email'] = '*Please enter email.';
        }
        if (email && regexTest.test(email) === false) {
            formIsValid = false;
            error['email'] = '*Please enter a valid email address.';
        }
        if (confirmationCode === '' || confirmationCode.trim().length === 0) {
            formIsValid = false;
            error['code'] = '*Please enter confirmation code.';
        }
        setErrors(error);
        return formIsValid;
    };

    const handleConfirm = async () => {
        try {
            if (isValidForm()) {
                setLoading(true); // Start loading
                const data = {
                    email: email,
                    code: confirmationCode
                };
                const res = await confirmAccountAPI(data); 
                if (res.data.status === 200) {
                    setLoading(false); // Stop loading
                    createNotification('success', 'Account confirmed successfully. You can login now.');
                    navigate(generateUrl('/'));
                }else{
                    setLoading(false); // Stop loading
                    createNotification('error', res?.data?.message); 
                }
            }
        } catch (error) {
            setLoading(false); // Stop loading
            createNotification('error', 'Failed to confirm account. Please try again.');
        }
    }; 

    return (
        <AuthLayout>
            <Helmet>
                <title>Eijent | Confirm Account</title>
            </Helmet>
            <Box component="form" >
                <Card >
                    <Typography
                        component="h1"
                        variant="h1"
                        textAlign={'center'}
                        sx={{ width: '100%', }}
                    >
                        Confirm Account
                        {loading ? <Loader /> : null}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
                    >
                        <Typography variant='body2'>
                            Please enter the code we have sent on your email to Confirm your account.
                        </Typography>
                        <FormControl>
                            <TextField
                                error={errors.email}
                                helperText={errors.email}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                variant="outlined"
                                size="large"
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                error={errors.code}
                                helperText={errors.code}
                                id="code"
                                type="text"
                                name="confirmationCode"
                                placeholder="Confirmation Code"
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                                fullWidth
                                variant="outlined"
                                size="large"
                            />
                        </FormControl>



                    </Box>

                    {/* ---Button--- */}
                    <Stack spacing={2.5} alignItems={'center'}>
                        <Box>
                            <Button
                                size='large'
                                color='primary'
                                variant='contained'
                                onClick={handleConfirm}
                                endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                                sx={{
                                    justifyContent: 'space-between',
                                    marginTop: '16px',
                                    minWidth: '222px',
                                }}>Confirm</Button>
                        </Box>
                    </Stack>
                </Card>
            </Box>
        </AuthLayout>
    );
};

export default ConfirmAccount;
