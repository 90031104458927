import React, { useState } from "react";
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Checkbox, IconButton, TextField, InputAdornment, Chip } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';


import {
  ArrowDownIcon,
  AddNewIcon,
  PaginationArrowRightIcon,
  PaginationArrowLeftIcon,
  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIcon,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  UnCheckedIcon,
  CheckedIcon,
  CheckAllIcon,
  UnCheckAllIcon,
  DeleteIcon,
  CancelIcon,
  MoreIcon,
  SentWhiteIcon,

} from '../../components/CustomIcons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillToolbar/quill.scss';
import QuillToolbar from "./QuillToolbar/QuillToolbar";

export default function ComposeEmail() {
  const [cc, setCC] = useState('');
  const [bcc, setBcc] = useState('');
  const [value, setValue] = useState('');

  const handleOpenCC = () => {
    setCC(true);
  };
  const handleCloseCC = () => {
    setCC(false);
  };
  const handleOpenBCC = () => {
    setBcc(true);
  };
  const handleCloseBCC = () => {
    setBcc(false);
  };

  // Define toolbar modules with alignment and custom attachment handler
  const modules = {
    toolbar: {
      container: "#toolbar", // Attach the toolbar using the ID
    },
  };



  // const editorContainerStyle = {
  //   minHeight: "200px",
  //   height: '100%',
  //   border: "1px solid #ddd",
  //   borderRadius: "5px",
  //   padding: "10px",
  //   fontSize: "16px",
  //   fontFamily: "Arial, sans-serif",
  //   lineHeight: "1.5",
  //   color: "#333",

  //   '&>.ql-container':{
  //     border: 'none',
  //   }

  // };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  return (
    <Stack px={2.5} py={1} height={'100%'}>

      {/* --To-- */}
      <TextField
        name="Recipients"
        placeholder="Add recipients"
        variant="standard"
        fullWidth
        size="large"
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">
              <Box gap={1} display={'flex'} alignItems={'center'}>
                <Stack direction="row" spacing={1}>
                  <Chip label="ajyseth@softobiz.com" size="medium" variant="outlined" onDelete={handleDelete} />
                  <Chip label="soumyakhatri@softobiz.com" size="medium" variant="outlined" onDelete={handleDelete} />
                </Stack>
                <Button
                  variant="tag"
                  size="small"
                  sx={{
                    backgroundColor: '#FBE5FE',
                    '&:hover': {
                      backgroundColor: '#FBE5FE',
                    },
                    '&:active': {
                      backgroundColor: '#FBE5FE',
                    },
                  }}
                >
                  +10 more
                </Button>
              </Box>
            </InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <Box gap={0.75} display={'flex'} >
                  {!cc &&
                    <Button
                      onClick={handleOpenCC}
                      variant="tag"
                      size="small"
                      sx={{
                        backgroundColor: '#B8F3F5',
                        '&:hover': {
                          backgroundColor: '#B8F3F5',
                        },
                        '&:active': {
                          backgroundColor: '#B8F3F5',
                        },
                      }}
                    >
                      CC
                    </Button>
                  }

                  {!bcc && (
                    <Button
                      onClick={handleOpenBCC}
                      variant="tag"
                      size="small"
                      sx={{
                        backgroundColor: '#E6FDF3',
                        '&:hover': {
                          backgroundColor: '#E6FDF3',
                        },
                        '&:active': {
                          backgroundColor: '#E6FDF3',
                        },
                      }}
                    >
                      BCC
                    </Button>
                  )}
                </Box>
              </InputAdornment>
            ),
          },
        }}
      // value={recipients}
      // onChange={handleRecipientsChange}
      />

      {cc && (

        <TextField
          name="CC"
          placeholder="CC"
          variant="standard"
          fullWidth
          size="large"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCloseCC}
                    color="transparent"
                    size="small"
                    variant="contained"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        // value={cC}
        // onChange={handleCcChange}
        />
      )}

      {bcc && (
        <TextField
          name="BCC"
          placeholder="BCC"
          variant="standard"
          fullWidth
          size="large"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCloseBCC}
                    color="transparent"
                    size="small"
                    variant="contained"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        // value={bcC}
        // onChange={handleBccChange}
        />
      )}



      {/* ---subject--- */}
      <TextField
        name="Subject"
        placeholder="Subject"
        variant="standard"
        fullWidth
        size="large"
      // value={recipients}
      // onChange={handleRecipientsChange}
      />

      {/* ---Email body--- */}
      <Stack py={2} borderBottom={'1px solid #F4F6F7'} flexGrow={1} overflow={'auto'} >

        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
          placeholder="Write something amazing..."
          // style={editorContainerStyle}
        />


      </Stack>

      {/* ---Buttons--- */}
      <Stack py={1.5} direction={'row'} alignItems={'center'} spacing={1} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Button sx={{ borderRadius: '6px', }} variant="contained" color="primary" endIcon={<SentWhiteIcon />} >
            Send
          </Button>

          <QuillToolbar />


        </Box>

        <IconButton
          color="transparent"
          size="small"
          variant="contained" >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>


  );
}
