import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';

import {
  SearchIcon,
} from './CustomIcons';

// ---Search---

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  // marginLeft: theme.spacing(2),
  marginRight: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  height: '100%',
  // position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 0, 1.5, 2.5),
    // vertical padding + font size from searchIcon
    // paddingRight: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

export default function Search() {
  return (
    <SearchWrapper>

      <StyledInputBase
        placeholder="Search"
        size='large'
        inputProps={{ 'aria-label': 'search' }}
      />

      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    </SearchWrapper>
  );
}
