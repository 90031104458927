import React, { useState } from "react";
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup, Tab, Badge, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';

import {
  ArrowDownDarkIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
} from '../../components/CustomIcons';
import { TabContext, TabList, TabPanel } from "@mui/lab";

import SideTabs from "../Inbox/SideTabs";
import { Padding } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import EijentDashboard from "eijent";


const StyledTabList = styled(TabList)(({ theme }) => ({
  '&.MuiTabs-flexContainer': {
    justifyContent: 'space-between'
  },

}));
const StyledTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    height: 52,
    marginBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: '30px',
    paddingRight: '30px',
    transition: 'background-color, font-weight, 100ms ease-in',

    '&:hover': {
      backgroundColor: '#efefef',
      borderColor: '#efefef',
    },

    '&>.MuiTab-icon': {
      marginLeft: '14px',
    },

  },

  '&.Mui-selected': {
    backgroundColor: '#fff',
    paddingRight: '15px',
    '&>.MuiTypography-root': {
      fontWeight: '700',
    },
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
  },

}));
const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  '&.MuiTabPanel-root': {
    justifyContent: 'space-between'
  }

}));

interface PageList {
  name: string; // Ensure this is explicitly a string
  content: React.ReactNode | string;
  path: string;
}

const pageList: PageList[] = [
  { name: 'Overview', content: 'Overview', path: 'overview' },
  { name: 'Pages', content: 'Pages', path: 'pages'},
  { name: 'Inbox', content: <SideTabs />, path: 'emails' },
  { name: 'Tasks', content: 'Tasks', path: 'tasks'},
  { name: 'Opportunities', content: 'Opportunities', path: 'opportunities'},
  { name: 'Accounts', content: 'Accounts', path: 'accounts'},
  { name: 'Contacts', content: 'Contacts', path: 'contacts'},
  { name: 'Meetings', content: 'Meetings', path: 'meetings'},
  { name: 'Drive', content: 'Drive', path: 'drive'},
  { name: 'Team', content: 'Team', path: 'team'},
];



export default function PageTabs({children}) {
  console.log("==========PageTabs")

  // const location = useLocation();
  // const navigate = useNavigate();
  const [value, setValue] = React.useState("3");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
useParams
  // Get the current path from the URL and set the active tab
  // useEffect(() => {
  //   const currentPath = location.pathname.split('/').pop(); // Get the last part of the URL
  //   const currentTabIndex = emailCategory.findIndex(
  //     (category) => category.path === currentPath,
  //   );
  //   if (currentTabIndex !== -1) {
  //     setValue((currentTabIndex + 1).toString());
  //   }
  // }, [location.pathname, emailCategory]);

  return (
    // <EijentDashboard>
    <Box display={'flex'} flexDirection={'column'} flexGrow='1'
      sx={{
        backgroundColor: '#F4F6F7',
        overflow: 'auto',
        borderRadius: '8px',
        // maxHeight: 'calc(100vh - 142px)',
      }}
    >
      <Box className={'filters'} p={0.5}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box gap={0.5} display={'flex'}>
            <IconButton color="inherit" size="medium" ></IconButton>
            <Button color="inherit" size="medium" >Comic Con Experience</Button>
          </Box>

          <Box gap={0.5} display={'flex'} >
            <Button color="inherit" size="medium" >Filers</Button>
            <Button color="inherit" size="medium" >Customize</Button>
          </Box>
        </Stack>
      </Box>
      <TabContext value={value}>
        <StyledTabList
          TabIndicatorProps={{ sx: { height: '0' } }}
          variant="fullWidth"
          onChange={handleChange} 
          aria-label="Page tabs">
          {pageList.map((item, index) => (
            <StyledTab
            key={index}
              label={
                <Typography variant="body2" >
                  {item.name}
                </Typography>
              }
              icon={
                // value === `${index + 1}` &&
                <Box sx={{ width: 32, height: 32, backgroundColor: "#F4F4F4", borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ArrowDownDarkIcon />
                </Box>
              }
              iconPosition="end"
              value={`${index + 1}`} />
          ))}
        </StyledTabList>
        {/* {pageList.map((item, index) => ( */}
          <TabPanel
            value={value}
            sx={{
              p: 0,
              backgroundColor: '#fff',
              height: '100%',
              flexGrow: '1',
            }}
          >
            {/* {item.content} */}
            {children}
            {/* hi */}
          </TabPanel>
        {/* ))} */}
      </TabContext>
    </Box>
    // </EijentDashboard>
  );
}
