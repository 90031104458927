import React, { useEffect, useRef, useState } from 'react';
import {
  Popover,
  Box,
  Stack,
  Checkbox,
  IconButton,
  Badge,
  TableFooter,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import {
  StartIconOutlined,
  StartIcon,
  AttachmentIcon,
  // MoreIcon,
} from '../../components/CustomIcons';
//@ts-ignore
import MoreIcon from '../../../../src/assets/images/icons/more.svg';

import { GmailData, Message } from './SideTabs';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import {
  xpAccountIntegrationGmailArchiveEmail,
  xpAccountIntegrationGmailDeleteEmail,
  xpAccountIntegrationGmailMarkEmailAsRead,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailMarkEmailAsBookmarked,
  xpAccountIntegrationGmailRestoreTrashMails,
  xpAccountIntegrationGmailUnBookmarkMultipleEmails,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import { useNavigate } from 'react-router';
import { generateUrl } from 'helpers/common';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import moment from 'moment';
import Loader from 'Components/Loader/loader';

type Props = {
  selectedCheckboxes: string[];
  setSelectedCheckboxes: React.Dispatch<React.SetStateAction<string[]>>;
  gmailData: GmailData;
  getGmailData: (
    limit?: number,
    page_no?: number,
    showLoader?: boolean,
  ) => Promise<void>;
  gmailId: string;
  setGmailData: React.Dispatch<React.SetStateAction<GmailData>>;
  mailType: 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | '';
  setLabelIdsArray: React.Dispatch<React.SetStateAction<string[]>>
  redirectToGmailView: (threadId: string, index: any) => void
};

export default function List({
  selectedCheckboxes,
  setSelectedCheckboxes,
  gmailData,
  getGmailData,
  gmailId,
  setGmailData,
  mailType,
  setLabelIdsArray,
  redirectToGmailView
}: Props) {
  const navigate = useNavigate();  

  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);

  // const [
  //   openPermanentDeleteConfirmationModel,
  //   setOpenPermanentDeleteConfirmationModel,
  // ] = useState<boolean>(false);

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mailId: string,
    labelArray: string[],
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setLabelIdsArray((prev) => [...prev, ...labelArray]);
      setSelectedCheckboxes((prev) => [...prev, mailId]);
    } else {
      setLabelIdsArray((prev) => filterOnce(prev, labelArray));
      setSelectedCheckboxes((prev) => prev.filter((id) => id !== mailId));
    }
  };

  const filterOnce = (source, toRemove) => {
    const toRemoveCount = {};
    toRemove.forEach((item) => {
      toRemoveCount[item] = (toRemoveCount[item] || 0) + 1;
    });
    return source.filter((item) => {
      if (toRemoveCount[item]) {
        toRemoveCount[item]--;
        return false; // Remove this occurrence
      }
      return true; // Keep this item
    });
  };

  //The following function may be required later

  // const handleDeleteSingle = async (messageId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const data = {
  //       messageId: messageId,
  //       gmailId: gmailId,
  //     };
  //     const res: any = await xpAccountIntegrationGmailDeleteEmail(data);
  //     if (res.data.success) {
  //       getGmailData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setOpenDeleteConfirmationModel(false);
  //     setSelectedCheckboxes([]);
  //     setIsLoading(false);
  //   }
  // };

  // const handleArchiveEmail = async (messageId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const data = {
  //       messageId: messageId,
  //       gmailId: gmailId,
  //     };
  //     const res: any = await xpAccountIntegrationGmailArchiveEmail(data);
  //     if (res.data.success) {
  //       getGmailData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setOpenDeleteConfirmationModel(false);
  //     setSelectedCheckboxes([]);
  //     setIsLoading(false);
  //   }
  // };

  // const handleMarkEmailAsRead = async (messageId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const data = {
  //       messageId: messageId,
  //       gmailId: gmailId,
  //     };
  //     const res: any = await xpAccountIntegrationGmailMarkEmailAsRead(data);
  //     if (res.data.success) {
  //       getGmailData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setOpenDeleteConfirmationModel(false);
  //     setSelectedCheckboxes([]);
  //     setIsLoading(false);
  //   }
  // };
  // const handleMarkEmailAsUnread = async (messageId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const data = {
  //       messageId: messageId,
  //       gmailId: gmailId,
  //     };
  //     const res: any = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
  //     if (res.data.success) {
  //       getGmailData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setOpenDeleteConfirmationModel(false);
  //     setSelectedCheckboxes([]);
  //     setIsLoading(false);
  //   }
  // };

  // const closeDeleteConfirmationModel = () => {
  //   setOpenDeleteConfirmationModel(false);
  // };

  // const closePermanentDeleteConfirmationModel = () => {
  //   setOpenPermanentDeleteConfirmationModel(false);
  // };


  const renderSendersName = (gmail: { messages: Message[] }) => {
    let existingSendersNames = [];
    let existingSendersEmails = [];
    gmail.messages.forEach((message: any) => {
      const fromHeader = message.payload.headers.find(
        (header: any) => header.name === 'From',
      );

      if (!fromHeader?.value) return null;

      // Extract name and email
      const nameOnly = fromHeader.value.replace(/<.*?>/g, '').trim();
      !existingSendersNames.includes(nameOnly) &&
        existingSendersNames.push(nameOnly);

      const emailOnly = fromHeader.value.match(/<([^>]+)>/)?.[1] || '';
      !existingSendersEmails.includes(emailOnly) &&
        existingSendersEmails.push(emailOnly);
    });

    return (
      <CustomHTMLTooltip
        placement="bottom-start"
        interactive
        arrow
        title={
          <Box className="tooltip-popup" width={200}>
            {existingSendersEmails
              .map((email) => (email === '' ? gmailId : email))
              .join(', ')}
          </Box>
        }
      >
        {existingSendersNames
          .map((name) => (name === gmailId ? 'me' : name))
          .join(', ')}
      </CustomHTMLTooltip>
    );
  };

  const markLastEmailAsBookmarked = async (messageId: string) => {
    try {
      setGmailDataAfterBookmarkingEmail(messageId);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailMarkEmailAsBookmarked(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            !message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, 'STARRED'],
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const restoreTrashMails = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailRestoreTrashMails(data);
      if (res.status == 200) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const renderAttachmentCount = (gmail) => {
    let count = 0;
    gmail.messages.forEach((message) => {
      if (
        message?.payload.mimeType === 'multipart/mixed' &&
        message?.payload.parts &&
        Array.isArray(message?.payload.parts) &&
        message?.payload?.parts?.length > 1
      ) {
        count += message?.payload?.parts?.length - 1;
      }
    });
    if (count > 0) {
      return (
        <Badge color="primary" badgeContent={count}>
          <AttachmentOutlinedIcon />
        </Badge>
      );
    } else {
      return null;
    }
  };

  const redirectToCompose = (mail: Message) => {
    const subjectDetail = mail?.payload?.headers.find(
      (detail) => detail.name === 'Subject',
    );
    const sendTo = mail?.payload?.headers.find(
      (detail) => detail.name === 'To',
    );
    const { payload } = mail;
    let payloadForDraft = payload;
    let messageIdForDraft = mail.id;
    let draftId = mail.draftId;
    let subject = subjectDetail?.value;
    let recipients = sendTo?.value;
    let editorText = mail?.snippet;

    const composeState = {
      payloadForDraft,
      messageIdForDraft,
      draftId,
      subject,
      recipients,
      editorText,
    };

    navigate(generateUrl(`/emails/drafts/compose/${mail.draftId}`), {
      state: composeState,
    });
  };

  const unBookmarkMultipleEmails = async (messages) => {
    try {
      const messageIds = messages
        .filter((message) => message.labelIds.includes('STARRED'))
        .map((message) => message.id);

      setGmailDataAfterUnbookmarkingMultipleEmails(messageIds);
      const data = {
        messageIds: messageIds,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailUnBookmarkMultipleEmails(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterUnbookmarkingMultipleEmails = (messageIds) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            messageIds.includes(message.id) &&
            message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter((label) => label !== 'STARRED'),
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Email List">
          <TableBody>
            {gmailData?.data ? (
              gmailData?.data.map(
                (
                  gmail: {
                    _id: string;
                    email: string;
                    messages: Message[];
                  },
                  index: number,
                ) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': { backgroundColor: '#E6FDF4' },
                      px: 2.25,
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: gmail.messages.some((message) =>
                        message.labelIds.includes('UNREAD'),
                      )
                        ? '#edecf5'
                        : '#fff',
                    }}
                  >
                    <TableCell sx={{ width: '44px', pl: 4 }}>
                      <Checkbox
                        onClick={(e) => {
                          handleSelectCheckBox(
                            e,
                            gmail.messages[0]?.threadId,
                            gmail.messages[0]?.labelIds,
                          );
                        }}
                        checked={selectedCheckboxes.includes(
                          gmail.messages[0]?.threadId,
                        )}
                      />
                    </TableCell>
                    {mailType !== 'TRASH' && (
                      <TableCell sx={{ width: '44px' }}>
                        
                        <IconButton
                          title="Bookmark"
                          onClick={() =>
                            gmail.messages.some((message) =>
                              message.labelIds.includes('STARRED'),
                            )
                              ? unBookmarkMultipleEmails(gmail.messages)
                              : markLastEmailAsBookmarked(
                                  gmail.messages.at(-1).id,
                                )
                          }
                          size='small'
                          // @ts-ignore
                          color='transparent'
                          variant='contained'
                        >
                          {gmail.messages.some((message) =>
                            message.labelIds.includes('STARRED'),
                          ) ? (
                            <StartIcon />
                          ) : (
                            <StartIconOutlined />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell
                      className={` ${
                        gmail.messages[0]?.labelIds?.includes('UNREAD')
                          ? 'bold'
                          : ''
                      }`}
                      onClick={() =>
                        mailType === 'DRAFT'
                          ? redirectToCompose(gmail.messages[0])
                          : redirectToGmailView(
                              gmail.messages[0].threadId,
                              index,
                            )
                      }
                    >
                      {mailType === 'SENT' ? (
                        <span>
                          {
                            gmail?.messages[0]?.payload.headers.find(
                              (header: any) => header.name === 'To',
                            )?.value
                          }
                        </span>
                      ) : mailType !== 'DRAFT' ? (
                        renderSendersName(gmail)
                      ) : (
                        <span>
                          {
                            gmail?.messages[0]?.payload.headers.find(
                              (header: any) => header.name === 'From',
                            )?.value
                          }
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        mailType === 'DRAFT'
                          ? redirectToCompose(gmail.messages[0])
                          : redirectToGmailView(
                              gmail.messages[0].threadId,
                              index,
                            )
                      }
                    >
                      <Stack
                        direction={'row'}
                        className="email-title"
                        spacing={1}
                      >
                        <Box
                          className="long-text"
                          style={{ maxWidth: '100%' }}
                          onClick={
                            () =>
                              mailType === 'DRAFT'
                                ? redirectToCompose(gmail.messages[0])
                                : // viewDraftMailsInCompose(gmail.messages[0])
                                  redirectToGmailView(
                                    gmail.messages[0].threadId,
                                    index,
                                  )
                            // viewGmailContent(gmail.messages, index)
                          }
                        >
                          <span
                            className={`${
                              gmail.messages[0]?.labelIds.includes('UNREAD')
                                ? 'bold'
                                : ''
                            }`}
                          >
                            {
                              gmail.messages[0]?.payload.headers.find(
                                (g: any) => g.name === 'Subject',
                              ).value
                            }{' '}
                          </span>{' '}
                          -{' '}
                          {gmail.messages[gmail.messages.length - 1]?.snippet
                            .length > 80
                            ? `${gmail.messages[
                                gmail.messages.length - 1
                              ]?.snippet.slice(0, 80)}...`
                            : gmail.messages[gmail.messages.length - 1]
                                ?.snippet}
                        </Box>
                        {renderAttachmentCount(gmail)}
                        {/* {gmail.messages[0]?.payload.mimeType ===
                            'multipart/mixed' &&
                            gmail.messages[0]?.payload.parts &&
                            Array.isArray(gmail.messages[0]?.payload.parts) &&
                            gmail.messages[0]?.payload?.parts?.length > 1 && (
                              <Badge
                                color="primary"
                                badgeContent={
                                  gmail.messages[0]?.payload?.parts?.length - 1
                                }
                              >
                                <AttachmentOutlinedIcon />
                              </Badge>
                            )
                          } */}
                      </Stack>
                    </TableCell>
                    <TableCell
                      className=""
                      style={{ minWidth: '90px', maxWidth: '90px' }}
                    >
                      {moment(
                        gmail.messages[0]?.payload.headers.find(
                          (g: any) => g.name === 'Date',
                        ).value,
                      ).format('MMM, DD')}
                    </TableCell>
                    {/* may need later */}
                    {/* <TableCell
                      className="options"
                      style={{ minWidth: '50px', maxWidth: '50px' }}
                    >
                      {' '}
                      <div className="options-button">
                        <img alt="" src={MoreIcon} />{' '}
                      </div>
                      <div className="options-list" style={{ right: '10px' }}>
                        {mailType !== 'TRASH' && (
                          <div
                            onClick={() =>
                              handleArchiveEmail(gmail.messages[0]?.threadId)
                            }
                          >
                            Archive
                          </div>
                        )}
                        <div
                          onClick={() =>
                            gmail.messages[0]?.labelIds.includes('UNREAD')
                              ? handleMarkEmailAsRead(
                                  gmail.messages[0]?.threadId,
                                )
                              : handleMarkEmailAsUnread(
                                  gmail.messages[0]?.threadId,
                                )
                          }
                        >{`Mark as ${
                          gmail.messages[0]?.labelIds.includes('UNREAD')
                            ? 'read'
                            : 'unread'
                        }`}</div>
                        <hr />
                        <div
                          style={{ color: '#ff4170' }}
                          onClick={() =>
                            handleDeleteSingle(gmail.messages[0]?.threadId)
                          }
                        >
                          Delete
                        </div>
                      </div>
                    </TableCell> */}
                  </TableRow>
                ),
                // StartIconOutlined
                // StartIcon
              )
            ) : (
              <TableRow style={{ cursor: 'pointer' }}>
                <TableCell sx={{ textAlign: 'center' }} colSpan={5}>
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {isLoading && <Loader />}
    </Box>
  );
}
