import React, { useEffect, useState } from 'react';
// import '../sass/main.scss';
import illumeetLogo from '../assets/images/dashboardLogo.png';

import PieChart from '../assets/images/icons/reports.svg';

import { ReactComponent as UpgradeIcon } from '../assets/images/icons/lock.svg';
import RegisteredUsericon1 from '../assets/images/icons/audience.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Url } from '../constants/constants';
import { ADMIN_COADMIN_ROLES } from '../config/config';
import { connect } from 'react-redux';
import CustomTooltip from '../Components/CustomHTMLTooltip';
import { Stack, Box, Tooltip } from '@mui/material';
import { Avatar, ClickAwayListener, Fade, Popper } from '@mui/material';
import { theme } from 'theme/theme';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import logOutIcon from '../assets/images/icons/logOutIcon-white.svg';
import { generateUrl, languageCode } from '../helpers/common';
import packageJson from '../../package.json';
import ChangelogPopup from './ChangelogPopup';
import { useSelector } from 'react-redux';

function getPreparePermission(state) {
  let perms = {};
  if (Array.isArray(state)) {
    state.forEach((data) => {
      perms[data.permission_name] = true;
    });
  }
  return perms;
}

 function logout() {
  let store = ['user_role', 'user_details'];
  store.forEach((item) => localStorage.removeItem(item));
   
  //window.location.href = Url.wixUrl;
  window.location.href = '/xp';
} 

  


const Sidebar = (props) => {
  const location = useLocation();
  const userData = localStorage.getItem('user_details');
  const user = JSON.parse(userData);
  const [openLanguages, setOpenLanguages] = useState(false);
  const initialArrayOfLangs = ['en', 'es', 'ro'];
  const [selectedLang, setSelectedLang] = useState('en');
  const anchorRefLanguage = React.useRef(null);
  const releaseVersion = packageJson.version;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allowed, setAllowed] = useState([]);
  const [opportunity, setOpportunity] = useState(generateUrl('/opportunity/opportunity'));

  const anchorRefAccount = React.useRef(null);
  const [openAccount, setOpenAccount] = useState(false);
  const xpGmailIntegrationState = useSelector(store => store.XpGmailIntegration);

  useEffect(() => {
    setAllowed(user?.systemLevelPermissions);
    getUserDetailsRequest();
    setLanguage();
    setSelectedLang(user?.language);
    props.newLangStateForRedux(user?.language);
  }, []);

  useEffect(() => {
    // Define paths with the generateUrl function
    const opportunityPaths = {
      viewOpportunity: generateUrl('/opportunity/opportunity'),
      viewAccount: generateUrl('/opportunity/accounts'),
      viewContacts: generateUrl('/opportunity/contacts'),
    };

    // Find the permission key
    const permissionKey = allowed.find(key => opportunityPaths[key]);

    if (permissionKey) {
      setOpportunity(opportunityPaths[permissionKey]);
    }
  }, [allowed]);

  const getUserDetailsRequest = () => {
    let userDetails = props.getUserDetailsRequest();
  };

  const setLanguage = () => {
    props.handleLang(languageCode(user?.language));
  };

  let permData = getPreparePermission(user?.permissions);
  let isExists = {};
 
  if (permData) {
    Object.keys(permData).forEach((data) => {
      if (['roles & permission'].indexOf(data.toLowerCase()) !== -1) {
        isExists['roles & permission'] = true;
      }
      if (['viewOpportunity'].indexOf(data) !== -1) {
        isExists['viewOpportunity'] = true;
      }

      if (['viewChatWidget'].indexOf(data) !== -1) {
        isExists['viewChatWidget'] = true;
      }
    });
  }

  let showAudience = false;
  let showAiAgent = false;
  let showOpportunity = false;
  if (ADMIN_COADMIN_ROLES.indexOf(user?.role) > -1) {
    showAudience = true;
    showAiAgent = true;
    showOpportunity = true;
  } else {
    if (user?.systemLevelPermissions) {
      if (user?.systemLevelPermissions.indexOf('View Audience') > -1) {
        showAudience = true;
      }
      if (user?.systemLevelPermissions.indexOf('viewChatWidget') > -1) {
        showAiAgent = true;
      }
      if (user?.systemLevelPermissions.indexOf('viewOpportunity') > -1) {
        showOpportunity = true;
      }
      if (user?.systemLevelPermissions.indexOf('viewAccount') > -1) {
        showOpportunity = true;
      }
      if (user?.systemLevelPermissions.indexOf('viewContacts') > -1) {
        showOpportunity = true;
      }
    }
  }


  var myEvents = generateUrl('/my-events');
var contact = generateUrl('/contact');
var audiencebkp = generateUrl('/audience-contacts');
var audience = generateUrl('/audience');
var analytics = generateUrl('/analytics');
var agenda = generateUrl('/agenda');
var eventRegisteredUser = generateUrl('/event-registered-user');
var aiChatbot = generateUrl('/aichatbot');

// For non-member-specific routes
var settings = generateUrl('/users');
var Projects = generateUrl('/Projects');
var report = generateUrl('/report');
var integrations = generateUrl('/integrations');
var emails = generateUrl('/emails/inbox');
var files = generateUrl('/files/home');


  const isAllowedFromServicePlan = (module) => {
    if (props.servicePlan?.findIfAllowedFromSuperadminSuccess) {
      let isAllowed = props.servicePlan?.servicePlanDetails?.[module];
      if (!isAllowed) {
        return (
          <CustomTooltip
            interactive={true}
            title={
              <Box className="upgrade-tooltip">
                <h5>Upgrade your Plan</h5>
                <p>
                  This feature is not a part of you current plan. <br />
                  <a
                    href="https://www.illumeetxp.com/upgrade?location=campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  for more info
                </p>
              </Box>
            }
            placement="right"
            arrow={true}
          >
            <span className="upgrade">
              <UpgradeIcon />
            </span>
          </CustomTooltip>
        );
      }
    }
  };

  const styles = {
    userAvatar: {
      backgroundColor: '#FF4170',
      color: '#ffffff',
      width: '40px',
      height: '40px',
      fontSize: '14px',
    },
    userAvatarMobile: {
      backgroundColor: '#FF4170',
      color: '#ffffff',
      width: '28px',
      height: '28px',
      fontSize: '14px',
    },
  };

  const openProfile = () => {
    window.open('https://www.illumeetxp.com/account/my-account', '_blank');
  };

  const handleLanguagesToggle = () => {
    setOpenLanguages((prevOpen) => !prevOpen);
  };

  const handleAccountToggle = () => {
    setOpenAccount((prevOpen) => !prevOpen);
  };

  const AccountHandleClose = (event) => {
    setOpenAccount(false);
  };
  const languageHandleClose = (event) => {
    setOpenLanguages(false);
  };

  const handleVersionClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const selectLang = (e) => {
    try {
      setSelectedLang(e);

      let user_details = JSON.parse(localStorage.getItem('user_details'));
      user_details.language = e;
      localStorage.setItem('user_details', JSON.stringify(user_details));
      props.changeLangForUserRequest({ lngCode: e });
    } catch (error) {
      return error;
    }
  };

  const returnLangName = (data) => {
    switch (data) {
      case 'en':
        return 'English';

      case 'es':
        return 'Spanish';

      case 'ro':
        return 'Romanian';

      default:
        return 'English';
    }
  };

  return (
    <>
      {/* --Destop menu-- */}
      <Box
        component="div"
        display={{ xs: 'none', sm: 'block' }}
        className="sidebarCompo"
      >
        <Box className="sidebarMainScreen">
          <div className="sidebarBrand">
            <Link to={myEvents}>
              <img src={illumeetLogo} alt="illumeet Logo" />
            </Link>
            {/* <a href=""><span style={{color:'#fff', fontWeight:'bold'}}>Ver : {releaseVersion}</span></a> */}

            <p onClick={handleVersionClick} style={{ cursor: 'pointer', color: '#fff', marginTop: '8px' }}>
              {releaseVersion}
            </p>

          </div>
          <div className="navigationMenu">
            <ul className="navbar-nav accordion" id="accordionSidebar">
            <li className={location.pathname ===  generateUrl('/my-events') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={myEvents}>
                  <div className="sidebar-icon sidebar-projects"></div>
                  <span className="navMenus">Projects</span>
                </Link>
              </li>
              {showOpportunity && (
                <li
                  className={
                    location.pathname === generateUrl('/opportunity/accounts') ||
                      location.pathname === generateUrl('/opportunity/opportunity') ||
                      location.pathname === generateUrl('/opportunity/contacts')
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link className="nav-link" to={props.servicePlan?.servicePlanDetails?.addOpportunityManagementXP && opportunity}>
                    <div className="sidebar-icon sidebar-opportunity"></div>
                    <span className="navMenus">Opportunity Management</span>
                    {isAllowedFromServicePlan('addOpportunityManagementXP')}
                  </Link>
                </li>
              )}
              {showAiAgent && (
                <li
                  className={
                    location.pathname === generateUrl('/aichatbot')
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link className="nav-link" to={props.servicePlan?.servicePlanDetails?.addAiAgentXP && aiChatbot}>
                    <div className="sidebar-icon sidebar-ai-chat"></div>
                    <span className="navMenus">AI Agent</span>
                    {isAllowedFromServicePlan('addAiAgentXP')}
                  </Link>
                </li>
              )}
              <li className={location.pathname === generateUrl('/report') ? 'nav-item active' : 'nav-item'}>
                <Link
                  disabled
                  className="nav-link"
                  to={
                    props.servicePlan?.servicePlanDetails?.addReportsXP &&
                    report
                  }
                >
                  <div className="sidebar-icon sidebar-reports"></div>

                  <span className="navMenus">Reports</span>
                  {isAllowedFromServicePlan('addReportsXP')}
                </Link>
              </li>

              <li
              >
                <Link className="nav-link" to={props.servicePlan?.servicePlanDetails?.addFileManagementXP && files}>
                  <div className="sidebar-icon sidebar-files"></div>
                  <span className="navMenus">Drive</span>
                  {isAllowedFromServicePlan('addFileManagementXP')}
                </Link>
              </li> 
              
              {((isExists && isExists['roles & permission']) || (user?.role == 1)) && (
                <li className={location.pathname === generateUrl('/users') || location.pathname === generateUrl('/settings') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to={settings}>
                    <div className="sidebar-icon sidebar-setings"></div>
                    <span className="navMenus">Settings</span>
                  </Link>
                </li>
              )}

              {showAudience && (
                 <li className={location.pathname === generateUrl('/audience') ? 'nav-item active' : 'nav-item'}>
                  <Link
                    className="nav-link"
                    to={
                      props.servicePlan?.servicePlanDetails?.addAudienceXP &&
                      audience
                    }
                  >
                    <div className="sidebar-icon sidebar-audience"></div>

                    <span className="navMenus">Audience</span>
                    {isAllowedFromServicePlan('addAudienceXP')}
                  </Link>
                </li>
              )}
              <Tooltip arrow placement='right' title={`${!xpGmailIntegrationState.isGmailConnected && "Emails are not integrated"}`}>
              <li className={location.pathname === generateUrl('/emails') ? 'nav-item active' : 'nav-item'}>
                  <Link
                    className={`nav-link ${!xpGmailIntegrationState.isGmailConnected && "disabled"}`}
                    to={emails}
                  >
                    <div className="sidebar-icon sidebar-emails "></div>
                    <span className="navMenus">Emails</span>
                  </Link>
                </li>
              </Tooltip>
            </ul>
            <Stack className="ul navbar-nav accordion">
              <Box sx={{ marginBottom: '0 !important' }} className="li">
                <ul className="navbar-nav accordion" id="accordionSidebar">
                  <li
                    className={openLanguages ? 'nav-item active' : 'nav-item'}
                    ref={anchorRefLanguage}
                    aria-controls={openLanguages ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleLanguagesToggle}
                  >
                    <Box>
                      <Box component="a" className="nav-link">
                        <div className="choose-language"></div>
                        <span className="navMenus">Language</span>
                      </Box>

                      <Popper
                        open={openLanguages}
                        className="language-options"
                        style={{ zIndex: 99 }}
                        placement="right-start"
                        transition
                        anchorEl={anchorRefLanguage.current}
                        role={undefined}
                        disablePortal={false}
                        modifiers={{
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                          },
                        }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={{
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '8px',
                                marginLeft: '10px',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={languageHandleClose}
                              >
                                <Box className="language-selector">
                                  <ul>
                                    {initialArrayOfLangs.map((option) => (
                                      <li
                                        key={option}
                                        onClick={() => selectLang(option)}
                                        className={
                                          selectedLang === option
                                            ? 'selected'
                                            : ''
                                        }
                                      >
                                        {returnLangName(option)}
                                      </li>
                                    ))}
                                  </ul>
                                </Box>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </Box>
                  </li>
                </ul>
              </Box>

              <Box sx={{ marginBottom: '20px !important' }} className="li">
                <ul className="navbar-nav accordion" id="accordionSidebar">
                  <li
                    className={openAccount ? 'nav-item active' : 'nav-item'}
                    ref={anchorRefAccount}
                    aria-controls={openAccount ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleAccountToggle}
                  >
                    <Box py={1}>
                      <Box component="a" className="nav-link" >
                        <Avatar style={styles.userAvatar}>
                          {props?.Users?.userDetails?.firstName
                            ?.charAt(0)
                            .toUpperCase()}
                          {props?.Users?.userDetails?.lastName
                            ?.charAt(0)
                            .toUpperCase()}
                        </Avatar>
                      </Box>
                      <Popper
                        open={openAccount}
                        className="language-options"
                        style={{ zIndex: 99 }}
                        placement="right-end"
                        transition
                        anchorEl={anchorRefAccount.current}
                        role={undefined}
                        disablePortal={false}
                        modifiers={{
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                          },
                        }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={{
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '8px',
                                marginLeft: '10px',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={AccountHandleClose}
                              >
                                <Box className="language-selector">
                                  <ul>
                                    <li
                                    // onClick={() => selectLang(option)}
                                    // className={
                                    //   selectedLang === option
                                    //     ? 'selected'
                                    //     : ''
                                    // }

                                    >
                                      <Link to={integrations}>
                                        Integrations
                                      </Link>
                                    </li>
                                    <li onClick={() => openProfile()} >
                                      My Account
                                    </li>
                                    <li onClick={() => logout()} >
                                      Logout
                                    </li>
                                  </ul>
                                </Box>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </Box>
                  </li>
                </ul>
              </Box>
              {/* <Box className="li">
                <Link
                  className="nav-link"
                  onClick={() => {
                    logout();
                  }}
                >
                  <span className="navMenus d-block">Logout</span>
                </Link>
              </Box> */}
            </Stack>
          </div>
        </Box>
      </Box>

      {/* --Mobile menu-- */}
      <Box
        component="div"
        display={{ xs: 'block', sm: 'none' }}
        className="mobile-menu"
      >
        <Box className="mobile-header">
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Link to={myEvents}>
                <img
                  className="mobile-logo"
                  src={illumeetLogo}
                  alt="illumeet Logo"
                />
              </Link>
            </Box>
            <Stack
              alignItems={'center'}
              spacing={2}
              direction={'row'}
              className="ul navbar-nav accordion"
            >
              <Box sx={{ marginBottom: '0 !important' }} className="li">
                <Link className="nav-link" onClick={() => openProfile()}>
                  <Avatar style={styles.userAvatarMobile}>
                    {props?.Users?.userDetails?.firstName
                      ?.charAt(0)
                      .toUpperCase()}
                    {props?.Users?.userDetails?.lastName
                      ?.charAt(0)
                      .toUpperCase()}
                  </Avatar>
                </Link>
              </Box>
              <Box pr={1.5} className="li">
                <Link
                  className="nav-link"
                  onClick={() => {
                    logout();
                  }}
                >
                  <img alt="" src={logOutIcon} />
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box className="mobile-navigation navigationMenu">
          <ul className="navbar-nav accordion" id="accordionSidebar">
          <li className={location.pathname === generateUrl('/my-events') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to={myEvents}>
                <div className="sidebar-icon sidebar-projects"></div>
                <span className="navMenus">Projects</span>
              </Link>
            </li>
            <li className={location.pathname === generateUrl('/report') ? 'nav-item active' : 'nav-item'}>
              <Link
                disabled
                className="nav-link"
                to={
                  props.servicePlan?.servicePlanDetails?.addReportsXP && report
                }
              >
                <div className="sidebar-icon sidebar-reports"></div>
                <span className="navMenus">Reports</span>
                {isAllowedFromServicePlan('addReportsXP')}
              </Link>
            </li>
            {showAudience && (
               <li className={location.pathname === generateUrl('/audience') ? 'nav-item active' : 'nav-item'}>
                <Link
                  className="nav-link"
                  to={
                    props.servicePlan?.servicePlanDetails?.addAudienceXP &&
                    audience
                  }
                >
                  <div className="sidebar-icon sidebar-audience"></div>
                  <span className="navMenus">Audience</span>
                  {isAllowedFromServicePlan('addAudienceXP')}
                </Link>
              </li>
            )}

            <li className={location.pathname === generateUrl('/aichatbot') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to={aiChatbot}>
                <div className="sidebar-icon sidebar-ai-chat"></div>
                <span className="navMenus">AI Agent</span>
              </Link>
            </li>

            {isExists && isExists['roles & permission'] && (
              <li className={location.pathname === generateUrl('/users') || location.pathname === generateUrl('/settings') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={settings}>
                  <div className="sidebar-setings"></div>
                  <span className="navMenus">Settings</span>
                </Link>
              </li>
            )}

            <li className={location.pathname === generateUrl('/files') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to={files}>
                <div className="sidebar-icon sidebar-files"></div>
                <span className="navMenus">Files</span>
              </Link>
            </li>
          </ul>
        </Box>
      </Box>
      <ChangelogPopup isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

const mapStateToProps = (state) => ({
  servicePlan: state.ServicePlan,
  Users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDetailsRequest: Actions.getUserDetailsRequest,
      changeLangForUserRequest: Actions.changeLangForUserRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(Sidebar);
