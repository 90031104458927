import { Component } from "react"; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import withCustomRouter from '../../Common/withCustomRouter';

/**
 * @class AutoLogin
 * @description -Used to wix AutoLogin
*/
class AdminAutoLogin extends Component {
    constructor(props) {
      super(props)
      this.state = {
        hash:'',  
        userData: {},
        errors: {},
        loading: false, 
      }
    } 

   /**
   * @function componentDidMount
   * @description - this is the default function and called at first
   * 
   */  
  componentDidMount() { 
      const params =  this.props.params; 
      console.log('params~~~~', params)
      const userData = {
          hash: params.hash  
      }
      this.setAdminAutoLogin(userData); 
  }  

  /**
   * @function setAutoLogin
   * @description -function is called to get user details and login by hash passed from wix
   * 
  */ 
  async setAdminAutoLogin(userData){ 
    const loginResponse =   this.props.adminAutoLogin(userData)
    this.handleLoading() 
  }

  /**
   * @function handleLoading
   * @description -function is handling loading 
   * @return {boolean}
  */ 
  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  /**
   * @function componentDidUpdate
   * @description -It is default function of react and called when component get updated 
   * @return {states}
  */ 
  componentDidUpdate(prevProps, prevState) {
     
  }
    
  render() {
    const { loading } = this.state
    return ( 
      <div className="container">
          {loading ? <Loader /> : null}
      </div>
    )
  }
} 

/**
 * @function mapStateToProps
 * @description -It is default function of react used to map states to props 
 * @return {props}
*/ 
const mapStateToProps = (state) => ({
    adminAutoLogin: state.AdminAutoLogin,
}) 

 
/**
 * @function mapActionsToProps
 * @description -It is default function of react used to map action to props
 * @return {props}
*/ 
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      adminAutoLogin: Actions.adminAutoLoginRequest
    },
    dispatch,
) 
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(AdminAutoLogin))
