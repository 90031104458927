import React, { useState } from "react";
import NotificationButton from "./NotificationButton";
import NotificationPopover from "./NotificationPopover";

export default function NotificationPopup() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <NotificationButton open={open} onClick={handleClick} />
      <NotificationPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //     vertical: "bottom",
        //     horizontal: "center",
        //   }}
        //   transformOrigin={{
        //     vertical: "top",
        //     horizontal: "center",
        //   }}
        //   slotProps={{
        //     paper: {
        //       sx: {
        //         marginTop: '6px',
        //         backgroundColor: '#F4F6F7',
        //         background: '#F4F6F7 0% 0% no-repeat padding-box',
        //         boxShadow: '0px 3px 6px #00000029',
        //         py: 2,
  
        //         // "&::before": {
        //         //   content: '""',
        //         //   position: "absolute",
        //         //   top: "-16px",
        //         //   left: "calc(50% - 26px)",
        //         //   width: "52px",
        //         //   height: "16px",
        //         //   backgroundColor: "#F4F6F7",
        //         //   borderTopLeftRadius: "0px",
        //         //   borderTopRightRadius: "0px",
  
        //         // },
        //       },
        //     },
        //   }}
      />
    </div>
  );
}
