import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { Link } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import Sidebar from '../../../Components/Sidebar'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';

import Button from '@mui/material/Button';
import { theme } from '../../../theme/theme';
import BackArrow from '../../../assets/images/icons/backArrow.svg';
import CampaignIcon from '../../../assets/images/icons/campaigns-white.svg';
import DoubleArrow from '../../../assets/images/icons/double-arrow.svg';
import { generateUrl, getProjectIdFromUrl } from '../../../helpers/common'
import { isValid } from 'date-fns'
import { createNotification } from '../../../helpers'
import Loader from '../../../Components/Loader/loader'
import withCustomRouter from '../../../Common/withCustomRouter';

const styles = {
    avatar: {
        backgroundColor: '#16D1FC',
        width: '80px',
        height: '80px',
    },
}

class CreateCampaign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            campaignName: "",
            errors: {},
            loading: false,
        }
    }

    componentDidMount = () => {
        const eventId = getProjectIdFromUrl()
        this.setState({
            eventId
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.campaigns.checkCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
            if (this.state.loading) {
                this.setState({
                    loading: false,
                })
            }
            setTimeout(() => {
                this.props.navigate({
                    pathname: generateUrl(`/project-dashboard/campaigns/select-template/ei/${this.state.eventId}/cn/${this.state.campaignName}`),
                    state: { campaignName: this.state.campaignName }
                })
            }, 500);
        }

        if (this.props.campaigns.error && this.props.campaigns.message !== prevProps.campaigns.message) {
            createNotification("error", this.props.campaigns.message)
        }
        if (this.state.loading) {
            this.setState({
                loading: false,
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.checkCampaignExist()
    }

    checkCampaignExist = () => {
        let error = {}
        const { eventId, campaignName } = this.state
        if (campaignName.trim().length === 0 || campaignName === "" || campaignName === undefined || campaignName === null) {
            error["campaignName"] = "Please enter a valid Campaign Name"
        } else {
            const requestBody = {
                projectId:eventId,
                campaignName: campaignName.trim(),
            }
            this.props.checkCampaign(requestBody)
            this.handleLoading()
        }
        this.setState({
            errors: error
        })
    }

    handleLoading = () => {
        let loading = this.state.loading
        this.setState({
            loading: !loading,
        })
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="dashboardMainPage">
                     <Sidebar />
                    <div className="dashboardContentArea">
                        <Box my={{xs:3, md:4}} mx={{xs:2, sm:3, md:4, lg:6, xl:7.5}} className="">
                            <Grid container spacing={2} mb={12}>
                                <Grid >
                                    <Link to={`/project-dashboard/campaigns/${this.state.eventId}`}>
                                        <Box component={Button} className='back-button' variant="outlined">
                                            <img alt="" src={BackArrow} />
                                        </Box>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justifyContent={"center"}>
                                <Grid size={{xs: "grow", sm:9, md:7, lg:5, xl:4}} >
                                    <Box mb={3}>
                                        <Avatar style={styles.avatar} >
                                            <img width={'38px'} height={'38px'} alt="" src={CampaignIcon} />
                                        </Avatar>
                                    </Box>
                                    <Box component={'p'} mb={4} className='heading'>
                                        {this.props.users.languageStateForRedux?.formfields?.campaigns?.modalHeading}
                                        {/* Create New Campaign */}
                                    </Box>
                                    <Box component="div" mb={4} className='inputField'>
                                        <label>
                                            {this.props.users.languageStateForRedux?.formfields?.campaigns?.modalDesc}
                                            {/* Enter Campaign Name and Create */}
                                        </label>
                                        <TextField fullWidth variant="outlined" inputProps={{ maxLength: 40 }} name="campaignName" onChange={this.handleChange} />
                                        <p className="error_mesage">{this.state.errors.campaignName}</p>
                                    </Box>
                                    <Box component="div" className='inputField' >
                                        <Button fullWidth size='large' variant="contained" color="primary" onClick={this.handleSubmit}>
                                            {this.props.users.languageStateForRedux?.buttons?.campaigns?.add}
                                           {/* Create Campaign  */}
                                            <Box component={'img'} ml={1.5} alt="DoubleArrow" src={DoubleArrow} />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
                {this.state.loading || this.props.users.isLoading ? <Loader /> : null}
          
            </ThemeProvider >

        )
    }
}

const mapStateToProps = (state) => ({
    campaigns: state.Campaigns,
    events: state.Events,
    users: state.Users,
})

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            checkCampaign: Actions.checkCampaignRequest,
        },
        dispatch,
    )

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(CreateCampaign))

