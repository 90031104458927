import { createNotification } from 'helpers';
import BaseAPI from './baseAPI';
import axios from 'axios';
class AIChatbotAPI extends BaseAPI {
  getAiAgentGeneralData = async (params: object) => {
    return this.API('chatbot/getGeneralData', {
      method: 'GET',
      params,
    });
  };
  createOrUpdateAiAgentGeneralData = async (data: {
    bodyParams: any;
    queryParams: any;
  }) => {
    const { bodyParams, queryParams } = data;
    return this.API('chatbot/createOrUpdate', {
      method: 'POST',
      data: bodyParams,
      params: queryParams,
    });
  };
  listChatbots = async (data: {
    bodyParams: any;
    queryParams: any;
  }) => {
    const { bodyParams, queryParams } = data;
    return this.API('chatbot/listChatbots', {
      method: 'POST',
      data: bodyParams,
      params: queryParams,
    });
  };
  trainAgent = async (data: object) => {
    //   console.info("URL ai ", process.env.REACT_APP_AI_SERVICE_URL);
    const res = await this.API("chatbot/train",
      {
        method: 'POST',
        data: data
      }
    );
    if (res.data.message) {
      createNotification(
        res.data.success ? 'success' : 'error',
        res.data.message,
      );
    }
    return res;
    // return this.API('chatbot/trainAgent', {
    //     method: 'POST',
    //     data
    // });
  };

  getChatSummary = async (data: object) => {

    const res = await this.API("chatbot/summarize",
      {
        method: 'POST',
        data: data
      }
    );

    if (res.data.message) {
      createNotification(
        res.data.success ? 'success' : 'error',
        res.data.message,
      );
    }
    return res;
  };
  deleteChatbot = async (data: { bodyParams: any; queryParams: any; }) => {
    const { bodyParams, queryParams } = data;
    return this.API('chatbot/deleteChatbot', {
      method: 'POST',
      data: bodyParams,
      params: queryParams,
    });
  };
  enableChatbot = async (data: { bodyParams: any; queryParams: any; }) => {
    const { bodyParams, queryParams } = data;
    return this.API('chatbot/enable', {
      method: 'POST',
      data: bodyParams,
      params: queryParams,
    });
  };
  setChatbotAsDefault = async (data: { bodyParams: any; queryParams: any; }) => {
    const { bodyParams, queryParams } = data;
    return this.API('chatbot/setChatbotAsDefault', {
      method: 'POST',
      data: bodyParams,
      params: queryParams,
    });
  };
  saveSettingsForAiChatbot = async (data: object) => {
    return this.API('chatbot/saveSettingsForAiChatbot', {
      method: 'POST',
      data: data,
    });
  };

  fetchDashboardLeads = async (params: object) => {
    return this.API('chatbot/fetchDashboardLeads', {
      method: 'GET',
      params,
    });
  };

  fetchChatsForChatLogs = async (params: object) => {
    return this.API('chatbot/fetchChatsForChatLogs', {
      method: 'GET',
      params,
    });
  };
  fetchAllConversationFromChat = async (params: object) => {
    return this.API('chatbot/fetchAllConversationFromChat', {
      method: 'GET',
      params,
    });
  };
  replicateChatWidget = async (params: object) => {
    return this.API('chatbot/replicateChatWidget', {
      method: 'GET',
      params,
    });
  };

  getLastChat = async (params: object) => {
    return this.API('chatbot/getLastChat', {
      method: 'GET',
      params,
    });
  };
  createNewConversation = async (data: object) => {
    return this.API('chatbot/newConversation', {
      method: 'POST',
      data,
    });
  };

  checkIfNameAiWidgetNameExists = async (data: object) => {
    return this.API('chatbot/checkIfNameAiWidgetNameExists', {
      method: 'POST',
      data,
    });
  };
  saveFeedback = async (data: object) => {
    return this.API('chat/message/feedback', {
      method: 'POST',
      data,
    });
  };

  downloadSampleCSVFile = async () => {
    return this.API('chatbot/downloadSampleCSVFile', {
      method: 'POST',
    });
  };

  importData = async (data: FormData, params: { id: string; }) => {
    return this.API(`chatbot/importData/${params.id}`, {
      method: 'POST',
      data: data
    });
  };
}

export default AIChatbotAPI;
