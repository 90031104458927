import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Stack,
  Checkbox,
  IconButton,
  Tab,
  TabProps,
  TableFooter,
  TableRow,
  TableContainer,
  Table,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import EmailList from './List';
import NoData from './NoData';
import {
  UnCheckAllIcon,
  CheckAllIcon,
  DeleteIcon,
} from '../../components/CustomIcons';
import SideTabs, { GmailData } from './SideTabs';
import PageTabs from '../Tabs';
import EijentDashboard from 'eijent';
import {
  xpAccountIntegrationGmailArchiveMultipleThreads,
  xpAccountIntegrationGmailDeleteMultipleThreads,
  xpAccountIntegrationGmailGetGmailId,
  xpAccountIntegrationGmailGetList,
  xpAccountIntegrationGmailMarkMultipleThreadsAsRead,
  xpAccountIntegrationGmailMarkMultipleThreadsAsUnread,
  xpAccountIntegrationGmailPermanentDeleteMultipleThreads,
  xpAccountIntegrationGmailUnBookmarkMultipleEmails,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import { useSelector } from 'react-redux';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import Loader from 'Components/Loader/loader';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PaginationFunctional, { handleEndCountForPagination } from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import { generateUrl } from 'helpers/common';

interface EmailCategory {
  name: string; // Ensure this is explicitly a string
  path: string;
}

const emailCategory: EmailCategory[] = [
  {
    name: 'All',
    path: 'all',
  },
  {
    name: 'Pinned',
    path: 'pinned',
  },
  { name: 'Priority', path: 'priority' },
  {
    name: 'New Opportunity',
    path: 'new-opportunity',
  },
  {
    name: 'At-Risk Opportunity',
    path: 'at-risk-opportunity',
  },
  {
    name: 'Creating a using AI',
    path: 'ai-creation',
  },
];

const mainRoute = '/inbox/my-inbox/';

export default function EmailsListContainer() {
  const location = useLocation();
  const navigate = useNavigate();

  const [gmailId, setGmailId] = useState('');
  const [value, setValue] = useState('all');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mailType, setMailType] = useState<
    'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | ''
  >('');
  const [gmailData, setGmailData] = useState<GmailData>({});
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [labelIdsArray, setLabelIdsArray] = useState<string[]>([]);
  const [
    openPermanentDeleteConfirmationModel,
    setOpenPermanentDeleteConfirmationModel,
  ] = useState<boolean>(false);
  // variables for pagination started
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [limit, setLimit] = useState<number>(10);
  const [page_no, setPage_no] = useState<number>(1);
  // variables for pagination ends

  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  // StyledTab customization
  const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
    ({ theme }) => ({
      '&.MuiTab-root': {
        height: 52,
        marginBottom: 0,
        borderBottom: 0,
        paddingLeft: '30px',
        paddingRight: '30px',
        fontSize: '12px',
        transitionDuration: '200ms',
        '&:hover': {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
        },
      },
      '&.Mui-selected': {
        fontWeight: '700',
      },
    }),
  );

  const setMailTypeFromUrl = () => {
    const currentPath = location.pathname.split('/')[2];
    console.log('!===============currentPath', location.pathname.split('/'));
    console.log('!===============currentPath', currentPath);
    let mailType: 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' = 'INBOX';
    switch (currentPath) {
      case 'my-inbox':
        mailType = 'INBOX';
        break;
      case 'drafts':
        mailType = 'DRAFT';
        break;
      case 'sent':
        mailType = 'SENT';
        break;
      case 'trash':
        mailType = 'TRASH';
        break;
      case 'bookmarks':
        mailType = 'STARRED';
        break;
      default:
        break;
    }
    setMailType(mailType);
  };
  console.log('!===============mailType', mailType);

  useEffect(() => {
    getGmailDetails();
    handleEndCountForPagination(
      gmailData?.totalMailCount,
      limit,
      page_no,
      setEndCount,
    );
    setStartCount((page_no - 1) * limit + 1);
  }, []);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [page_no]);

  useEffect(() => {
    console.log('==========pathname has changed');
    resetPaginationStates();
  }, [location.pathname]);

  // Get the current path from the URL and set the active tab
  useEffect(() => {
    const currentPath = location.pathname.split('/').pop(); // Get the last part of the URL
    const currentTabIndex = emailCategory.findIndex(
      (category) => category.path === currentPath,
    );
    if (currentTabIndex !== -1) {
      setValue((currentTabIndex + 1).toString());
    }
    setMailTypeFromUrl();
  }, [location.pathname, emailCategory]);

  useEffect(() => {
    console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
    if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
      // if (page_no === 1) {
      getGmailData(undefined, undefined, false);
      // }
    }
  }, [xpUsersWebSocketMessage]);

  useEffect(() => {
    if (mailType) {
      getGmailData();
    }
  }, [mailType]);

  const resetPaginationStates = () => {
    setStartCount(1);
    setEndCount(10);
    setLimit(10);
    setPage_no(1);
  };

  const getGmailData = async (limit = 10, page_no = 1, showLoader = true) => {
    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: page_no,
      limit: limit,
      type: mailType,
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newTabPath = emailCategory[parseInt(newValue) - 1].path;
    navigate(`${mainRoute}${newTabPath}`);
  };

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            !message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, 'STARRED'],
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const setGmailDataAfterUnbookmarkingMultipleEmails = (messageIds) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            messageIds.includes(message.id) &&
            message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter((label) => label !== 'STARRED'),
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const unBookmarkMultipleEmails = async (messages) => {
    try {
      const messageIds = messages
        .filter((message) => message.labelIds.includes('STARRED'))
        .map((message) => message.id);

      setGmailDataAfterUnbookmarkingMultipleEmails(messageIds);
      const data = {
        messageIds: messageIds,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailUnBookmarkMultipleEmails(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const handleDeleteMultiple = async () => {
    try {
      setIsLoading(true);
      const data = {
        ids: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailDeleteMultipleThreads(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const getGmailDetails = async () => {
    try {
      const result: any = await xpAccountIntegrationGmailGetGmailId();
      if (result.status === 200 && result.data.success) {
        setGmailId(result.data.details.appData.user.email);
      }
    } catch (error) {
      console.log('error');
      if (error instanceof Error) {
        createNotification('error', error.message);
      } else {
        createNotification('error', 'An unexpected error occurred.');
      }
    }
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      gmailData?.data?.forEach((mail) => arr.push(mail._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const markMultipleThreadsAsRead = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsRead(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const markMultipleThreadsAsUnread = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsUnread(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const archiveMultipleThreads = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailArchiveMultipleThreads(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const redirectToGmailView = (threadId: string, index) => {
    navigate(
      generateUrl(
        `/emails/inbox/gmailView/${threadId}?pageNo=${page_no}&limit=${limit}&mailNo=${
          index + 1
        }&totalCount=${gmailData?.totalMailCount}`,
      ),
      { state: { gmailData: gmailData } },
    );
  };

  const handlePermanentDeleteMultiple = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res: any =
        await xpAccountIntegrationGmailPermanentDeleteMultipleThreads(data);
      if (res.status == 200) {
        getGmailData();
      } else if (res.status == 207) {
        createNotification('info', res.data.message);
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenPermanentDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const closePermanentDeleteConfirmationModel = () => {
    setOpenPermanentDeleteConfirmationModel(false);
  };

  return (
    <EijentDashboard>
      <PageTabs>
        <SideTabs>
          <Stack>
            {/* --Select and menu-- */}
            {selectedCheckboxes.length >= 1 && (
              <Stack
                px={1.5}
                borderBottom={'1px solid #C1C1C1'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack
                  spacing={2.75}
                  py={1.5}
                  px={2}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <Checkbox
                      onClick={handleSelectAllCheckboxes}
                      checked={
                        gmailData?.data?.length
                          ? selectedCheckboxes.length ===
                            gmailData?.data?.length
                          : false
                      }
                      icon={<UnCheckAllIcon />}
                      checkedIcon={<CheckAllIcon />}
                    />
                    <Typography>{selectedCheckboxes.length}</Typography>
                  </Box>

                  <IconButton
                    size="small"
                    // @ts-ignore
                    color="transparent"
                    variant="contained"
                    onClick={() => setOpenDeleteConfirmationModel(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {mailType !== 'TRASH' && (
                    <IconButton
                      size="small"
                      // @ts-ignore
                      color="transparent"
                      variant="contained"
                      onClick={() => archiveMultipleThreads()}
                    >
                      <ArchiveOutlinedIcon
                        sx={{ fontSize: '22px !important' }}
                      />
                    </IconButton>
                  )}

                  {labelIdsArray.includes('UNREAD') ? (
                    <IconButton
                      size="small"
                      // @ts-ignore
                      color="transparent"
                      variant="contained"
                      onClick={() => markMultipleThreadsAsRead()}
                    >
                      <DraftsOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      // @ts-ignore
                      color="transparent"
                      variant="contained"
                      onClick={() => markMultipleThreadsAsUnread()}
                    >
                      <MarkEmailUnreadOutlinedIcon />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            )}

            <TabContext value={value}>
              {mailType === 'INBOX' && (
                <TabList
                  TabIndicatorProps={{ sx: { height: 3 } }}
                  variant="standard"
                  onChange={handleChange}
                  aria-label="Page tabs"
                  sx={{ borderBottom: '1px solid' }}
                >
                  {emailCategory.map((item, index) => (
                    <StyledTab
                      key={index}
                      label={item.name}
                      value={`${index + 1}`}
                      // onClick={() => handleNavigation(item.path)}
                    />
                  ))}
                </TabList>
              )}
            </TabContext>
            <EmailList
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              gmailData={gmailData}
              getGmailData={getGmailData}
              gmailId={gmailId}
              setGmailData={setGmailData}
              mailType={mailType}
              setLabelIdsArray={setLabelIdsArray}
              redirectToGmailView={redirectToGmailView}
            />

            {gmailData?.data?.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <PaginationFunctional
                        startCount={startCount}
                        setStartCount={setStartCount}
                        endCount={endCount}
                        limit={limit}
                        setLimit={setLimit}
                        page_no={page_no}
                        setPage_no={setPage_no}
                        listItemsFn={getGmailData}
                        totalItemsCount={gmailData?.totalMailCount}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}

            <ConfirmationAlertPopUp
              openModal={openDeleteConfirmationModel}
              closeModalFunc={closeDeleteConfirmationModel}
              title={'Delete Confirmation!'}
              text={'Are you sure you want to delete it?'}
              confirmationButtonText={'Delete'}
              confirmationButtonColor="secondary"
              closeButtonText={'Cancel'}
              functionality={() => handleDeleteMultiple()}
            />
          </Stack>
          {isLoading && <Loader />}
        </SideTabs>
      </PageTabs>
    </EijentDashboard>
  );
}
