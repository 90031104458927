import React from "react";
import { Popover,Stack } from "@mui/material";
import NotificationList from "./NotificationList";

export default function NotificationPopover({ open, anchorEl, onClose }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            marginTop: '6px',
            backgroundColor: '#F4F6F7',
            background: '#F4F6F7 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            py: 2,

            // "&::before": {
            //   content: '""',
            //   position: "absolute",
            //   top: "-16px",
            //   left: "calc(50% - 26px)",
            //   width: "52px",
            //   height: "16px",
            //   backgroundColor: "#F4F6F7",
            //   borderTopLeftRadius: "0px",
            //   borderTopRightRadius: "0px",

            // },
          },
        },
      }}
    >
         {/* <Stack className={'eijent-notifications-container'}

sx={{
  overflow: 'auto',
  maxHeight: 'calc(100vh - 150px)',
  px: 1.5,
}}
> */}
      <NotificationList />
      {/* </Stack> */}
    </Popover>
  );
}
