import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;
let isRefreshing = false;
let requestQueue = [];

axios.interceptors.request.use(
  async (config) => {
    const hostname = window.location.hostname;

    // Skip token logic for certain URLs or subdomains
    if (
      hostname === 'localhost' ||
      config.url.includes('/api/users/login') ||
      config.url.includes('/register') ||
      config.url.includes('/refreshToken') ||
      config.url.includes('/confirmYourEmailOnCognito') ||
      config.url.includes('/forgotPassword') ||
      config.url.includes('/resetPassword') ||
      config.url.includes('/getInvitedUserDetails')
    ) {
      return config;
    }

    // Check user details in localStorage
    const rawUserDetails = localStorage.getItem('user_details');
    if (!rawUserDetails) {
      window.location.href = '/';
      throw new Error('User not logged in');
    }

    const users = JSON.parse(rawUserDetails);
    if (!users.token) {
      throw new Error('Access token not found in user details');
    }

    let accessToken = users.token;

    // Validate token expiration
    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    const isTokenExpired = payload.exp * 1000 < Date.now();

    if (isTokenExpired) {
      if (!isRefreshing) {
        console.log('Access token expired. Refreshing...');
        isRefreshing = true;

        try {
          const newAccessToken = await refreshToken();
          isRefreshing = false;
          processQueue(null, newAccessToken);
        } catch (err) {
          isRefreshing = false;
          processQueue(err, null);
          throw err;
        }
      }

      // Queue current request
      return new Promise((resolve, reject) => {
        requestQueue.push({
          resolve: (token) => {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.token = `Bearer ${token}`;
            resolve(config);
          },
          reject: (err) => reject(err),
        });
      });
    } else {
      // Attach valid token to headers
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.token = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor to handle 401 errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log('Unauthorized. Redirecting to login...');
      localStorage.removeItem('user_details');
      window.location.href = '/'; // Redirect to login page
    }
    return Promise.reject(error);
  },
);

function processQueue(error, token = null) {
  requestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
}

const refreshToken = async () => {
  try {
    const rawUserDetails = localStorage.getItem('user_details');
    if (!rawUserDetails) {
      throw new Error('User not logged in');
    }

    const users = JSON.parse(rawUserDetails);
    const storedRefreshToken = users.refreshToken;

    if (!storedRefreshToken) {
      throw new Error('Refresh token missing');
    }

    const response = await axios.post(`${URL}/users/refreshToken`, { refreshToken: storedRefreshToken });
    const { accessToken, idToken } = response.data;

    // Update user details in localStorage
    users.token = accessToken;
    users.idToken = idToken;
    localStorage.setItem('user_details', JSON.stringify(users));

    console.log('Token refreshed successfully:', users);
    return accessToken;
  } catch (err) {
    console.error('Error refreshing token:', err);
    localStorage.removeItem('user_details');
    window.location.href = '/'; // Redirect to login
    throw err;
  }
};

export default axios;
