import React, { Component, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import Modal from 'react-modal';
import { ReactComponent as ProjectCreated } from '../../assets/images/project-created.svg';
import FinishIcon1 from '../../assets/images/icons/finish-pop-1.svg';
import FinishIcon2 from '../../assets/images/icons/finish-pop-2.svg';
import FinishIcon3 from '../../assets/images/icons/finish-pop-3.svg';
import PopUpVideo1 from '../../assets/images/play-img.jpg';
import CloseIcon from '../../assets/images/icons/close.svg';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router-dom'
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { connect } from 'react-redux';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import lockIcon from '../../assets/images/icons/lock.svg'
import { generateUrl } from 'helpers/common';


const useStyles = makeStyles((theme) => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}));

function FinishPopUp(Props) {
  const classes = useStyles();
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [servicePlansData, setServicePlansData] = React.useState({});

  useEffect(() => {
    setServicePlansData(Props?.servicePlan?.servicePlanDetails)
  }, [])


  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {

  }

  function closeModal() {
    setIsOpen(false);
  }


  function redirectRouteFunction() {
    window.location.href = generateUrl(`/project-dashboard/overview/${Props.eventId}`);
  }


  function redirectRouteFunctionAgenda() {
    window.location.href = generateUrl(`/project-dashboard/agenda/${Props.eventId}`);
  }

  function redirectRouteFunctionCampaigns() {
    window.location.href = generateUrl(`/project-dashboard/campaigns/${Props.eventId}`);
  }
  function redirectRouteFunctionManagepages() {
    window.location.href = generateUrl(`/project-dashboard/manage-pages/${Props.eventId}`);
  }

  const getLabelForTabs = (label, module) => {
    return (
      <Stack direction="row" spacing={1} sx={{ mr: 0 }}>
        <Box>{label}</Box>
        {servicePlansData[module] === false && (
          <CustomTooltip
            interactive={true}
            title={
              <Box className="upgrade-tooltip">
                <h5>Upgrade your Plan</h5>
                <p>
                  This feature is not a part of you current plan. <br />
                  <a
                    href="https://www.illumeetxp.com/upgrade?location=campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  for more info
                </p>
              </Box>
            }
            placement="bottom"
            arrow={true}
          >
            <img className="lock" alt="disabled" src={lockIcon} />
          </CustomTooltip>
        )}
      </Stack>
    )
  }

  return (

    <div className="dashboard-containor">
      {/*  */}
      <div>
        <button id="finishPopup" onClick={openModal} style={{ visibility: 'hidden' }}>Click Open Modal</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          style={useStyles}
          contentLabel="Example Modal"
          className="finish-pop-ain"
        >

          <Box className="finish-popup-outer">
            <div className="finish-popup-head">
              <Box>
                <ProjectCreated />
              </Box>
              <h2 className="subsecondary"> Your project is created </h2>
              {
                Props.projectTemplateId === 'blank' ? <p className='paragraph'>Congratulations on the successful creation of your event project! You can now navigate to the respective page using the following link.</p> 
                : <p className='paragraph'>Pages, Campaigns, Agenda, Notifications, Groups, Custom Fields, Transactional Email Templates & Challenges details are copied from the selected project template.</p>
              }
              
              <div className="finish-single-btn">
                <Button variant="contained" color="primary" className="step2-use" onClick={() => redirectRouteFunction()
                }> Go to project overview </Button>
              </div>
              <Grid container spacing={1} className="finish-popup-btns">
                <Grid size={{xs: 'grow',}}>
                  <Button fullWidth variant="outlined" style={{backgroundColor: !servicePlansData?.addPagesXP && '#e0e0e0'}} color="primary" onClick={() => servicePlansData?.addPagesXP && redirectRouteFunctionManagepages()}> <img src={FinishIcon1} />   {getLabelForTabs('Project Pages', 'addPagesXP',)}</Button>
                </Grid>
                <Grid size={{xs: 'grow',}}>
                  <Button fullWidth variant="outlined" style={{backgroundColor: !servicePlansData?.addCampaignsXP && '#e0e0e0'}} color="primary" onClick={() => servicePlansData?.addCampaignsXP && redirectRouteFunctionCampaigns()}> <img src={FinishIcon2} /> {getLabelForTabs('Campaigns', 'addCampaignsXP',)}</Button>
                </Grid>
                <Grid size={{xs: 'grow',}}>
                  <Button fullWidth variant="outlined" style={{backgroundColor: !servicePlansData?.addAgendaXP && '#e0e0e0'}} color="primary" onClick={() => servicePlansData?.addAgendaXP && redirectRouteFunctionAgenda()}> <img src={FinishIcon3} />{getLabelForTabs('Agenda', 'addAgendaXP',)}</Button>
                </Grid>
              </Grid>
            </div>
            {/* <div className="finish-popup-footer">
                <div className="finish-footer-lft">
                  <div className="finish-footer-lft-content">
                    <span> Video </span>
                    <p> Learn how to create new pages by using the templates </p>
                  </div>
                  <img src={PopUpVideo1} />
                </div> 
                 <div className="finish-footer-lft">
                  <div className="finish-footer-lft-content">
                    <span className="new-bg-green"> New </span>
                    <p> Learn how to create new pages by using the templates </p>
                  </div>
                  <img src={PopUpVideo1} />
                </div> 
              </div> */}
          </Box>
        </Modal>
      </div>

    </div>

  )

}

const mapStateToProps = (state) => ({
  events: state.Events,
  servicePlan: state.ServicePlan,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
      handleDoContinue: Actions.handleDoContinueState,
      findIfAllowedFromSuperadminOuterRequest: Actions.findIfAllowedFromSuperadminOuterRequest
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)((FinishPopUp))
