import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { NotificationContainer } from 'react-notifications';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import { GetInvitedUserDetailsAPI } from '../../ReduxStore/API';
//import * as React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Loader from '../../Components/Loader/loader';

const label = (
  <span>
    I agree Illumeet &nbsp;
    <a href="/terms_and_conditions" target="_blank">
      Terms & Conditions
    </a>
  </span>
);

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      phoneNumber: '',
      company: '',
      errors: {},
      terms: false,
      loading: false,
    };
  }


  componentDidMount() {
    const { location } = this.props;

    // Parse query parameters from the location prop
    const searchParams = new URLSearchParams(location.search);
    const invited = searchParams.get('invited');
    const token = searchParams.get('token');
    this.setState({ invited: invited });
    this.setState({ invitedToken: token });
    if (invited === '1' && token) {
      this.getInvitedUserDetails(token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;
    if (
      this.props.isRegister === true &&
      this.props.registerMsg !== prevProps.registerMsg
    ) {
      createNotification('success', this.props.registerMsg);
      let st = (prevState.loading ? this.setState({ loading: false }) : null);
      setTimeout(() => {
        this.props.navigate('/');
      }, 2000);
    }

    if (this.props.error && this.props.errMessage !== prevProps.errMessage) {
      createNotification('error', this.props.errMessage);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }


  getInvitedUserDetails = async (token) => {
    try {
      const data = { inviteToken: token };
      const response = await GetInvitedUserDetailsAPI(data);
      console.log("response", response);

      // Prefill email in the form
      this.setState({ email: response?.data?.users?.email });
    } catch (error) {
      console.error('Error fetching user details:', error);
      this.setState({ loading: false });
    }
  };


  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  isValid = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      phoneNumber,
      terms,
    } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobPattern = /^[0-9]{10}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (company === '' || company.trim().length === 0) {
      formIsValid = false;
      error['company'] = '*Please enter company name.';
    }
    if (email && regexTest.test(email) === false) {

      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    if (firstName === '' || firstName.trim().length === 0) {
      formIsValid = false;
      error['firstName'] = '*Please enter first name.';
    }
    if (lastName === '' || lastName.trim().length === 0) {
      formIsValid = false;
      error['lastName'] = '*Please enter last name.';
    }

    if (password === '' || password.trim().length === 0) {
      formIsValid = false;
      error['password'] = '*Please enter password.';
    }

    if (password && passwordRegex.test(password) === false) {
      formIsValid = false;
      error['password'] = "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8.";
    }

    if (confirmPassword === '' || confirmPassword.trim().length === 0) {
      formIsValid = false;
      error['confirmPassword'] = '*Please confirm your password.';
    } else if (password !== confirmPassword) {
      formIsValid = false;
      error['confirmPassword'] = '*Passwords do not match.';
    }

    if (phoneNumber === '' || phoneNumber.trim().length === 0) {
      formIsValid = false;
      error['phoneNumber'] = '*Phone enter phone number.';
    }
    if (phoneNumber && mobPattern.test(phoneNumber) === false) {
      formIsValid = false;
      error['phoneNumber'] =
        '*Phone number should not less than 10 or nor more than 15.';
    }

   /*  if (terms === false) {
      formIsValid = false;
      error['terms'] = '*I agree Illumeet terms and conditions.';
    } */

    this.setState({ errors: error });
    return formIsValid;
  };

  handleChange = (event) => {
    let error = {};
    if (event.target.name === 'phoneNumber') {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
        this.setState({ errors: {} });
      } else {
        error['phoneNumber'] = '*Please enter a valid numeric phone number.';
        this.setState({ errors: error });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }


  };

  handleCheckBox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {
        firstName,
        lastName,
        password,
        confirmPassword,
        email,
        phoneNumber,
        company,
        invited,
        invitedToken,
      } = this.state;
      const requestbody = {
        firstName,
        lastName,
        password,
        email,
        phoneNumber,
        company,
        invited,
        invitedToken,
      };
      this.props.userRegister(requestbody);
      this.handleLoading();
      this.props.startLoading();
    }
  };

  render() {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      phoneNumber,
      errors,
      terms,
      loading,
    } = this.state;
    return (
      <AuthLayout>
        <Helmet>
          <title>Eijent | Register</title>
        </Helmet>
        <Box component="form" onSubmit={this.handleSubmit}>
          <Card >
            <Typography
              component="h1"
              variant="h1"
              textAlign={'center'}
              sx={{ width: '100%', }}
            >
              Registration

             {loading ? <Loader /> : null}  
            </Typography>

            {/* ---Form--- */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
            >
              <FormControl>
                <TextField
                  error={errors.firstName}
                  helperText={errors.firstName}
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={firstName}
                  placeholder="First Name"
                  autoFocus
                  fullWidth
                  variant="outlined"
                  size="large"
                  onChange={this.handleChange}
                  color={errors.firstName ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>

              <FormControl>
                <TextField
                  error={errors.lastName}
                  helperText={errors.lastName}
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  placeholder="Last Name"
                  autoComplete="last name"
                  fullWidth
                  variant="outlined"
                  size="large"
                  color={errors.lastName ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl> 
              
              <FormControl>
                <TextField
                  error={errors.password}
                  helperText={errors.password}
                  name="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  type="password"
                  autoComplete="password"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  size='large'
                  color={errors.password ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  error={errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={this.handleChange}
                  autoComplete="confirmPassword"
                  fullWidth
                  variant="outlined"
                  size='large'
                  color={errors.confirmPassword ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>

              <FormControl>
                <TextField
                  error={errors.email}
                  helperText={errors.email}
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder="Work email"
                  autoComplete="email"
                  disabled={this.state.invited == '1'}
                  fullWidth
                  variant="outlined"
                  size="large"
                  color={errors.email ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>

              <FormControl>
                <TextField
                  error={errors.company}
                  helperText={errors.company}
                  id="company"
                  type="text"
                  name="company"
                  value={company}
                  onChange={this.handleChange}
                  placeholder="Company"
                  autoComplete="company" 
                  fullWidth
                  variant="outlined"
                  size="large"
                  color={errors.company ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl> 
              <FormControl>
                <TextField
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber}
                  id="phone"
                  type="tel"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                  placeholder="Phone"
                  autoComplete="phoneNumber" 
                  fullWidth
                  variant="outlined"
                  size="large"

                  // color={emailError ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl> 
            </Box> 
            {/* ---Button--- */}
            <Stack spacing={2.5} alignItems={'center'}>
              <Box>
                <Button size='large' color='primary' type="submit" variant='contained'>CREATE YOUR ACCOUNT</Button>
              </Box>
            </Stack>
          </Card>
        </Box>
        <NotificationContainer />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegister: state.Register.isRegister,
  error: state.Register.registerErr,
  errMessage: state.Register.errMessage,
  registerMsg: state.Register.registerMsg,
  loading: state.Loader.loading,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userRegister: Actions.userRegisterRequest,
      startLoading: Actions.startLoading,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(RegisterPage);
