//@ts-nocheck
import React, { SyntheticEvent, useState, useEffect, useContext } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
// import '../../sass/main.scss';
import Box from '@mui/material/Box';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AccountIcon from '../../../src/assets/images/icons/account-nav-icon.svg';
import OpportunityIcon from '../../../src/assets/images/icons/Opportunity-nav-icon.svg';
import ContactsIcon from '../../../src/assets/images/icons/contacts-nav-icon.svg';
import AudienceIcon from '../../../src/assets/images/icons/opp_audience-icon.svg';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import DashboardLayout from 'Layouts/DashboardLayout';
import Accounts from './Accounts/Accounts';
import Opportunities from './Opportunity/Opportunity';
import Contacts from './Contacts/Contacts';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { EventsAllAPI } from 'ReduxStore/API'; 
import { generateUrl, getSystemLevelPermissions } from './../../helpers/common';
// import Audience from './Audience/Audience';
// import Audience from './Audience/Audience';
import Audience from './Audience/Audience';
import ContactGroups from './Audience/ContactGroup';
// import ImportWizardContextProvider, { ImportWizardContext } from 'Contexts/ImportWizardContextProvider';
import { Button } from '@mui/material';
import { ImportWizardContext } from 'Contexts/ImportWizardContextProvider';
import ImportWizardModal from './CommonComponents/ImportWizardModal';
import { useNavigate, useLocation } from 'react-router-dom';


// Styling for custom badge
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    right: 8,
    padding: '0 4px',
    borderRadius: '4px',
    width: 24,
    height: 22,
  },
}));

// Custom styles
const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '48px',
    height: '48px',
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
    color: '#000056',
  },
};

/**
 * OpportunityLayout Component
 * @description Parent component for managing accounts, opportunities, and contacts.
 * @param {Object} props - Component props 
 * @returns {JSX.Element} - OpportunityLayout component
 */
function OpportunityLayout(props: { history: string[] }) {

  const navigate = useNavigate(); // Use navigate instead of history
  const location = useLocation();
  
  const importWizardContext = useContext(ImportWizardContext);
  if (!importWizardContext) {
    throw new Error(
      'ImportWizardModal must be used within an ImportWizardContextProvider',
    );
  }
  const { dispatchOpenModal } = importWizardContext;

  const [allowed, setAllowed] = useState<any>({}); 
  const [value, setValue] = React.useState('');
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);

  // Fetch project list and direct to tab as per URL
  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    getProjectList();
  }, []);

  useEffect(() => {
    directToTabAsPerURL();
  }, [location.pathname]);

  /**
   * Directs to tab based on the current URL path.
   * Extracts the last part of the path and sets the corresponding tab value.
   * @function directToTabAsPerURL
   * @description Determines the tab based on the current URL path and sets its value.
   * @return {void}
   */
  // Direct to tab based on current URL
  const directToTabAsPerURL = () => {
    const currentPath = location.pathname.split('/').at(3);
    let newValue: string = null as unknown as string;
    switch (currentPath) {
      case 'opportunity':
        newValue = '1';
        break;
      case 'accounts':
        newValue = '2';
        break;
      case 'contacts':
        newValue = '3';
        break;
      case 'accountview':
        newValue = '2';
        break;

      case 'contactdetail':
        newValue = '3';
        break;

        case 'opportunityview':
        newValue = '1';
        break;
      // case 'contactGroups':
      //   newValue = '5';
      //   break;
      default:
        break;
    }
    setValue(newValue);
  };

  /**
   * Handles tab change event.
   * Sets the tab value and redirects to the corresponding URL path.
   * @function handleTabChange
   * @param {SyntheticEvent<Element, Event>} event - The synthetic event object.
   * @param {string} newValue - The new tab value.
   * @description Handles tab change event and redirects to the corresponding URL path.
   * @return {void}
   */
  const handleTabChange = (
    event: any,
    newValue: string,
    idToSearch: string,
  ) => {
    let tabName;
    switch (newValue) {
      case '1':
        tabName = 'opportunity';
        break;
      case '2':
        tabName = 'accounts';
        break;
      case '3':
        tabName = 'contacts';
        break;

      // case '4':
      // tabName = 'audience';
      // break;

      // case '5':
      // tabName = 'contactGroups';
      // break;

      default:
        break;
    }
    setValue(newValue); 
    navigate(generateUrl(`/opportunity/${tabName}`), {
      state: idToSearch,
    });
  };

  /**
   * Fetches the list of projects asynchronously.
   * Calls the EventsAllAPI function to get project data.
   * Sets the retrieved project list to the state.
   * @function getProjectList
   * @description Fetches the list of projects asynchronously and sets it to the state.
   * @return {void}
   */
  const getProjectList = async () => {
    try {
      const result: any = await EventsAllAPI();
      const projects: ProjectsList = result.data.events;
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout title="" pageTitle="">
      {/* <ImportWizardContextProvider> */}
        <ThemeProvider theme={theme}>
          <Box component={'div'} className="header-common">
            <Grid >
              <h1 className="heading"> Opportunity Management </h1>
            </Grid>
            <Grid >
              <Button
                onClick={() => dispatchOpenModal('OPEN_IMPORT_WIZARD_PAGE')}
                variant="contained"
                color="primary"
              >
                Import
              </Button>
            </Grid>
          </Box>

          <Box className="project-navigation">
            <TabContext value={value}>
              <Box
                pl={{ md: 2 }}
                sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}
              >
                <TabList
                  style={styles.customTabList}
                  variant="standard"
                  scrollButtons
                  //@ts-ignore
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  {allowed['viewOpportunity'] && (
                    <Tab
                      style={styles.customTab}
                      icon={<img alt="" src={OpportunityIcon} />}
                      iconPosition="start"
                      label={
                        <StyledBadge badgeContent={0} color="primary">
                          <Box sx={{ mr: 0 }}>Opportunities</Box>
                        </StyledBadge>
                      }
                      value="1"
                    >
                      {null}
                    </Tab>
                  )}
                  {allowed['viewAccount'] && (
                    <Tab
                      style={styles.customTab}
                      icon={<img alt="" src={AccountIcon} />}
                      iconPosition="start"
                      label={
                        <StyledBadge badgeContent={0} color="primary">
                          <Box sx={{ mr: 0 }}>Accounts</Box>
                        </StyledBadge>
                      }
                      value="2"
                    >
                      {null}
                    </Tab>
                  )}
                  {/* {allowed['viewContacts'] && 
                ( */}
                  <Tab
                    style={styles.customTab}
                    icon={<img alt="" src={ContactsIcon} />}
                    iconPosition="start"
                    label={
                      <StyledBadge badgeContent={0} color="primary">
                        <Box sx={{ mr: 0 }}>Contacts</Box>
                      </StyledBadge>
                    }
                    value="3"
                  >
                    {null}
                  </Tab>
                  {/* )} */}
                  {/* {allowed['viewContacts'] &&  */}
                  {/* ( */}
                  {/* <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={AudienceIcon} />}
                  iconPosition="start"
                  label={
                    <StyledBadge badgeContent={0} color="primary">
                      <Box sx={{ mr: 0 }}>Contact Groups</Box>
                    </StyledBadge>
                  }
                  value="4"
                >
                  {null}
                </Tab> */}
                  {/* <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={AudienceIcon} />}
                  iconPosition="start"
                  label={
                    <StyledBadge badgeContent={0} color="primary">
                      <Box sx={{ mr: 0 }}>Contact Groups</Box>
                    </StyledBadge>
                  }
                  value="5"
                >
                  {null}
                </Tab> */}
                  {/* )} */}
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: '0' }}>
                <Opportunities
                  projectsList={projectsList}
                  handleTabChange={handleTabChange}
                />
              </TabPanel>
              <TabPanel value="2" style={{ padding: '0' }}>
                <Accounts
                  projectsList={projectsList}
                  handleTabChange={handleTabChange}
                />
              </TabPanel>
              <TabPanel value="3" style={{ padding: '0', }}>
                <ContactGroups handleTabChange={handleTabChange} />
                {/* <Contacts handleTabChange={handleTabChange}/> */}
              </TabPanel>
              {/* <TabPanel value="4">
              <Box py={0} className='tab-container'>
                <Audience />
              </Box>
            </TabPanel> */}
              {/* <TabPanel value="5" style={{ padding: '0', display: 'flex' }}>
                <ContactGroups />
            </TabPanel> */}
            </TabContext>
          </Box>
        </ThemeProvider>
      {/* </ImportWizardContextProvider> */}
      <ImportWizardModal/>

    </DashboardLayout>
  );
}

export default  OpportunityLayout;