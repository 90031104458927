import React, { useState } from "react";
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup } from "@mui/material";


export default function DraftsPopup() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const DraftsList = [
    { draft: 'Opportunity 01', avatar: 'AS' },
    { draft: 'Comic Con Experience', avatar: 'SK' },
    { draft: 'Workspace', avatar: 'PK' },
  ];

  return (
    <div>
      {/* Drafts Button */}
      <Button
        color='secondary'
        variant="contained"
        size='large'
        endIcon={
          <Box sx={{ width: 38, height: 38, backgroundColor: "#fff", borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='body2'>
              4
            </Typography>
          </Box>
        }
        onClick={handleClick}

        sx={{
          paddingRight: 1.5,
          backgroundColor: open && '#E6EFFF',
          borderBottomLeftRadius: open ? "0px" : 8,
          borderBottomRightRadius: open ? "0px" : 8,
          transitionDuration: '200ms',
          "&::before": {
            content: '""',
            position: "absolute",
            bottom: open ? "-6px" : 0,
            left: "0",
            right: '0',
            height: open ? "6px" : 0,
            transitionDuration: '200ms',
            backgroundColor: "#E6EFFF",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",

          },
        }}
      >
        Drafts
      </Button>

      {/* Drafts Popup */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '6px',
              backgroundColor: '#E6EFFF',
              boxShadow: '0px 3px 6px #00000029',
              py: 0.5,
            },
          },
        }}
      >
        <Stack spacing={0.5} className={'drafts-container'}

          sx={{
            overflow: 'auto',
            maxHeight: 'calc(100vh - 150px)',
            px: 0.5,

          }}
        >
          {DraftsList.map((item, index) => (
            <Box
              key={index}
              sx={{
                maxWidth: 558,
                minWidth: 300,
                backgroundColor: '#DCE6F7',
                borderRadius: '4px',
              }}
              p={1.5}
              className={'draft'}

            >

              <Stack direction={'row'} flexGrow={1} alignItems={'center'} justifyContent={'space-between'} >
                <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }} >
                  {item.draft}
                </Typography>
                <Button variant="contained" color="white" size="small" sx={{ minWidth: 52, }}>
                  Edit
                </Button>
              </Stack>


              <Stack spacing={1.4} alignItems={'start'} >
                <Box display={'flex'} gap={0.5}>
                  <Avatar
                    sizes="small"
                    alt="Riley Carter"
                    sx={{ width: 32, height: 32, fontSize: '10px', fontWeight: 400, border: 'none !important', }}
                  >
                    {item.avatar}
                  </Avatar>
                  <Avatar
                    sizes="small"
                    alt="Riley Carter"
                    sx={{ width: 32, height: 32, fontSize: '10px', fontWeight: 400, border: 'none !important', }}
                  >
                    {item.avatar}
                  </Avatar>
                </Box>

                <Typography sx={{ fontSize: '13px', fontWeight: 400, pb: 2 }} >
                  AI need to ask the followup question if user idle more than. After the leave chat the feedback. Remove the tack.to from production. Wrong username Comments User. Under IT, write an “how to” article for new users.
                </Typography>

                <Box display={'flex'} gap={0.5}>
                  <Button variant="contained" color="white" size="small" sx={{ height: '28px', minWidth: 52, }}>
                    Eijent
                  </Button>

                  <Button variant="contained" color="white" size="small" sx={{ height: '28px', minWidth: 52, }}>
                    Apple
                  </Button>
                </Box>

              </Stack>
            </Box>
          ))}


        </Stack>
      </Popover>
    </div>
  );
}
