import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';

import {
  ArrowDownIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
} from './CustomIcons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90vh',
  bgcolor: 'background.paper',
  borderRadius: '8px',
};

export default function NewWorkspace() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>

      <Box
        onClick={handleOpen}
        sx={{
          width: 158,
          cursor: 'pointer',
          transitionDuration: '200ms',
          '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 5%)',
          }
        }}
        pl={4}
        pr={2}
        py={1.5}
        className={'new-list-item'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={2.25} >
          <HomeIconActive />

          <Typography variant="subtitle2" sx={{ fontWeight: 400, lineHeight: '16px', color: '#fff' }} >
            Project
          </Typography>
        </Stack>

      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            timeout: 200,
            sx: {
              backdropFilter: 'blur(4px)',
            }
          },
        }}
      >
        <Box sx={style}>
          <Stack px={4} py={2} borderBottom={'1px solid #D9D9D9'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography id="modal-modal-title" variant="h2" component="h2">
              New Workspace
            </Typography>

            <Box onClick={handleClose}>close</Box>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
