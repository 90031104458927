import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Login from '../Pages/Login';
import { Url, showLogin } from '../constants/constants';
import { UserData } from 'helpers/common';
import withCustomRouter from '../Common/withCustomRouter';
import EijentDashboard from '../eijent/index';
import ForgotPassword from '../Pages/ForgotPassword';
import Register from '../Pages/Register';
import ConfirmAccount from '../Pages/ConfirmAccount';
import ResetPassword from '../Pages/ResetPassword';
import EmailsListContainer from 'eijent/internals/Inbox/EmailsListContainer';
import Home from 'eijent/internals/Home';

class EijentRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            UserData() ? (
              <Navigate to="/dashboard" />
            ) : showLogin ? (
              <Login navigate={this.props.navigate} location={this.props.location} params={this.props.params} />
            ) : (
              <Navigate to={Url.wixUrl} />
            )
          }
        />
        <Route path="/dashboard" element={<Home />} /> 
        <Route
          path="/forgotPassword"
          element={<ForgotPassword navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/register"
          element={<Register navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/user/confirm-account"
          element={<ConfirmAccount navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        /> 
        <Route
          path="/resetPassword/:token"
          element={<ResetPassword navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/all"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/pinned"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/priority"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/new-opportunity"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/at-risk-opportunity"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/my-inbox/ai-creation"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/sent"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/bookmarks"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/trash"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
        <Route
          path="/inbox/drafts"
          element={<EmailsListContainer navigate={this.props.navigate} location={this.props.location} params={this.props.params} />}
        />
      </Routes>
    );
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(EijentRoutes));
