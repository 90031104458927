import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions' 
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme'
import Box from '@mui/material/Box'
import axios from 'axios'
import 'react-responsive-modal/styles.css'
import 'react-multi-carousel/lib/styles.css' 
import 'react-quill/dist/quill.snow.css';
import { generateUrl } from 'helpers/common';

const responsive = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 12,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  mediumDesktop: {
    breakpoint: { max: 1400, min: 1024 },
    items: 11,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  smallDesktop: {
    breakpoint: { max: 1024, min: 700 },
    items: 5,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  largeTablet: {
    breakpoint: { max: 699, min: 600 },
    items: 3,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  tablet: {
    breakpoint: { max: 599, min: 450 },
    items: 6,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },

  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 4,
    partialVisibilityGutter: 8, // this is needed to tell the amount of px that should be visible.
  },
}

class AttendeeQr extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projectId: '',
      attendeeId: '',
      projectName: 'Demo Project',
      attendeeName: 'John Deo',
      attendeeEmail: 'johndeo@yopmail.com',
      attendeeAddress: 'johndeo@yopmail.com',
      attendeeMob: '+1 6788990543',
      source: ''
    }
  }

  handleRouting = (route) => {
    this.props.navigate(generateUrl(`/attendee/${route}`)); // Use navigate from props
  };

  componentDidMount(prevProps, prevState) {
    let domainUrl = window.location.href;
    const params = this.props.location;
    var eventId = new URLSearchParams(params.search).get("projectId");
    var attendee = new URLSearchParams(params.search).get("attendeeId");
     
    setTimeout(() => {
      var payload = {
        projectId: eventId,
        attendeeId: attendee
      }
      if (eventId && attendee) {
        this.setState({
          projectId: eventId,
          attendeeId: attendee
        })
        this.getAttendeeDetailForBadge(payload);
      }
    }, 0)

  } 
 
  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      value: newValue,
    })
  }

  handleChange = (e) => {
    try {
      this.setState({
        [e.target.name]: e.target.value,
      })
    } catch (error) {
      return error
    }
  }

  getAttendeeDetailForBadge = async (params) => {
    let payload = {
      projectId: this.state.projectId,
      attendeeId: this.state.attendeeId,
    }
    if (this.state.attendeeId) {
      var URL = process.env.REACT_APP_API_URL
      await axios.post(`${URL}/attendees/getAttendeeDetailForBadge`, payload, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then((dataRes) => { 
        if (dataRes.data.status === 200) {
          this.setState({
            projectName: dataRes.data.eventData?.eventName ? dataRes.data.eventData?.eventName : this.state.eventName, 
            attendeeEmail: dataRes.data.attendeeData?.email ? dataRes.data.attendeeData?.email : this.state.email, 
            source: dataRes.data.attendeeData?.source ? dataRes.data.attendeeData?.source : this.state.source,
          })

          if(dataRes.data.attendeeData?.phone){
            this.setState({attendeeMob: dataRes.data.attendeeData?.phone})
          }
          if(dataRes.data.attendeeData?.attendeeAddress){
            this.setState({attendeeAddress: dataRes.data.attendeeData?.attendeeAddress})
          }
          if(dataRes.data.attendeeData?.attendeeName){
            this.setState({attendeeName: dataRes.data.attendeeData?.attendeeName})
          } 
        }
      }) 
    } 
  }

  render() {
    const {
      value,
      loading,
      projectName,
      attendeeName,
      attendeeEmail,
      attendeeAddress,
      attendeeMob,
      source
    } = this.state
    const styles = {
      customTabList: {
        padding: '34px 0px 0',
        background: '#F9F9F9',
        borderRadius: '8px 8px 0 0',
      },
      customTab: {
        minHeight: 42,
        padding: '12px 16px',
        background: '#FFFFFF',
      },
      customTabPanel: {
        height: 'calc(100vh - 148px)',
        overflow: 'auto',
        borderTop: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)'
      },

      avatar: {
        // backgroundColor: '#FF4170',
        width: '200px',
        height: '200px',
      },
      avatarAdded: {
        backgroundColor: '#FFF',
        width: '200px',
        height: '200px',
      },
      attendeeEmail: {
        cursor: 'not-allowed:',
      },
      speaker: {
        backgroundColor: '#FF4170',
        width: '48px',
        height: '48px',
      },
    }

    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>User Details</title>
        </Helmet>
        <Box
          sx={{
            background: '#f3f3f3',
            height: '100vh',
            top: '0'
          }}
        >
          <div class="container Container-Qr">
            <h6 class="alinCenter">Attendee Detail</h6>
            <div>
              <span>Event: </span>
              <span>{projectName}</span>
            </div>
            <div>
              <span>Name: </span>
              <span>{attendeeName}</span>
            </div>

            <div>
              <span>Email: </span>
              <span>{attendeeEmail}</span>
            </div>
            <div>
              <span>Address: </span>
              <span>{attendeeAddress}</span>
            </div>
            <div>
              <span>Mobile: </span>
              <span>{attendeeMob}</span>
            </div>
            <div>
              <span>Source: </span>
              <span>{source}</span>
            </div>
          </div>
        </Box>
      </ThemeProvider>

    )
  }
}
const mapStateToProps = (state) => ({
  project: state.Projects,
  profile: state.Profile,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccessCode: Actions.getAccessCodeRequest,
      assignAccessCodeProfile: Actions.assignAccessCodeProfileRequest,
      getCustomFieldsProfile: Actions.getCustomFieldsProfileRequest,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AttendeeQr)
