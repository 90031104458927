import React from "react";
import { IconButton, Badge } from "@mui/material";
import { NotificationIcon } from "../CustomIcons";

export default function NotificationButton({ open, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      color="white"
      variant="contained"
      aria-label="open notifications"
      size="large"
      sx={{
        backgroundColor: open && '#F4F6F7',
        borderBottomLeftRadius: open ? "0px" : 8,
        borderBottomRightRadius: open ? "0px" : 8,
        transitionDuration: '200ms',
        "&::before": {
          content: '""',
          position: "absolute",
          bottom: open ? "-6px" : 0,
          left: "0",
          right: '0',
          // width: "52px",
          height: open ? "6px" : 0,
          transitionDuration: '200ms',
          backgroundColor: "#F4F6F7",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",

        },
      }}
    >
      <Badge color="error" variant="dot" sx={{ padding: '10px' }}>
        <NotificationIcon />
      </Badge>
    </IconButton>
  );
}
