import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import {
  gray,
  brand,
  colorSchemes,
  getDesignTokens,
} from '../../eijentTheme/themePrimitives';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import Logo from '../../assets/images/EIJENT_logo_still.png';

// import { SitemarkIcon } from './CustomIcons';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(4),
  height: 'auto',
  backgroundColor: colorSchemes.light.palette.primary.main,
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  [theme.breakpoints.up('md')]: {
    // width: `calc(612px - (${theme.spacing(4)} * 2))`,
    height: '200px',
    width: '612px',
  },
  [theme.breakpoints.up('lg')]: {
    // width: `calc(612px - (${theme.spacing(4)} * 2))`,
    // height: `calc(791px - (${theme.spacing(4)} * 2))`,
    width: '612px',
    minHeight: '791px',
    height: 'auto',    
  },
  ...theme.applyStyles('dark', {
    // boxShadow:
    //   'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function LoginBanner() {
  return (
    <Card>
      <img src={Logo} alt='logo' width={'320px'} maxheight={'100%'} />
    </Card>
  );
}
