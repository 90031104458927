//>>NNN@ts-nocheck
import React, { useRef, useState, useEffect, Children } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme/theme';
import '../../sass/main.scss';
import DashboardLayout from 'Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Box } from '@mui/material';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import GmailView, { Message } from './gmailView';
import Loader from 'Components/Loader/loader';
import { xpAccountIntegrationGmailGetGmailId, xpAccountIntegrationGmailGetList } from 'ReduxStore/API';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { useSelector } from 'react-redux';
import Compose from './Compose';
import 'react-quill/dist/quill.snow.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import EmailList from './EmailList';
import { createNotification } from 'helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Link } from 'react-router-dom';
import { generateUrl } from 'helpers/common';

export type GmailData = {
  success?: boolean;
  message?: string;
  data?: { _id: string, email: string, messages: Message[] }[];
  totalMailCount?: number;
}

const EmailsLayout = ({children}: {children: React.ReactNode}) => {
  // const navigate = useNavigate();
  // const [draftId, setDraftId] = useState("");
  // const [subject, setSubject] = useState('');
  // const [recipients, setRecipients] = useState('');
  // const [editorText, setEditorText] = useState('');
  // const [payloadForDraft, setPayloadForDraft] = useState({});
  // const [messageIdForDraft, setMessageIdForDraft] = useState({});
  // const [viewCompose, setViewCompose] = useState<boolean>(false);
  // const [activeTabIndex, setActiveTabIndex] = useState(0);
  // const [tempStart, setTempStart] = useState<number>(0);
  // const [gmailData, setGmailData] = useState<GmailData>({});
  // const [selectedGmailToView, setSelectedGmailToView] = useState<any>(null);
  // const [isGmailInView, setIsGmailInView] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [gmailId, setGmailId] = useState("");
  // const [selectedEmailIndex, setSelectedEmailIndex] = useState<number | null>(null);

  // const [cc, setCC] = useState<boolean>(false);
  // const [bcc, setBcc] = useState<boolean>(false);
  // // variables for pagination started
  // const [limit, setLimit] = useState<number>(10);
  // const [page_no, setPage_no] = useState<number>(1);
  // const [mailType, setMailType] = useState<'INBOX' | 'DRAFT' | 'SENT' | 'TRASH'>('INBOX');

  // useEffect(() => {
  //   getGmailDetails();
  // }, []);

  // const handleOpenCC = () => {
  //   setCC(true);
  // };
  // const handleCloseCC = () => {
  //   setCC(false);
  // };
  // const handleOpenBCC = () => {
  //   setBcc(true);
  // };
  // const handleCloseBCC = () => {
  //   setBcc(false);
  // };

  // const {
  //   message: xpUsersWebSocketMessage
  // } = useSelector(
  //   (state: any) => state.XpUsersWebSocket,
  // ) as XpUsersWebSocket_State;


  // const viewDraftMailsInCompose = (gmail: any) => {
  //   setViewCompose(true);
  //   const subjectDetail = gmail?.payload?.headers.find(detail => detail.name === 'Subject');
  //   const sendTo = gmail?.payload?.headers.find(detail => detail.name === 'To');
  //   const { payload } = gmail;
  //   setPayloadForDraft(payload);
  //   setMessageIdForDraft(gmail.id);
  //   setDraftId(gmail.draftId);
  //   setSubject(subjectDetail?.value);
  //   setRecipients(sendTo?.value);
  //   setEditorText(gmail?.snippet);
  // };

  // useEffect(() => {
  //   console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
  //   if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
  //     if (page_no === 1) {
  //       getGmailData(limit, page_no, false);
  //     }
  //   }
  // }, [xpUsersWebSocketMessage]);



  return (
    <>
      <DashboardLayout title="Emails" pageTitle="Emails">
        <ThemeProvider theme={theme}>
          <div className="dashboard-containor">
            <div className="container-with-sidebar">
              <h3 className="heading"> Emails </h3>

              <Tabs>
                <div className="project-sidebar">
                  <TabList>
                    {/* <Tab value="1" onClick={() => handleNavigation('/emails')}> */}
                    <Link to={generateUrl('/emails/compose')}>
                      <Box py={1} pl={'6px'}>
                        <EditOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Compose
                      </a>
                    </Link>
                    {/* </Tab> */}
                    {/* <Tab value="2" onClick={() => {
                      setMailType("INBOX");
                      handleNavigation('/emails');
                    }}> */}
                    <Link to={generateUrl('/emails/inbox')}>
                      <Box py={1} pl={'6px'}>
                        <InboxOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Inbox
                      </a>
                    </Link>
                    {/* </Tab> */}
                    {/* <Tab value="3" onClick={() => {
                      setMailType("DRAFT");
                      handleNavigation('/emails');
                    }}> */}
                    <Link to={generateUrl('/emails/drafts')}>
                      <Box py={1} pl={'6px'}>
                        <DraftsOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Drafts
                      </a>
                    </Link>
                    {/* </Tab> */}
                    {/* <Tab value="4" onClick={() => {
                      setMailType("SENT");
                      handleNavigation('/emails');
                    }}> */}
                    <Link to={generateUrl('/emails/sent')}>
                      <Box py={1} pl={'6px'}>
                        <SendOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Sent
                      </a>
                    </Link>
                    {/* </Tab> */}
                    {/* <Tab value="5" onClick={() => {
                      // setMailType("TRASH");
                      handleNavigation('/emails');
                    }}> */}
                    <Link to={generateUrl('/emails/trash')}>
                      <Box py={1} pl={'6px'}>
                        <DeleteOutlineOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Trash
                      </a>
                    </Link>
                    {/* </Tab> */}
                    {/* <Tab value="6" onClick={() => {
                      // setMailType("STARRED");
                      handleNavigation('/emails');
                    }}> */}
                    <Link to={generateUrl('/emails/bookmark')}>
                      <Box py={1} pl={'6px'}>
                        <DeleteOutlineOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Bookmark
                      </a>
                    </Link>
                    {/* </Tab> */}

                  </TabList>
                </div>

                <div className="container-sidebar-right">
                  {children}
                </div>

                {/* {gmailData.success ? (
                  <div className="container-sidebar-right">
                    {(!isGmailInView && !viewCompose) ? (
                      <>
                        <TabPanel value="1">
                          <Compose
                            gmailId={gmailId}
                            recipients={recipients}
                            subject={subject}
                            editorText={editorText}
                            cc={cc}
                            bcc={bcc}
                            modules={modules}
                            handleOpenCC={handleOpenCC}
                            handleCloseCC={handleCloseCC}
                            handleOpenBCC={handleOpenBCC}
                            handleCloseBCC={handleCloseBCC}
                            setRecipients={setRecipients}
                            setSubject={setSubject}
                            setEditorText={setEditorText}
                            payload={payloadForDraft}
                            setPayload={setPayloadForDraft}
                            messageIdForDraft={null}
                            draftId={null}
                          />
                        </TabPanel>
                        <TabPanel value="2">
                          <EmailList
                            // limit={limit}
                            // setLimit={setLimit}
                            // page_no={page_no}
                            // setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            // viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            // mailType={mailType}
                            // viewDraftMailsInCompose={viewDraftMailsInCompose}
                            setGmailDataAfterBookmarkingEmail={setGmailDataAfterBookmarkingEmail}
                            setGmailDataAfterUnbookmarkingMultipleEmails={setGmailDataAfterUnbookmarkingMultipleEmails}
                          />
                        </TabPanel>
                        <TabPanel value="3">
                          <EmailList
                            // limit={limit}
                            // setLimit={setLimit}
                            // page_no={page_no}
                            // setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            // viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            // mailType={mailType}
                            // viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                        <TabPanel value="4">
                          <EmailList
                            // limit={limit}
                            // setLimit={setLimit}
                            // page_no={page_no}
                            // setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            // viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            // mailType={mailType}
                            // viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                        <TabPanel value="5">
                          <EmailList
                            // limit={limit}
                            // setLimit={setLimit}
                            // page_no={page_no}
                            // setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            // viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                        <TabPanel value="6">
                          <EmailList
                            // limit={limit}
                            // setLimit={setLimit}
                            // page_no={page_no}
                            // setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            // viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                      </>
                    ) : isGmailInView ? (
                      <>
                        <GmailView
                          thread={selectedGmailToView}
                          // closeGmailContent={closeGmailContent}
                          totalCount={gmailData?.totalMailCount}
                          tempStart={tempStart}
                          // handlePreviousMail={handlePreviousMail}
                          // handleNextMail={handleNextMail}
                          gmailId={gmailId}
                          getGmailData={getGmailData}
                          modules={modules}
                          mailType={mailType}
                          setGmailDataAfterBookmarkingEmail={setGmailDataAfterBookmarkingEmail}
                          setGmailDataAfterUnBookmarkingEmail={setGmailDataAfterUnBookmarkingEmail}

                        />
                      </>
                    ) : viewCompose ? <>
                      <Compose
                        gmailId={gmailId}
                        recipients={recipients}
                        subject={subject}
                        editorText={editorText}
                        cc={cc}
                        bcc={bcc}
                        modules={modules}
                        handleOpenCC={handleOpenCC}
                        handleCloseCC={handleCloseCC}
                        handleOpenBCC={handleOpenBCC}
                        handleCloseBCC={handleCloseBCC}
                        setRecipients={setRecipients}
                        setSubject={setSubject}
                        setEditorText={setEditorText}
                        viewCompose={viewCompose}
                        handleBackFromCompose = {handleBackFromCompose}
                        payload={payloadForDraft}
                        setPayload={setPayloadForDraft}
                        messageIdForDraft={messageIdForDraft}
                        draftId={draftId}
                      />
                    </> : <></>}
                  </div>
                ) : (
                  <>{gmailData.message}</>
                )} */}
              </Tabs>
            </div>
          </div>
        </ThemeProvider>
      </DashboardLayout>
      {/* {isLoading && <Loader />} */}
    </>
  );
};

export default EmailsLayout;
