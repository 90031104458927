import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Buffer } from 'buffer'; // Import Buffer from the buffer package
import { Helmet } from 'react-helmet';
import { get, cloneDeep } from 'lodash';
import { NotificationContainer } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  updateEventTemplateById,
  createTemplate,
  createTemplateToLib,
  getTemplateLib,
  getCustomToolTemplates,
  GetToolCustomFieldsAPI,
  getEventAPI,
  getchallengesListForCustomToolAPI,
  getSessionsListForCdctAPI,
  UploadUnlayerPic, listAudienceCustomFieldsAPI
} from 'ReduxStore/API';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Loader from '../../../Components/Loader/loader';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
// import '../../../sass/main.scss';
import { createNotification, redirectTo } from '../../../helpers';
import getUnLayerConfig from './config';
import axios from 'axios';
import Grid from '@mui/material/Grid2';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import urlImg from '../../../assets/images/icons/urlWhite.svg';
import Settings from '../../../assets/images/icons/settings.svg';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { pink } from '@mui/material/colors';
import { Stack } from '@mui/material';
import { theme } from '../../../theme/theme';
import { generateUrl, getEventURL, getHostName } from '../../../helpers/common';
import { languageCode } from '../../../helpers/common';
import { accessType, permissions, FILE_SECTION, FILE_TYPE } from '../../../constants/constants';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from 'react-responsive-modal';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material';
import { UserData } from '../../../helpers/common';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
const pageAccessType = Buffer.from(accessType).toString('base64');

const URL = process.env.REACT_APP_API_URL;
const section = FILE_SECTION.images;

const styles = {
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: '40px',
    height: '40px',
  },
};

let allowRendering = true;

// HOC to provide navigate and location props
const withCustomRouter = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <WrappedComponent {...props} navigate={navigate} location={location} params={params}/>;
  };
};
class ProjectEditorPage extends Component {
  constructor(props) {
    super(props);
    this.chooseUnlayerImage = React.createRef(null);
    this.anchorRef = React.createRef();
    this.mayJsonSave = false;
    this.state = {
      showLoader: false,
      templateName: '',
      eventId: '',
      templateId: '',
      pageName: '',
      pageTitle: '',
      pageDesc: '',
      customCss: '',
      customeJs: '',
      pageUrl: '',
      eventStartDate: '',
      eventEndDate: '',
      eventData: [],
      html: ' ',
      json: {},
      formType: '',
      isUpdatePage: false,
      projectPageTid: '',
      eventDomain: '',
      toolTemplates: null,
      customFileds: null,
      challenges: null,
      sessions: null,
      open: false,
      openSaveAsMyTemplateModal: false,
      exitBackButton: false,
      pageCreatedAlready: false,
      pageHasJustMounted: true,
      fromPreview: false,
      openSettingsPage: false,
      sentFrom: '',
      fromSettings: false,
      pageHasChanged: false,
      allowed: {},
      vanityDomain: null,
      isSSOEnabled: null,
      errors: {},
      isAccessCode: false,
      customFiledsForAudience: null,
      eventTimezone: null,
      uploadFromFiles: false,
      buttonState: false,
      parentForPopup: "unlayerProjectPages",
      displayS3Image: false,
      parentId: "",
      pageEditorImage:"",
      pageEditorImageForEdit:""
    };
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  getPageData = async (templateId, eventId) => {
    try {
      const response = await axios.get(`${URL}/getPageDetails?pageId=${templateId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.page && response?.data?.page[0]?.isLoginPage) {
        this.state.formType = 'loginform';
      }
      if (this.state.isUpdatePage) {
        this.state.pageCreatedAlready = true;
      } else {
        if (
          response?.data?.pageFromSuperAdmin === false && //checking default templates
          response?.data?.page &&
          response?.data?.page[0]?.eventId !== null // checking user defined templates
        ) {
          this.state.pageCreatedAlready = true;
        }
      }
      this.setState({
        isAccessCode: response.data.isAccessCode,
        pageTitle: Array.isArray(response?.data?.page)
          ? response?.data?.page[0]?.pageTitle
          : 'test',
        pageDesc: Array.isArray(response?.data?.page)
          ? response?.data?.page[0]?.pageDesc
          : 'testDesc',
        customCss: Array.isArray(response?.data?.page)
          ? response?.data?.page[0]?.customCss
          : '//testCustomCss',
        customeJs: Array.isArray(response?.data?.page)
          ? response?.data?.page[0].customeJs
          : '//customeJs',
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  async componentDidMount() {
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    this.props.newLangStateForRedux(users.language);

    if (
      this.props.location?.state &&
      this.props.location?.state?.hasRenderedByTransactionEmail
    ) {
      this.setState({
        hasRenderedByTransactionEmail:
          this.props.location.state.hasRenderedByTransactionEmail,
      });
    }

    if (
      this.props.location?.state &&
      this.props.location?.state?.hasRenderedBySelectPageTemplate
    ) {
      this.setState({
        hasRenderedBySelectPageTemplate:
          this.props.location.state.hasRenderedBySelectPageTemplate,
      });
    } 
    const { pid, tid, pn, purl } = this.props.params;

    if (!pid || !tid || !pn || !purl) {
      console.error("Missing required route parameters.");
      return;
    }


    let eventId =  pid
    const templateId = tid;
    const pageName = pn;
    const pageUrl = purl;
    this.props.getProjectRequest(eventId);
    this.setState({
      eventId,
      pageName,
      pageUrl,
      templateId,
    });

    const updatePath = window.location.pathname.split('/');
    const updatePathTrue = updatePath.includes('update-page');
    this.setState({
      isUpdatePage: updatePathTrue,
    });
    this.setState({
      eventData: this.props.project.project
    });

    let response = await this.getPageData(templateId, eventId);
    const apiUrl = process.env.REACT_APP_API_URL;
    const params = {
      type: 'registrationform',
      eventId,
      fields: 'id,html,templateName,type,icon,toolCategory',
    };
    await getCustomToolTemplates(params).then((result) => {
      if (result?.data?.success) {
        this.state.toolTemplates = get(result, 'data.templates', []).map(
          (form) => ({
            ...form,
            html: form?.html
              ?.replace('100vh;', `auto;`)
              .replace('min-height: auto', 'min-height: auto!important'),
          }),
        );
      }
    });
    await listAudienceCustomFieldsAPI().then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFiledsForAudience = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });
    const param = { projectId: eventId };
    await GetToolCustomFieldsAPI(param).then((fieldResult) => {
      if (fieldResult?.data?.status == 200) {
        this.state.customFileds = get(fieldResult, 'data.customFields', []).map(
          (fields) => ({
            ...fields,
          }),
        );
      }
    });

    /*code for ytct start*/
    const challangeParam = { projectId: eventId };
    await getchallengesListForCustomToolAPI(challangeParam).then((result) => {
      if (result?.data?.status == 200) {
        this.state.challenges = get(result, 'data.challenges', []).map(
          (challenge) => ({
            ...challenge,
          }),
        );
      }
    });
    /*code for ytct end*/

    /*code for countdown widget start*/
    const sessionsParam = { projectId: eventId };
    await getSessionsListForCdctAPI(sessionsParam).then((result) => {
      if (result?.data?.status == 200) {
        this.state.sessions = get(result, 'data.sessions', []).map(
          (sessions) => ({
            ...sessions,
          }),
        );
      }
    });
    /*code for countdown widget start end*/

    if (response && Array.isArray(response?.data?.page)) {
      this.setState({
        html: response.data.page[0].html,
        json: response.data.page[0].json,
      });
    }

    var timerTimeTypeDynamic = [{}];
    if (this.state.isAccessCode) {
      timerTimeTypeDynamic = [
        { 'label': "Project Start Date", 'value': "PojectStartDate" },
        { 'label': "Custom Date & Time", 'value': "CustomDateTime" },
        { 'label': "Session Start Date", 'value': "AgendaStartDate" },
        { 'label': "Access Code Timer", 'value': "AccessCodeTimer" },
      ];
    } else {
      timerTimeTypeDynamic = [
        { 'label': "Project Start Date", 'value': "PojectStartDate" },
        { 'label': "Custom Date & Time", 'value': "CustomDateTime" },
        { 'label': "Session Start Date", 'value': "AgendaStartDate" },
      ];
    }



    var qrCodeTypeDynamic = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Attendees", 'value': "attendee" },
      { 'label': "Sessions", 'value': "sessions" },
      { 'label': "Custom", 'value': "custom" }
    ];

    var qrCodeSessionOption = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Title", 'value': "{{{sessionTitleQrCode}}}" },
      { 'label': "Location", 'value': "{{{sessionLocationQrCode}}}" },
      { 'label': "Description", 'value': "{{{sessionDescriptionQrCode}}}" },
      { 'label': "Tracks", 'value': "{{{sessionTracksQrCode}}}" },
    ];

    var QrCodeSizeDropdown = [
      { 'label': "100X100", 'value': "100" },
      { 'label': "200X200", 'value': "200" },
      { 'label': "300X300", 'value': "300" },
      { 'label': "400X400", 'value': "400" },
    ];

    var addToCalenderTimeType = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Project Start Date", 'value': "PojectStartDate" },
      { 'label': "Custom Date & Time", 'value': "CustomDateTime" },
      { 'label': "Session Start Date", 'value': "AgendaStartDate" },
    ];

    const formEnabled = false;
    const setState = this.setState.bind(this);
    let mayJsonSave = this.mayJsonSave;
    window.scrollTo(0, 0);
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId, this.state.toolTemplates, this.state.customFileds, this.state.customFiledsForAudience, this.state.passwordType, this.state.customCss, this.state.customeJs, this.state.eventStartDate, this.state.challenges, templateId, this.state.sessions, this.state.isAccessCode, timerTimeTypeDynamic, qrCodeTypeDynamic, qrCodeSessionOption, this.state.isSSOEnabled, QrCodeSizeDropdown, this.state.eventTimezone,
      this.state.eventData, addToCalenderTimeType),
    );

    /* callback event for images module in unlayer start 1 */
    window.unlayer.registerCallback('selectImage', (data, done) => {
      this.handleOpenFileModulePopup();
      document.body.addEventListener('click', function (event) {
        // Ensure the clicked element has the class 'driveImages'
        if (event.target.classList.contains('driveImages')) {
          const src = event.target.src; // Get the src of the clicked image          
          done({ url: src });
        }
      });
    });
    /* callback event for images module in unlayer end  1*/

    window.unlayer.addEventListener('design:updated', function (updates) {
      if (!mayJsonSave) {
        setState({ pageHasChanged: false });
        mayJsonSave = true;
      } else {
        setState({ pageHasChanged: true });
      }
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        let formType = '';
        if (updates?.item?.values?.formType != undefined) {
          formType = updates?.item?.values?.formType;
        }
        /* if a login page alreday we are setting it again login page  */
        if (
          Array.isArray(response?.data?.page) &&
          response?.data?.page[0]?.isLoginPage == true
        ) {
          formType = 'loginform';
        }
        /* if a login page alreday we are setting it again login page  */
        setState({ json, html, formType });
      });
    });

    if (templateId !== 'blank') {
      this.getPageData(templateId).then((result) => {
        if (result?.data?.success) {
          const templates = result.data.page || {};
          const oldTemplates = cloneDeep(templates);
          this.setState({
            ...templates,
            showLoader: false,
            oldTemplates,
          });
          if (Object.keys(this.state.json).length > 0) {
            window.unlayer.loadDesign(this.state.json);
          } else {
            window.unlayer.loadDesign();
          }
        }
      });
    } else {
      getTemplateLib({})
        .then(async (result) => {
          if (
            result.data &&
            result.data.templates &&
            result.data.templates.length
          ) {
            // let temp = result.data.templates.filter()
            let temp = result.data.templates;
            if (temp.length) {
              let templates = {};
              templates['json'] = temp[0].json;
              templates['html'] = temp[0].html;
              window.unlayer.loadDesign();
              this.setState({ ...templates });
            }
          }
        })
        .catch(console.log);

      if (formEnabled && templateId === 'blank') {
        let eventPageState = JSON.parse(localStorage.getItem('eventPageState'));
        this.setState(
          {
            ...eventPageState,
          },
          () => {
            window.unlayer.loadDesign(this.state.json);
            localStorage.removeItem('eventPageState');
          },
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.project.getProjectSuccess &&
      !prevProps.project.getProjectSuccess
    ) {
      //this.props.handleLang(languageCode(this.props.users.newLangState))
      //this.props.handleLngCodeRedux(languageCode(this.props.location.state.lngCode));

      this.setState({
        eventDomain: this.props.project.project.eventDomain,
        passwordType: this.props.project.project.passwordType,
        eventStartDate: this.props.project.project.startDate,
        vanityDomain: this.props.project.project.vanityDomain,
        isSSOEnabled: this.props.project.project.isSSOEnabled,
        eventTimezone: this.props.project.project.timezone,
        eventEndDate: this.props.project.project.endDate,
        eventData: this.props.project.project,
      });



      let allowed = {};
      if (
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'Edit Page Settings',
        )
      ) {
        allowed['Edit Page Settings'] = true;
      }
      this.setState({ allowed: allowed });
    }
  }

  handleCloseSaveAsMyTemplateModal = () => {
    this.setState({
      openSaveAsMyTemplateModal: false,
    });
  };

  handleOpenSaveAsMyTemplateModal = () => {
    this.setState({
      openSaveAsMyTemplateModal: true,
    });
  };

  handleTemplateNameChange = (e) => {
    this.setState({
      pageName1: e.target.value,
    });
  };

  saveLayout = () => {
    let { json, html, pageName, pageUrl } = this.state;
    const { pid, tid, pn, purl } = this.props.params;
    const templateId = tid;
    const eventId = pid;
    let type = get(this.props, 'type', '').toLowerCase();

    if (templateId !== 'blank') {
      const data = { json, html, pageName, pageUrl };
      updateEventTemplateById(templateId, data)
        .then((result) => {
          if (result.data.success) {
            createNotification('success', result.data.message);
            const newTemplate = get(result, 'data.template', {});
            const oldTemplates = cloneDeep(newTemplate);
            this.setState({
              ...newTemplate,
              oldTemplates,
            });
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    } else {
      const data = { json, html, type, pageName, pageUrl };
      createTemplate(eventId, data)
        .then((result) => {
          if (result.data.success) {
            const templateId = result.data.template._id;
            redirectTo(
              this.props.navigate,
              `event-page/eid/${eventId}/tid/${templateId}`,
              this.props.location.pathname,
            );
            createNotification('success', result.data.message);
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    }
  };

  saveConfirmation = (isActive) => {
    this.setState(
      ({ templateName = '' }) => ({ templateName: templateName.trim() }),
      () => {
        const {
          html,
          templateName,
          oldTemplates,
          defaultTemplate,
          selectedRegTemplate,
          path,
          landingPage,
          registrationPage,
          saveTempDisabled,
        } = this.state;
        const oldHtml = get(oldTemplates, 'html', '');
        const oldTemplateName = get(oldTemplates, 'templateName', '');
        const templateId = get(this.props, 'match.params.tid');
        if (
          templateName &&
          (path || landingPage) &&
          html &&
          (html !== oldHtml ||
            (templateName && templateName !== oldTemplateName) ||
            selectedRegTemplate !== oldTemplates.oldTemplates)
        ) {
          this.saveLayout(isActive);
        } else {
          const message = !templateName.trim()
            ? `Can't save template with blank name`
            : 'No changes found';
          createNotification('error', message);
        }
      },
    );
  };

  handleRegTemplate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda);
  };

  handleCheckBox = (name, value) => {
    this.setState({ [name]: value });
  };

  saveTemplateToLib = () => {
    let { json, html, templateNameLib } = this.state;
    const data = {
      json,
      html,
      templateName: templateNameLib,
      type: 'event-page',
    };
    if (html && json && templateNameLib) {
      createTemplateToLib(data)
        .then((result) => {
          if (result.data.success) {
            this.setState({ saveTempDisabled: true });
            createNotification('success', result.data.message);
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body inputField">
              <h1>Template Name</h1>
              <input
                type="text"
                className="form-control"
                name="templateNameLib"
                placeholder={'Please enter template name'}
                value={this.state.templateNameLib}
                maxLength="25"
                onChange={this.onChange}
              />
              <div className="react-confirm-alert-button-group">
                <button onClick={() => onClose()}>Cancel</button>
                <button
                  onClick={() => {
                    if (this.state.templateNameLib) {
                      this.saveTemplateToLib();
                      onClose();
                    } else {
                      createNotification(
                        'error',
                        'Please enter the template name first.',
                      );
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  savePageAndExit = async () => {
    const { pageName, pageUrl, html, json, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == 'loginform') {
      isLoginPage = true;
    }

    let isRegistrationPage = false;
    if (
      this.state.formType != '' &&
      this.state.formType == 'registrationform'
    ) {
      isRegistrationPage = true;
    }
    const body = {
      eventId,
      pageName,
      pageUrl,
      html,
      json,
      isLoginPage,
      isRegistrationPage,
      pageType: 'Project',
    };
    const users = await this.UserData();
    const response = await axios.post(`${URL}/createPage`, body, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant': `${eventId}-${permissions.createPage}`,
      },
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification('success', response.data.message);
        if (this.state.hasRenderedByTransactionEmail) {
          // window.location.href = `/project-dashboard/attendees/${eventId}`;
        } else {
          // window.location.href = `/project-dashboard/manage-pages/${eventId}`;
        }
      } else if (response.data.status == 403) {
        // window.location.href = '/my-events';
      }
    }
  };

  savePage = async (pageType, redirectToPageSettings = false) => {
    const { pageName, pageUrl, html, json, eventId, pageName1 } = this.state;
    let isLoginPage = false;
    let error = {};
    let regexForPageName = /[*|\":<>[\]{}`\\()';@&$]/;
    if (this.state.formType != '' && this.state.formType == 'loginform') {
      isLoginPage = true;
    }

    let isRegistrationPage = false;
    if (
      this.state.formType != '' &&
      this.state.formType == 'registrationform'
    ) {
      isRegistrationPage = true;
    }
    let body = {
      html,
      json,
      pageType,
      lngCode: this.props.users.newLangState,
    };

    if (pageType !== 'UserDefined') {
      body = {
        ...body,
        pageName,
        eventId,
        pageUrl,
        isLoginPage,
        isRegistrationPage,
        pageTitle: pageName,
      };
    } else {
      body = {
        ...body,
        pageTitle: pageName1.trim(),
        pageName: pageName1.trim(),
        path: pageName1.replace(/ /g, ''),
        pageTemplate: this.state.templateId
      };
    }

    if (regexForPageName.test(pageName1) === true) {
      error['blankPageName'] = 'Please enter a valid page name';
    } else {

      const users = await this.UserData();
      const response = await axios.post(`${URL}/createPage`, body, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${eventId}-${permissions.createPage}`,
        },
      });
      if (response.status === 200) {
        if (response.data.status === 200) {
          createNotification('success', response.data.message);
          if (pageType == 'UserDefined') {
            this.setState({
              openSaveAsMyTemplateModal: false,
            });
          }

          if (pageType === 'Project') {
            this.setState({
              templateId: response.data.pages._id,
              pageCreatedAlready: true,
            });

            this.props.navigate({
              pathname: generateUrl(`/project-page/update-page/settings/pid/${eventId}/tid/${response.data.pages._id}/pn/${pageName}/purl/${pageUrl}`),
              state: { lngCode: this.props.users.newLangState },
            });

            if (this.state.fromPreview) {
              window.open(
                `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${this.state.pageUrl}&pageName=${pageName}&pageTemplateId=${this.state.templateId}`,
                '_blank',
              );
              this.setState({
                fromPreview: false,
              });
            }
            if (redirectToPageSettings) {
              this.props.navigate({
                pathname: generateUrl(`/project-page/update-page/settings/pid/${eventId}/tid/${response.data.pages._id}/pn/${pageName}/purl/${pageUrl}`),
                state: { lngCode: this.props.location.state.lngCode },
              });
            }
          }
        } else if (response.data.status == 403) {
          window.location.href = '/my-events';
        } else {
          createNotification('error', response.data.message);
        }
      }
    }
    this.setState({
      errors: error
    });

    //change to false if user clicked save or save&exit (Not for save as mytemplate)
    if (pageType !== 'UserDefined') {
      this.setState({
        pageHasChanged: false,

      });
    }
  };

  updatePageAndExit = async (pageType) => {
    const { pageName, pageUrl, eventId, templateId, html, json } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == 'loginform') {
      isLoginPage = true;
    }

    let isRegistrationPage = false;
    if (
      this.state.formType != '' &&
      this.state.formType == 'registrationform'
    ) {
      isRegistrationPage = true;
    }
    const body = {
      pageName,
      pageUrl,
      html,
      json,
      isLoginPage,
      isRegistrationPage,
      eventId: eventId,
      pageType,
      lngCode: this.props.users.newLangState,
      requestCheck: "editPage",
    };
    const users = await this.UserData();
    const response = await axios.patch(
      `${URL}/updatePage/${templateId}`,
      body,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification('success', this.props.users.languageStateForRedux?.messages?.editPageSuccess);
        if (this.state.hasRenderedByTransactionEmail) {
          window.location.href = `/project-dashboard/attendees/${eventId}`;
        } else {
          window.location.href = `/project-dashboard/manage-pages/${eventId}`;
        }
      } else if (response.data.status == 403) {
        window.location.href = '/my-events';
      } else {
        createNotification('error', response.data.message);
      }
    } else if (response.status === 404) {
      createNotification('error', 'Something went wrong');
    }
  };

  updatePage = async () => {
    const { pageName, pageUrl, html, json, templateId, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == 'loginform') {
      isLoginPage = true;
    }

    let isRegistrationPage = false;
    if (
      this.state.formType != '' &&
      this.state.formType == 'registrationform'
    ) {
      isRegistrationPage = true;
    }
    const body = {
      pageName,
      pageUrl,
      html,
      json,
      isLoginPage,
      isRegistrationPage,
      eventId: eventId,
      requestCheck: "editPage",
    };
    const users = await this.UserData();
    const response = await axios.patch(
      `${URL}/updatePage/${templateId}`,
      body,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification('success', response.data.message);
        this.getPageData(this.state.templateId);
        if (this.state.fromPreview) {
          window.open(
            `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${this.state.pageUrl}&pageName=${pageName}&pageTemplateId=${templateId}`,
            '_blank',
          );
          this.setState({
            fromPreview: false,
          });
        }
        if (this.state.fromSettings) {
          this.props.navigate({
            pathname: generateUrl(`/project-page/update-page/settings/pid/${eventId}/tid/${templateId}/pn/${pageName}/purl/${pageUrl}`),
            state: { lngCode: this.props.users.newLangState },
          }

          );
          this.setState({
            fromSettings: false,
          });
        }
      } else if (response.data.status == 403) {
        window.location.href = '/my-events';
      } else {
        createNotification('error', response.data.message);
      }
    } else if (response.status === 404) {
      createNotification('error', 'Something went wrong');
    }
    this.setState({
      pageHasChanged: false,
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        open: false,
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleCloseSettingsConfirmationModal = () => {
    this.setState({
      openSettingsPage: false,
    });
  };

  openSettings = () => {
    if (this.state.pageHasChanged) {
      this.setState({
        openSettingsPage: true,
        settingsPage: true,
      });
    } else {
      const { eventId, templateId, pageName, pageUrl } = this.state;
      if (this.state.pageCreatedAlready) {
        this.props.navigate({
          pathname: generateUrl(`/project-page/update-page/settings/pid/${eventId}/tid/${templateId}/pn/${pageName}/purl/${pageUrl}`),
          state: { lngCode: this.props.users.newLangState },
        });
      } else {
        this.savePage('Project', true);
      }
    }
  };

  openPreview = () => {
    this.setState({ fromPreview: true }, () => {

      if (this.state.pageHasChanged) {
        this.setState({
          openSettingsPage: true,
          settingsPage: false,
        });
      } else {
        const { eventId, templateId, pageName, pageUrl } = this.state;
        if (this.state.pageCreatedAlready) {
          window.open(
            `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${this.state.pageUrl}&pageName=${pageName}&pageTemplateId=${templateId}&sentFrom=projectPageEditor&lngCode=${this.props.users.newLangState}`,
            '_blank',
          );
          this.setState({
            fromPreview: false,
          });
        } else {
          this.savePage('Project', true);
        }
      }
    });
  };

  handleSettingsConfirmation = (style) => {
    const { eventId, templateId, pageName, pageUrl } = this.state;
    if (style === 'saveAndGoToSettings') {
      this.setState(
        {
          fromSettings: true,
        },
        () => {
          this.state.pageCreatedAlready
            ? this.updatePage('Project')
            : this.savePage('Project', true);
        },
      );
    } else if (style === 'close') {
      this.props.navigate({
        pathname: generateUrl(`/project-page/update-page/settings/pid/${eventId}/tid/${templateId}/pn/${pageName}/purl/${pageUrl}`),
        state: { lngCode: this.props.users.newLangState },
      });
    }
  };

  handlePreviewConfirmation = (style) => {
    const { eventId, templateId, pageName, pageUrl } = this.state;
    if (style === 'saveAndGoToPreview') {
      this.setState(
        {
          openSettingsPage: false,
        },
        () => {
          this.state.pageCreatedAlready
            ? this.updatePage('Project')
            : this.savePage('Project');
        },
      );
    } else if (style === 'close') {
      window.open(
        `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${this.state.pageUrl}&pageName=${pageName}&pageTemplateId=${templateId}`,
        '_blank',
      );

      this.setState({
        fromPreview: false,
        openSettingsPage: false,
      });
    }
  };

  handleCloseBackButtonModal = () => {
    this.setState({
      exitBackButton: false,
    });
  };

  handleOpenBackButtonModal = () => {
    if (this.state.pageHasChanged) {
      this.setState({
        exitBackButton: true,
      });
    } else {
      this.handleBackButton('discard');
    }
  };

  handleBackButton = (exitStyle) => {
    if (exitStyle === 'saveAndExit') {
      this.state.pageCreatedAlready
        ? this.updatePageAndExit('Project')
        : this.savePageAndExit();
    } else if (exitStyle === 'discard') {
      this.props.navigate({
        pathname: generateUrl(`/project-dashboard/manage-pages/${this.state.eventId}`)
      }
        ,
      );
    }
  };
  /* callback event for images module in unlayer start 2 */
  handleOpenFileModulePopup = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };
  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  handleChoosedFile = () => {
    this.setState(
      {
        uploadFromFiles: false,
        openEditAttendeeModal: false,
      }
    );
  };


  chooseUnlayerImageClick = (id) => {
    this.setState({
      parentId: id
    });
    this.chooseUnlayerImage.current.click();
  };

  onUnlayerImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      if (method === 'add') {
        this.setState({ pageEditorImage: event.target.files[0] });

        let formData = new FormData();
        formData.append('imageFile', event.target.files[0]);
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', this.state.parentId);
        this.props.addImagesRequest((formData));

      } else {
        this.setState({ pageEditorImageForEdit: event.target.files[0] });
      }
    }
  };

  /* callback event for images module in unlayer end 2  */
  render() {
    const {
      showLoader,
      hasRenderedByTransactionEmail,
      pageTitle,
      pageDesc,
      customCss,
      customeJs,
      pageCreatedAlready,
      templateId,
      allowed,
    } = this.state;

    const { type, events } = this.props;
    const { pid} = this.props.params;
    const eventId = pid;
    const backTo = `/project-dashboard/${eventId}`;


    return (
      <ThemeProvider theme={theme}>
        <div className="editingPageWrapper">
          <div className="unlayer-header">
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid >
                <Grid container alignItems="center">
                  <Button
                    className="back-button"
                    variant="text"
                    onClick={this.handleOpenBackButtonModal}
                  >
                    <img alt="" src={BackArrow} />
                  </Button>
                  <Avatar style={styles.pink}>
                    <img alt="" src={PageIcon} />
                  </Avatar>
                  <h3 className="page-title">{this.state?.pageName} </h3>
                  <CustomTooltip
                    title={
                      <Box className="default-tooltip">
                        {
                          getEventURL(
                            this.state.eventDomain,
                            this.state.vanityDomain?.domainName,
                            this.state.vanityDomain?.isActive,
                            this.state.vanityDomain?.cloudFrontDistribution?.enabled,
                          ) +
                          '/' +
                          this.state.pageUrl
                        }
                      </Box>
                    }
                    placement="right"
                    arrow
                  >
                    <a className='page-url' target="_blank" href={
                      getEventURL(
                        this.state.eventDomain,
                        this.state.vanityDomain?.domainName,
                        this.state.vanityDomain?.isActive,
                        this.state.vanityDomain?.cloudFrontDistribution?.enabled,
                      ) +
                      '/' +
                      this.state.pageUrl
                    } rel="noreferrer">
                      <Stack direction={'row'}>
                        <Box component={'div'} className='long-url' maxWidth={{ md: '250px', lg: '500px' }} display={{ xs: 'none', md: 'block' }}>


                          {getEventURL(
                            this.state.eventDomain,
                            this.state.vanityDomain?.domainName,
                            this.state.vanityDomain?.isActive,
                            this.state.vanityDomain?.cloudFrontDistribution?.enabled,
                          ) +
                            '/' +
                            this.state.pageUrl}


                        </Box>

                        <span>
                          <img alt="url-img" src={urlImg} />
                        </span>

                      </Stack>
                    </a>
                  </CustomTooltip>
                </Grid>
              </Grid>
              <Grid className="buttons">
                <Stack spacing={1} direction="row">
                  {allowed['Edit Page Settings'] && (
                    <>
                      <Box display={{ xs: 'block', lg: 'none' }}>
                        <Button onClick={this.openSettings} variant="text">
                          <img alt="" src={Settings} />
                        </Button>
                      </Box>
                      <Box display={{ xs: 'none', lg: 'block' }}>
                        <Button
                          onClick={this.openSettings}
                          startIcon={<img alt="" src={Settings} />}
                          variant="text"
                        >
                          {this.props.users.languageStateForRedux?.unlayer?.settings}
                        </Button>
                      </Box>
                    </>
                  )}
                  <Button variant="outlined" onClick={() => this.openPreview()}>
                    <Box display={{ xs: 'none', lg: 'block' }}>{this.props.users.languageStateForRedux?.unlayer?.preview}</Box>
                    <Box display={{ xs: 'block', lg: 'none' }}>
                      <VisibilityOutlinedIcon />
                    </Box>
                  </Button>
                  {hasRenderedByTransactionEmail || pageCreatedAlready ? (
                    <Button variant="contained" onClick={() => this.updatePage()}>
                      <Box display={{ xs: 'none', lg: 'block' }}>{this.props.users.languageStateForRedux?.unlayer?.save}</Box>
                      <Box display={{ xs: 'block', lg: 'none' }}>
                        <SaveOutlinedIcon />
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => this.savePage('Project')}
                    >
                      <Box display={{ xs: 'none', lg: 'block' }}>{this.props.users.languageStateForRedux?.unlayer?.save}</Box>
                      <Box display={{ xs: 'block', lg: 'none' }}>
                        <SaveOutlinedIcon />
                      </Box>
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{
                      width: '130px',
                      justifyContent: 'space-between',
                      minWidth: 'max-content'
                    }}
                    disableElevation
                    ref={this.anchorRef}
                    aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {this.props.users.languageStateForRedux?.unlayer?.saveAs}
                  </Button>
                  <Popper
                    open={this.state.open}
                    sx={{ top: 10 }}
                    placement="bottom-end"
                    transition
                    anchorEl={this.anchorRef.current}
                    role={undefined}
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={250}>
                        <Box
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                            marginTop: '8px',
                          }}
                        >
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList
                              autoFocusItem={this.state.open}
                              id="menu-list-grow"
                              onKeyDown={this.handleListKeyDown}
                            >
                              {hasRenderedByTransactionEmail ||
                                pageCreatedAlready ? (
                                <MenuItem
                                  onClick={() =>
                                    this.updatePageAndExit(this.state.pageUrl == 'kiosk' ? 'Kiosk' : 'Project')
                                  }
                                >
                                  {this.props.users.languageStateForRedux?.unlayer?.saveExit}
                                </MenuItem>
                              ) : (
                                <MenuItem onClick={() => this.savePageAndExit()}>
                                  {this.props.users.languageStateForRedux?.unlayer?.saveExit}
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={this.handleOpenSaveAsMyTemplateModal}
                              >
                                {this.props.users.languageStateForRedux?.unlayer?.saveAsTemplate}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </Stack>
              </Grid>
            </Grid>
            {/* Save Drop Down */}


            {/* input for open files */}
            <input
              type="file"
              id="pageEditorImage"
              name="pageEditorImage"
              onChange={(e) => this.onUnlayerImageChange(e, 'add')}
              accept="image/*"
              ref={this.chooseUnlayerImage}
              style={{ display: "none" }}
            />
            {/* input for open files */}

            <Modal
              open={this.state.openSaveAsMyTemplateModal}
              onClose={this.handleCloseSaveAsMyTemplateModal}
              classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
              }}
              center
            >
              <Grid component="form" container className="modal-wrapper">
                <Grid size={{xs: 12,}} pb={3}>
                  <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.managePages?.saveAsMyTemp}
                  </Box>
                  <p className="small-subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.managePages?.saveTempDesc}
                  </p>
                </Grid>
                <Grid size={{xs: 12,}} pb={4}>
                  <Box component="div" className="inputField">
                    <label>
                      {this.props.users.languageStateForRedux?.formfields?.managePages?.tempName} <span className="required"></span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      placeholder=""
                      value={this.state.pageName1}
                      onChange={this.handleTemplateNameChange}
                    />
                  </Box>
                  <span className="error_mesage"> {this.state.errors['blankPageName']} </span>
                </Grid>
                <Grid size={{xs: 12,}}>
                  <Grid container justifyContent="end">
                    <Stack spacing={1} direction="row" fullWidth>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={
                          this.state.pageName1 !== '' &&
                            this.state.pageName1?.trim() !== '' &&
                            this.state.pageName1 !== undefined
                            ? false
                            : true
                        }
                        onClick={() => this.savePage('UserDefined')}
                      >
                        {this.props.users.languageStateForRedux?.common?.save}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={this.handleCloseSaveAsMyTemplateModal}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Modal>

            <Modal
              open={this.state.exitBackButton}
              onClose={this.handleCloseBackButtonModal}
              classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
              }}
              center
            >
              <Box component={Grid} container className="" px={1.5}>
                <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                  <Box component="p" mb={1} className="subtitle">
                    {' '}
                    {this.props.users.languageStateForRedux?.common?.confirmReplicate}
                  </Box>
                  <p className="small-subtitle">
                    {this.props.users.languageStateForRedux?.actions?.managePages?.exitMsg}
                  </p>
                </Box>
                <Grid size={{xs: 12,}}>
                  <Grid container justifyContent="end">
                    <Stack spacing={1} direction="row" fullWidth>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => this.handleBackButton('saveAndExit')}
                      >
                        {this.props.users.languageStateForRedux?.unlayer?.saveExit}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={() => this.handleBackButton('discard')}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.managePages?.discard}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={this.state.openSettingsPage}
              onClose={() => {
                this.setState({ openSettingsPage: false });
              }}
              classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
              }}
              center
            >
              <Grid container className="modal-wrapper">
                <Grid size={{xs: 12,}} pb={4}>
                  <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.common?.confirmReplicate}
                  </Box>
                  <p className="small-subtitle">
                    {this.props.users.languageStateForRedux?.actions?.managePages?.saveMsg}{' '}
                    {this.state.settingsPage ? 'settings' : 'preview page'}?
                  </p>
                </Grid>
                <Grid size={{xs: 12,}}>
                  <Grid container justifyContent="end">
                    <Stack spacing={1} direction="row" fullWidth>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() =>
                          this.state.settingsPage
                            ? this.handleSettingsConfirmation(
                              'saveAndGoToSettings',
                            )
                            : this.handlePreviewConfirmation('saveAndGoToPreview')
                        }
                      >
                        {this.props.users.languageStateForRedux?.buttons?.managePages?.saveChange}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={() =>
                          this.state.settingsPage
                            ? this.handleSettingsConfirmation('close')
                            : this.handlePreviewConfirmation('close')
                        }
                      >
                        {this.props.users.languageStateForRedux?.buttons?.managePages?.discard}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Modal>
          </div>
          { /* callback event for images module in unlayer start -3 */}
          <UploadFromFilesPopUp
            uploadFromFiles={this.state.uploadFromFiles}
            chooseUnlayerImageClick={this.chooseUnlayerImageClick}
            chooseUnlayerImage={this.chooseUnlayerImage}
            handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
            onUnlayerImageChange={this.onUnlayerImageChange}
            handleChoosedFile={this.handleChoosedFile}
            parentForPopup={this.state.parentForPopup}
            buttonState={this.state.buttonState}
          />

          { /* callback event for images module in unlayer end -3 */}
          <Helmet>
            <title>IllumeetXP | {pageTitle ? pageTitle : ''}</title>
            <meta name="description" content={pageDesc ? pageDesc : ''} />
            <style>{customCss ? customCss : ''}</style>
            <script>{customeJs ? customeJs : ''}</script>
          </Helmet>
          {showLoader && <Loader />}
          {
            <div className="editingDashboard">
              <div id="pageEditor" style={{ height: 'calc(100vh - 60px)' }}>
                {''}
              </div>
            </div>
          }
          <NotificationContainer />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.Events,
    project: state.Projects,
    users: state.Users,
  };
};

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
      getProjectRequest: Actions.getProjectRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(ProjectEditorPage)); 