//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Modal from 'react-responsive-modal';
import CloseIcon from '@mui/icons-material/Close';
import { Contact } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import { CustomField, CustomFields } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { generateUrl, separateByComma } from 'helpers/common';
import { Account, ContactCf, Project } from 'ReduxStore/Reducers/opportunity/accounts.reducer';
import Loader from 'Components/Loader/loader';
import { getSystemLevelPermissions,rgbToHex2 } from '../../../helpers/common';
import moment from 'moment';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { createNotification } from 'helpers'; 
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  OpportunityStates as OpportunityDynamicStates, 
  OpportunityStates_State 
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer'; 
type Props = {
  open: boolean;
  handleClose: () => void;
  details: Contact | Account;
  customFields: CustomFields;
  usedFor: 'account' | 'contact';
  title: string;
};

function ContactDetailsSideModal({
  open,
  handleClose,
  details,
  customFields,
  usedFor,
  title,
}: Props) {
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState<any>({});

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
  }, []);

  const { opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const handleEdit = () => {
    let transformedCustomFields = {};
    if (Object.keys(details?.customFields).length) {
      transformedCustomFields = Object.fromEntries(
        Object.entries(details?.customFields).map(([key, value]) => {
          if (typeof value === 'object' && !Array.isArray(value)) {
            return [key, value._id];
          }
          return [key, value];
        }),
      );
    }
    const path = usedFor === 'contact' ? 'contacts' : 'accounts';
    const state: {
      comingFromOtherTab: boolean;
      contact?: Contact;
      account?: Account;
    } = {
      comingFromOtherTab: true,
    };
    const detailsCopy = { ...details };
    detailsCopy.customFields = transformedCustomFields;
    if (usedFor === 'contact' && detailsCopy?.uniqueEijentId) {
      state.contact = detailsCopy as Contact;
    } else if (usedFor === 'account' && detailsCopy?.uniqueEijentId) {
      state.account = detailsCopy as Account;
    } else {
      createNotification('error', "uniqueEijentId not present");
      // If neither contact nor account has uniqueEijentId, do nothing or show an error message
      return; // Exit the function without pushing to history
    }
    // Push to the appropriate path if conditions are met
    navigate(generateUrl(`/opportunity/${path}`), { state });
  };

  const customFieldValue = (cf: CustomField) => {
    const value = details?.customFields?.[cf._id as any];
    if (cf.fieldData.fieldType === 'contact') {
      const contactField = (details as Account)?.customFields?.[cf._id as any] as ContactCf;
      return contactField?.fullName || '';
    }
    if (cf.fieldData.fieldType === 'account') {
      return (details as Account)?.customFields?.[cf._id as any]?.name as any;
    }
    if (cf.fieldData.fieldType === 'boolean') {
      return value === true ? 'True' : 'False';
    }
    if(cf.fieldData.fieldType === 'list') {
      if (Array.isArray(value) && value.length) {
        return (
          <>
            <span
              className={`${cf.fieldData.fieldType === 'list'}`}
              style={{ maxWidth: '80%' }}
            >
              {value[0]}
            </span>
            {value.length > 1 && (
              <Box ml={1} className="detail-popup">
                <span className="count">+{value.length - 1}</span>
                <Box sx={{ right: '0 !important' }} className="info">
                  {value.slice(1).map((cfValue: string, i: number) => (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                    >
                      <p style={{ maxWidth: '200px' }}>{cfValue}</p>
                    </div>
                  ))}
                </Box>
              </Box>
            )}
          </>
        );
      }
    }

    if (Array.isArray(value) && value.length) {
      return (
        <>
          <span
            className={`${cf.fieldData.fieldType === 'user' && 'link'}`}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user') {
                window.open(`mailto:${value[0].email}`);
              }
            }}
            style={{ maxWidth: '80%' }}
          >
            {value[0].email}
          </span>
          {value.length > 1 && (
            <Box ml={1} className="detail-popup">
              <span className="count">+{value.length - 1}</span>
              <Box sx={{ right: '0 !important' }} className="info">
                {value.slice(1).map((cfValue: string, i: number) => (
                  <div
                    key={i}
                    className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                    onClick={() => {
                      if (cf.fieldData.fieldType === 'user' && typeof cfValue === 'object') {
                        window.open(`mailto:${cfValue.email}`);
                      }
                    }}
                  >
                    <p style={{ maxWidth: '200px' }}>{cfValue.email}</p>
                  </div>
                ))}
              </Box>
            </Box>
          )}
        </>
      );
    }
    if (typeof value === 'string') {
      if (cf.fieldData.fieldType === 'date') {
        if (value){
        return moment(new Date(value)).format('YYYY-MM-DD');
        }
      }
      return (
        <span
          onClick={() => {
            if (cf.fieldData.fieldType === 'email') {
              window.open(`mailto:${value}`);
            }
            if (['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
              let url = value;
              if (!/^https?:\/\//i.test(url)) {
                url = 'https://' + url;
              }
              window.open(url, '_blank');
            }
            if (cf.fieldData.fieldType === 'phone') {
              window.open(`tel:${value}`);
            }
          }}
          className={`${['email', 'url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) && 'link'}`}
        >
          {value}
        </span>
      );
    }
    return null;
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  const handleExpand = (uniqueEijentId: string | number) => {
    if (uniqueEijentId) {
      if (usedFor === "contact") {
        navigate(`/xp/opportunity/contactdetail/${uniqueEijentId}`);
      } else {
        navigate(`/xp/opportunity/accountview/${uniqueEijentId}`);
      }
    }
  };

  const getStateColor = (contact: Contact | undefined) => {
    // Ensure opportunity and state exist and have at least one element
    if (contact?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === contact?.state[0].name
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: rgbToHex2(defaultState.backgroundColor) || "#ffffff00", // Default background color
        };
      }
    }

    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      classNames={{
        overlay: 'full-screen_overlay',
        modal: 'full-screen_modal manage-custom-fields modal-default-close-none',
      }}
      center
    >
      {Object.keys(details).length > 0 ? (
        <Grid container justifyContent="end" width={"100%"}>
          <Grid size={{xs: 12, md:6, lg:5, xl:4}}  className="full-screen_modal__form">
            <Box component="div" className="full-screen_modal__form_header">
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid >
                  <Box className="subtitle">{title}</Box>
                </Grid>
                <Grid >
                  <Grid container spacing={1}>
                    <Grid >
                      {(usedFor === 'contact' ? allowed['editContacts'] : allowed['editAccount']) && (
                        <Button variant="contained" color="primary" disableElevation onClick={handleEdit}>
                          Edit
                        </Button>
                      )}
                    </Grid>
                    <Grid >
                      <Tooltip title="Expand View" placement='bottom-end' arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={() => handleExpand((details as Account)?.uniqueEijentId || (details as Contact)?.uniqueEijentId)}
                          style={{minWidth: '36px', padding: '5px'}}
                        >
                          <FullscreenIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Box component={Grid} ml={1}>
                      <CloseIcon onClick={handleClose} className="modal-close-button" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box component="div" py={1} className="full-screen_modal__form_body">
              <Box px={3} pt={2} pb={4}>
                <Box component={Grid} rowSpacing={2} columnSpacing={2} container>
                  {usedFor === 'contact' && (
                    <>
                     <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Eijent Id:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Contact)?.uniqueEijentId ? (details as Contact)?.uniqueEijentId:""}
                        </Box>
                      </Grid>

                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">First Name:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Contact)?.firstName}
                        </Box>
                      </Grid>

                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Middle Name:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Contact)?.middleName || '-'}
                        </Box>
                      </Grid>

                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">last Name:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Contact)?.lastName || '-'}
                        </Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Email:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className={`small-subtitle primary-text link`} display={'flex'} justifyContent={'end'}
                          onClick={() => {
                            (details as Contact)?.email && window.open(`mailto:${(details as Contact)?.email}`);
                          }}
                        >
                          {(details as Contact)?.email || '-'}
                        </Box>
                      </Grid>

                           {/* <Grid container> */}
                        <Grid size={{ xs: 12, }}>
                          <Box className="small-subtitle bold primary-text">
                            State:
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, }}>
                          {details?.state?.length > 0 ? (
                            <Box className="small-subtitle primary-text bold"
                            sx={{
                              color: `${getStateColor(details).textColor} !important`,
                              bgcolor: `${getStateColor(details).backgroundColor}`,
                            }}
                            >
                              {(details && details.state && Array.isArray(details.state)) ? details.state[0]?.name || "" : ''}
                            </Box>
                          ) : (
                            '-'
                          )}
                        </Grid>
                      {/* </Grid> */}
                     
                    </>
                  )}

                  {usedFor === 'account' && (
                    <>
                     <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Eijent Id:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Account)?.uniqueEijentId ? (details as Account)?.uniqueEijentId:""}
                        </Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Name:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Account)?.name || '-'}
                        </Box>
                      </Grid>

                       <Grid size={{ xs: 6, }}>
                          <Box  className="small-subtitle primary-text bold" >
                            State:
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 6, }}>
                          {details?.state?.length > 0 ? (
                            <Box className="small-subtitle primary-text " display={'flex'} justifyContent={'end'}
                            sx={{
                              color: `${getStateColor(details).textColor} !important`,
                              bgcolor: `${getStateColor(details).backgroundColor}`,
                            }}
                            >
                              {(details && details.state && Array.isArray(details.state)) ? details.state[0]?.name || "" : ''}
                            </Box>
                          ) : (
                            '-'
                          )}
                        </Grid>

                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Source:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          {(details as Account)?.source === 'aiAgent' ? 'AI Agent' : details?.source === 'imported' ? 'Imported' : 'Manually Added'}
                        </Box>
                      </Grid>

                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text bold">Projects:</Box>
                      </Grid>
                      <Grid size={{xs: 6,}}>
                        <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                          <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <span className="long-text" style={{ maxWidth: '80%' }}>
                              {(details as Account)?.projectIds?.length > 0 ? (details as Account)?.projectIds[0]?.eventName : ''}
                            </span>
                            {(details as Account)?.projectIds?.length > 1 && (
                              <div className="detail-popup">
                                <span className="count">+{(details as Account)?.projectIds.length - 1}</span>
                                <div className="info">
                                  {showRestOfProjects((details as Account)?.projectIds).map((project: Project, i: number) => (
                                    <div key={i}>
                                      <p>{project.eventName}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {usedFor === 'account' &&
                    Array.isArray(customFields) &&
                    customFields.length > 0 &&
                    customFields.map((cf) => (
                      <>
                        <Grid size={{xs: 6,}}>
                          <Box className="small-subtitle primary-text bold">{cf.fieldData.fieldName}:</Box>
                        </Grid>
                        <Grid size={{xs: 6,}}>
                          <Box className="small-subtitle primary-text" display={'flex'} justifyContent={'end'}>
                            {customFieldValue(cf)}
                          </Box>
                        </Grid>
                      </>
                    ))}

                  {usedFor === 'contact' &&
                    Array.isArray(customFields) &&
                    customFields.length > 0 &&
                    customFields.map((cf) => (
                      <>
                        <Grid size={{xs: cf.fieldData.fieldType === 'longText' ? 12 : 6,}} >
                          <Box className="small-subtitle primary-text bold">{cf.fieldData.fieldName}:</Box>
                        </Grid>
                        <Grid size={{xs: cf.fieldData.fieldType === 'longText' ? 12 : 6,}}>
                          <Box className="small-subtitle primary-text" textAlign={'right'} display={'flex'} justifyContent={'end'}>
                            {customFieldValue(cf)}
                          </Box>
                        </Grid>
                      </>
                    ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}

export default ContactDetailsSideModal;
